import React, { useEffect, useState } from "react"
import ProgramCard from '../../assets/icons/programCards'
import { useCentralStore } from "../../store/appContext"
import { toPascalCase } from "../../utils/functionUtils"
import { isEmpty } from "../../utils/functionUtils"
import { useNavigate } from "react-router-dom";
import ActionTypes from "../../store/actionTypes";
import API from "../../api";
import moment from "moment";

const ProgramCards = ({ actionTypes, dispatch, clientName, clientObject, programData, resources, userData, userDetails }) => {

    const [ {clientList, allDashboardsList, uniqueClientsList, mapSelected, selectedTab, reportFilterDuration, projectName} ] = useCentralStore()
    
    let clientId = clientList?.filter(c => toPascalCase(c?.name?.toLowerCase()?.replaceAll("_", " ")) === toPascalCase(clientName?.toLowerCase()?.replaceAll("_", " ")))
    let clientNameList = []
    clientNameList.push(clientName)
    const HNDP = uniqueClientsList?.[2]?.Channels?.[1]?.AllDashboards

    useEffect(() => {
      if(!isEmpty(uniqueClientsList) && !isEmpty(clientId)){
        let allDashboardsArray = []
        // uniqueClientsList?.filter(v => v?.ClientId == clientId?.[0]?.value)[0]?.Channels?.map(v => v?.AllDashboards?.map(a => allDashboardsArray.push(a)))
        // let allDashboards = allDashboardsArray?.filter((v,i,a)=>a.findIndex(v2=>(v2.Label===v.Label))===i)  
        uniqueClientsList?.map(c => allDashboardsArray.push(c?.Channels?.[0]?.AllDashboards))
        if (HNDP) {
          allDashboardsArray?.push(...HNDP);
        }
        let allDashboards = allDashboardsArray.flat()?.filter((v,i,a)=>a.findIndex(v2=>(v2.Label===v.Label))===i)
        dispatch({ type: actionTypes.SET_ALL_DASHBOARDS, payload: allDashboards })
      }
    }, [uniqueClientsList])
    
    const navigate = useNavigate();
    const setLoading = (action) => {
      dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
    };
  
    const [isOpen, openPopup] = useState(false);
    const [classInOut, setClassInOut] = useState(null);
  

    const gotoDashBoard = (name) => {
      dispatch({ type: actionTypes.GO_TO_MAPS_PAGE, payload: false });
      dispatch({ type: actionTypes.SET_PROGRAM_NAME, data: name });
      dispatch({ type: actionTypes.SET_SELECTED_FAV_DATA, data: undefined })
      if (name === 'Smart Stock') {
          let startDate = moment(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD 00:00:00')
          let endDate = moment(new Date()).format('YYYY-MM-DD 23:59:59')

          let payload = {
            StartDate: startDate,
            EndDate: endDate,
          };
          // setLoading(true)
          API[ActionTypes.GET_SS_REPORT_COUNT](
            payload,
            userData?.access_token,
            userDetails && userDetails?.email?.split("@")?.[0]
          )
            .then((data) => {
              // setLoading(false);
              navigate('/smartstock')
                openPopup(true);
                setClassInOut("modal-container modal-space-in");
                dispatch({
                  type: ActionTypes.GET_SS_REPORT_COUNT_SUCCESS,
                  payload: data,
              });
            })
            .catch((err) => {
              openPopup(false);
              dispatch({
                type: ActionTypes.GET_SS_REPORT_COUNT_FAILURE,
                payload: err,
              });
              dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
              let _payload = {
                level: resources.POST_LOGGER_ERROR,
                category: resources.POST_LOGGER_API,
                ApiName: resources.POST_LOGGER_APINAME,
                message: {
                  error: err,
                  info: {
                    idToken: "",
                  },
                },
              };
              API[ActionTypes.POST_LOGGER](
                _payload,
                userData?.access_token,
                userDetails && userDetails?.email?.split("@")?.[0]
              );
            });
        } else {
        if(mapSelected?.toLowerCase() === 'singapore'){
          if(name?.toLowerCase() === 'device protection'){
            let clientDashboardLabel = client_name?.toUpperCase() === 'SINGTEL' ? 'Singtel BPM Performance' :
            client_name?.toUpperCase() === 'STARHUB' ? 'StarHub BPM Performance' :
            client_name?.toUpperCase() === 'M1' ? 'M1 BPM Performance' : undefined
            let dashBoard = allDashboardsList?.filter(v => v?.Label === clientDashboardLabel)
            dispatch({ type: actionTypes.SET_SELECTED_TAB, payload: dashBoard?.[0]?.Label });
          }else if(name?.toLowerCase() === 'home plus'){
            let dashBoard = allDashboardsList?.filter(v => v?.Label === 'Home Plus BPM Performance')
            dispatch({ type: actionTypes.SET_SELECTED_TAB, payload: dashBoard?.[0]?.Label });
          }
        }else if(mapSelected?.toLowerCase() === 'thailand') {
          if(name?.toLowerCase() === 'device protection'){
            let clientDashboardLabel = client_name?.toUpperCase() === 'AIS' ? 'AIS BPM Performance' :
            client_name?.toUpperCase() === 'TRUE' ? 'TRUE BPM Performance' : 'Asurion_TechCare BPM Performance'
            let dashBoard = allDashboardsList?.filter(v => v?.Label === clientDashboardLabel)
            dispatch({ type: actionTypes.SET_SELECTED_TAB, payload: dashBoard?.[0]?.Label });
          }
        } else if(mapSelected?.toLowerCase() === 'taiwan') {
          if(name?.toLowerCase() === 'device protection'){
            let clientDashboardLabel = client_name?.toUpperCase() === 'FET' ? 'FET BPM Performance' : undefined
            let dashBoard = allDashboardsList?.filter(v => v?.Label === clientDashboardLabel)
            dispatch({ type: actionTypes.SET_SELECTED_TAB, payload: dashBoard?.[0]?.Label });
          }
        } else if(mapSelected?.toLowerCase() === 'australia') {
          if(name?.toLowerCase() === 'device protection'){
            let clientDashboardLabel = client_name?.toUpperCase() === 'HARVEY_NORMAN' ? 'Harvey_Norman BPM Performance' : 
            client_name?.toUpperCase() === 'SAMSUNG' ? 'Samsung BPM Performance' : 
            client_name?.toUpperCase() === 'TELSTRA' ? 'Telstra BPM Performance' : 
            client_name?.toUpperCase() === 'JBHIFI' ? 'JBHIFI BPM Performance' :
            "Trade-In BPM Performance"
            let dashBoard = allDashboardsList?.filter(v => v?.Label === clientDashboardLabel)
            dispatch({ type: actionTypes.SET_SELECTED_TAB, payload: dashBoard?.[0]?.Label })
            dispatch({ type: actionTypes.SET_SELECTED_DASHBOARD, payload: dashBoard?.[0]?.DashboardId })
          } else if(name?.toLowerCase() === 'trade-in'){
            let dashBoard = allDashboardsList?.filter(v => v?.Label === 'Trade-In BPM Performance')
            dispatch({ type: actionTypes.SET_SELECTED_TAB, payload: dashBoard?.[0]?.Label });
            dispatch({ type: actionTypes.SET_SELECTED_DASHBOARD, payload: dashBoard?.[0]?.DashboardId })
          }
        } 
        else {
          dispatch({ type: actionTypes.SET_SELECTED_TAB, payload: allDashboardsList?.[0]?.Label });
        }
        }
        dispatch({ type: actionTypes.SELECTED_CLIENT, payload: clientId?.[0]?.value });
        dispatch({ type: actionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS, payload: false })
    }
    const [client_name, setClientName] = useState(undefined)
    const enablePrograms = mapSelected?.toLowerCase() === 'singapore' || (mapSelected?.toLowerCase() === "malaysia" && client_name?.toLowerCase() === 'celcom') || mapSelected?.toLowerCase() == 'thailand' || (mapSelected?.toLowerCase() === "taiwan" && client_name?.toLowerCase() === 'fet') || (mapSelected?.toLowerCase() === "australia" && client_name?.toLowerCase() === "harvey_norman") || (mapSelected?.toLowerCase() === "australia" && client_name?.toLowerCase() === "telstra") || (mapSelected?.toLowerCase() === "australia" && client_name?.toLowerCase() === "samsung") || (mapSelected?.toLowerCase() === "australia" && client_name?.toLowerCase() === "jbhifi")

    return(
        <>
        {programData?.map(prg =>
          <div onMouseOver={() => setClientName(clientName)}
            onMouseOut={()=> setClientName(undefined)}
            className={`relative my-5 ${enablePrograms ? 'cursor-pointer transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125' : prg?.Program === 'Device Protection' ? 'cursor-not-allowed' : 'cursor-pointer transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125'}`} 
            onClick={() => enablePrograms ? gotoDashBoard(prg?.Program) : prg?.Program === 'Device Protection' ? undefined : gotoDashBoard(prg?.Program)}>
            <p
              className={`absolute text-xs text-center`}
              style={{
                top: "50%",
                right: "50%",
                transform: "translate(50%, -50%)",
                width: 'max-content'
              }}
            >
              {mapSelected?.toLowerCase() === "australia" ? 
                (prg?.Program)
                : (prg?.Program?.toLowerCase() === "device protection" ? "Device Care" : prg?.Program) 
              }
            </p>
            <ProgramCard className="ml-1" fill={prg?.Color?.toLowerCase() === 'green' ? '#37E7A7' : prg?.Color?.toLowerCase() === 'red' ? '#DB1B1B' : prg?.Color?.toLowerCase() === 'amber' ? '#EDB800' : '#37E7A7'} />
          </div>
        )}
          {/* <div className="relative my-5 cursor-pointer" onClick={() => gotoDashBoard('Device Protection')}>
            <p
              className={`absolute text-xs text-center`}
              style={{
                top: "50%",
                right: "50%",
                transform: "translate(50%, -50%)",
              }}
            >
              Device Protection
            </p>
            <ProgramCard className="" fill="#37E7A7"/>
        </div> */}
        {/* <div className="relative my-5 cursor-pointer" onClick={() => gotoDashBoard('Trade-In')}>
          <p
            className={`absolute text-xs text-center`}
            style={{
              top: "50%",
              right: "50%",
              transform: "translate(50%, -50%)",
            }}
          >
            Trade-In
          </p>
          <ProgramCard className="" 
          fill="#37E7A7" /> */}
          {/* fill="#F9D51C" /> */}
        {/* </div> */}
        </>
    )
}

export default ProgramCards