import { generateCSV, saveExcel } from "./export-to-csv-native";
import moment from "moment";

export default function exportToCSVInventory(tableColumns,rawData, callback, location, label, dispatch, ActionTypes, ClientName) {
  const now = moment().format("MMDDYYYY");
  let columnHeders = []
  tableColumns?.map(v => columnHeders.push({
    header: v.key, key: v.key
  }))

  if(columnHeders?.length === 0){
    const totalKeys = {};
    rawData.reduce((acc, current) => {
      const keys = Object.keys(current);
      for (var i in keys) {
        totalKeys[keys[i]] = 1;
      }
    }, totalKeys);

    var keys = Object.keys(totalKeys);
    for (var i in keys) {
      columnHeders.push({
        header: keys[i], key: keys[i]
      })
    }
  }

  let csvData = rawData?.map(data => data)

  if (!csvData.length) return false;

  saveExcel({
    workBookName: `${ClientName}_export_${now}`,
    data: csvData,
    workSheetName: label,
    columns: columnHeders,
    dispatch: dispatch,
    ActionTypes: ActionTypes
  })

}
