import { useEffect } from "react";
import { useState } from "react";
import { ReactComponent as DownArrow } from "../../../assets/icons/down-arrow.svg";

const NewSelector = ({
    label,
    value: selectedValue,
    options = [],
    onSelect,
    placeholder
  }) => {
    const [showOptions, setShowOptions] = useState(false);
    const [selectedClient, setSelectedClient] = useState("");
  
    const handleShowOptions = (e) => {
      e.stopPropagation();
      setShowOptions(true);
    };
  
    const handleOptionSelection = (e, value) => {
      e.stopPropagation();
      if (onSelect) {
        onSelect(e?.target?.innerText?.replace(" ", "_")?.toUpperCase(), value);
      }
      setSelectedClient(e?.target?.innerText); // Update selected client
      setShowOptions(false);
    };
  
    useEffect(() => {
      const handleClick = () => {
        setShowOptions(false);
      };
  
      document.addEventListener("click", handleClick);
  
      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, []);
  
    return (
      <div className="relative mr-4 w-fit">
        <div className={`flex px-3 py-2 border border-app-border rounded-lg  last:mr-0 bg-black`}>
          <div className="pr-4 text-app-label">{label}</div>
          <div className="flex cursor-pointer" onClick={handleShowOptions}>
            <div className="pr-2 font-bold bg-transparent outline-none text-white">
                {selectedClient || placeholder}
            </div>
            <DownArrow />
          </div>
        </div>
        {showOptions && (
          <div className="absolute right-0 z-10 w-full rounded-xl">
            <Option
              key={`key-${placeholder}`}
              isSelected={selectedValue === ""}
              value=""
              disabled
              name={placeholder}
            />
            {options.map(({ name, value }) => (
              <Option
                key={`key-${value}`}
                isSelected={selectedValue === value}
                value={value}
                name={name}
                onSelect={handleOptionSelection}
              />
            ))}
          </div>
        )}
      </div>
    );
  };
  
  const Option = ({ name, value, isSelected, onSelect, disabled }) => (
    <div
      className={`py-2 overflow-y-auto px-4 first:rounded-t-md last:rounded-b-md cursor-pointer w-full border-b last:border-b-0 text-black border-gray-400 hover:bg-blue-200 ${
        isSelected ? "bg-blue-300 font-bold" : "bg-gray-300"
      } ${disabled ? "bg-gray-300 text-gray-400 cursor-not-allowed" : ""}`}
      onClick={disabled ? undefined : (e) => onSelect(e, value)}
    >
      {name}
    </div>
  );
    
  export default NewSelector;
  