import config from "./config";

export const OidcMetadata = {
  issuer: config.SSO.provider,
  jwks_uri: config.SSO.provider + "/pf/JWKS",
  authorization_endpoint: config.SSO.provider + "/as/authorization.oauth2",
  token_endpoint: config.SSO.provider + "/as/token.oauth2",
  userinfo_endpoint: config.SSO.provider + "/idp/userinfo.openid",
  end_session_endpoint: config.SSO.provider + "/idp/startSLO.ping",
  revocation_endpoint: config.SSO.provider + "/as/revoke_token.oauth2",
  introspection_endpoint: config.SSO.provider + "/as/introspect.oauth2",
};

export const SsoConfig = {
  authority: config.SSO.provider,
  client_id: config.SSO.clientId,
  redirect_uri: config.SSO.redirectSignIn,
  login: config.SSO.redirectSignOut,
  post_logout_redirect_uri: config.SSO.redirectSignOut,
  automaticSilentRenew: false,
  loadUserInfo: true,
  filterProtocolClaims: false,
  autoSignIn: false,
  response_type: "code",
  scope: "openid profile email",
  metadata: {
    ...OidcMetadata,
  },
};
