import React, { useEffect, useRef, useState } from "react";
import { Table  } from "antd";
import { getColumnSearchProps } from "./AntTableSearchFilter";
import CrossIcon from "../../../assets/icons/cross-icon";
import API from "../../../api";
import moment from "moment";
import ActionTypes from "../../../store/actionTypes";
import { useCentralStore } from "../../../store/appContext";
import Button from "../button/button";
import exportToCSVInventory from "../hepler/export-to-csv-submatricsData";
import Popup from "../popup/popup";

const SGPDataTable = ({
  label,
  value,
  onClose,
  reportDetailsSuccess,
  MetricsLabel,
  resources
}) => {
  const inputRef = useRef(null);
  const resetRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [column, setColumn] = useState("");
  const [
    {
      exportSubmatricsData,
      projectData,
      nintyDaysData,
      selectedStore,
      selectedClient,
      clientData,
      userData,
      userDetails,
    },
    dispatch,
  ] = useCentralStore();
  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
  };
  let tableColumns = [];
  const totalKeys = {};

  reportDetailsSuccess?.reduce((acc, current) => {
    const keys = Object.keys(current);
    for (var i in keys) {
      totalKeys[keys[i]] = 1;
    }
  }, totalKeys);

  var keys = Object.keys(totalKeys);
  for (var i in keys) {
    tableColumns.push({
      title: keys[i]?.replace(/([a-z0-9])([A-Z])/g, "$1 $2")?.toUpperCase(),
      dataIndex: keys[i],
      key: keys[i],
      width: 280
    });
  }

  const getPartsColumns = () => {
    let headerColumns = [];
    tableColumns.forEach((c, i) => {
      if (i < 5) {
        headerColumns.push({
          ...c,
          ...getColumnSearchProps(
            c.key,
            inputRef,
            resetRef,
            searchText,
            setSearchText,
            column,
            setColumn
          ),
        });
      } else {
        headerColumns.push({
          ...c,
        });
      }
    });
    return headerColumns;
  };

  const reportDetailsSuccessData = function (reportDetailsSuccess) {
      return reportDetailsSuccess?.map((obj) => {
        let dateObj = {};
        Object.entries(obj)?.map(
          ([key, value]) =>
            (dateObj[key] = key?.toUpperCase()?.includes("_DATE")
              ? value?.length !== 0
                ? (MetricsLabel?.toLowerCase() === 'device registration' || MetricsLabel?.toLowerCase() === 'enrolment') ? moment(value).format("DD-MM-YYYY") : moment(value).format("DD-MM-YYYY HH:mm:ss")
                : null
              : value)
        );
        return dateObj;
      });
  };

  const [currentSearchedData, setCurrentSearchedData] = useState(undefined);

  const handleTableChange = (currentPagination, filters, sorter, extra) => {
    let isFilter = false;
    for (let objKey in filters) {
      setCurrentSearchedData(extra?.currentDataSource);
    }
    if (isFilter === false) {
      setCurrentSearchedData(undefined);
    }
  };


  return (
    <div className="mx-auto mt-6 py-3">
      <Table
        columns={getPartsColumns()}
        dataSource={currentSearchedData || reportDetailsSuccessData(reportDetailsSuccess)}
        onChange={handleTableChange}
        style={{ fontSize: "10px !important" }}
        rowKey="data"
        key="data"
        className="table-css border-current border-white mb-10"
        locale={{ emptyText: "No data" }}
        pagination={false}
      />
    </div>
  );
};

export default SGPDataTable;
