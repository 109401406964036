import React, { useEffect, useState } from "react";
import API from "../../../api";
import moment from "moment";
import Button from "../button/button";
import ActionTypes from "../../../store/actionTypes";
import { useCentralStore } from "../../../store/appContext";
import exportToCSVInventory from "../hepler/export-to-csv-submatricsData";
import resources from "../resources.json";
import { isEmpty } from "../../../utils/functionUtils";
import "./table.css"
import { perfromanceExportColumns } from "./constants"

const AIReportSummaryTable = ({
  reportDetailsSuccess
}) => {
  const [
    {
      userData,
      userDetails,
      GetAISummaryReportSuccess,
      selectedAIReportProgramType,
      selectedAIReportClient,
      selectedAIReportYear
    },
    dispatch,
  ] = useCentralStore();
  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
  };

   
  let tableColumns = [];
  const totalKeys = {};

  reportDetailsSuccess?.reduce((acc, current) => {
    const keys = Object.keys(current);
    for (var i in keys) {
      totalKeys[keys[i]] = 1;
    }
  }, totalKeys);

  var keys = Object.keys(totalKeys);
  for (var i in keys) {
    tableColumns.push({
      title: keys[i]?.replace(/([a-z0-9])([A-Z])/g, "$1 $2")?.toUpperCase(),
      dataIndex: keys[i],
      key: keys[i],
      width: 280
    });
  }

  const exportData = async () => {

    let selectedYear =  moment().date()
     
     if(selectedAIReportYear)
     {
       selectedYear =  selectedAIReportYear
     }
     else
     {
      selectedYear =  new Date().getYear()
     }

    let payload = {
          "ClientName": selectedAIReportClient,
          "ProgramType": selectedAIReportProgramType,
          "isMonthlySummary": true,
          "ReportYear": selectedYear
    }

   if(selectedAIReportClient && selectedAIReportProgramType)
   {
    setLoading(true)
    API[ActionTypes.GET_AI_SUMMARY_REPORT](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
    .then(({ data }) => {
        setLoading(false);
              
        const report_title =`${selectedAIReportClient}_${selectedAIReportProgramType}_${data[0]?.Year}_AI_Performance_Report`

          if (data) {
            exportToCSVInventory(
              perfromanceExportColumns,
              data,
              "",
              "",
              report_title,
              dispatch,
              ActionTypes,
              true
            )
    
          }
      })
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: ActionTypes.GET_AI_SUMMARY_REPORT_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
    }
  }

  useEffect(()=>{
     let selectedYear =  moment().date()
     
     if(selectedAIReportYear)
     {
       selectedYear =  selectedAIReportYear
     }
     else
     {
      selectedYear =  new Date().getYear()
     }

      let payload = {
          "ClientName": selectedAIReportClient,
          "ProgramType": selectedAIReportProgramType,
          "isMonthlySummary": true,
          "ReportYear": selectedYear
      }
    
     if(selectedAIReportClient && selectedAIReportProgramType && selectedYear)
     {
      setLoading(true)
      API[ActionTypes.GET_AI_SUMMARY_REPORT](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
          setLoading(false);
          if (data) {
            dispatch({
              type: ActionTypes.GET_AI_SUMMARY_REPORT_SUCCESS,
              payload: data,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          dispatch({
            type: ActionTypes.GET_AI_SUMMARY_REPORT_FAILURE,
            payload: err,
          });
          dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
          let _payload = {
            level: resources.POST_LOGGER_ERROR,
            category: resources.POST_LOGGER_API,
            ApiName: resources.POST_LOGGER_APINAME,
            message: {
              error: err,
              info: {
                idToken: "",
              },
            },
          };
          API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
        });
      }
  }, [selectedAIReportClient, selectedAIReportYear])

  
const DataFormatter =(percent, positive=true)=>{
    const color = positive ? (percent > 50 ? "forestgreen" : percent > 30 ? "yellow"  : "red") : (percent > 50 ? "red" : percent > 30 ? "yellow"  : "forestgreen")
    const value = percent ?  `${percent}%` : "-"
return(
    <span style={{color:value === "-" ? "white" : color}}>
        {value}
    </span>
)
}

// const AHTFormatter = (time)=>{
//   if(time){
//     const days = Math.floor(time / 24); // Full days
//     const remainingHours = time % 24;   // Remaining hours
//      return `${days}D : ${ Math.trunc(remainingHours)}H`
//   }
//   else{
//      return "-";
//   }
// }

  return (
    <div className="py-3">
       {
        GetAISummaryReportSuccess &&  !isEmpty(GetAISummaryReportSuccess) && 
          <div className="flex flex-row justify-end pb-4 pr-4">
            <Button
                  label={'Export All'}
                  className="text-sm  max-w-[120px] w-full px-5 py-2 rounded-3xl !bg-[#1ED760] text-black hover:text-white"
                  onClick={() => exportData()}
              />
          </div>
        }  
        <table id="print" style={{background:'black'}} className="table-fixed bg-black w-full mx-auto aisummarytable">
        <thead>
            <tr className="text-center text-white bg-purple-color rounded-t-[8px] py-2">
            <th>Month</th>
            <th>Total Contacts</th>
            <th>AI Responses</th>
            <th>AI Responses(%)</th>
            <th>Approved / Edited</th>
            <th>Approved / Edited(%)</th>
            <th>Rejected</th>
            <th>Rejected(%)</th>
            <th>Pending</th>
            <th>Pending(%)</th>
            <th>Closed W/O Feedback</th>
            <th>Closed W/O Feedback(%)</th>
            <th>Avg. AHT (Hrs)</th>
            </tr>
        </thead>
        <tbody>
            
        {GetAISummaryReportSuccess &&  !isEmpty(GetAISummaryReportSuccess) && GetAISummaryReportSuccess?.map((data)=>{
            return(
            <tr className="text-center">
              <td className="font-bold text-white">{data?.Month ? `${data?.Month}-${data?.Year}`  : "-"}</td>
              <td className="text-white">{data["Total Contacts"] ? data["Total Contacts"] : "-" }</td>
              <td >{data["AI Responses"] ? data["AI Responses"] : "-"}</td>
              <td >{DataFormatter(data["AI Responses Percentage"])}</td>
              <td >{data["Approved/Edited"] ? data["Approved/Edited"] : "-"}</td>
              <td >{DataFormatter(data["Approved/Edited Percentage"])}</td>
              <td >{data["Rejected"] ? data["Rejected"] : "-"}</td>
              <td >{DataFormatter(data["Rejected Percentage"], false)}</td>
              <td >{data["Pending"] ? data["Pending"] : "-"}</td>
              <td >{DataFormatter(data["Pending Percentage"], false)}</td>
              <td >{data["Closed W/O Feedback"] ? data["Closed W/O Feedback"] : "-"}</td>
              <td >{DataFormatter(data["Closed W/O FeedbackPercentage"], false)}</td>
              <td >{data["AvgResolutionTimeInHour"]}</td>
            </tr>
            )
        })}
        
        </tbody>
        </table>
        {
          isEmpty(GetAISummaryReportSuccess) && <p className="text-center mt-6 text-sm">No Data Found</p>
        }
    </div>
  );
};

export default AIReportSummaryTable;