import React from 'react'

const icon = ({ className, fill, onClick }) => (
    <svg className={className} onClick={onClick} width="256" height="200" viewBox="0 0 256 200" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1261_4644)">
        <g mask="url(#mask0_1261_4644)">
        <path d="M198.848 180.912H82.088C78.288 180.912 75.184 177.8 75.112 173.912L72.2 22.328C72.128 18.392 75.176 15.136 79.024 15.048L198.704 12.376C202.616 12.288 205.832 15.512 205.832 19.52V173.776C205.832 177.72 202.704 180.92 198.856 180.92L198.848 180.912Z" fill="url(#paint0_linear_1261_4644)"/>
        <path d="M193.664 33.608H86.344C84.0244 33.608 82.144 35.4884 82.144 37.808V169.28C82.144 171.6 84.0244 173.48 86.344 173.48H193.664C195.984 173.48 197.864 171.6 197.864 169.28V37.808C197.864 35.4884 195.984 33.608 193.664 33.608Z" fill="#91B3FA"/>
        <path d="M193.352 39.712H129.384V44.752H193.352V39.712Z" fill="#BDD0FB"/>
        <path d="M193.352 46.8721H129.384V49.7921H193.352V46.8721Z" fill="#BDD0FB"/>
        <path d="M193.352 51.92H129.384V54.84H193.352V51.92Z" fill="#BDD0FB"/>
        <path d="M193.352 56.9601H129.384V59.8801H193.352V56.9601Z" fill="#BDD0FB"/>
        <path d="M126.992 102.88H90.368V107.92H126.992V102.88Z" fill="#BDD0FB"/>
        <path d="M126.992 110.04H90.368V112.96H126.992V110.04Z" fill="#BDD0FB"/>
        <path d="M126.992 115.088H90.368V118.008H126.992V115.088Z" fill="#BDD0FB"/>
        <path d="M126.992 120.128H90.368V123.048H126.992V120.128Z" fill="#BDD0FB"/>
        <path d="M127.744 77.584H124.176V92.792H127.744V77.584Z" fill="url(#paint1_linear_1261_4644)"/>
        <path d="M133.704 82.8159H130.136V92.7919H133.704V82.8159Z" fill="url(#paint2_linear_1261_4644)"/>
        <path d="M139.672 69.896H136.104V92.792H139.672V69.896Z" fill="url(#paint3_linear_1261_4644)"/>
        <path d="M145.632 87.296H142.064V92.792H145.632V87.296Z" fill="url(#paint4_linear_1261_4644)"/>
        <path d="M151.6 72.96H148.032V92.792H151.6V72.96Z" fill="url(#paint5_linear_1261_4644)"/>
        <path d="M157.56 76.392H153.992V92.792H157.56V76.392Z" fill="url(#paint6_linear_1261_4644)"/>
        <path d="M163.528 80.1279H159.96V92.7919H163.528V80.1279Z" fill="url(#paint7_linear_1261_4644)"/>
        <path d="M169.496 65.272H165.928V92.792H169.496V65.272Z" fill="url(#paint8_linear_1261_4644)"/>
        <path d="M175.456 68.184H171.888V92.792H175.456V68.184Z" fill="url(#paint9_linear_1261_4644)"/>
        <path d="M181.424 72.584H177.856V92.792H181.424V72.584Z" fill="url(#paint10_linear_1261_4644)"/>
        <path d="M187.384 87.7999H183.816V92.7919H187.384V87.7999Z" fill="url(#paint11_linear_1261_4644)"/>
        <path d="M193.352 78.1119H189.784V92.7919H193.352V78.1119Z" fill="url(#paint12_linear_1261_4644)"/>
        <path d="M193.352 164.192H90.368V167.112H193.352V164.192Z" fill="#BDD0FB"/>
        <path d="M115.432 39.712H94.512C92.2233 39.712 90.368 41.5674 90.368 43.856V88.648C90.368 90.9367 92.2233 92.792 94.512 92.792H115.432C117.721 92.792 119.576 90.9367 119.576 88.648V43.856C119.576 41.5674 117.721 39.712 115.432 39.712Z" fill="#BDD0FB"/>
        <path opacity="0.57" d="M113.4 128.36H104.24C96.7289 128.36 90.64 134.449 90.64 141.96V146.08C90.64 153.591 96.7289 159.68 104.24 159.68H113.4C120.911 159.68 127 153.591 127 146.08V141.96C127 134.449 120.911 128.36 113.4 128.36Z" fill="white"/>
        <path d="M163.344 131.12C162.936 131.12 162.544 130.952 162.256 130.656C161.92 130.304 161.768 129.816 161.856 129.336L166.408 104.112C166.48 103.72 166.704 103.368 167.04 103.136C167.368 102.912 167.776 102.824 168.176 102.896C179.608 105.048 188.464 114.208 190.216 125.696C190.28 126.096 190.176 126.496 189.936 126.824C189.696 127.144 189.336 127.36 188.936 127.416L163.56 131.096C163.488 131.104 163.416 131.112 163.344 131.112V131.12Z" fill="#BDD0FB"/>
        <path d="M156.656 159.144C144.296 159.144 134.232 149.088 134.232 136.72C134.232 124.352 144.288 114.296 156.656 114.296C158.04 114.296 159.44 114.432 160.824 114.688C161.64 114.84 162.176 115.624 162.032 116.44L158.736 134.704L177.12 132.04C177.936 131.92 178.704 132.488 178.832 133.304C179 134.424 179.088 135.568 179.088 136.712C179.088 149.072 169.032 159.136 156.664 159.136L156.656 159.144Z" fill="#BDD0FB"/>
        <path d="M63.984 102.712C63.592 116.704 62.32 164.952 62.928 182.048C62.992 183.856 64.632 185.312 66.68 185.376C76.8 185.672 113.896 186.416 130.448 185.8C133.896 185.672 136.64 183.2 136.712 180.144C137.056 165.464 137.816 124.168 136.008 105.248C135.792 102.984 133.784 101.176 131.224 100.952C120.2 99.976 82.944 97.592 68.648 98.408C66.072 98.56 64.048 100.424 63.984 102.712Z" fill="url(#paint13_linear_1261_4644)"/>
        <path d="M127.64 104.76H71.0079C68.7325 104.76 66.8879 106.605 66.8879 108.88V120.728C66.8879 123.003 68.7325 124.848 71.0079 124.848H127.64C129.915 124.848 131.76 123.003 131.76 120.728V108.88C131.76 106.605 129.915 104.76 127.64 104.76Z" fill="#7D97F4"/>
        <path opacity="0.2" d="M128.872 108.808C128.368 108.288 127.688 107.992 126.968 107.984L73.9679 107.144C72.5519 107.12 71.3519 108.192 71.2239 109.6L70.12 121.36C70.032 122.32 71.616 123.136 73.632 123.16L125.736 123.792C127.752 123.816 129.416 123.048 129.432 122.088L129.632 110.752C129.648 110.032 129.368 109.336 128.872 108.816V108.808Z" fill="white"/>
        <path d="M81.0479 136.872C81.0479 138.96 79.352 140.656 77.264 140.656H72.656C70.568 140.656 68.8719 138.96 68.8719 136.872C68.8719 134.784 70.568 133.088 72.656 133.088H77.264C79.352 133.088 81.0479 134.784 81.0479 136.872Z" fill="url(#paint14_linear_1261_4644)"/>
        <path d="M98.1759 136.872C98.1759 138.96 96.4799 140.656 94.3919 140.656H89.7839C87.6959 140.656 85.9999 138.96 85.9999 136.872C85.9999 134.784 87.6959 133.088 89.7839 133.088H94.3919C96.4799 133.088 98.1759 134.784 98.1759 136.872Z" fill="url(#paint15_linear_1261_4644)"/>
        <path d="M114.64 136.872C114.64 138.96 112.944 140.656 110.856 140.656H106.248C104.16 140.656 102.464 138.96 102.464 136.872C102.464 134.784 104.16 133.088 106.248 133.088H110.856C112.944 133.088 114.64 134.784 114.64 136.872Z" fill="url(#paint16_linear_1261_4644)"/>
        <path d="M131.328 137.424C131.328 139.552 129.608 141.272 127.48 141.272H123.024C120.896 141.272 119.176 139.552 119.176 137.424C119.176 135.296 120.896 133.576 123.024 133.576H127.48C129.608 133.576 131.328 135.296 131.328 137.424Z" fill="#8223D2"/>
        <path d="M81.0479 148.72C81.0479 150.808 79.352 152.504 77.264 152.504H72.656C70.568 152.504 68.8719 150.808 68.8719 148.72C68.8719 146.632 70.568 144.936 72.656 144.936H77.264C79.352 144.936 81.0479 146.632 81.0479 148.72Z" fill="url(#paint17_linear_1261_4644)"/>
        <path d="M98.1759 148.72C98.1759 150.808 96.4799 152.504 94.3919 152.504H89.7839C87.6959 152.504 85.9999 150.808 85.9999 148.72C85.9999 146.632 87.6959 144.936 89.7839 144.936H94.3919C96.4799 144.936 98.1759 146.632 98.1759 148.72Z" fill="url(#paint18_linear_1261_4644)"/>
        <path d="M114.64 148.72C114.64 150.808 112.944 152.504 110.856 152.504H106.248C104.16 152.504 102.464 150.808 102.464 148.72C102.464 146.632 104.16 144.936 106.248 144.936H110.856C112.944 144.936 114.64 146.632 114.64 148.72Z" fill="url(#paint19_linear_1261_4644)"/>
        <path d="M131.76 148.72C131.76 150.808 130.064 152.504 127.976 152.504H123.368C121.28 152.504 119.584 150.808 119.584 148.72C119.584 146.632 121.28 144.936 123.368 144.936H127.976C130.064 144.936 131.76 146.632 131.76 148.72Z" fill="url(#paint20_linear_1261_4644)"/>
        <path opacity="0.63" d="M81.0479 159.224C81.0479 161.312 79.352 163.008 77.264 163.008H72.656C70.568 163.008 68.8719 161.312 68.8719 159.224C68.8719 157.136 70.568 155.44 72.656 155.44H77.264C79.352 155.44 81.0479 157.136 81.0479 159.224Z" fill="url(#paint21_linear_1261_4644)"/>
        <path opacity="0.63" d="M98.1759 159.224C98.1759 161.312 96.4799 163.008 94.3919 163.008H89.7839C87.6959 163.008 85.9999 161.312 85.9999 159.224C85.9999 157.136 87.6959 155.44 89.7839 155.44H94.3919C96.4799 155.44 98.1759 157.136 98.1759 159.224Z" fill="url(#paint22_linear_1261_4644)"/>
        <path opacity="0.63" d="M114.64 159.224C114.64 161.312 112.944 163.008 110.856 163.008H106.248C104.16 163.008 102.464 161.312 102.464 159.224C102.464 157.136 104.16 155.44 106.248 155.44H110.856C112.944 155.44 114.64 157.136 114.64 159.224Z" fill="url(#paint23_linear_1261_4644)"/>
        <path opacity="0.63" d="M81.0479 170.744C81.0479 172.832 79.352 174.528 77.264 174.528H72.656C70.568 174.528 68.8719 172.832 68.8719 170.744C68.8719 168.656 70.568 166.96 72.656 166.96H77.264C79.352 166.96 81.0479 168.656 81.0479 170.744Z" fill="url(#paint24_linear_1261_4644)"/>
        <path opacity="0.63" d="M98.1759 170.744C98.1759 172.832 96.4799 174.528 94.3919 174.528H89.7839C87.6959 174.528 85.9999 172.832 85.9999 170.744C85.9999 168.656 87.6959 166.96 89.7839 166.96H94.3919C96.4799 166.96 98.1759 168.656 98.1759 170.744Z" fill="url(#paint25_linear_1261_4644)"/>
        <path opacity="0.63" d="M114.64 170.744C114.64 172.832 112.944 174.528 110.856 174.528H106.248C104.16 174.528 102.464 172.832 102.464 170.744C102.464 168.656 104.16 166.96 106.248 166.96H110.856C112.944 166.96 114.64 168.656 114.64 170.744Z" fill="url(#paint26_linear_1261_4644)"/>
        <path d="M125.32 156.464H125.024C122.377 156.464 120.232 158.609 120.232 161.256V170.44C120.232 173.086 122.377 175.232 125.024 175.232H125.32C127.966 175.232 130.112 173.086 130.112 170.44V161.256C130.112 158.609 127.966 156.464 125.32 156.464Z" fill="#7D97F4"/>
        <path d="M173.144 136.328C174.712 136.464 177.424 136.68 179.568 136.776C180.856 136.832 181.6 136.704 182.024 136.536C182.024 136.584 182.024 136.632 182.024 136.672C182.016 136.896 181.832 137.08 181.6 137.088C177.552 137.312 174.336 137.168 173.192 137.096C172.952 137.08 172.816 136.824 172.936 136.624C173 136.52 173.072 136.424 173.152 136.328H173.144Z" fill="url(#paint27_linear_1261_4644)"/>
        <path d="M179.568 136.776C177.424 136.68 174.712 136.464 173.144 136.328C174.68 134.44 179.568 133.168 179.568 133.168H180.944C181.896 134.536 182.032 135.84 182.016 136.536C181.592 136.704 180.848 136.832 179.56 136.776H179.568Z" fill="url(#paint28_linear_1261_4644)"/>
        <path d="M181.184 130.92L181.056 133.472C181.04 133.752 180.832 133.984 180.56 134.024C180.288 134.064 179.912 134.072 179.52 133.936C179.28 133.856 179.104 133.656 179.056 133.408L178.608 130.92H181.184Z" fill="url(#paint29_linear_1261_4644)"/>
        <path d="M199.096 136.76C199.776 136.664 200.08 136.312 200.208 135.936C200.208 135.936 200.208 135.936 200.208 135.944C200.304 136.344 200.08 136.752 199.616 136.952C199.056 137.192 198.448 137.232 197.968 137.208C197.456 137.184 196.992 136.976 196.736 136.64C197.248 136.888 197.968 136.92 199.096 136.76Z" fill="url(#paint30_linear_1261_4644)"/>
        <path d="M195.896 130.896H197.032C198.768 130.864 199.856 134.512 200.208 135.928C200.08 136.304 199.776 136.656 199.096 136.752C197.96 136.912 197.248 136.88 196.736 136.624C195.8 135.392 195.336 133.584 195.112 132.536C194.856 131.336 195.896 130.888 195.896 130.888V130.896Z" fill="url(#paint31_linear_1261_4644)"/>
        <path d="M196.888 128.528L197.52 131.04C197.6 131.352 197.448 131.664 197.168 131.816C196.816 132 196.32 132.184 195.856 132.136C195.64 132.112 195.464 131.96 195.392 131.76L194.464 129.12L196.888 128.544V128.528Z" fill="url(#paint32_linear_1261_4644)"/>
        <path d="M197.64 129.256C196.856 129.952 194.296 130.048 194.296 130.048C185.088 107.944 181.32 104.808 181.32 104.808C182.648 113.152 181.536 131.624 181.536 131.624C180.016 132.296 178.056 131.624 178.056 131.624C177.936 117.968 175.832 106.056 173.352 96.6641C179.576 96.5281 182.888 94.3921 183.968 93.5361C189.16 101.904 197.64 129.264 197.64 129.264V129.256Z" fill="url(#paint33_linear_1261_4644)"/>
        <path d="M182.392 68.112C183.568 73.992 183.144 84.504 182.872 89.304C182.792 90.656 183.736 92.52 184.496 93.8C184.616 94 184.544 94.256 184.336 94.36C183.064 95.016 178.744 97.04 173.744 97.296C173.408 97.312 173.112 97.088 173.024 96.76C169.312 82.904 165.008 74.056 165.008 74.056C169.768 66.456 182.392 68.112 182.392 68.112Z" fill="#8223D2"/>
        <path d="M180.696 104.28C180.752 104.28 180.8 104.264 180.848 104.24C180.984 104.152 181.032 103.976 180.944 103.832C180.896 103.752 179.704 101.824 177.296 100.664C177.152 100.592 176.976 100.656 176.904 100.8C176.832 100.944 176.896 101.12 177.04 101.192C179.288 102.28 180.432 104.12 180.448 104.136C180.504 104.224 180.6 104.28 180.704 104.28H180.696Z" fill="url(#paint34_linear_1261_4644)"/>
        <path d="M189.784 84.9761C189.408 87.7121 188.288 90.696 186.048 93.872L185.2 93.1761C185.2 93.1761 186.184 89.192 185.624 84.672C187 84.4 188.632 84.6801 189.784 84.9681V84.9761Z" fill="url(#paint35_linear_1261_4644)"/>
        <path d="M181.072 75.3601L182.384 68.1121C182.384 68.1121 191.176 74.8321 189.776 84.9761C188.624 84.6881 186.992 84.4081 185.616 84.6801C185.216 81.4241 184.016 77.8881 181.072 75.3681V75.3601Z" fill="#8223D2"/>
        <path d="M165.008 74.056L168.4 81.368C167.848 85.784 165.48 87.688 162.928 88.424C162.768 86.416 162.264 84.792 161.792 83.664C165.056 80.296 165.016 74.064 165.016 74.064L165.008 74.056Z" fill="#8223D2"/>
        <path d="M161.784 83.6561C162.256 84.7761 162.768 86.4081 162.92 88.4161C156.104 91.0641 154.296 85.9281 154.296 85.9281L155.56 85.7041C158.408 86.0001 160.392 85.0881 161.784 83.6481V83.6561Z" fill="url(#paint36_linear_1261_4644)"/>
        <path d="M168.136 65.032L169.776 71.056C169.96 71.736 170.688 72.096 171.344 71.832C172.048 71.552 172.936 71.168 173.704 70.752C174.52 70.312 174.776 69.256 174.264 68.48L170.856 63.4L168.136 65.024V65.032Z" fill="url(#paint37_linear_1261_4644)"/>
        <path d="M151.176 81.9041L193.16 97.1841C194.92 97.8241 195.76 99.8321 194.984 101.536L194.856 101.816C194.168 103.328 192.384 103.984 190.88 103.288L150.112 84.4321L151.168 81.9041H151.176Z" fill="url(#paint38_linear_1261_4644)"/>
        <g opacity="0.15">
        <path d="M131.88 93.0641C143.328 93.0641 152.608 83.9522 152.608 72.7121C152.608 61.472 143.328 52.3601 131.88 52.3601C120.432 52.3601 111.152 61.472 111.152 72.7121C111.152 83.9522 120.432 93.0641 131.88 93.0641Z" fill="#7D97F4"/>
        </g>
        <path d="M108.816 72.7121C108.816 85.2161 119.144 95.3601 131.88 95.3601C144.616 95.3601 154.944 85.2241 154.944 72.7121C154.944 60.2001 144.616 50.0641 131.88 50.0641C119.144 50.0641 108.816 60.2001 108.816 72.7121ZM112.2 72.7121C112.2 62.0401 121.008 53.3921 131.872 53.3921C142.736 53.3921 151.544 62.0401 151.544 72.7121C151.544 83.3841 142.736 92.0321 131.872 92.0321C121.008 92.0321 112.2 83.3841 112.2 72.7121Z" fill="url(#paint39_linear_1261_4644)"/>
        <path d="M153.184 75.4641C153.184 75.4641 153.176 75.4641 153.168 75.4641C153.112 75.4641 153.072 75.4001 153.08 75.3441C153.144 74.8161 153.192 74.2721 153.224 73.7441C153.224 73.6881 153.28 73.6401 153.336 73.6401C153.392 73.6401 153.44 73.6961 153.44 73.7521C153.416 74.2881 153.368 74.8321 153.296 75.3761C153.296 75.4321 153.24 75.4721 153.192 75.4721L153.184 75.4641ZM153.024 69.1361C152.976 69.1361 152.928 69.0961 152.92 69.0481C152.664 67.6081 152.248 66.1921 151.688 64.8401C148.4 56.8721 140.624 51.7281 131.872 51.7281C127.792 51.7281 123.824 52.8641 120.392 55.0081C120.344 55.0401 120.28 55.0241 120.248 54.9761C120.216 54.9281 120.232 54.8641 120.28 54.8321C123.736 52.6641 127.744 51.5201 131.872 51.5201C140.712 51.5201 148.568 56.7201 151.888 64.7681C152.448 66.1361 152.872 67.5601 153.128 69.0161C153.136 69.0721 153.104 69.1281 153.04 69.1361H153.024Z" fill="#BDD0FB"/>
        <path d="M164.544 60.456C164.528 60.56 165.912 67.328 165.912 67.328L169.76 66.456L169.4 59.848C169.4 59.848 164.792 58.728 164.544 60.456Z" fill="url(#paint40_linear_1261_4644)"/>
        <path d="M168.544 61.32L169 64.784C169 64.832 169 64.872 169 64.92C168.96 65.192 168.76 66.12 167.824 65.848C166.976 65.608 166.2 65.4 165.664 65.568C165.424 65.64 165.28 65.888 165.328 66.136C165.456 66.752 165.76 68.128 166.216 69.288C166.304 69.52 166.568 69.648 166.808 69.576C167.536 69.36 169.064 68.872 170.2 68.184C170.352 68.088 170.448 67.928 170.44 67.744C170.4 66.872 170.304 64.208 170.384 63.464C170.464 62.768 171.32 62.344 171.696 62.192C171.816 62.144 171.912 62.048 171.96 61.92C172.136 61.488 172.368 60.488 171.112 60.072C170.848 59.984 170.712 59.704 170.8 59.44C170.984 58.92 171.064 58.128 170.088 57.688C169.096 57.24 168.352 57.776 167.952 58.2C167.792 58.376 167.528 58.4 167.328 58.272C167.216 58.2 167.064 58.176 166.888 58.288C166.648 58.44 166.328 58.336 166.208 58.08C165.968 57.592 165.488 57.072 164.512 57.232C162.784 57.528 162.592 59.44 163.048 60.56C163.504 61.68 165.352 61.256 166.28 60.688C166.984 60.256 167.904 60.712 168.304 60.952C168.432 61.032 168.512 61.16 168.536 61.304L168.544 61.32Z" fill="url(#paint41_linear_1261_4644)"/>
        <path d="M170.536 64.736C170.536 64.736 169.824 62.752 170.552 62.264C171.28 61.776 172.36 62.032 172.12 63.472C171.824 65.264 170.528 64.736 170.528 64.736H170.536Z" fill="url(#paint42_linear_1261_4644)"/>
        <path d="M185.552 93.0561L181.584 92.7361L179.864 94.3201C179.864 94.3201 180.272 94.8801 181.544 94.5601C182.816 94.2401 184.56 94.1601 184.56 94.1601C184.56 94.1601 186.312 94.9521 186.336 93.4721L185.56 93.0641L185.552 93.0561Z" fill="url(#paint43_linear_1261_4644)"/>
        <path d="M154.808 86.8721C154.464 87.1441 153.288 86.5841 152.568 86.1921C152.216 86.0001 152.048 85.5921 152.168 85.2161C152.344 84.6721 152.632 83.9681 152.984 83.8801C153.576 83.7361 156.224 85.7681 154.808 86.8801V86.8721Z" fill="url(#paint44_linear_1261_4644)"/>
        <path d="M192.328 102.656C192.288 102.656 192.24 102.632 192.224 102.584C192.208 102.528 192.232 102.472 192.288 102.448C193.496 102.032 194.128 101.352 194.168 100.432C194.208 99.504 193.624 98.64 192.704 98.28L187.664 96.312C187.608 96.288 187.584 96.232 187.6 96.176C187.616 96.12 187.68 96.096 187.736 96.112L192.776 98.08C193.776 98.472 194.416 99.416 194.376 100.44C194.328 101.456 193.656 102.2 192.36 102.648C192.352 102.648 192.336 102.648 192.328 102.648V102.656Z" fill="#BDD0FB"/>
        <path d="M186.152 95.7201C186.152 95.7201 186.128 95.7201 186.112 95.7201L185.456 95.4641C185.4 95.4401 185.376 95.3841 185.392 95.3281C185.408 95.2721 185.472 95.2481 185.528 95.2641L186.184 95.5201C186.24 95.5441 186.264 95.6001 186.248 95.6561C186.232 95.6961 186.192 95.7201 186.152 95.7201Z" fill="#BDD0FB"/>
        <path d="M58.704 182.56C58.704 182.56 58.4 184.272 58.256 185.968C58.2 186.632 58.712 187.208 59.376 187.232C61.632 187.328 66.464 187.512 68.192 187.432C68.456 187.424 68.624 187.152 68.528 186.912C68.448 186.712 68.288 186.544 68.096 186.456L60.12 182.792L58.712 182.568L58.704 182.56Z" fill="url(#paint45_linear_1261_4644)"/>
        <path d="M58.456 181.552L58.696 183.6C58.696 183.6 60.096 184.248 61.048 183.52L60.472 181.24L58.456 181.56V181.552Z" fill="url(#paint46_linear_1261_4644)"/>
        <path d="M58.64 143.048C58.536 143.584 58.08 169.736 60.872 181.456C60.92 181.648 60.824 181.848 60.64 181.92C60.208 182.096 59.368 182.352 58.36 182.384C58.048 182.392 57.768 182.192 57.68 181.896C56.88 179.176 53.048 165.552 52.552 152.536C51.992 137.992 52.552 152.04 52.552 152.04L52.88 143.048H58.64Z" fill="url(#paint47_linear_1261_4644)"/>
        <path d="M49.528 183.456C49.528 183.456 49.048 184.32 48.584 186.616C48.496 187.032 48.792 187.424 49.208 187.472C49.952 187.544 51.104 187.64 52.016 187.632C52.44 187.632 52.768 187.264 52.72 186.84C52.616 186 52.304 184.624 51.32 183.552L49.528 183.456Z" fill="url(#paint48_linear_1261_4644)"/>
        <path d="M49.104 180.816L49.672 183.912C49.672 183.912 50.232 184.328 51.344 184.024L51.072 180.688L49.104 180.808V180.816Z" fill="url(#paint49_linear_1261_4644)"/>
        <path d="M47.576 143.856C45.288 157.656 48.072 177.96 49.008 181.936C49.064 182.176 49.264 182.368 49.512 182.408C49.928 182.48 50.624 182.536 51.304 182.336C51.536 182.272 51.704 182.056 51.712 181.816C51.84 178.6 51.608 158.88 53.744 151.08C53.848 150.688 54.096 150.344 54.424 150.104L58.56 147.168L58.88 142.416L47.584 143.864L47.576 143.856Z" fill="url(#paint50_linear_1261_4644)"/>
        <path d="M62.144 117.704C55.288 117.592 47.728 119.504 47.728 119.504C45.688 131.424 47.368 145.168 47.368 145.168C52.672 146.56 58.656 144.32 58.656 144.32C59.536 139.336 63.648 125.32 63.648 125.32C66.992 124.904 69.752 123.936 72.024 122.664C70.288 120.656 69.472 118.104 69.12 116.592C66.696 117.344 64.248 117.744 62.136 117.712L62.144 117.704Z" fill="#8223D2"/>
        <path d="M82.384 107.616C81.712 106.8 80.856 107.184 80.856 107.184C79.848 111.44 74.56 114.896 69.128 116.584C69.48 118.096 70.296 120.648 72.032 122.656C81.048 117.584 82.384 107.608 82.384 107.608V107.616Z" fill="url(#paint51_linear_1261_4644)"/>
        <path d="M82.216 107.824C82.288 108.048 81.472 108.392 80.992 107.472C80.976 107.032 80.712 105.152 80.968 104.792C82.368 102.888 85.072 100.504 85.072 100.504C85.528 100.952 85.152 101.4 85.152 101.4L83.84 102.768C84.112 103.264 84.448 103.68 84.608 104.288C84.728 104.744 84.656 105.232 84.424 105.64C84.192 106.056 83.88 106.592 83.584 107.016C83.024 107.816 82.216 107.824 82.216 107.824Z" fill="url(#paint52_linear_1261_4644)"/>
        <path d="M83.272 103.656L82.072 104.672L81.696 105.68L82.76 106.864C82.76 106.864 83.12 104.856 83.384 104.424C83.648 103.992 83.512 103.784 83.264 103.656H83.272Z" fill="url(#paint53_linear_1261_4644)"/>
        <path d="M50.36 126.144L47.728 119.512C47.728 119.512 41.328 123.864 40.272 129.376C41.48 129.464 43.68 129.776 45.832 130.864C46.304 128.264 50.352 126.144 50.352 126.144H50.36Z" fill="#8223D2"/>
        <path d="M55.952 132.384C55.952 132.384 48.768 136.448 46.344 132.976C45.84 132.256 45.72 131.544 45.848 130.856C43.696 129.768 41.496 129.456 40.288 129.368C39.968 131.032 40.136 132.8 41.112 134.584C45.36 142.352 56.912 134.152 56.912 134.152L55.96 132.384H55.952Z" fill="url(#paint54_linear_1261_4644)"/>
        <path d="M55.088 137.52L59.008 123.824C59.056 123.672 59.184 123.552 59.344 123.536C60.056 123.456 62.112 123.224 64.488 123.016C67.064 122.8 70.016 122.616 72.032 122.704C72.616 122.728 72.984 123.344 72.72 123.872C71.248 126.824 67.056 135.112 64.888 137.952C64.808 138.056 64.688 138.112 64.568 138.112L55.472 138.024C55.208 138.024 55.016 137.768 55.088 137.512V137.52Z" fill="url(#paint55_linear_1261_4644)"/>
        <path d="M64.904 121.832L67.744 121.36C67.896 121.336 68.016 121.48 67.968 121.624L67.688 122.48C67.664 122.552 67.6 122.608 67.52 122.616L64.784 122.984C64.64 123 64.528 122.872 64.56 122.736L64.736 121.992C64.752 121.912 64.816 121.848 64.904 121.84V121.832Z" fill="url(#paint56_linear_1261_4644)"/>
        <path d="M62.768 124.24L63 123.016C63.024 122.896 63.128 122.8 63.248 122.792L68.992 122.208C69.184 122.192 69.344 122.368 69.296 122.552L68.928 124.072C68.896 124.192 68.792 124.28 68.664 124.288L63.048 124.568C62.864 124.576 62.72 124.416 62.76 124.232L62.768 124.24Z" fill="url(#paint57_linear_1261_4644)"/>
        <path d="M56.032 134.216C56.032 134.216 55.488 131.968 55.704 131.176C55.928 130.376 56.352 129.352 57.776 129.584C59.2 129.808 60.88 130.408 60.88 130.408C60.88 130.408 60.792 131.448 59.712 131.112L60.384 131.632C60.384 131.632 60.288 132.464 59.376 132.016L60 132.704C60 132.704 59.608 133.384 58.936 132.952C58.264 132.512 57.944 134.488 56.032 134.216Z" fill="url(#paint58_linear_1261_4644)"/>
        <path d="M55.2 112.144L53.016 118.448C52.976 118.552 53.016 118.672 53.12 118.72C53.712 119.048 55.856 120 58.432 118.36C58.704 118.192 58.88 117.912 58.928 117.592L59.632 112.136H55.192L55.2 112.144Z" fill="url(#paint59_linear_1261_4644)"/>
        <path d="M59.944 106.304C59.944 106.304 62.336 110.808 62.032 112.632C61.728 114.456 58.76 114.32 56.168 112.84L54.36 107.976L59.952 106.304H59.944Z" fill="url(#paint60_linear_1261_4644)"/>
        <path d="M56.28 110.456C56.384 110.408 56.424 110.28 56.36 110.184C56.168 109.888 55.808 109.296 55.696 108.768C55.696 108.768 55.264 108.496 56.792 108.128C58.32 107.76 60.592 107.192 61.072 105.896C61.536 104.632 60.312 101.448 58.36 103.984C58.288 104.08 58.136 104.08 58.064 103.984C57.744 103.568 56.904 102.704 55.728 103.224C54.632 103.712 54.512 104.52 54.528 104.92C54.528 105.04 54.424 105.128 54.312 105.112C53.76 105.008 52.432 104.912 51.808 106.28C51.296 107.4 51.632 108.04 51.928 108.344C52.024 108.448 51.984 108.608 51.848 108.648C51.488 108.76 51.016 109.064 51.064 109.92C51.128 111.096 52.448 110.992 52.824 110.944C52.888 110.936 52.944 110.96 52.992 111C53.312 111.352 54.76 112.864 56.328 113.048C56.464 113.064 56.568 112.928 56.528 112.8L55.848 110.824C55.816 110.736 55.864 110.632 55.952 110.592L56.288 110.44L56.28 110.456Z" fill="url(#paint61_linear_1261_4644)"/>
        <path d="M56.552 110.736C56.552 110.736 54.848 109.568 54.72 110.552C54.592 111.536 56.256 111.976 57.08 111.608L56.552 110.736Z" fill="url(#paint62_linear_1261_4644)"/>
        <path d="M168.864 185.664C169.168 185.664 169.408 185.488 169.408 185.272V138.08C169.408 137.864 169.16 137.688 168.864 137.688C168.568 137.688 168.32 137.864 168.32 138.08V185.272C168.32 185.488 168.568 185.664 168.864 185.664Z" fill="url(#paint63_linear_1261_4644)"/>
        <path d="M178.864 185.664C179.168 185.664 179.408 185.488 179.408 185.264V137.728C179.408 137.512 179.16 137.328 178.864 137.328C178.568 137.328 178.32 137.504 178.32 137.728V185.264C178.32 185.48 178.568 185.664 178.864 185.664Z" fill="url(#paint64_linear_1261_4644)"/>
        <path d="M215.32 186.032C215.392 186.032 215.464 186.016 215.536 185.992C215.816 185.888 215.944 185.6 215.824 185.352L193.024 137.416C193.024 137.416 191.712 137.168 191.832 137.416L214.816 185.736C214.904 185.92 215.104 186.032 215.32 186.032Z" fill="url(#paint65_linear_1261_4644)"/>
        <path d="M198.024 186.032C198.096 186.032 198.168 186.016 198.24 185.992C198.52 185.888 198.648 185.6 198.528 185.352L175.832 137.648C175.712 137.4 174.504 137.352 174.616 137.6L197.512 185.736C197.6 185.92 197.8 186.032 198.016 186.032H198.024Z" fill="url(#paint66_linear_1261_4644)"/>
        <path d="M168.24 138.568H200.464C200.844 138.568 201.152 138.26 201.152 137.88C201.152 137.5 200.844 137.192 200.464 137.192H168.24C167.86 137.192 167.552 137.5 167.552 137.88C167.552 138.26 167.86 138.568 168.24 138.568Z" fill="url(#paint67_linear_1261_4644)"/>
        <path d="M196.928 146.32H179.576V147.296H196.928V146.32Z" fill="url(#paint68_linear_1261_4644)"/>
        <path d="M202.08 157.456H184.728V158.432H202.08V157.456Z" fill="url(#paint69_linear_1261_4644)"/>
        <path d="M190.136 169.568H207.488C207.792 169.568 208.032 169.352 208.032 169.08C208.032 168.808 207.784 168.592 207.488 168.592H190.136C189.832 168.592 189.592 168.808 189.592 169.08C189.592 169.352 189.84 169.568 190.136 169.568Z" fill="url(#paint70_linear_1261_4644)"/>
        <path d="M195.36 180.696H212.712C213.016 180.696 213.256 180.48 213.256 180.208C213.256 179.936 213.008 179.72 212.712 179.72H195.36C195.056 179.72 194.816 179.936 194.816 180.208C194.816 180.48 195.064 180.696 195.36 180.696Z" fill="url(#paint71_linear_1261_4644)"/>
        <path d="M76.9155 91.6937C79.2218 88.4924 78.4962 84.0276 75.295 81.7214C72.0937 79.4151 67.6289 80.1406 65.3226 83.3419C63.0163 86.5432 63.7419 91.0079 66.9432 93.3142C70.1444 95.6205 74.6092 94.895 76.9155 91.6937Z" fill="#6700B8"/>
        <path d="M73.6144 93.9636C77.1749 92.5869 78.9452 88.5845 77.5685 85.024C76.1917 81.4635 72.1893 79.6932 68.6288 81.0699C65.0684 82.4467 63.2981 86.4491 64.6748 90.0096C66.0515 93.5701 70.054 95.3404 73.6144 93.9636Z" fill="#8223D2"/>
        <path opacity="0.78" d="M65.5599 84.5921C65.5599 84.5921 65.4879 84.5841 65.4559 84.5601C65.3439 84.4961 65.3039 84.3521 65.3679 84.2481C66.6159 82.0641 68.9439 80.7921 71.4479 80.9201C71.8479 80.9441 72.2479 81.0001 72.6319 81.0881C72.7599 81.1201 72.8319 81.2401 72.7999 81.3681C72.7679 81.4961 72.6479 81.5681 72.5199 81.5361C72.1599 81.4481 71.7919 81.4001 71.4159 81.3761C69.0879 81.2561 66.9199 82.4401 65.7599 84.4721C65.7119 84.5521 65.6319 84.5921 65.5439 84.5921H65.5599Z" fill="white"/>
        <path opacity="0.78" d="M75.3506 91.8646C77.659 89.6057 77.699 85.9031 75.4401 83.5948C73.1812 81.2864 69.4786 81.2463 67.1703 83.5053C64.8619 85.7642 64.8218 89.4668 67.0808 91.7751C69.3397 94.0835 73.0422 94.1236 75.3506 91.8646Z" fill="white"/>
        <path d="M70.464 91.992L70.496 91.36C69.832 91.296 69.184 91.112 68.736 90.88C68.576 90.8 68.496 90.608 68.552 90.432L68.744 89.848C68.816 89.632 69.056 89.536 69.256 89.632C69.704 89.848 70.272 90.032 70.888 90.064C71.528 90.096 72.016 89.904 72.032 89.464C72.056 89.064 71.696 88.808 70.824 88.52C69.576 88.112 68.696 87.512 68.752 86.4C68.8 85.392 69.576 84.664 70.864 84.528L70.896 83.896C70.904 83.688 71.08 83.536 71.288 83.544L71.664 83.56C71.872 83.568 72.024 83.744 72.016 83.952L71.984 84.52C72.592 84.576 73.04 84.704 73.384 84.848C73.56 84.92 73.648 85.12 73.592 85.304L73.416 85.84C73.344 86.048 73.12 86.152 72.912 86.064C72.592 85.92 72.136 85.768 71.536 85.744C70.776 85.704 70.544 86.008 70.536 86.288C70.52 86.632 70.96 86.896 71.928 87.248C73.312 87.744 73.864 88.352 73.816 89.408C73.768 90.368 73.008 91.2 71.616 91.344L71.576 92.056C71.568 92.264 71.392 92.416 71.184 92.408L70.808 92.392C70.6 92.384 70.448 92.208 70.456 92L70.464 91.992Z" fill="#6700B8"/>
        <path d="M89.0732 62.446C89.1395 56.0045 83.9714 50.7288 77.5299 50.6625C71.0884 50.5962 65.8127 55.7642 65.7464 62.2058C65.6801 68.6473 70.8482 73.9229 77.2897 73.9892C83.7312 74.0556 89.0068 68.8875 89.0732 62.446Z" fill="#6700B8"/>
        <path d="M88.5131 64.2745C89.5877 58.1426 85.4879 52.3006 79.356 51.226C73.2241 50.1514 67.3821 54.2511 66.3075 60.383C65.2329 66.5149 69.3326 72.357 75.4646 73.4316C81.5965 74.5062 87.4385 70.4064 88.5131 64.2745Z" fill="#8223D2"/>
        <path opacity="0.78" d="M68.352 57.3919C68.352 57.3919 68.288 57.3839 68.256 57.3599C68.152 57.3039 68.112 57.1679 68.176 57.0639C70.176 53.5599 73.928 51.5039 77.952 51.7119C78.592 51.7439 79.24 51.8319 79.856 51.9839C79.976 52.0079 80.048 52.1279 80.016 52.2479C79.992 52.3679 79.872 52.4399 79.752 52.4079C79.16 52.2639 78.544 52.1839 77.92 52.1519C74.056 51.9519 70.464 53.9199 68.536 57.2799C68.496 57.3519 68.416 57.3919 68.336 57.3919H68.352Z" fill="white"/>
        <path opacity="0.78" d="M82.1133 71.0214C86.7678 68.5476 88.5356 62.769 86.0618 58.1146C83.588 53.4601 77.8094 51.6923 73.155 54.1661C68.5005 56.6399 66.7327 62.4184 69.2065 67.0729C71.6803 71.7274 77.4589 73.4951 82.1133 71.0214Z" fill="white"/>
        <path d="M76.3359 69.624L76.3919 68.584C75.3119 68.488 74.2559 68.176 73.5119 67.8C73.2479 67.664 73.1279 67.352 73.2159 67.072L73.5279 66.12C73.6399 65.768 74.0399 65.608 74.3679 65.768C75.1039 66.12 76.0239 66.424 77.0239 66.472C78.0639 66.528 78.8559 66.216 78.8959 65.488C78.9279 64.832 78.3519 64.416 76.9199 63.952C74.8879 63.28 73.4479 62.304 73.5439 60.488C73.6319 58.848 74.8959 57.656 76.9919 57.424L77.0479 56.384C77.0639 56.048 77.3519 55.792 77.6799 55.808L78.2879 55.84C78.6239 55.856 78.8799 56.144 78.8639 56.472L78.8159 57.4C79.8159 57.488 80.5359 57.696 81.1039 57.936C81.3919 58.056 81.5439 58.384 81.4399 58.68L81.1519 59.56C81.0399 59.896 80.6639 60.072 80.3359 59.928C79.8159 59.696 79.0719 59.448 78.0959 59.4C76.8639 59.336 76.4799 59.832 76.4559 60.288C76.4239 60.856 77.1519 61.28 78.7279 61.856C80.9839 62.664 81.8879 63.664 81.7999 65.376C81.7199 66.944 80.4799 68.296 78.2079 68.536L78.1519 69.696C78.1359 70.032 77.8479 70.288 77.5199 70.272L76.9119 70.24C76.5759 70.224 76.3199 69.936 76.3359 69.608V69.624Z" fill="#6700B8"/>
        <path d="M103.466 90.0841C106.807 84.3603 104.875 77.012 99.1514 73.6713C93.4275 70.3306 86.0793 72.2625 82.7385 77.9863C79.3978 83.7101 81.3297 91.0584 87.0536 94.3991C92.7774 97.7398 100.126 95.8079 103.466 90.0841Z" fill="#6700B8"/>
        <path d="M103.538 89.0699C106.326 83.302 103.91 76.3659 98.1426 73.5776C92.3747 70.7892 85.4385 73.2047 82.6502 78.9726C79.8619 84.7405 82.2773 91.6766 88.0452 94.4649C93.8131 97.2533 100.749 94.8378 103.538 89.0699Z" fill="#8223D2"/>
        <path opacity="0.78" d="M83.776 78.944C83.776 78.944 83.712 78.936 83.68 78.912C83.576 78.856 83.536 78.72 83.6 78.616C85.656 75.008 89.512 72.904 93.656 73.112C94.32 73.144 94.976 73.24 95.616 73.392C95.736 73.416 95.808 73.536 95.776 73.656C95.752 73.776 95.632 73.848 95.512 73.816C94.896 73.672 94.264 73.584 93.632 73.552C89.656 73.344 85.952 75.376 83.976 78.832C83.936 78.904 83.856 78.944 83.776 78.944Z" fill="white"/>
        <path opacity="0.78" d="M102.736 87.1724C104.323 81.9838 101.402 76.4917 96.2137 74.9054C91.0252 73.3191 85.533 76.2393 83.9467 81.4279C82.3604 86.6165 85.2806 92.1086 90.4692 93.6949C95.6578 95.2812 101.15 92.361 102.736 87.1724Z" fill="white"/>
        <path d="M92 91.536L92.056 90.472C90.944 90.368 89.856 90.048 89.096 89.672C88.824 89.536 88.696 89.216 88.792 88.928L89.112 87.952C89.232 87.592 89.64 87.424 89.976 87.592C90.736 87.96 91.68 88.264 92.712 88.312C93.784 88.368 94.6 88.048 94.64 87.304C94.672 86.632 94.08 86.2 92.608 85.728C90.512 85.04 89.032 84.032 89.128 82.16C89.216 80.472 90.512 79.248 92.68 79.008L92.736 77.944C92.752 77.6 93.048 77.336 93.392 77.352L94.016 77.384C94.36 77.4 94.624 77.696 94.608 78.04L94.56 79C95.584 79.096 96.328 79.312 96.912 79.544C97.208 79.664 97.36 80 97.264 80.312L96.968 81.216C96.856 81.568 96.464 81.744 96.128 81.592C95.592 81.36 94.832 81.104 93.824 81.048C92.552 80.984 92.16 81.488 92.136 81.96C92.104 82.544 92.848 82.976 94.472 83.576C96.8 84.408 97.728 85.44 97.632 87.2C97.552 88.816 96.28 90.208 93.936 90.448L93.872 91.64C93.856 91.984 93.56 92.248 93.216 92.232L92.592 92.2C92.248 92.184 91.984 91.888 92 91.544V91.536Z" fill="#6700B8"/>
        <path d="M112.809 61.0046C113.646 55.7692 110.081 50.8461 104.846 50.0085C99.6105 49.1708 94.6874 52.7359 93.8498 57.9712C93.0122 63.2066 96.5772 68.1297 101.813 68.9673C107.048 69.805 111.971 66.2399 112.809 61.0046Z" fill="#6700B8"/>
        <path d="M112.398 61.4557C113.486 56.4472 110.307 51.5055 105.298 50.418C100.29 49.3306 95.3482 52.5092 94.2607 57.5177C93.1732 62.5262 96.3519 67.468 101.36 68.5554C106.369 69.6429 111.311 66.4642 112.398 61.4557Z" fill="#8223D2"/>
        <path opacity="0.78" d="M95.872 55.48C95.872 55.48 95.8 55.472 95.768 55.448C95.656 55.384 95.616 55.24 95.68 55.128C97.336 52.224 100.44 50.528 103.784 50.696C104.312 50.72 104.848 50.8 105.36 50.92C105.488 50.952 105.568 51.08 105.536 51.208C105.504 51.336 105.376 51.416 105.248 51.384C104.76 51.272 104.256 51.2 103.752 51.176C100.592 51.016 97.656 52.624 96.088 55.368C96.04 55.448 95.96 55.488 95.872 55.488V55.48Z" fill="white"/>
        <path opacity="0.78" d="M105.575 67.281C109.763 66.1464 112.238 61.8317 111.103 57.644C109.969 53.4562 105.654 50.9812 101.466 52.1158C97.2786 53.2505 94.8036 57.5651 95.9383 61.7529C97.0729 65.9407 101.388 68.4157 105.575 67.281Z" fill="white"/>
        <path d="M102.448 65.488L102.488 64.632C101.6 64.552 100.728 64.296 100.12 63.992C99.904 63.88 99.8 63.624 99.88 63.392L100.136 62.608C100.232 62.32 100.552 62.184 100.832 62.32C101.44 62.608 102.192 62.856 103.016 62.896C103.872 62.936 104.528 62.68 104.56 62.088C104.584 61.552 104.112 61.208 102.936 60.824C101.264 60.272 100.08 59.472 100.152 57.976C100.224 56.624 101.264 55.648 102.992 55.456L103.032 54.6C103.048 54.328 103.28 54.112 103.552 54.128L104.056 54.152C104.328 54.168 104.544 54.4 104.528 54.672L104.488 55.44C105.312 55.512 105.904 55.688 106.368 55.88C106.608 55.976 106.728 56.248 106.648 56.496L106.408 57.224C106.32 57.504 106.008 57.64 105.736 57.528C105.304 57.336 104.696 57.136 103.888 57.096C102.872 57.04 102.56 57.448 102.544 57.824C102.52 58.288 103.112 58.64 104.416 59.112C106.272 59.776 107.016 60.6 106.944 62.008C106.88 63.304 105.856 64.416 103.984 64.608L103.936 65.56C103.92 65.832 103.688 66.048 103.416 66.032L102.912 66.008C102.64 65.992 102.424 65.76 102.44 65.488H102.448Z" fill="#6700B8"/>
        </g>
        </g>
        <defs>
        <linearGradient id="paint0_linear_1261_4644" x1="188.016" y1="45.744" x2="43.096" y2="200.216" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1261_4644" x1="124.176" y1="85.192" x2="127.744" y2="85.192" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DAE3FE"/>
        <stop offset="1" stop-color="#E9EFFD"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1261_4644" x1="130.136" y1="87.7999" x2="133.704" y2="87.7999" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DAE3FE"/>
        <stop offset="1" stop-color="#E9EFFD"/>
        </linearGradient>
        <linearGradient id="paint3_linear_1261_4644" x1="136.104" y1="81.344" x2="139.672" y2="81.344" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DAE3FE"/>
        <stop offset="1" stop-color="#E9EFFD"/>
        </linearGradient>
        <linearGradient id="paint4_linear_1261_4644" x1="142.064" y1="90.04" x2="145.64" y2="90.04" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DAE3FE"/>
        <stop offset="1" stop-color="#E9EFFD"/>
        </linearGradient>
        <linearGradient id="paint5_linear_1261_4644" x1="148.032" y1="82.872" x2="151.6" y2="82.872" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DAE3FE"/>
        <stop offset="1" stop-color="#E9EFFD"/>
        </linearGradient>
        <linearGradient id="paint6_linear_1261_4644" x1="153.992" y1="84.592" x2="157.568" y2="84.592" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DAE3FE"/>
        <stop offset="1" stop-color="#E9EFFD"/>
        </linearGradient>
        <linearGradient id="paint7_linear_1261_4644" x1="159.96" y1="86.4559" x2="163.528" y2="86.4559" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DAE3FE"/>
        <stop offset="1" stop-color="#E9EFFD"/>
        </linearGradient>
        <linearGradient id="paint8_linear_1261_4644" x1="165.928" y1="79.032" x2="169.496" y2="79.032" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DAE3FE"/>
        <stop offset="1" stop-color="#E9EFFD"/>
        </linearGradient>
        <linearGradient id="paint9_linear_1261_4644" x1="171.888" y1="80.488" x2="175.456" y2="80.488" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DAE3FE"/>
        <stop offset="1" stop-color="#E9EFFD"/>
        </linearGradient>
        <linearGradient id="paint10_linear_1261_4644" x1="177.856" y1="82.688" x2="181.424" y2="82.688" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DAE3FE"/>
        <stop offset="1" stop-color="#E9EFFD"/>
        </linearGradient>
        <linearGradient id="paint11_linear_1261_4644" x1="183.816" y1="90.2959" x2="187.392" y2="90.2959" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DAE3FE"/>
        <stop offset="1" stop-color="#E9EFFD"/>
        </linearGradient>
        <linearGradient id="paint12_linear_1261_4644" x1="189.784" y1="85.4479" x2="193.352" y2="85.4479" gradientUnits="userSpaceOnUse">
        <stop stop-color="#DAE3FE"/>
        <stop offset="1" stop-color="#E9EFFD"/>
        </linearGradient>
        <linearGradient id="paint13_linear_1261_4644" x1="62.768" y1="142.136" x2="137.16" y2="142.136" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint14_linear_1261_4644" x1="56.1839" y1="154.944" x2="106.072" y2="106.92" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint15_linear_1261_4644" x1="64.4159" y1="163.496" x2="114.304" y2="115.472" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint16_linear_1261_4644" x1="72.336" y1="171.728" x2="122.224" y2="123.696" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint17_linear_1261_4644" x1="62.1039" y1="161.096" x2="111.992" y2="113.072" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint18_linear_1261_4644" x1="70.3439" y1="169.648" x2="120.232" y2="121.624" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint19_linear_1261_4644" x1="78.264" y1="177.88" x2="128.152" y2="129.848" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint20_linear_1261_4644" x1="86.496" y1="186.432" x2="136.384" y2="138.408" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint21_linear_1261_4644" x1="67.3519" y1="166.544" x2="117.24" y2="118.52" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint22_linear_1261_4644" x1="75.5919" y1="175.104" x2="125.472" y2="127.072" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint23_linear_1261_4644" x1="83.5119" y1="183.328" x2="133.392" y2="135.296" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint24_linear_1261_4644" x1="73.1119" y1="172.528" x2="123" y2="124.496" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint25_linear_1261_4644" x1="81.3439" y1="181.08" x2="131.232" y2="133.056" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint26_linear_1261_4644" x1="89.2639" y1="189.304" x2="139.152" y2="141.28" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint27_linear_1261_4644" x1="184.752" y1="120.368" x2="177.192" y2="137.328" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint28_linear_1261_4644" x1="182.024" y1="134.984" x2="173.144" y2="134.984" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint29_linear_1261_4644" x1="181.184" y1="132.48" x2="178.608" y2="132.48" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint30_linear_1261_4644" x1="204.512" y1="118.984" x2="198.192" y2="137.296" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint31_linear_1261_4644" x1="200.216" y1="133.88" x2="195.08" y2="133.88" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint32_linear_1261_4644" x1="197.544" y1="130.336" x2="194.472" y2="130.336" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint33_linear_1261_4644" x1="219.888" y1="46.2401" x2="178.416" y2="126.696" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint34_linear_1261_4644" x1="198.032" y1="65.4" x2="183.816" y2="92.984" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint35_linear_1261_4644" x1="183.456" y1="85.496" x2="194.4" y2="95.192" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint36_linear_1261_4644" x1="162.928" y1="86.4161" x2="154.304" y2="86.4161" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint37_linear_1261_4644" x1="171.312" y1="63.376" x2="171.4" y2="79.472" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint38_linear_1261_4644" x1="151.392" y1="81.1041" x2="196.584" y2="102.744" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint39_linear_1261_4644" x1="116.152" y1="49.7521" x2="145.216" y2="92.1841" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint40_linear_1261_4644" x1="166.28" y1="60.7801" x2="172.629" y2="72.2928" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint41_linear_1261_4644" x1="173.304" y1="62.448" x2="145.8" y2="65.928" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint42_linear_1261_4644" x1="176.583" y1="70.6567" x2="171.219" y2="63.2953" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint43_linear_1261_4644" x1="179.128" y1="90.3841" x2="190.08" y2="100.08" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint44_linear_1261_4644" x1="155.216" y1="85.4081" x2="152.128" y2="85.4081" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint45_linear_1261_4644" x1="71.424" y1="163.384" x2="66.336" y2="177.384" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint46_linear_1261_4644" x1="58.456" y1="182.552" x2="61.048" y2="182.552" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint47_linear_1261_4644" x1="46.976" y1="174.616" x2="88.872" y2="122.832" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint48_linear_1261_4644" x1="61.384" y1="174.168" x2="32.1761" y2="205.936" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint49_linear_1261_4644" x1="49.104" y1="182.416" x2="51.344" y2="182.416" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint50_linear_1261_4644" x1="42.864" y1="171.288" x2="84.768" y2="119.512" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint51_linear_1261_4644" x1="69.128" y1="114.88" x2="82.384" y2="114.88" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint52_linear_1261_4644" x1="79.432" y1="112.656" x2="70.544" y2="132.888" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint53_linear_1261_4644" x1="82.32" y1="105.92" x2="86.024" y2="100.216" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint54_linear_1261_4644" x1="40.128" y1="133.648" x2="56.904" y2="133.648" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint55_linear_1261_4644" x1="55.072" y1="130.4" x2="72.808" y2="130.4" gradientUnits="userSpaceOnUse">
        <stop stop-color="#4F52FF"/>
        <stop offset="1" stop-color="#4042E2"/>
        </linearGradient>
        <linearGradient id="paint56_linear_1261_4644" x1="64.432" y1="100.6" x2="65.704" y2="115.552" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint57_linear_1261_4644" x1="64.096" y1="100.624" x2="65.376" y2="115.584" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint58_linear_1261_4644" x1="55.656" y1="131.888" x2="60.88" y2="131.888" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint59_linear_1261_4644" x1="53.008" y1="115.72" x2="59.64" y2="115.72" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint60_linear_1261_4644" x1="61.117" y1="111.576" x2="53.8279" y2="107.134" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint61_linear_1261_4644" x1="49.9697" y1="102.439" x2="59.3889" y2="109.88" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint62_linear_1261_4644" x1="59.2044" y1="113.23" x2="52.6436" y2="109.231" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FEBBBA"/>
        <stop offset="1" stop-color="#FF928E"/>
        </linearGradient>
        <linearGradient id="paint63_linear_1261_4644" x1="270.32" y1="55.3282" x2="217.496" y2="110.696" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint64_linear_1261_4644" x1="275.464" y1="60.2401" x2="222.64" y2="115.608" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint65_linear_1261_4644" x1="288.568" y1="72.7361" x2="235.744" y2="128.112" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint66_linear_1261_4644" x1="279.584" y1="64.16" x2="226.752" y2="119.536" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint67_linear_1261_4644" x1="266.272" y1="52.0161" x2="213.624" y2="107.2" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint68_linear_1261_4644" x1="273.048" y1="57.928" x2="220.216" y2="113.296" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint69_linear_1261_4644" x1="281.304" y1="65.8091" x2="228.481" y2="121.177" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint70_linear_1261_4644" x1="289.696" y1="73.8079" x2="236.864" y2="129.184" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <linearGradient id="paint71_linear_1261_4644" x1="297.992" y1="81.7281" x2="245.16" y2="137.096" gradientUnits="userSpaceOnUse">
        <stop stop-color="#09005D"/>
        <stop offset="1" stop-color="#1A0F91"/>
        </linearGradient>
        <clipPath id="clip0_1261_4644">
        <rect width="256" height="200" fill="white"/>
        </clipPath>
        </defs>
    </svg>
)
export default icon


