import Definitions from "./definitions";
import { useEffect, useState, useRef } from "react";
import Popup from "../../components/common/popup/popup";
import InfoIcon from "../../assets/icons/infoIcon";
import { useCentralStore } from "../../store/appContext";
import moment from "moment";
import FlipCountdown from "@rumess/react-flip-countdown";
import { toPascalCase, setCursorPositioning } from "../../utils/functionUtils";

const WelcomeSectionSS = ({ className, resources }) => {
  const [{ refreshedOnTime, userDetails, fullscreenMode, selectedTab, mapSelected }, dispatch] =
    useCentralStore();
  const [isOpen, openPopup] = useState(false);
  const [updateCounter, setUpdateCounter] = useState(false);
  const [clockValue, setClockValue] = useState(new Date());
  const [animate, setAnimate] = useState(null);

  const prevAmount = usePrevious(refreshedOnTime);
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {
    if (prevAmount?.refreshedOnTime !== refreshedOnTime) {
      setUpdateCounter(true);
      // setClockValue("00:03:00");
    }
  }, [refreshedOnTime, fullscreenMode]);

  const handleOpen = (e) => {
    setCursorPositioning(e);
    setAnimate("modal-space-in");
    openPopup(true);
  };

  const handleClose = () => {
    setAnimate("modal-space-out");
    setTimeout(() => openPopup(false), 1000);
  };

  return (
    <div
      // className={`flex flex-row border-t border-app-border justify-between items-center p-4 ${className}`}
      className={`flex flex-row justify-between items-center px-4 ${className}`}
    >
      <div className="flex flex-row">
        {/* <Header /> */}
        {/* <div className="">
          <div className="font-bold text-xl">
            Welcome back, {userDetails && toPascalCase(userDetails?.firstName)}
          </div>
          <div className="text-xs">
            Monitoring operational performance of APAC products
          </div>
        </div> */}
      </div>
      <div className="flex flex-row">
        {/* {fullscreenMode &&  */}
        {fullscreenMode && refreshedOnTime && updateCounter && (
          <>
            {/* <span>Last refresh on <span className="font-bold pl-2">{moment(refreshedOnTime)?.format("DD-MM-YYYY HH:mm:ss")}</span></span> */}
            <p className="text-xs font-bold mt-[.0325rem]">
              Next refresh in -
            </p>

            <FlipCountdown
              hideYear
              hideMonth
              hideDay
              hideHour
              endAtZero
              size="extra-small"
              theme="dark"
              minuteTitle="min"
              secondTitle="sec"
              titlePosition="bottom"
              endAt={moment(new Date(refreshedOnTime).getTime()) + 600000}
            />
          </>
        )}
        {/* {mapSelected?.toLowerCase() === 'australia' && */}
          {/* <a
            className="cursor-pointer float-right w-auto text-xs ml-5 mt-1"
            onClick={handleOpen}
          >
            <InfoIcon className="w-4 h-4" fill="white" />
          </a> */}
        {/* } */}
      </div>
      {isOpen && (
        <Popup
          show={isOpen}
          global={true}
          className={`${animate}`}
          fitContent={true}
          onClose={handleClose}
          definations={true}
        >
          <Definitions onClose={handleClose} selectedTab={selectedTab} />
        </Popup>
      )}
    </div>
  );
};

export default WelcomeSectionSS;
