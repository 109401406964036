import { useCentralStore } from "../../store/appContext"
import resources from '../../components/common/resources.json'
import BPMIcon from '../../assets/homeImg/BPM_small_v2'
import HomeIcon from '../../assets/icons/home-icon'
import Transactional_ReportingIcon from "../../assets/homeImg/Transactional_Reporting_small_v2";
import ActionTypes from "../../store/actionTypes";
import API from "../../api";
import moment from "moment";
import InfoIcon from '../../assets/icons/infoIcon';
import DownloadIcon from '../../assets/icons/downloadIcon';

const SHDashboardMenu = () => {

    const [ { projectName, selectedTab, userData, userDetails, projectSHSubMenu }, dispatch ] = useCentralStore()

    const setLoading = (action) => {
        dispatch({ type: ActionTypes.SET_LOADING, payload: action });
      };

    const handleBPMClick = () => {
        dispatch({
          type: ActionTypes.SET_PROJECT_NAME,
          payload: null,
        });
        dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: null });
        dispatch({ type: ActionTypes.SET_PROGRAM_NAME, data: "" });
        // dispatch({ type: ActionTypes.SGP_SET_REPORT_NAME, payload: {
        //   reportLabel: null,
        //   reportValue: null
        // }})
        dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION, payload: {
          type: null,
          startDate: null,
          endDate: null,
          duration: null
        }})
        
        dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS, payload: null})
        dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS_SUCCESS, payload: null})
        dispatch({ type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS, payload: null})
    
        dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER, payload: 'real time'})
        // goToHomePage();
      };

      const goToHomePage = () => {
        let payload = {
          // Username: userDetails && userDetails?.email?.split("@")?.[0],
          idToken: userData?.access_token,
        };
        setLoading(true);
        dispatch({
          type: ActionTypes.GET_DASHBOARD_DETAILS_DATA_SUCCESS,
          payload: false,
        });
        API[ActionTypes.GET_ROLE_BASES_MATRICS](payload, userDetails && userDetails?.email?.split("@")?.[0])
          .then(({ data }) => {
            setLoading(false);
            if (data) {
              dispatch({
                type: ActionTypes.GET_ROLE_BASES_MATRICS_SUCCESS,
                payload: data,
              });
            }
          })
          .catch((err) => {
            dispatch({
              type: ActionTypes.GET_ROLE_BASES_MATRICS_FAILURE,
              payload: err,
            });
            dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
            let _payload = {
              level: resources.POST_LOGGER_ERROR,
              category: resources.POST_LOGGER_API,
              ApiName: resources.POST_LOGGER_APINAME,
              message: {
                error: err,
                info: {
                  idToken: "",
                },
              },
            };
            API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
          });
      };

      const handleMonthlyTrendData = () => {
        // dispatch({
        //     type: ActionTypes.SET_SELECTED_TAB,
        //     payload: 'Monthly Trends',
        //   })
        //   dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS, payload: null})
        //   dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS_SUCCESS, payload: null})
        //   dispatch({ type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS, payload: null})
        //   dispatch({
        //     type: ActionTypes.HIDE_DATE_FILTER,
        //     payload: true,
        //   })

          let payload = {
            // Username: userDetails && userDetails?.email?.split("@")?.[0],
            idToken: userData?.access_token,
          };

          setLoading(true);
          API[ActionTypes.GET_CHART_COUNT_DATA](payload, userDetails && userDetails?.email?.split("@")?.[0])
            .then(({ data }) => {
                setLoading(false);
                if (data) {
                dispatch({
                    type: ActionTypes.GET_CHART_COUNT_DATA_SUCCESS,
                    payload: data,
                });
                }
            })
            .catch((err) => {
                dispatch({
                type: ActionTypes.GET_CHART_COUNT_DATA_FAILURE,
                payload: err,
                });
            dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
            let _payload = {
            level: resources.POST_LOGGER_ERROR,
            category: resources.POST_LOGGER_API,
            ApiName: resources.POST_LOGGER_APINAME,
            message: {
                error: err,
                info: {
                idToken: "",
                },
            },
            };
            API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
        });
          
      }

      const handleRealTimeTab = (val, parentLabel) => {
        dispatch({
          type: ActionTypes.SET_SELECTED_TAB,
          payload: val,
        })
        dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER, payload: 'real time'})
        
        if(val === 'Real Time' && parentLabel === 'Real Time Service Request Report') {
          let startDate = moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD 00:00:00')
          let endDate = moment(new Date()).format('YYYY-MM-DD 23:59:59')
      
          dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION, payload: {
              type: 'dates',
              startDate: startDate,
              endDate: endDate,
              duration: 'dates'
          }})
          dispatch({
              type: ActionTypes.HIDE_DATE_FILTER,
              payload: false,
          })
        }

        if(val === 'Monthly Trends' && (parentLabel === 'Real Time Service Request Report' || parentLabel === 'Customer Service SLA Report' || parentLabel === 'Technical Service SLA Report')) handleMonthlyTrendData()

        // if(val === 'DownloadReport' && parentLabel === 'Real Time Service Request Report') {

        // }
      }

    return(
        <div className="flex ml-10 justify-between">
            <div className="flex">
                <div className={`cursor-pointer py-0 pr-3 pl-1 flex justify-center items-center border-b border-app-lightGreen mr-5 ${
                    selectedTab === 'HomePage' ? "bg-app-lightGreen font-bold rounded-lg" : "" }`}>
                <div className="flex items-center" onClick={() => handleBPMClick()}>
                    <HomeIcon className="" fill={selectedTab === 'HomePage' ? "black" : "#1ED760" }
                    />
                    <div className={`text-black text-xs`}>
                        Home
                    </div>
                </div>
            </div>

            {projectSHSubMenu && projectSHSubMenu?.subLabels?.map(v =>
              <div className={`cursor-pointer py-0 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5 ${
                    selectedTab === v?.value ? "bg-app-starhubGreen font-bold rounded-lg" : "" }`}>
                <div className="flex items-center mr-2" onClick={() => handleRealTimeTab(v?.value, v?.parentLabel)} >
                    {v?.value === 'DownloadReport' ? <DownloadIcon className="w-5 h-5" fill={selectedTab === v?.value ? "black" : '#1ED760'} /> : v?.value === "Glossary" ? <InfoIcon className="flex w-3 h-3" fill={selectedTab === v?.value ? "black" : "#1ED760"} /> : <BPMIcon className="" fill={selectedTab === v?.value ? "black" : "#1ED760"}/>}
                    <div className={`text-black text-xs ml-1 `}>
                      {v?.label}
                    </div>
                </div>
              </div>
            )}

            {/* {projectName === 'Service Request Report' &&
              <>
              <div className={`cursor-pointer py-0 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5 ${
                    selectedTab === 'Service Request Report' ? "bg-app-starhubGreen font-bold rounded-lg" : "" }`}>
                <div className="flex items-center mr-2" onClick={() => handleRealTimeTab()} >
                    <BPMIcon className="" fill={selectedTab === 'Service Request Report' ? "black" : "#1ED760"}/>
                    <div className={`text-black text-xs ml-1 `}>
                        Real Time
                    </div>
                </div>
            </div>
            <div className={`cursor-pointer py-0 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5 ${
                selectedTab === 'Monthly Trends' ? "bg-app-starhubGreen font-bold rounded-lg" : "" }`}>
                    <div className="flex items-center mr-2" onClick={() => handleTrend()}>
                    <Transactional_ReportingIcon className="" fill={selectedTab === 'Monthly Trends' ? "black" : "#1ED760"}/>
                        <div className={`text-black text-xs ml-1 `}>
                            Monthly Trends
                        </div>
                    </div>
                </div>
                </>
            } */}
            </div>
        </div>
    )
}

export default SHDashboardMenu