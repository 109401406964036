import React, { useEffect, useState } from "react";
import PerformanceCard from "../performanceCard/performanceCard";
import Xarrow from "react-xarrows";
import { useCentralStore } from "../../../store/appContext";

export default function RecursiveComponent({
  submetrics1,
  barObject,
  colors,
  parent,
  resources,
  getSubData,
  dispatch,
  ActionTypes
}) {
  
  const [ { removedSubMatricsData } ] = useCentralStore()
  
  const [subMetricData, setSubMetricData] = useState(undefined)
  const [animationArrow, setAnimationArrow] = useState(0.1)

  const collapseMatrics = (action,subData) =>{
      if(action == 'collapse'){
        let subData1 = submetrics1?.map(s => {
          if(s?.label === subData?.label){
            dispatch({
              type: ActionTypes.SET_REMOVED_SUBMATRICS_DATA,
              payload: {
                label: s.label,
                submetrics : s.submetrics
              },
            });
            delete s.submetrics
            return s
          }else{
            return s
          }
        })
        setSubMetricData(subData1)
      }else {
        let subData1 = submetrics1?.map(s => {
          if(s?.label === subData?.label){
            let addSubData = removedSubMatricsData?.filter(r => r?.label == subData?.label)?.[0]?.submetrics
            s["submetrics"] = addSubData
            return s
          }else{
            return s
          }
        })
        setSubMetricData(subData1)
      }
  }
  let submetrics = subMetricData || submetrics1

  useEffect(() => {
    if(subMetricData){
      dispatch({
        type: ActionTypes.SET_UPDATED_SUBMATRICS_DATA,
        payload: subMetricData,
      });
      // setTimeout(() => {
      //   setAnimationArrow(1)
      // }, [1000])
    }
  }, [subMetricData])

  return (
    <div className={`${parent === undefined ? "mx-5 mt-3" : ""} slideRightCss`} id={`${parent}`}>
      {submetrics?.map((submetrics, i) => {
        return (
          <div className={`flex w-fit`}>
            <PerformanceCard
              id={submetrics.label?.replaceAll(" ", "")}
              key={submetrics.label}
              label={submetrics.label}
              value={submetrics.value}
              status={submetrics.status}
              colors={colors}
              subquerykey={submetrics.subquerykey}
              MetricsLabel={barObject?.label}
              barLabel={barObject?.label}
              metricsCss="w-52 mr-24 ml-3 h-fit relative z-1 mt-0"
              subMetricsTypeIndicator={submetrics?.SubMetricsTypeIndicator}
              isSubMatrics={true}
              getSubData={(e, subquerykey, MetricsLabel, value, label) =>
                getSubData(e, subquerykey, MetricsLabel, value, label)
              }
              resources={resources}
              showMinusIcon={submetrics?.submetrics?.length > 0 ? true : false}
              // stopAnimaiton1={stopAnimaiton1}
              submetrics={submetrics}
              collapseMatrics={(action,submetrics) => collapseMatrics(action,submetrics)}
            />
            {parent !== undefined && (
              <Xarrow
                start={parent}
                end={`${submetrics.label?.replaceAll(" ", "")}`}
                strokeWidth={2.5}
                startAnchor="right"
                endAnchor="left"
                zIndex="-10"
                color="#CB5AFF"
                // dashness
                // dashness={{ strokeLen: 10, nonStrokeLen: 15, animation: -2 }}
                // showHead={false}
                headSize={5}
                animateDrawing={animationArrow}
                path={"grid"}
                monitorDOMchanges={true}
                // curveness={1}
              />
            )}
            {submetrics?.submetrics && (
              <>
                <RecursiveComponent
                  parent={submetrics.label?.replaceAll(" ", "")}
                  submetrics1={submetrics.submetrics}
                  index={i}
                  getSubData={getSubData}
                  dispatch={dispatch} 
                  ActionTypes={ActionTypes}
                />
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}
