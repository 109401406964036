import React from "react";

const icon = ({ className, fill, onClick, stroke }) => (
  <svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.8334 32.5H4.16675" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.0002 35.4165C15.2303 35.4165 15.4168 35.6031 15.4168 35.8332V35.8332C15.4168 36.0633 15.2303 36.2498 15.0002 36.2498V36.2498C14.77 36.2498 14.5835 36.0633 14.5835 35.8332V35.8332C14.5835 35.6031 14.77 35.4165 15.0002 35.4165" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.8334 25.833V35.833C25.8334 37.674 24.341 39.1663 22.5001 39.1663H7.50008C5.65913 39.1663 4.16675 37.674 4.16675 35.833V4.16634C4.16675 2.32539 5.65913 0.833008 7.50008 0.833008H20.0001" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M34.8002 6.86644L22.0835 19.5831L15.8335 20.8331L17.0835 14.5831L29.8002 1.86644C30.4611 1.20449 31.3581 0.83252 32.2935 0.83252C33.2289 0.83252 34.1259 1.20449 34.7868 1.86644L34.8002 1.87978C35.4621 2.54069 35.8341 3.4377 35.8341 4.37311C35.8341 5.30852 35.4621 6.20553 34.8002 6.86644Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


);
export default icon;
