const Button = ({ label, onClick, disabled = false, className = 'font-bold text-base px-8 py-2 m-2 hover:text-purple-600 text-gray-900' }) => {
  return (
    <button
      className={`${className} bg-slate-100 rounded-md`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;

