import React from 'react';
import { useState } from 'react';
import { useCentralStore } from '../../../store/appContext';
import SubscriptionReport from './SubscriptionReport';
import ServiceRequestRevenueReport from './ServiceRequestRevenueReport';
import ServiceRequestReport from './ServiceRequestReport';
import NPSReport from './NPSReport';

const TrendCompoent = () => {

    const [ { chartReportCountSuccess }, dispatch ] = useCentralStore()
      
    let getBgColor = {0: '#1ED760', 1: '#685BC7', 2: '#C800A1', 3: '#500878', 4: '#1CD4B0', 5: '#09411D', 6: '#8CBDFA', 7: '#C800A1', 8: '#8A2252', 9: '#878787', 10: '#DC3545', 11: '#1ED760'}

    return(
        <div className='pb-10 mt-5'>
            <div className='flex flex-wrap justify-center'>
                <div className='w-[50%] mx-auto p-3' style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray'}}>
                    <SubscriptionReport 
                      chartReportCountSuccess={chartReportCountSuccess} 
                      reportName='Subscription'
                      getBgColor={getBgColor}
                    />
                </div>
                <div className='w-[50%] mx-auto p-3' style={{ borderBottom: '1px solid gray'}}>
                    <ServiceRequestReport
                      chartReportCountSuccess={chartReportCountSuccess} 
                      reportName='Service Request'
                      getBgColor={getBgColor}
                    />
                </div>
                <div className='w-[50%] mx-auto p-3' style={{ borderRight: '1px solid gray'}}>
                    <NPSReport
                      chartReportCountSuccess={chartReportCountSuccess} 
                      reportName='NPS'
                      getBgColor={getBgColor}
                    />
                </div>
                <div className='w-[50%] mx-auto p-3'>
                    <ServiceRequestRevenueReport 
                      chartReportCountSuccess={chartReportCountSuccess} 
                      reportName='Service Request Revenue'
                      getBgColor={getBgColor}
                    />
                </div>
            </div>
        </div>
        
    )
}

export default TrendCompoent