import React from "react";

const icon = ({ className, fill, onClick, stroke }) => (
  <svg className={className} width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M26.3332 32.5H4.6665" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.4997 35.417V35.417C15.7298 35.417 15.9163 35.6035 15.9163 35.8337V35.8337C15.9163 36.0638 15.7298 36.2503 15.4997 36.2503V36.2503C15.2696 36.2503 15.083 36.0638 15.083 35.8337V35.8337C15.083 35.6035 15.2696 35.417 15.4997 35.417" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M26.3332 25.8335V35.8335C26.3332 37.6744 24.8408 39.1668 22.9998 39.1668H7.99984C6.15889 39.1668 4.6665 37.6744 4.6665 35.8335V4.16683C4.6665 2.32588 6.15889 0.833496 7.99984 0.833496H16.3332" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M28.833 9.16699H36.333V1.66699" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M35.7629 9.16686C34.2446 4.86963 30.0127 2.14266 25.4721 2.53555C20.9315 2.92844 17.231 6.34179 16.4733 10.836C15.7157 15.3301 18.0926 19.768 22.2535 21.6278C26.4143 23.4876 31.3063 22.2988 34.1496 18.7369" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);
export default icon;
