import { DatePicker } from 'antd'
import API from '../../api'
import moment from 'moment'
import { useState, useEffect } from 'react'
import CrossIcon from '../../assets/icons/cross-icon'
import ActionTypes from '../../store/actionTypes'
import { useCentralStore } from '../../store/appContext'
import SGPReportsFilter from '../operationalReport/SGPReportsFilter'
import resources from '../../components/common/resources.json'
import axios from 'axios'
import Dropdown from '../../assets/Starhub/ic-chevron_big_down_new.png'
import StarhubLogo from "../../assets/icons/starhubLogo";
import config from "../../config/config";
import { useNavigate } from "react-router-dom";
import './DownloadReportsNew.css'; 
import { isEmpty } from '../../utils/functionUtils'
import exportToCSVInventory from '../../components/common/hepler/export-to-csv-submatricsData'

const DownloadReportsNew = () => {

    const [ { selectedDownloadReportDuration, selectedDownloadReportDurationLBL, userData, userDetails, selectedTab = "Download Reports", slaReportCountSuccess }, dispatch] = useCentralStore()

    const setLoading = (val) => {
        dispatch({
            type: ActionTypes.SET_LOADING,
            payload: val,
        });
    }

    let filterOptionsReal = [
      {
        label: 'Monthly',
        value: 'monthly'
      },
      {
        label: 'Quarterly',
        value: 'quarterly'
    }]

    let filterOptionsSLA = [
        {
          label: 'Monthly',
          value: 'monthly'
        }
    ]

    const [dates, setDates] = useState(null);
    const [value, setValue] = useState(null);
    const [downloadSuccessMsg, setDownloadSuccessMsg] = useState(false);

    const currentMonth = moment().month();
    const currentYear = moment().year();
    const disabledMonth = (current) => {
        const month = current.month();
        const year = current.year();
        return year > currentYear || (year === currentYear && month > currentMonth);
    };

    const disabledQuarter = (current) => {
        const month = current.month();
        const year = current.year();
        return year > currentYear || (year === currentYear && month > currentMonth);
    };

    const handleDateChange = (dates, dateString, type) => {
        dispatch({ type: ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION, payload: {
            type: type,
            duration: dateString
        }})
        setDates(dates);
        setDownloadSuccessMsg(false)
    };

    const handleFilterChange = (val) => {
        dispatch({ type: ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION_LABEL, payload: val})
        dispatch({ type: ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION, payload: {
            type: undefined,
            duration: undefined
        }})
        setDates(null)
        setDownloadSuccessMsg(false)
    }

    const closeDownloadPopup = () => {
        // dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: 'DownloadReport' })
        // dispatch({ type: ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION_LABEL, payload: undefined})
        // dispatch({ type: ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION, payload: {
        //     type: undefined,
        //     duration: undefined
        // }})
        // setDates(null)
    }

    const [isOpen, setIsOpen] = useState(true);
    const toggleDropdown = (val) => {
        dispatch({ type: ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION_LABEL, payload: undefined})
        dispatch({ type: ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION, payload: {
            type: undefined,
            duration: undefined
        }})
        setDates(null)
        setIsOpen(!isOpen);
        setIsReadyToDownload(false)
        setChecked([])
        setDownloadSuccessMsg(false)
    };

    const content = [
        "Real Time Service Request Status Report", 
        "Customer SLA Report", 
        "Technical SLA Report", 
        "Program Summary Report"
    ];

    const [checked, setChecked] = useState([]);
    const [selectedReportType, setSelectedReportType] = useState(null);
    const [isContentChecked, setIsContentChecked] = useState(false);
    const [isReadyToDownload, setIsReadyToDownload] = useState(false);

    useEffect(() => {
        setIsReadyToDownload(
            selectedReportType !== null && // Ensure report type is selected
            selectedDownloadReportDurationLBL !== null && // Ensure duration label is selected
            dates !== null // Ensure dates are selected
        );
    }, [selectedReportType, selectedDownloadReportDurationLBL, dates]);
    
    const filterOptions = (selectedReportType === "Real Time Service Request Status Report" ? filterOptionsReal : filterOptionsSLA)
    
    const handleCheck = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
          updatedList = [...checked, event.target.value];
          setIsContentChecked(true);
        } else {
          updatedList.splice(checked.indexOf(event.target.value), 1);
          setIsContentChecked(false);
        }
        setChecked(updatedList);
        setSelectedReportType(event.target.value);
        dispatch({ type: ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION_LABEL, payload: undefined})
        dispatch({ type: ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION, payload: {
            type: undefined,
            duration: undefined
        }})
        setIsReadyToDownload(false)
        setValue(null)
        setDownloadSuccessMsg(false)
    };
    
    var isChecked = (item) => checked.includes(item) ? "cursor-pointer" : "cursor-not-allowed";

    const reportTypeContainerClass = isContentChecked ? 'report-type-container border' : 'report-type-container';

    useEffect(() => {
        dispatch({ type: ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION_LABEL, payload: undefined})
        dispatch({ type: ActionTypes.SGP_SET_DOWNLOAD_REPORT_DURATION, payload: {
            type: undefined,
            duration: undefined
        }})
        dispatch({ type: ActionTypes.GET_SLA_REPORTS_DETAILS_SUCCESS, payload: undefined})
        
    }, [])
    
    useEffect(() => {
        if(selectedDownloadReportDuration?.duration !== undefined && (selectedReportType === "Customer SLA Report" || selectedReportType === "Technical SLA Report")) {
        const date = new Date()
        const month = '0'+date.getMonth()
        const year = date.getFullYear()

        let payload = {
            idToken: userData?.access_token,
            month: selectedDownloadReportDuration?.duration
          };
    
          setLoading(true);  
          API[ActionTypes.GET_SLA_REPORTS_DETAILS](payload, userDetails && userDetails?.email?.split("@")?.[0])
            .then(({ data }) => {
              setLoading(false);  
              if (data) {
                dispatch({
                  type: ActionTypes.GET_SLA_REPORTS_DETAILS_SUCCESS,
                  payload: data,
                });
              }
            })
            .catch((err) => {
              dispatch({
                type: ActionTypes.GET_SLA_REPORTS_DETAILS_FAILURE,
                payload: err,
              });
              dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
              let _payload = {
                level: resources.POST_LOGGER_ERROR,
                category: resources.POST_LOGGER_API,
                ApiName: resources.POST_LOGGER_APINAME,
                message: {
                  error: err,
                  info: {
                    idToken: "",
                  },
                },
              };
              API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
            }
          )
        }
    }, [selectedReportType, selectedDownloadReportDuration])

    const [slaData, setSLAData] = useState([])

    useEffect(() => {
        if(!isEmpty(slaReportCountSuccess)) {
            setSLAData(selectedReportType === "Customer SLA Report" ? slaReportCountSuccess?.GetSLADetailsResponse?.CustomerServiceSLA?.map(({ CalculationMethodology, CustomerServiceSLA, ...rest }) => rest) : slaReportCountSuccess?.GetSLADetailsResponse?.TechnicalSLA?.map(({ Descriptions, TechnicalSLA, ...rest }) => rest))
        }
    }, [slaReportCountSuccess])

    // console.log('slaReportCountSuccess', slaReportCountSuccess,slaData);

    const realTimeSRReportDownload = () => {
        let payload = {
            ReportType: "All",
            Range: selectedDownloadReportDuration?.duration
        }
        setLoading(true);
        API[ActionTypes.SGP_DOWNLOAD_REPORT](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
        .then(({ data }) => {
          if (data) {
            axios({
                url: data?.DownloadReportResponse?.Result, //your url
                method: 'GET',
                responseType: 'blob', // important
            })
            .then((response) => {
                setLoading(false);
                const href = URL.createObjectURL(response.data);
            
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${selectedReportType}_${selectedDownloadReportDuration?.duration}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
            
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                closeDownloadPopup()
                setDownloadSuccessMsg(true)
            })
            .catch((e)=>{
                setLoading(false);
                closeDownloadPopup()
                setDownloadSuccessMsg(false)
            })
          }
        })
        .catch((err) => {
            setLoading(false);
            closeDownloadPopup()
            dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
            let _payload = {
                level: resources.POST_LOGGER_ERROR,
                category: resources.POST_LOGGER_API,
                ApiName: resources.POST_LOGGER_APINAME,
                message: {
                error: err,
                info: {
                    idToken: "",
                },
                },
            };
            API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
        });
    }

    const downloadSLAReports = () => {

        let tableColumns = [];
        const totalKeys = {};
    
        slaData?.reduce((acc, current) => {
        const keys = Object.keys(current);
        for (var i in keys) {
            totalKeys[keys[i]] = 1;
        }
        }, totalKeys);
    
        var keys = Object.keys(totalKeys);
        for (var i in keys) {
        tableColumns.push({
            title: keys[i]?.replace(/([a-z0-9])([A-Z])/g, "$1 $2")?.toUpperCase(),
            dataIndex: keys[i],
            key: keys[i],
            width: 280
        });
        }

        exportToCSVInventory(
            tableColumns,
            slaData,
            "",
            "",
            selectedReportType,
            dispatch,
            ActionTypes
          )
    }

    const downloadReport = () => {
        if(selectedReportType === 'Real Time Service Request Status Report') {
            realTimeSRReportDownload()
        } else if(selectedReportType === "Customer SLA Report" || selectedReportType === "Technical SLA Report") {
            downloadSLAReports()
        } else {
            // downloadProgramSummaryReport()
        }
    } 


    return(
        <div className='bg-white h-screen mt-10 mx-20 flex flex-col items-center'>
            {/* <div >
                <StarhubLogo className="w-14 h-[50px] cursor-pointer" onClick={() => resetState(config.isSGP)} />
            </div> */}
            {downloadSuccessMsg && <p className='text-black text-center my-8'><span className='font-bold'>{selectedReportType}</span> downloaded for - {selectedDownloadReportDuration?.duration}</p>}
            <div className='flex'>
                <div className="">
                    <div className='min-w-[360px] border border-app-starhubGreen rounded-md'>
                        <div className='flex justify-between text-black font-bold my-4 pl-3'>
                            <p className='px-3'>Report Type</p>
                            <img
                                onClick={toggleDropdown}
                                className={`dropdown-icon ${isOpen ? 'open' : ''} mr-3`}
                                src={Dropdown}
                                alt='dropdown-icon' 
                            />
                        </div>
                        {isOpen && 
                            <div className="list-container">
                                {content.map((item, index) => (
                                    <div key={index} className='text-black text-sm border-t border-app-starhubGreen py-4 pr-14 pl-10'>
                                        <input value={item} type="radio" onChange={handleCheck} checked={selectedReportType === item} />
                                        <span className="ml-5">{item}</span>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
                <div className={`${!isEmpty(selectedReportType) ? 'border border-app-starhubGreen px-10 rounded-md' : ''}`}>
                    <div className='flex flex-row my-2 float-right mb-5 w-full'>
                        {isContentChecked && filterOptions?.map(v =>
                            <div className={`text-black relative rounded-md cursor-pointer py-2 px-5 flex justify-center items-center border-b border-app-starhubGreen mr-10 font-semibold
                                ${selectedDownloadReportDurationLBL === v.value ? 'border border-app-starhubGreen' : ''}`}
                                onClick={() => handleFilterChange(v.value)}
                            >
                                {v.label}
                            </div>
                        )}
                    </div>
                    {selectedDownloadReportDurationLBL === 'monthly' && isChecked &&
                        <DatePicker
                            allowClear={true}
                            className={`${selectedDownloadReportDurationLBL === 'monthly' && isChecked ? '' : 'hidden'}`}
                            picker="month"
                            onChange={(d, ds, t) => (handleDateChange(d, ds, 'month'))}
                            value={dates}
                            disabledDate={disabledMonth}
                        />
                    }
                    {selectedDownloadReportDurationLBL === 'quarterly' &&
                        <DatePicker
                            allowClear={true}
                            picker="quarter" 
                            className={`${selectedDownloadReportDurationLBL === 'quarterly' ? '' : 'hidden'} left-[32%]`}
                            onChange={(d, ds, t) => (handleDateChange(d, ds, 'quarter'))}
                            disabledDate={disabledQuarter}
                        />
                    }
                </div>
            </div>

            {selectedDownloadReportDurationLBL && isReadyToDownload &&
                <div className='flex mx-auto justify-center mt-10'>
                    <button
                        id="continue"
                        onClick={() => selectedDownloadReportDuration?.duration !== undefined ? downloadReport() : undefined}
                        className="px-4 py-2 bg-app-lightGreen w-80 rounded-lg text-black"
                        type="submit"
                    >
                        Download Report
                    </button>
                </div>
            }
        </div>
    );
};
export default DownloadReportsNew