import React from 'react'

const icon = ({ className, fill, onClick, primaryColor, secodaryColor }) => (
    // <svg className={className} onClick={onClick} width="230" height="60" viewBox="0 0 230 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    //     <path d="M202.343 2.34315C200.843 0.842856 198.808 0 196.686 0H8C3.58173 0 0 3.58172 0 8V52C0 56.4183 3.58172 60 8 60H196.686C198.808 60 200.843 59.1571 202.343 57.6569L228.586 31.4142C229.367 30.6332 229.367 29.3668 228.586 28.5858L202.343 2.34315Z" fill={`url(#paint0_linear_423_4539)${primaryColor}`} />
    //     <defs>
    //     <linearGradient id={`paint0_linear_423_4539${primaryColor}`} x1="0" y1="30" x2="230" y2="30" gradientUnits="userSpaceOnUse">
    //     <stop stop-color="#8223D2"/>
    //     <stop offset="0.4375" stop-color={primaryColor} />
    //     <stop offset="1" stop-color={secodaryColor} />
    //     </linearGradient>
    //     </defs>
    // </svg>

    <svg className={className} width="252" height="83" viewBox="0 0 252 83" fill="none" xmlns="http://www.w3.org/2000/svg">
<g >
<path d="M213.343 13.6873C211.843 12.187 209.808 11.3442 207.686 11.3442H19C14.5817 11.3442 11 14.9259 11 19.3442V63.3442C11 67.7625 14.5817 71.3442 19 71.3442H207.686C209.808 71.3442 211.843 70.5013 213.343 69.001L239.586 42.7584C240.367 41.9773 240.367 40.711 239.586 39.93L213.343 13.6873Z" fill={`url(#paint0_linear_31_4930${primaryColor})`} />
<path d="M213.697 13.3338C212.103 11.7397 209.941 10.8442 207.686 10.8442H19C14.3056 10.8442 10.5 14.6498 10.5 19.3442V63.3442C10.5 68.0386 14.3056 71.8442 19 71.8442H207.686C209.941 71.8442 212.103 70.9486 213.697 69.3546L239.939 43.1119C240.916 42.1356 240.916 40.5527 239.939 39.5764L213.697 13.3338Z" stroke=""/>
</g>
<g filter="url(#filter1_d_31_4930)">
<path d="M212.591 14.6166C211.097 13.1596 209.093 12.3442 207.006 12.3442H20C15.5817 12.3442 12 15.9259 12 20.3442V62.3442C12 66.7625 15.5817 70.3442 20 70.3442H207.006C209.093 70.3442 211.097 69.5287 212.591 68.0718L238.532 42.7761C239.337 41.9912 239.337 40.6972 238.532 39.9123L212.591 14.6166Z" fill={`url(#paint1_linear_31_4930${primaryColor})`} />
<path d="M207.006 12.8442C208.962 12.8442 210.841 13.6087 212.242 14.9746L238.183 40.2703C238.786 40.8589 238.786 41.8294 238.183 42.4181L212.242 67.7138C210.841 69.0797 208.962 69.8442 207.006 69.8442H20C15.8579 69.8442 12.5 66.4863 12.5 62.3442V20.3442C12.5 16.202 15.8579 12.8442 20 12.8442H207.006Z" stroke=""/>
</g>
<defs>
<filter id={`filter0_d_31_4930${primaryColor}`} x="0" y="0.344177" width="251.172" height="82" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.941176 0 0 0 0 0.941176 0 0 0 0 0.960784 0 0 0 0.5 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_4930"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_4930" result="shape"/>
</filter>
<filter id="filter1_d_31_4930" x="4" y="4.34418" width="243.135" height="74" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="4"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.913823 0 0 0 0 0.835208 0 0 0 0 0.95 0 0 0 1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_4930"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_4930" result="shape"/>
</filter>
<linearGradient id={`paint0_linear_31_4930${primaryColor}`} x1="11" y1="41.3442" x2="241" y2="41.3442" gradientUnits="userSpaceOnUse">
<stop stop-color={primaryColor} />
<stop offset="1" stop-color={secodaryColor} />
</linearGradient>
</defs>
</svg>


// {/* <svg className={`${className}`} width="244" height="85" viewBox="0 0 244 75" fill="none" xmlns="http://www.w3.org/2000/svg">
// <g filter={`url(#${primaryColor})`}>
// <path d="M208.591 10.6166C207.097 9.15964 205.093 8.34418 203.006 8.34418H16C11.5817 8.34418 8 11.9259 8 16.3442V58.3442C8 62.7625 11.5817 66.3442 16 66.3442H203.006C205.093 66.3442 207.097 65.5287 208.591 64.0718L234.532 38.7761C235.337 37.9912 235.337 36.6972 234.532 35.9123L208.591 10.6166Z" fill={`url(#paint0_linear_31_4932_${primaryColor})`}/>
// <path d="M203.006 8.84418C204.962 8.84418 206.841 9.60867 208.242 10.9746L234.183 36.2703C234.786 36.8589 234.786 37.8294 234.183 38.4181L208.242 63.7138C206.841 65.0797 204.962 65.8442 203.006 65.8442H16C11.8579 65.8442 8.5 62.4863 8.5 58.3442V16.3442C8.5 12.202 11.8579 8.84418 16 8.84418H203.006Z" stroke="#DFDFF4"/>
// </g>
// <defs>
// <filter id={primaryColor} x="0" y="0.344177" width="243.135" height="74" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
// <feFlood flood-opacity="0" result="BackgroundImageFix"/>
// <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
// <feOffset/>
// <feGaussianBlur stdDeviation="4"/>
// <feComposite in2="hardAlpha" operator="out"/>
// <feColorMatrix type="matrix" values="0 0 0 0 0.913823 0 0 0 0 0.835208 0 0 0 0 0.95 0 0 0 1 0"/>
// <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_31_4932"/>
// <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_31_4932" result="shape"/>
// </filter>
// <linearGradient id={`paint0_linear_31_4932_${primaryColor}`} x1="8" y1="37.3442" x2="236" y2="37.3442" gradientUnits="userSpaceOnUse">
// <stop stop-color={primaryColor} />
// <stop offset="1" stop-color={secodaryColor} />
// </linearGradient>
// </defs>
// </svg> */}

)
export default icon


