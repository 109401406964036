import React from 'react'
import AIReportTable from  "../../components/common/dataTable/AIReportTable"
import Filter from  "./Filter"

function AITradeIn() {
  return (
    <>
        <Filter/>
        <AIReportTable/>
    </>
  )
}

export default AITradeIn