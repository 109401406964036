import React from 'react'

const icon = ({ className, fill, onClick }) => 
(
<svg className={className} onClick={onClick} width="32" height="53" viewBox="0 0 32 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.47745 52.6009L0 47.1148L20.5144 26.6004L0 6.08597L5.47745 0.599854L31.4867 26.6004L5.47745 52.6009Z" fill={fill}/>
</svg>

)
export default icon


