import React from "react";

const icon = ({ className, fill, onClick }) => (
  <svg
    className={className}
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.3335 9.99967C13.3335 12.9452 10.9457 15.333 8.00016 15.333C5.05464 15.333 2.66683 12.9452 2.66683 9.99967C2.66683 7.05416 5.05464 4.66634 8.00016 4.66634C10.9457 4.66634 13.3335 7.05416 13.3335 9.99967ZM14.6668 9.99967C14.6668 13.6816 11.6821 16.6663 8.00016 16.6663C4.31826 16.6663 1.3335 13.6816 1.3335 9.99967C1.3335 6.31778 4.31826 3.33301 8.00016 3.33301C11.6821 3.33301 14.6668 6.31778 14.6668 9.99967ZM7.14271 13.1067L11.8341 7.77332L10.8329 6.89269L6.60669 11.6973L5.13389 10.2512L4.19977 11.2027L6.17508 13.1421L6.67759 13.6354L7.14271 13.1067Z"
      fill="#37E7A7"
    />
  </svg>
);
export default icon;
