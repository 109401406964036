import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';
import { useState } from 'react';
import { useCentralStore } from '../../../store/appContext';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SubscriptionReport = ({
    chartReportCountSuccess,
    reportName,
    getBgColor
}) => {

    const options = {
        plugins: {
          title: {
            display: true,
            text: reportName,
          },
          datalabels: {
            formatter: function(value, context) {
              // return value > 0 ? value+'%' : null;
            },
            // align: "top",
            labels: {
                usePointStyle: true,
                    font: {
                        weight: 700,
                        color: 'black',
                        size: 16,
                    }
            },
          }
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      };
      
    //   console.log('chartReportCountSuccess', chartReportCountSuccess);
      

      const enrollmentReportCount = {
        Months: chartReportCountSuccess['EnrollmentReportCount']?.map(v => v?.Month),
        ['Total Subscriptions']: chartReportCountSuccess['EnrollmentReportCount']?.map(v => v?.['Total Subscriptions']),
        ['Accepted Subscriptions']: chartReportCountSuccess['EnrollmentReportCount']?.map(v => v?.['Accepted Subscriptions']),
        ['Rejected Subscriptions']: chartReportCountSuccess['EnrollmentReportCount']?.map(v => v?.['Rejected Subscriptions'])
      }
      
      let labels = enrollmentReportCount?.Months

      let subscriptionLabel = ['Accepted Subscriptions', 'Rejected Subscriptions']

       let _reportData =[]
      for(let i=0; i < (subscriptionLabel.length); i++){
        _reportData.push({
            label: subscriptionLabel[i],
            data: enrollmentReportCount[subscriptionLabel[i]],
            backgroundColor: getBgColor[i],
            stack: 0
        })
      }

       const reportData = {
        labels,
        datasets: _reportData,
      };

    return(
        <div>
            <Bar options={options} data={reportData} />
        </div>
    )
}

export default SubscriptionReport