import { isEmpty } from "../../utils/functionUtils";
import { DatePicker } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import { useCentralStore } from "../../store/appContext";
import ActionTypes from "../../store/actionTypes";
import { programTypes, AIClients, ProgramClientMapping } from "../../components/common/dataTable/constants";
import Selector from "../../components/common/selector/selector";

const { RangePicker } = DatePicker;

const AIReportFilter = () => {
  const [
    {
      selectedReportFilter,
      startDateToShow,
      endDateToShow,
      AIReportFilterDuration,
      selectedAIReportProgramType,
      selectedAIReportClient
    },
    dispatch,
  ] = useCentralStore();

  let filterOptions = [
    {
      label: "Select Dates",
      value: "real time",
    }
  ];

  const [dates, setDates] = useState(null);
  const [changedRealTime, setChangedRealTime] = useState(true);
  const [filteredClients, setClients] = useState(AIClients);

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_AI_REPORT_FILTER_DURATION,
      payload: {
        type: null,
        startDate: AIReportFilterDuration?.startDate ? AIReportFilterDuration?.startDate : moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD 00:00:00'),
        endDate: AIReportFilterDuration?.endDate ? AIReportFilterDuration?.endDate : moment(new Date()).format('YYYY-MM-DD 23:59:59'),
        duration: null,
      },
    });
    dispatch({
      type: ActionTypes.SET_AI_REPORT_PROGRAM_TYPE,
      payload: programTypes[0]?.name
    });
    handleSelectClient(AIClients[0]?.name)
  }, []);

  useEffect(()=>{
    if(selectedAIReportProgramType)
    {
      let filteredClients = ProgramClientMapping?.find((item)=> item?.program === selectedAIReportProgramType)?.clients
      setClients(filteredClients);
    }
  },[selectedAIReportProgramType])

  useEffect(() => {
    if (dates && dates.length === 2) {
      const startDate = dates[0];
      const endDate = dates[1];
  
      dispatch({
        type: ActionTypes.SET_START_DATE,
        payload: startDate.format("YYYY-MM-DD 00:00:00"),
      })
      dispatch({
        type: ActionTypes.SET_END_DATE,
        payload: endDate.format("YYYY-MM-DD 23:59:59"),
      })
    }
  }, [dates]);

  const handleDateChange = (dates, dateString, type) => {
    dispatch({
      type: ActionTypes.SET_AI_REPORT_FILTER_DURATION,
      payload: {
        type: type,
        startDate: moment(dateString[0]).format("YYYY-MM-DD 00:00:00"),
        endDate: moment(dateString[1]).format("YYYY-MM-DD 23:59:59"),
        duration: dateString,
      },
    });
    setDates(dates);
  };

  const handleFilterChange = () => {
    let startDate = AIReportFilterDuration?.startDate ? AIReportFilterDuration?.startDate : moment(new Date(Date.now() - 12 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD 00:00:00");
    let endDate = AIReportFilterDuration?.endDate ? AIReportFilterDuration?.endDate : moment(new Date()).format("YYYY-MM-DD 23:59:59");
    dispatch({
      type: ActionTypes.SET_START_DATE,
      payload: startDate,
    });
    dispatch({
      type: ActionTypes.SET_END_DATE,
      payload: endDate,
    });
    setDates(null);
    setChangedRealTime(false);
  };

  const handleSelectProgramType=(value)=>{
    dispatch({
      type: ActionTypes.SET_AI_REPORT_PROGRAM_TYPE,
      payload: value
    });
    let filteredClients = ProgramClientMapping?.find((item)=> item?.program === selectedAIReportProgramType)?.clients
    setClients(filteredClients);  
    dispatch({
      type: ActionTypes.GET_AI_PERFORMANCE_REPORT_SUCCESS,
      payload: [],
    });
  }

  const handleSelectClient=(value)=>{
    dispatch({
      type: ActionTypes.SET_AI_REPORT_CLIENT_NAME,
      payload: value
    });
  }

  const disabledDate = (current) => {
    return (
      (current && current > moment().endOf("day")) ||
      (current && current < moment().subtract(90, "days"))
    );
  };

  const formattedStartDate = moment(startDateToShow).format("DD-MM-YYYY");
  const formattedEndDate = moment(endDateToShow).format("DD-MM-YYYY");

  return (
    <>
      <div className={`px-4 flex flex-row justify-between`}>
        <div className="pt-4 flex flex-row">
          <Selector
                key="Program Type"
                options={programTypes}
                label="Program Type"
                value={selectedAIReportProgramType}
                onSelect={(name, val) => handleSelectProgramType(name, val)}
                placeholder="Program Type"
                isAIReport={true}
          />
          <Selector
                key="Client Name"
                options={filteredClients}
                label="Client Name"
                value={selectedAIReportClient}
                onSelect={(name, val) => handleSelectClient(name, val)}
                placeholder="Client Name"
                isAIReport={true}
              
          />
        </div>
        <div>
        <div>
          <div className="pt-3 flex justify-end">
            {(isEmpty(selectedReportFilter) ||
              selectedReportFilter === "real time") &&
              filterOptions?.map((v) => (
                <div
                  className={`text-white relative rounded-md text-[16px] cursor-pointer py-2 px-3 flex justify-center items-center border-b bg-black border-app-border font-bold
                    ${
                      selectedReportFilter === v.value
                        ? "border border-app-border"
                        : ""
                    }`}
                  onClick={() => handleFilterChange(v.value)}
                >
                  {v.label}
                </div>
              ))}
          </div>
        </div>
        {selectedReportFilter === "real time" && (
          <div>
            {changedRealTime ? (
              <div>
                {dates && dates.length === 2 ? (
                  <p
                    className="text-[18px] text-white mt-2"
                    onClick={() => setChangedRealTime(false)}
                  >
                    Showing data from <b>{formattedStartDate}</b> to{" "}
                    <b>{formattedEndDate}</b>.
                  </p>
                ) : (
                  <p
                    className="text-[18px] text-white mt-2"
                    onClick={() => setChangedRealTime(false)}
                  >
                    Showing data for last <b>7</b> days.
                  </p>
                )}
              </div>
            ) : (
              <RangePicker
                allowClear={true}
                className="bg-white hover:bg-white mt-2"
                value={dates}
                onChange={handleDateChange}
                disabledDate={disabledDate}
              />
            )}
          </div>
        )}
        </div>
      </div>
    </>
  );
};

export default AIReportFilter;