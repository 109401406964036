import { useCentralStore } from "../../store/appContext";
import HeaderInquiry from "./headerInquiry";
import Menu from "./menu"
import AITradeIn from "../AIReport/AITradeIn";
import AISummaryReport from "../AISummaryReport/AITradeIn";
import InquiryTradeIn from "./InquiryTradeIn";

const InquiryMenu = () => {

  const [{ reportType }] = useCentralStore();

  return (
    <>
      <div className=" bg-black min-h-screen ">
        <div className=""><HeaderInquiry /></div>
        {!reportType && <Menu/>}
        {reportType === "Inquiry" &&  <InquiryTradeIn/>}
        {reportType === "AI" && <AITradeIn/>}
        {reportType === "AI_Summary" && <AISummaryReport/>}
      </div>
    </>
  );
};

export default InquiryMenu;