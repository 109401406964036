import React from 'react'

const icon = ({ className, fill, onClick }) => 
(
    <svg className={className} onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="16" height="16" rx="4" fill={fill} />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5 4L8.5 12L7.5 12L7.5 4L8.5 4Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7.5L4 7.5L4 8.5L12 8.5L12 7.5Z" fill="white"/>
    </svg>

)
export default icon


