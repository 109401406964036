import React from "react";

const icon = ({ className, fill, onClick }) => (
  <svg
    className={className}
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.1294 15.3341L7.99969 5.54093L2.86995 15.3341H13.1294ZM14.2312 16.6674H1.76816C1.26706 16.6674 0.945089 16.1353 1.1776 15.6914L7.40914 3.79483C7.6587 3.31839 8.34069 3.31839 8.59025 3.79483L14.8218 15.6914C15.0543 16.1353 14.7323 16.6674 14.2312 16.6674ZM7.33332 12.6673V8.00059H8.66666V12.6673H7.33332ZM7.99969 14.6674C8.36789 14.6674 8.66636 14.3689 8.66636 14.0007C8.66636 13.6326 8.36789 13.3341 7.99969 13.3341C7.63151 13.3341 7.33303 13.6326 7.33303 14.0007C7.33303 14.3689 7.63151 14.6674 7.99969 14.6674Z"
      fill="#FF5070"
    />
  </svg>
);
export default icon;
