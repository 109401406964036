import React from 'react'

const icon = ({ className, fill, onClick }) => 
(
    <svg className={className} onClick={onClick} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.5 18V11.5C20.4961 7.95026 18.2867 4.77673 14.959 3.541C14.7226 2.08937 13.4687 1.02316 11.998 1.02316C10.5273 1.02316 9.27338 2.08937 9.037 3.541C5.70956 4.77636 3.50125 7.95064 3.5 11.5V18C3.5 19.6569 2.15685 21 0.5 21H23.5C21.8431 21 20.5 19.6569 20.5 18Z" stroke="#A5AAAF" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15 21C15 22.6569 13.6569 24 12 24C10.3431 24 9 22.6569 9 21" stroke="#A5AAAF" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

)
export default icon


