import React from "react";
import CommonError from "./Error/CommonError";
import API from "./api";
import ActionTypes from "./store/actionTypes";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: "", errorInfo: "" };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.message, errorInfo: error };
  }

  componentDidCatch(e, info) {
    let payload = {
      level: "error",
      category: "UI",
      ApiName: "OneOps-UI",
      message: { info, error: e?.toString() },
    };
    API[ActionTypes.POST_LOGGER](payload, this.props.userData?.access_token, this.props?.userDetails?.email?.split("@")?.[0]);
  }

  render() {
    if (this.state.hasError || this.props.isApiError) {
      return (
        <CommonError
          isExceptionalError={this.state.hasError}
          errorInfo={this.state.errorInfo}
          errorMessage={this.state.errorMessage}
        />
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
