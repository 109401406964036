import React from "react";

const icon = ({ className, fill, onClick, stroke }) => (
  <svg className={className} width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M18.0002 27.5002V33.3335C18.0002 35.1744 16.5078 36.6668 14.6668 36.6668H4.66683C2.82588 36.6668 1.3335 35.1744 1.3335 33.3335V6.66683C1.3335 4.82588 2.82588 3.3335 4.66683 3.3335H14.6668C16.5078 3.3335 18.0002 4.82588 18.0002 6.66683V12.5002" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18.0002 30H1.3335" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M23 12.5002V6.66683C23 4.82588 24.4924 3.3335 26.3333 3.3335H36.3333C38.1743 3.3335 39.6667 4.82588 39.6667 6.66683V33.3335C39.6667 35.1744 38.1743 36.6668 36.3333 36.6668H26.3333C24.4924 36.6668 23 35.1744 23 33.3335V27.5002" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M39.6667 30H23" stroke='#d648fd' stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M8 20H33" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M13 25.0254L8 20.0254L13 15.0254" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M28 25.0254L33 20.0254L28 15.0254" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M9.66667 32.917C9.89679 32.917 10.0833 33.1035 10.0833 33.3337V33.3337C10.0833 33.5638 9.89679 33.7503 9.66667 33.7503V33.7503C9.43655 33.7503 9.25 33.5638 9.25 33.3337V33.3337C9.25 33.1035 9.43655 32.917 9.66667 32.917" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M31.3337 32.917C31.5638 32.917 31.7503 33.1035 31.7503 33.3337V33.3337C31.7503 33.5638 31.5638 33.7503 31.3337 33.7503V33.7503C31.1035 33.7503 30.917 33.5638 30.917 33.3337V33.3337C30.917 33.1035 31.1035 32.917 31.3337 32.917" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);
export default icon;
