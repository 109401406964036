import React from 'react'
import AIReportSummaryTable from  "../../components/common/dataTable/AIReportSummaryTable"
import Filter from  "./Filter";


function AITradeIn() {
  return (
    <>
        <Filter/>
        <AIReportSummaryTable/>
    </>
  )
}

export default AITradeIn