import { DatePicker } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import { useCentralStore } from "../../store/appContext";
import ActionTypes from "../../store/actionTypes";
import { programTypes, AIClients, ProgramClientMapping } from "../../components/common/dataTable/constants";
import Selector from "../../components/common/selector/selector";

const AIReportFilter = () => {
  const [
    {
      selectedAIReportProgramType,
      selectedAIReportClient
    },
    dispatch,
  ] = useCentralStore();
  
  const [year, setYear] = useState();
  const [filteredClients, setClients] = useState(AIClients);

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_AI_REPORT_PROGRAM_TYPE,
      payload: programTypes[0]?.name
    });
    dispatch({
      type: ActionTypes.SET_AI_REPORT_YEAR,
      payload: moment()
    });
    handleSelectClient(AIClients[0]?.name)
  }, []);


  useEffect(()=>{
    if(selectedAIReportProgramType)
    {
      let filteredClients = ProgramClientMapping?.find((item)=> item?.program === selectedAIReportProgramType)?.clients
      setClients(filteredClients);
    }
  },[selectedAIReportProgramType])

  const handleSelectProgramType=(value)=>{
    dispatch({
      type: ActionTypes.SET_AI_REPORT_PROGRAM_TYPE,
      payload: value
    });
    let filteredClients = ProgramClientMapping?.find((item)=> item?.program === selectedAIReportProgramType)?.clients
    setClients(filteredClients);  
    dispatch({
      type: ActionTypes.GET_AI_SUMMARY_REPORT_SUCCESS,
      payload: [],
    });
  }

  const handleSelectClient=(value)=>{
    dispatch({
      type: ActionTypes.SET_AI_REPORT_CLIENT_NAME,
      payload: value
    });
  }

  const handleYearChange =(year)=>{
    setYear(year);
    dispatch({
      type: ActionTypes.SET_AI_REPORT_YEAR,
      payload: year
    });
  }


  return (
    <>
      <div className={`px-4 flex flex-row justify-between py-4`}>
        <div className="pt-4 flex flex-row">
          <Selector
                key="Program Type"
                options={programTypes}
                label="Program Type"
                value={selectedAIReportProgramType}
                onSelect={(name, val) => handleSelectProgramType(name, val)}
                placeholder="Program Type"
                isAIReport={true}
          />
          <Selector
                key="Client Name"
                options={filteredClients}
                label="Client Name"
                value={selectedAIReportClient}
                onSelect={(name, val) => handleSelectClient(name, val)}
                placeholder="Client Name"
                isAIReport={true}
              
          />
        </div>
        <div>
        <p className="mb-1 text-sm">Select Year</p>
          <DatePicker className="max-w-[120px] w-full"   value={year} onChange={handleYearChange} picker="year" />
        </div>
        
      </div>
    </>
  );
};

export default AIReportFilter;