import { getGuageIcons, getTypeIndicator, numberWithCommas } from "../../utils/functionUtils"
import ArrowDiv from '../../assets/icons/arrowDiv'
import DashboardArrow from '../../assets/icons/dashboardArrow'
import InfoIcon from '../../assets/icons/infoIcon'
import CrossIcon from '../../assets/icons/cross-icon'
import { useState } from "react"
import ActionTypes from "../../store/actionTypes"
import API from "../../api"
import { useCentralStore } from "../../store/appContext"
import AnimatedNumbers from "react-animated-numbers";
import LoadingNumbers from '../../assets/icons/loadingGif'
import resources from'../../components/common/resources.json'
import Popup from "../../components/common/popup/popup"
import SGPDataTable from "../../components/common/dataTable/SGPDataTable"

const SHDashboardData = () => {

    const [ { userData, userDetails, reportFilterDuration, selectedTab, dashboardLoading, reportDetailsSuccess, reportCountSuccess }, dispatch ] = useCentralStore()

    let dataBlocks = [
        {
            label: 'SUBSCRIPTION', 
            reportType: 'enrollment', 
            value: 0, 
            metricsTypeIndicator: 'record_count',
            subMetrics: [
                {label: "Total Subscription", keyLabel: "TotalCount", value: 0, subMetricsTypeIndicator: 0},
                {label: "Accepted Subscription", keyLabel: "AcceptedCount", value: 0},
                {label: "Rejected Subscription", keyLabel: "RejectedCount", value: 0}
            ]
        },
        {
            label: 'SERVICE REQUEST', 
            reportType: 'servicerequest', 
            value: 0, 
            subMetrics: [
                {label: "Total Service Requests", keyLabel: 'Total Service Requests', value: 100},
                {label: "Accepted Service Requests",keyLabel: "Accepted Service Requests", value: 0},
                {label: "Cancelled Service Requests",keyLabel: "Canceled Service Requests", value: 0},
                {label: "Rejected Service Requests",keyLabel: "Rejected Service Requests", value: 0}
            ]
        },
        {
            label: 'NPS', 
            reportType: 'NPS', 
            value: 0, 
            subMetrics: [
                {label: "Promoters", keyLabel: 'Promoters', value: 100},
                {label: "Detractors",keyLabel: "Detractors", value: 0},
                {label: "Passive",keyLabel: "Passive", value: 0}
            ]
        },
        {
            label: 'SERVICE REQUEST REVENUE', 
            reportType: 'ServiceRequestRevenue', 
            value: 0, 
            metricsTypeIndicator: 'Currency_Sum',
            subMetrics: [
                {
                  label: "SR Revenue(excl. GST)", 
                  SubMetricsTypeIndicator : "Currency_Sum", 
                  keyLabel : "SR Revenue(excl. GST)",
                  value: 0
                },
                {
                  label: "SR Revenue GST", 
                  SubMetricsTypeIndicator : "Currency_Sum", 
                  keyLabel : "SR Revenue GST",
                  value: 0
                },
            ]
        },
    ]


    const getValue = (l, r) => {
        return reportCountSuccess?.Metrics?.filter(v => v?.ReportType.toLowerCase() === r?.toLowerCase() && v?.label.toLowerCase() === l?.toLowerCase())[0]?.value
    }

    const getSubmetricsValue = (l, index) => {
        return reportCountSuccess?.Metrics?.[index]?.submetrics?.filter(v => v?.label.toLowerCase() === l?.toLowerCase())[0]?.value
    }

    const setLoading = (action) => {
        dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
      };

    const [isOpen, openPopup] = useState(false);
    const [classInOut, setClassInOut] = useState(null);
    const [_label, setLabel] = useState(null);
    const [_index, setIndex] = useState(null);
    const [_metricsLabel, setMetricsLabel] = useState(null);
    const [_reportType, setReportType] = useState(null);
    const [_subLabel, setSubLabel] = useState(null);

    const getReportDetails = (reportType, label, index, mLabel, subLabel) => {
        setLabel(label)
        setIndex(index)
        setMetricsLabel(mLabel)
        setReportType(reportType)
        setSubLabel(subLabel)
        dispatch({
          type: ActionTypes.GET_SUBMATRICS_DETAILS_SUCCESS,
          payload: [],
        });
        let payload = {
          StartDate: reportFilterDuration?.startDate,
          EndDate: reportFilterDuration?.endDate,
          ReportType: reportType,
          Type: reportType.toLowerCase() === 'servicerequest' ? undefined : label ,
          StatusCode: reportType.toLowerCase() === 'servicerequest' ? label : undefined,
          StartingRow: 1,
          RowsCount: 10
      }
      setLoading(true)
      API[ActionTypes.GET_STARHUB_REPORT_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
          setLoading(false);
          if (data) {
            openPopup(true);
            setClassInOut("modal-container modal-space-in");
            dispatch({
              type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS,
              payload: data,
            });
          }
        })
        .catch((err) => {
          openPopup(false);
          dispatch({
            type: ActionTypes.GET_STARHUB_REPORT_DETAILS_FAILURE,
            payload: err,
          });
          dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
          let _payload = {
            level: resources.POST_LOGGER_ERROR,
            category: resources.POST_LOGGER_API,
            ApiName: resources.POST_LOGGER_APINAME,
            message: {
              error: err,
              info: {
                idToken: "",
              },
            },
          };
          API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
        });
      }
    
      let infoData = [{
        label: 'TotalCount',
        reportType: 'enrollment',
        info: 'Total subscriptions received for Smart support program (In Selected time variant)'
      },
      {
        label: 'AcceptedCount',
        reportType: 'enrollment',
        info: 'Accepted subscriptions for Smart support program (In Selected time variant)'
      },
      {
        label: 'RejectedCount',
        reportType: 'enrollment',
        info: 'Rejected subscriptions for Smart support program (In Selected time variant)'
      },
      {
        label: 'Total Service Requests',
        reportType: 'servicerequest',
        info: 'Total Service requests raised for Smart support program (In Selected time variant)'
      },
      {
        label: 'Canceled Service Requests',
        reportType: 'servicerequest',
        info: 'Cancelled Service requests(by customer, no response from customer or entitlements exceeded  for Smart support program (In Selected time variant)'
      },
      {
        label: 'Accepted Service Requests',
        reportType: 'servicerequest',
        info: 'Completed Service requests for Smart support program(In Selected time variant)'
      },
      {
        label: 'Rejected Service Requests',
        reportType: 'servicerequest',
        info: 'Rejected Service requests for Smart support program(In Selected time variant)'
      },
      {
        label: 'Promoters',
        reportType: 'NPS',
        info: 'Customers responded with a score of 9 or 10. They are highly satisfied with the product or service and are enthusiastic advocates who actively recommend it to others.'
      },
      {
        label: 'Detractors',
        reportType: 'NPS',
        info: 'Customers responded with a score of 7 or 8. They are generally satisfied with the product or service but are not as enthusiastic or loyal as Promoters.'
      },
      {
        label: 'Passive',
        reportType: 'NPS',
        info: 'Customers responded with a score of 0 to 6. They are dissatisfied or unhappy with the product or service and are more likely to share negative feedback with others.'
      },
      {
        label: 'SR Revenue(excl. GST)',
        reportType: 'ServiceRequestRevenue',
        info: 'Total Service request fee charged without GST for all service request (In Selected time variant)'
      },
      {
        label: 'SR Revenue GST',
        reportType: 'ServiceRequestRevenue',
        info: 'Total Service request fee GST amount charged   for all service request (In Selected time variant)'
      }
    ]
    
    const [openTooltip, setOpenTooltip] = useState(false)
    const [tooltipText, setTooltip] = useState(null)
    const [tooltipTextType, setTooltipType] = useState(null)
    
    const openInfoPopup = (e, label, reportType) => {
      e.stopPropagation()
      setOpenTooltip(true)
      setTooltipType(label)
      setTooltip(infoData?.filter(v => v.reportType?.toLowerCase() === reportType?.toLowerCase() && v.label?.toLowerCase() === label?.toLowerCase())[0]?.info)
    }

    const [stopAnimation, setStopAnimation] = useState(false);

    setTimeout(() => {
        setStopAnimation(true);
      }, [5000]);


      const openPopup1 = () => {
        setTimeout(() => {
          openPopup(false);
          dispatch({
            type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS,
            payload: [],
          });
        }, [2000]);
      };

    return(
        <div className="flex">
            {dataBlocks?.map((v, index) =>
                <div className="flex flex-col p-2 w-60 mx-8">
                <div className={``}>
                    <div className="text-center">
                        <div className={`text-3xl font-bold mb-1 text-black  flex justify-center items-center`}>
                            {getGuageIcons(v.label, '#1ED760')}
                            <p>
                                {getTypeIndicator(v.metricsTypeIndicator, null)}
                                {/* {v.value ? numberWithCommas(Math?.round(v.value)) : 0} */}
                                {numberWithCommas(Math?.round(getValue(v.label, v.reportType))) || 0}
                            </p>
                        </div>
                    </div>
                    <div className="relative">
                        <div>
                            <p
                            className={`text-xs  absolute font-bold text-center`}
                            style={{
                                color: 'black',
                                top: "50%",
                                right: "48%",
                                transform: "translate(50%, -50%)",
                            }}
                            >
                            {v?.label?.toUpperCase()}
                            </p>
                            <ArrowDiv
                                className=""
                                primaryColor={'#00BFB2'}
                                secodaryColor={'#03F182'}
                                label={v?.label?.toUpperCase()}
                            />
                        </div>
                            {!(index === dataBlocks?.length - 1) && (
                                <DashboardArrow
                                    className={`w-10 h-10 absolute right-m32% top-23%`}
                                    fill={'#03F182'}
                                />
                            )}
                        </div>
                </div>
                <div className="flex flex-col mx-3">
                    {v?.subMetrics?.map((s) => 
                        <>
                        <div className={`${v?.status?.toLowerCase()}-cards rounded-xl shadow-card text-black p-2 border-3px my-3 bg-card-greenBg border-card-greenBorder shadow-card-greenBorder 
                            ${tooltipTextType !== s?.keyLabel ? 'transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500' : '' } perf-card
                            ${getSubmetricsValue(s?.keyLabel, index) === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                            onClick={(evt) => getSubmetricsValue(s?.keyLabel, index) === 0 ? undefined : getReportDetails(v?.reportType, s?.keyLabel, index, v?.label, s?.label)}
                        >
                            <div className="flex flex-row justify-between w-full" id="label" >
                                <div className={` text-sm bg-inherit text-app-border w-[90%]`} id="label1">{s?.label}</div>
                                    <a
                                    className="cursor-pointer relative float-right w-auto text-xs ml-5 mt-1"
                                    onClick={(e) => openInfoPopup(e, s?.keyLabel, v?.reportType)}
                                    >
                                    <InfoIcon className="w-3 h-3" fill="green" />
                                    <div
                                        className={`absolute flex flex-row justify-between items-center right-0 mx-auto w-[200px] ${(openTooltip && tooltipTextType === s?.keyLabel) ? 'visible' : 'hidden'
                                        }  z-10 py-2 px-4 text-14 rounded-md border text-blue-700 shadow-lg border-blue-700`}
                                        style={{ backgroundColor: '#F0F3FF' }}
                                    >
                                        {tooltipText}
                                        <CrossIcon className="w-6 h-6" onClick={(e) => (e.stopPropagation(), setOpenTooltip(false), setTooltip(null))} />
                                    </div>
                                    </a>
                                </div>
                            <div id="value" className="text-2xl mt-5 font-normal text-black flex flex-row justify-between items-center px-1 h-8">
                                <div className="flex">
                                    <p className={` mr-[2px]`}>{getTypeIndicator(s.SubMetricsTypeIndicator, null)}</p>
                                    {dashboardLoading ? 
                                    <LoadingNumbers className="w-10 h-10" fill={'green'} />
                                    :
                                    stopAnimation ? (
                                        <div> 
                                            <p> 
                                                {/* {numberWithCommas(Math.round(Number(v?.value)))}  */}
                                                {isNaN(Number(getSubmetricsValue(s?.keyLabel, index))) ? 0 : numberWithCommas(Math.round(Number(getSubmetricsValue(s?.keyLabel, index)))) || 0}
                                            </p>
                                        </div>
                                    ) : (
                                        <AnimatedNumbers
                                            includeComma
                                            animateToNumber={Math.round(isNaN(getSubmetricsValue(s?.keyLabel, index)) ? 0 : Math.round(getSubmetricsValue(s?.keyLabel, index))) || 0}
                                            // fontStyle={{ fontSize: 24 }}
                                            configs={(number, index) => {
                                                return {
                                                mass: number,
                                                tension: 230 * (index + 1),
                                                friction: 140,
                                                };
                                            }}
                                            ></AnimatedNumbers>
                                        )
                                    }
                                </div>
                                </div>
                                
                        </div>
                        </>
                        
                    )}
                </div>
                </div>
            )}
            <Popup
                show={isOpen}
                global={true}
                className={`rounded-xl ${classInOut}`}
                fitContent={true}
                // onClose={() => openPopup(false)}
                submatricsDetailsData={reportDetailsSuccess}
                submatrics={true}
                >
                {(isOpen && (reportDetailsSuccess)) && (
                    <SGPDataTable
                        reportDetailsSuccess={reportDetailsSuccess}
                        label={_label}
                        value={getSubmetricsValue(_label, _index)}
                        MetricsLabel={_metricsLabel}
                        onClose={(e) => ( openPopup1(), setClassInOut("modal-container modal-space-out"), e.stopPropagation()
                        )}
                        resources={resources}
                        reportType={_reportType}
                        subLabel={_subLabel}
                    />
                    )}
                </Popup>
        </div>
    )
}

export default SHDashboardData