const ServiceReqReport = ({reportCountSuccess, getReportDetails, reportAction}) => {

    return(
        <div>
            <div className='flex gap-6 my-6'>
                {reportCountSuccess?.map(v => {
                    return <p className={`${v?.SrStatus === reportAction ? 'text-black bg-app-greenBorder' : 'text-white hover:text-app-greenBorder'} rounded-lg text-sm cursor-pointer font-semibold w-44 md:w-64 flex flex-col justify-start border-1 border-gray-700 bg-app-border mb-1 mt-2 py-2 px-4`}
                        onClick={() => getReportDetails(v?.SrStatus)}>
                        {v?.SrStatus}: <a className="mt-1" target='_blank'>
                            {v?.Count}
                    </a></p>
                    })
                }
            </div>
        </div>
    )
}

export default ServiceReqReport;