import axios from "axios";
import ActionTypes from "./store/actionTypes";
import types from "./store/actionTypes";
import { getUniqueValuesFromArray } from "./utils/functionUtils";
import config from "./config/config";
import { Auth } from 'aws-amplify';

import  AuthService  from './utils/authSerivce'

const auth = new AuthService


const http = axios.create({
  // baseURL: '/',
  // baseURL: 'https://api-oneops-uat.consoleone.apac.prd.aws.asurion.net/',
  // baseURL: 'https://oneops-uat.apac.asurion.com/',
  baseURL: config?.baseURL,
  responseType: "json",
});

async function signIn(username, password) {
  return await Auth.signIn(username, password)
}

async function signOut() {
  return await Auth.signOut()
}

const API = {};
let headers = {
  Accept: "application/json",
  // "x-api-key": 'YEuqiwRh3Ku9XJBOPHXH8WVWwT2qaTU8IegxDcu9',
  "x-api-key": config?.xApiKey,
  "Asurion-client": "samsung", //(window.client || "").toLowerCase(),
  "Content-Type": "application/json",
  "Asurion-channel": "agent",
  "Asurion-region": "APAC-SEA",
  "Asurion-lineofbusiness": "Mobility",
  pragma: "no-cache",
  "X-Requested-With": "XMLHttpRequest",
  Expires: "-1",
  "If-Modified-Since": "Thu, 13 Feb 1985 13:40:01 GMT",
  "Cache-Control": "no-cache,no-store,must-revalidate,max-age=-1,private",
  "Asurion-application": "oneops",
};


API[types.GET_CLIENTS] = (dispatch) =>
  http.get("api/getalldashboards", { headers }).then((data) => {
    const response = data.data?.GetAllDashboardsResponse;
    let uniqueClients = getUniqueValuesFromArray(response, "ClientId");
    dispatch({ type: ActionTypes.SET_LOADING, payload: false });
    if (response) {
      uniqueClients = uniqueClients.map((c) => {
        const channelsArray = response
          .filter((r) => r.ClientId === c.ClientId)
          .map((m) => ({
            Channel: m.Channel,
            Label: m.Label,
            DashboardId: m.DashboardId,
          }));
        return {
          Client: c.Client,
          ClientId: c.ClientId,
          Channels: channelsArray,
        };
      });

      dispatch({
        type: ActionTypes.GET_CLIENTS_SUCCESS,
        payload: uniqueClients,
      });
    }
    return data;
  });

API[types.GET_ROLE_BASES_MATRICS] = (payload, userName) =>
  http.get("api/getrolebasedmetrics", {
    headers: { ...headers, Authorization: payload?.idToken, 'Asurion-enduser': userName || 'oneops' },
  });

API[types.GET_DASHBOARD_DETAILS] = (payload, idToken, userName) =>
  http.post(
    "api/getdashboarddetails",
    { GetDashboardRequest: payload },
    { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } }
  );

API[types.GENERATE_TOKEN] = (url, payload, headers) =>
  http.post(url, payload, headers);

API[types.GET_SUBMATRICS_DETAILS] = (payload, idToken, userName) =>
  http.post(
    "api/getsubmetricsdetails",
    { GetSubMetricsDetailsRequest: payload },
    { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } }
  );

API[types.POST_LOGGER] = (payload, idToken, userName) =>
  http.post(
    "api/logger",
    { LoggerRequest: payload },
    { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } }
  );

API[types.ADD_FAVOURITE] = (payload, idToken, userName) =>
  http.post(
    "api/addfavourites",
    { AddUpdateFavouriteRequest: payload },
    { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } }
  );

API[types.GET_CHILD_SUBMATRICS] = (payload, idToken, userName) =>
  http.post("api/getchildsubmetrics", 
    { GetChildSubMetricsRequest: payload },
    {headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' }
  });

API[types.GET_TRANSACTION_REPORTS] = (payload, userName) =>
  http.get("api/gettransactionalreports", {
    headers: { ...headers, Authorization: payload?.idToken, 'Asurion-enduser': userName || 'oneops' },
  });

API[types.UPLOAD_BILLING_REVENUE] = ({ BillingRevenueUploadParameters }, idToken, userName) =>
  http.post("api/billingrevenueupload", { BillingRevenueUploadParameters }, { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } })

API[types.UPDATE_BILLING_REVENUE] = ({ UpdateBillingRevenueParameters }, idToken, userName) =>
  http.post("api/updatebillingrevenue", { UpdateBillingRevenueParameters }, { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } })

API[types.GET_BILLING_REVENUE_FILES] = (idToken, userName) =>
  http.get("api/getbillingrevenue", { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } })

API[types.GET_M1_RECONCILE_SUMMARY] = ({ GetM1BillingReconcileSummaryRequest }, idToken, userName) =>
  http.post("api/osot/getm1reconciliationsummary", { GetM1BillingReconcileSummaryRequest }, { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } })

API[types.GET_M1_BILLING_RECONCILE_DATA] = ({ GetM1BillingReconcileRequest }, idToken, userName) =>
  http.post("api/osot/getm1billingreconcile", { GetM1BillingReconcileRequest }, { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } })
  
API[types.GET_M1_BILLING_RECONCILE_SUMMARY_DATA_REQUEST] = ({ GetM1BillingReconcileSummaryDataRequest }, idToken, userName) =>
  http.post("api/osot/getm1reconciliationsummarydata", { GetM1BillingReconcileSummaryDataRequest }, { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } })
  
API[types.GET_STARHUB_REPORT_COUNT] = (payload, idToken, userName) =>
  http.post("api/getreportcount", { GetReportCoutRequest : payload }, { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } })

API[types.GET_STARHUB_REPORT_COUNT_DETAILS] = (payload, idToken, userName) =>
  http.post("api/getreportcountdetails", { GetReportCountDetailsRequest : payload }, { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } })

API[types.GET_STARHUB_REPORT_DETAILS] = (payload, idToken, userName) =>
  http.post("api/getreportdetails", { GetReportDetailsRequest : payload }, { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } })

  API[types.VALIDATE_EMAIL_PASSWORD] = async (email, password) => {
    return await auth.signIn(email, password)
  }

  API[types.CHANGE_PASSWORD] = async (password) => {
    return await auth.changePassword(password)
  }

  API[types.VERIFY_OTP_REQUEST] = async (otp) => {
    return await auth.verifyOtp(otp)
  }

  API[types.SIGNOUT_REQUEST] = () => {
    return auth.signOut()
  }

  API[types.GET_CHART_COUNT_DATA] = (payload, userName) =>
  http.get("api/gettotalreportscount", {
    headers: { ...headers, Authorization: payload?.idToken, 'Asurion-enduser': userName || 'oneops' },
  });
  
  API[types.GET_SLA_REPORTS_DETAILS] = (payload, userName) =>
  http.get(`api/getsladetails?reportMonth=${payload?.month}`, {
    headers: { ...headers, Authorization: payload?.idToken, 'Asurion-enduser': userName || 'oneops' },
  });

  API[types.SGP_DOWNLOAD_REPORT] = (payload, idToken, userName) =>
    http.post("api/downloadreports", { DownloadReportRequest : payload }, { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } })
  
  API[types.SIGNOUT_REQUEST] = () => {
    return auth.signOut()
  }

  API[types.GET_SS_REPORT_COUNT] = (payload, idToken, userName) =>
    http.post("api/getsmartstockcount", { GetSmartStockCountRequest : payload }, { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } })

  API[types.GET_SS_REPORT_DETAILS] = (payload, idToken, userName) =>
    http.post("api/getsmartstockdetails", { GetSmartStocktDetailsRequest : payload }, { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } })

    API[types.GET_SCORECARD_SUMMARY] = (payload, idToken, userName) =>
    http.post("api/scoreCardSummary", 
      { ScoreCardSummaryRequest : payload }, 
      { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } 
    })

    API[types.GET_PRM_EXPORT_DATA] = (payload, idToken, userName) =>
    http.post("api/exportprmdata", 
      { GetPrmDataDetailsRequest : payload }, 
      { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } 
    })

    API[types.GET_INQUIRY_DATA] = (payload, idToken, userName) =>
    http.post("api/inquirydetails", 
      { GetInquiryDetailsRequest : payload }, 
      { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } 
    })

    API[types.GET_EXPORT_INQUIRY_DATA] = (payload, idToken, userName) =>
    http.post("api/exportinquirydetails", 
      { ExportInquiryDetailsRequest : payload }, 
      { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } 
    })

    API[types.GET_INQUIRY_AI_RESPONSE] = (payload, idToken, userName) =>
    http.post("api/airesponseforinquiry", 
      { GetAIResponseForInquiryRequest : payload }, 
      { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } 
    })

    API[types.GET_INQUIRY_AI_GRAPH_RESPONSE] = (payload, idToken, userName) =>
    http.post("api/aigraphchartforinquiry", 
      { GetAIGraphChartForInquiryRequest : payload }, 
      { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } 
    })

    API[types.VERIFY_JWT] = (payload, idToken, userName) =>
    http.post("api/verify-jwt", 
      { VerifyJWTBody : payload }, 
      { headers: { ...headers, Authorization: payload.token, 'Asurion-enduser': userName || 'oneops' } 
    })

  API[types.GET_CELCOM_RECONCILE_SUMMARY] = ({ GetCelcomBillingReconcileSummaryRequest }, idToken, userName) =>
    http.post("api/osot/getcelcomreconciliationsummary", { GetCelcomBillingReconcileSummaryRequest }, { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } })
  
  API[types.GET_CELCOM_BILLING_RECONCILE_DATA] = ({ GetCelcomBillingReconcileRequest }, idToken, userName) =>
    http.post("api/osot/getcelcombillingreconcile", { GetCelcomBillingReconcileRequest }, { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } })
    
  API[types.GET_CELCOM_BILLING_RECONCILE_SUMMARY_DATA_REQUEST] = ({ GetCelcomBillingReconcileSummaryDataRequest }, idToken, userName) =>
    http.post("api/osot/getcelcomreconciliationsummarydata", { GetCelcomBillingReconcileSummaryDataRequest }, { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops' } })

  API[types.GET_AI_PERFORMANCE_REPORT] = (payload, idToken, userName) =>
    http.post("api/getainotes", 
      { GetAINotesDetailsRequest : payload }, 
      { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops', 'Asurion-application':"" } 
    })

  API[types.GET_AI_SUMMARY_REPORT] = (payload, idToken, userName) =>
    http.post("api/getainotes", 
      { GetAINotesDetailsRequest : payload }, 
      { headers: { ...headers, Authorization: idToken, 'Asurion-enduser': userName || 'oneops', 'Asurion-application':"" } 
    })

    export default API;
