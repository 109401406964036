import { useState, useEffect } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { useCentralStore } from "../../store/appContext";
import ActionTypes from "../../store/actionTypes";
import { useNavigate } from "react-router-dom";
import { getParameterByName, isEmpty } from "../../utils/functionUtils";
import API from "../../api";
import OtpInput from "./otpInput";
import Spinner from "../../components/common/spinner/spinner";
import Timer from "./Timer"

const OtpPage = ({}) => {
  const [{ userLoggedIn, authCodeSearch, loadingData, email, password }, dispatch] =
    useCentralStore();

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
  };
  const [otp, setOtpvalue] = useState();
  const [otpErrors, setOtpError] = useState();
  const [incorrectAttempts, setIncorrectAttempts] = useState(0);
  const [disabledResend, setDisabledResend] = useState(false);
  const [consoleMessage, setConsoleMessage] = useState("");
  const [maxResendAttemptsError, setMaxResendAttemptsError] = useState(false);

  const getotpvalue = (otp) => {
    setOtpvalue(otp);
  };

  const verifyOtp = () => {
    setLoading(true);
    if (otp.length === 6) {
      setOtpError('')
        API[ActionTypes.VERIFY_OTP_REQUEST](otp.join(""))
          .then(async (data) => {
            if(data?.signInUserSession?.accessToken?.jwtToken){
              dispatch({
                type: ActionTypes.SET_USER_LOGGED_IN,
                payload: true,
              });
              dispatch({
               type: ActionTypes.SET_USER_DATA,
               payload: {access_token: data?.signInUserSession?.accessToken?.jwtToken}
             });
             dispatch({
               type: ActionTypes.SET_USER_DETAILS,
               payload: data?.attributes?.email
             });
              navigate("/");
              setLoading(false);  
            } else {
              setLoading(false);
              setConsoleMessage(false);
              setOtpvalue('');
              setOtpError('Invalid OTP')
            }
          })
          .catch((err) => {
            setLoading(false);
            setOtpvalue('');
            setIncorrectAttempts((prevAttempts) => prevAttempts + 1);
            console.log("error", incorrectAttempts)
            if (incorrectAttempts >= 0) {
              setLoading(false);
              setConsoleMessage(false);
              !consoleMessage && setOtpError('OTP Expired. Please resend code and try again.');
              setDisabledResend(false);
            } else {
              setLoading(false);
              setConsoleMessage(false);
              setOtpError('Invalid OTP');
            }
          })
    }
  };
  console.log("setConsoleMessage", consoleMessage )
  
  const dtt = new Date()
  const OTP_EXPIRE_MINUTES = 2
  dtt.setMinutes(dtt.getMinutes() + OTP_EXPIRE_MINUTES)

  const [timer, setTimer] = useState({ Timer: dtt })
  const callback = (value) => {
    if (value <= '00:30') {
      setDisabledResend(false)
    } else {
      setDisabledResend(true)
    }
  }

  const isOtpValid = otp && otp.join("").length === 6;

  const resendOtp = () => {
    setLoading(true);
    setOtpvalue('');
    setMaxResendAttemptsError(false);
    setConsoleMessage('');
      API[ActionTypes.VALIDATE_EMAIL_PASSWORD](email, password)
        .then((data) => {
          setLoading(false);
          setOtpError(false);
          const dtt = new Date();
          dtt.setMinutes(dtt.getMinutes() + OTP_EXPIRE_MINUTES);
          setTimer({ Timer: dtt });
          setConsoleMessage("OTP Resent Successfully!");
          setOtpvalue('');
        })
        .catch((error) => {
          setLoading(false);
          setOtpvalue('');
          setOtpError(false);
          if (error?.message) {
            setMaxResendAttemptsError(true);
            setDisabledResend(true);
            setConsoleMessage("Max Resend attempts reached. Please try again after sometime!");
          } else {
            setConsoleMessage("Max Resend attempts reached. Please try again after sometime!");
          }        
        });
  };

  return (
    <>
      {loadingData && <Spinner showOverlay={true} />}
      <div className="bg-white h-screen text-black">
        <div className="background-container">
          <div className="boxone"></div>
          <div className=" box   "></div>
          <div className="flex justify-evenly">
            <div className="">
              <img
                className="mx-auto mt-16 h-32 w-32"
                src={require("../../assets/Starhub/otpStar.png")}
              />
              <p className="text-black mt-5 text-center">
                A code has been sent to your StarHub email.<br></br> Please
                enter the code that was sent to you below.
              </p>
              <OtpInput
                value={otp}
                otpLength={6}
                fontClasses={"font-semibold"}
                getotpvalue={getotpvalue}
              />

              <div className="flex justify-center mt-5">
                <Timer parentCallback={(value) => callback(value)} state={timer} />
              </div>
              <p className="flex justify-center mt-3 text-black">Your code will expire in<span className="text-app-starhubGreen font-bold"> { OTP_EXPIRE_MINUTES} minutes</span>.</p>
              <div className="text-center mt-3">
                <a
                  className={`${disabledResend ? 'cursor-not-allowed opacity-25 text-app-starhubGreen' : 'cursor-pointer text-app-starhubGreen font-semibold'}`}
                  onClick={resendOtp}
                  disabled={disabledResend}
                >
                  Resend Code
                </a>
              </div>
              <div>
                {consoleMessage && <p className="text-center text-sm error">{consoleMessage}</p>}
              </div>

              <div className="mt-5 mx-m15 flex justify-center">
                <button
                  id="continue"
                  className={`${isOtpValid ? "cursor-pointer" : "cursor-not-allowed"} px-4 py-2 bg-app-lightGreen mx-20 w-full rounded-lg`}
                  type="submit"
                  onClick={verifyOtp}
                  disabled={!isOtpValid}
                >
                  Continue
                </button>
              </div>
              {otpErrors && <p className="error text-sm">{otpErrors}</p>}
              
            </div>
            <div className="flex justify-end">
            </div>
            <img className="h-screen" src={require('../../assets/icons/OtpImage.png')}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpPage;