import React, { useState } from "react";
import sitemapjson from "../../utils/sitemap.json";

export default function SiteMap() {
  const [list, setList] = useState(Object.entries(sitemapjson.Australia));
  const [country, setCountry] = useState("australia");

  //   let datakeys = Object.keys(sitemapjson.Australia);
  //   let dataValues = Object.values(sitemapjson.Australia);

  const handleAus = () => {
    setList(Object.entries(sitemapjson.Australia));
    setCountry("australia");
  };
  const handleSin = () => {
    setList(Object.entries(sitemapjson.Singapore));
    setCountry("singapore");
  };
  let DataArray = ({ item }) => {
    return (
      <div>
        {item?.map((ele, i) => {
          return (
            <div className="flex">
              {i == 0 ? (
                <div
                  className={`${
                    country == "australia"
                      ? "border-2 border-app-siteGreen1 bg-app-siteGreen2 "
                      : "border-card-amberBorder bg-app-siteYellow1"
                  } border-2 mb-4 w-44 md:w-64 border-1 py-2 rounded-md pl-3 text-lg`}
                >
                  {ele}
                </div>
              ) : (
                <li
                  className={`${
                    country == "australia"
                      ? "border-app-siteGreen1 bg-app-siteGreen3"
                      : "bg-app-siteYellow3  border-card-yellowBorder"
                  } border-2 mb-4 w-44 md:w-64 site py-2 pl-3 pr-6 rounded-md text-lg`}
                >
                  {ele}
                </li>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex mt-9 ml-20">
      <div className="flex flex-col ">
        <div className="border-2 border-gray-200 bg-gray-600 text-left rounded-md w-44 md:w-64 pr-8 pl-3 py-2 text-lg mr-6">
          Home
        </div>
      </div>
      <div className="flex flex-col justify-start mr-6 ">
        <div
          className={`border-2 ${
            country == "australia"
              ? "border-app-purpleLight1 bg-app-sitePurple2"
              : "border-gray-200 bg-gray-800"
          } w-44 md:w-64 flex flex-col text-lg justify-start border-1  pr-8 text-left mb-8 py-2 rounded-md pl-3 cursor-pointer`}
          onClick={handleAus}
        >
          {"Australia"}
        </div>
        <div
          className={`${
            country == "singapore"
              ? "border-app-purpleLight1 bg-app-sitePurple2"
              : "border-gray-200 bg-gray-800"
          } border-2  w-44 md:w-64 flex flex-col text-lg justify-start border-1  rounded-md pr-8 py-2 pl-3 cursor-pointer`}
          onClick={handleSin}
        >
          {"Singapore"}
        </div>
      </div>
      {/* <div className="flex flex-col justify-start">
        {datakeys?.map((obj, i) => {
          console.log("obj", obj);
          return (
            <div className={`flex flex-row mb-4`}>
              <div
                className={`
                  bg-app-sitePurple1 border-app-purpleLight1
                 border-2 pr-8 w-44 md:w-64 flex flex-col justify-start border-1 py-2 rounded-md pl-3`}
              >
                {obj}
              </div>
              {}
              {/* <DataArray item={obj[1]} /> 
            </div>
          );
        })}
      </div>
     <div>
        {" "}
        {dataValues?.map((val, ind) => {
          return <DataArray item={val} />;
        })}
      </div> */}

      <div className=" flex flex-col justify-between">
        {list?.map((ele, i) => {
          let a = [...ele, ele[i]];
          return (
            <div className="flex ">
              <div
                className={`
                  bg-app-sitePurple1 border-app-purpleLight1
                 border-2 pr-8 w-44 md:w-64 h-12 text-lg flex flex-col justify-start border-1 py-2 rounded-md pl-3 mr-6`}
              >
                {ele[0]}
              </div>
              <div className="flex flex-col">
                {ele[1]?.map((item, i) => {
                  return (
                    <div className="flex" key={i}>
                      {i == 0 ? (
                        <div
                          className={`${
                            ele[0] === "Vodafone"
                              ? "border-2 border-app-siteGreen1 bg-app-siteGreen2"
                              : "border-2 border-app-siteblue1 bg-app-siteblue2"
                          } mb-4 w-44 md:w-64 py-2 pl-3 siteWrapper `}
                        >
                          {item}
                        </div>
                      ) : i != 3 ? (
                        <li
                          className={`${
                            ele[0] === "Vodafone"
                              ? "border-2 border-app-siteGreen1 bg-app-siteGreen3"
                              : "border-2 border-app-siteblue1  bg-app-siteblue3"
                          } mb-4 w-44 md:w-64 py-2 siteWrapper  `}
                        >
                          {item}
                        </li>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="ml-6">
                {/* {console.log("nmcbvfhbvjhd", a[1][3])} */}
                {ele[1] && a[1][3] != undefined && <DataArray item={a[1][3]} />}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
