import { isEmpty } from "../../utils/functionUtils"
import { DatePicker } from 'antd';
import moment from 'moment';
import { useEffect, useState } from "react";
import API from "../../api";
import { useCentralStore } from "../../store/appContext";
import ActionTypes from "../../store/actionTypes";
import resources from '../../components/common/resources.json'
const { RangePicker } = DatePicker;

const SGPReportsFilter = () => {

    const [ { selectedTab, projectName, selectedReportFilter, reportFilterDuration, selectedStarhubReport, userData, userDetails}, dispatch] = useCentralStore()
    let filterOptions = [{
        label: 'Real Time',
        value: 'real time'
      },
      {
        label: 'Monthly',
        value: 'monthly'
      },
      {
        label: 'Quarterly',
        value: 'quarterly'
      }]

      let filterOptionsMonthly = [
        {
          label: 'Monthly',
          value: 'monthly'
        }]  

    const [dates, setDates] = useState(null);
    const [value, setValue] = useState(null);
    const [changedRealTime, setChangedRealTime] = useState(true);


    useEffect(() => {
        selectedTab === "Prev Month" && dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER, payload: 'monthly'})
        // if (selectedTab === "Prev Month" || selectedTab === 'Program Summary') dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER, payload: 'monthly'})
    }, [selectedTab])

    const handleDateChange = (dates, dateString, type) => {
        var y, m;
        var firstDay;
        var lastDay;

        if(type === 'month'){
            y = dateString?.split('-')[0]
            m = dateString?.split('-')[1];
            firstDay = moment(new Date(Number(y), Number(m-1), 1)).format('YYYY-MM-DD 00:00:00');
            lastDay = moment(new Date(Number(y), Number(m), 0)).format('YYYY-MM-DD 23:59:59');
        }

        let today;
        let quarter;
        let startFullQuarter;
        let endFullQuarter;
        if(type === "quarter"){
            today = new Date(dates?.$d);
            quarter = Math.floor((today.getMonth() / 3));
            startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
            endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
        }

        dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION, payload: {
            type: type,
            startDate: type === 'dates' ? moment(dateString[0]).format('YYYY-MM-DD 00:00:00') : type === 'month' ? firstDay : moment(startFullQuarter).format('YYYY-MM-DD 00:00:00'),
            endDate: type === 'dates' ? moment(dateString[1]).format('YYYY-MM-DD 23:59:59') : type === 'month' ? lastDay : moment(endFullQuarter).format('YYYY-MM-DD 23:59:59'),
            duration: type === 'dates' ? dateString : dateString
        }})
        setDates(dates);
        dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS, payload: null})
        dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS_SUCCESS, payload: null})
        dispatch({ type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS, payload: null})
    };

    const disabledQuarter = (current) => {
        const month = current.month();
        const year = current.year();
        return year > currentYear || (year === currentYear && month > currentMonth);
    };
    

    const currentMonth = moment().month();
    const currentYear = moment().year();
    const disabledMonth = (current) => {
        const month = current.month();
        const year = current.year();
        return year > currentYear || (year === currentYear && month > currentMonth);
      };

    const disabledDate = (current) => {
        if (!dates) {
          return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') >= 30;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 30;
        const today = current && current > moment().endOf('day')
        return !!today || !!tooEarly || !!tooLate;
    };

    const onOpenChange = (open) => {
        if (open) {
            setDates([null, null]);
        } else {
          setDates(null);
        }
    };

    const setLoading = (action) => {
        dispatch({ type: ActionTypes.SET_LOADING, payload: action });
    };

    // useEffect(() => {
    //     if(!isEmpty(reportFilterDuration?.duration) && (!isEmpty(reportFilterDuration?.endDate) || !isEmpty(reportFilterDuration?.startDate) || !isEmpty(reportFilterDuration?.type))) {
    //         let payload = {
    //             StartDate: reportFilterDuration?.startDate,
    //             EndDate: reportFilterDuration?.endDate,
    //             ReportType: selectedStarhubReport?.starHubReportValue,
    //             // Type: "accepted",
    //             // Action: "Add",
    //             // Reason: ""
    //         };
    //         setLoading(true)
    //         API[ActionTypes.GET_STARHUB_REPORT_COUNT](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
    //         .then(({ data }) => {
    //             setLoading(false);
    //             if (data) {
    //               dispatch({
    //                 type: ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS,
    //                 payload: data,
    //               });
    //             }
    //           })
    //           .catch((err) => {
    //             dispatch({
    //               type: ActionTypes.GET_STARHUB_REPORT_COUNT_FAILURE,
    //               payload: err,
    //             });
    //             dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
    //             let _payload = {
    //               level: resources.POST_LOGGER_ERROR,
    //               category: resources.POST_LOGGER_API,
    //               ApiName: resources.POST_LOGGER_APINAME,
    //               message: {
    //                 error: err,
    //                 info: {
    //                   idToken: "",  
    //                 },
    //               },
    //             };
    //             API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
    //           });
    //     }
    // }, [reportFilterDuration])
    // }, [reportFilterDuration?.duration || reportFilterDuration?.endDate || reportFilterDuration?.startDate || reportFilterDuration?.type])

    const handleFilterChange = (val) => {
        var y, m;
        var firstDaypreviousmonth;
        var lastDaypreviousmonth;
        
        if(val === 'monthly'){
            const lastmonth = moment().month();
            const currentyear = moment().year()
            y = currentyear
            m = lastmonth
            firstDaypreviousmonth = moment(new Date(Number(y), Number(m-1), 1)).format('YYYY-MM-DD 00:00:00');
            lastDaypreviousmonth = moment(new Date(Number(y), Number(m), 0)).format('YYYY-MM-DD 23:59:59');
        }
        let startFullQuarter
        let endFullQuarter
        if(val === "quarterly"){
             let today = new Date()
          let last3monthsfromtoday = today.getMonth() - 3;
           let  quarter = Math.floor((last3monthsfromtoday / 3));
             startFullQuarter = new Date(today.getFullYear(), quarter * 3, 1);
             endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);
        }
        let currentDate
        let firstDayofMonth
        if(val === "real time"){
          let today =new Date();
          currentDate = moment(new Date()).format('YYYY-MM-DD 23:59:59');
          firstDayofMonth = moment(new Date(today. getFullYear(), today. getMonth(), 1)).format('YYYY-MM-DD 00:00:00')}
          let current  = new Date()
          
//           const monthyear = (new Date(current. getFullYear)).concat("-")(new Date(current.getMonth()))
//    console.log(monthyear,"monthyear")

          dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER, payload: val})
        dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION, payload: {
             type: val === "monthly" ? 'month' :val === "quarterly"? "quarter": "realtime",
             startDate: val === "monthly" ? firstDaypreviousmonth: val === "quarterly" ?  startFullQuarter: firstDayofMonth,
             endDate: val === "monthly" ? lastDaypreviousmonth: val === "quarterly" ?  endFullQuarter: currentDate,
             duration: '2023-04'
        }})
        setDates(null)
        setValue(null)
        setChangedRealTime(false)
        dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS, payload: null})
        dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS_SUCCESS, payload: null})
        dispatch({ type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS, payload: null})
    }

    return(
        <div className={``}>
            <div>
                <div className='flex my-2'>
                    {/* {(isEmpty(selectedReportFilter) || selectedReportFilter === 'real time' || selectedReportFilter === 'monthly'  || selectedReportFilter === 'quarterly') && ((selectedTab === "Prev Month" || selectedTab === 'Program Summary') ? filterOptionsMonthly : filterOptions)?.map(v =>  */}
                    {(isEmpty(selectedReportFilter) || selectedReportFilter === 'real time' || selectedReportFilter === 'monthly'  || selectedReportFilter === 'quarterly') && (selectedTab === "Prev Month" ? filterOptionsMonthly : filterOptions)?.map(v =>
                        <div className={`text-black relative rounded-md text-[11px] cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-starhubGreen mr-5 font-semibold
                            ${selectedReportFilter === v.value ? 'border border-app-starhubGreen' : ''}`}
                            onClick={() => handleFilterChange(v.value)}
                        >
                            {v.label}
                        </div>
                    )}
                </div>
            </div>
        {selectedReportFilter === "real time" ?
            <>
            {
                changedRealTime ?
                <>{ selectedTab === "Prev Month" ? "" : <p className="text-[11px] text-black">Showing last 7days Report Data <span><a className="text-app-starhubGreen underline cursor-pointer" onClick={() => setChangedRealTime(false)}>change dates</a></span></p>}</>
                :
                <>{ selectedTab === "Prev Month" ? "" : 
                <RangePicker
                    allowClear={false}
                    className=""
                    value={dates || value}
                    disabledDate={disabledDate}
                    onCalendarChange={(val) => {
                        setDates(val);
                    }}
                    onChange={(d, ds, t) => (handleDateChange(d, ds, 'dates'), setValue(d))}
                    onOpenChange={onOpenChange}
                    // defaultValue= {[moment("2020-03-09 13:00"), moment("2020-03-27 13:17")]}
                />}</>
            }
            </>
            : 
            selectedReportFilter === "monthly" ?
                <DatePicker
                    allowClear={false}
                    className={`${(selectedTab === "Prev Month" || selectedTab === 'Program Summary') ?"" : "left-[33%]"}`}
                    picker="month"
                    onChange={(d, ds, t) => (handleDateChange(d, ds, 'month'))}
                    value={dates}
                    disabledDate={disabledMonth}
                />
            :
            <DatePicker
                allowClear={false}
                picker="quarter" 
                className="left-[55%]"
                onChange={(d, ds, t) => (handleDateChange(d, ds, 'quarter'))}
                disabledDate={disabledQuarter}
            />
        }
        </div>
    )
}

export default SGPReportsFilter