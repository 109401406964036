import Header from "../components/common/header/header";
import Spinner from "../components/common/spinner/spinner";
import { useCentralStore } from "../store/appContext";
import Dashboard from "./dashboard/dashboard";
import DashboardOps from "./dashboard/dashboardOps"
import  config  from '../config/config'

const Application = () => {
  const [{ loading, loadingData }] = useCentralStore();
  return (
    <>
      {(loading || loadingData) && <Spinner showOverlay={true} />}
      {config.isSGP === 'true' ? 
        <div>
          {/* <Header /> */}
          <Dashboard />
        </div>
        : 
        <div>
          <DashboardOps />
        </div>
      }
    </>
  );
};

export default Application;
