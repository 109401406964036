import React from "react";

const icon = ({ className, fill, onClick, stroke }) => (
  <svg className={className} width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 13.4743L30.1533 6.69092" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M22.8397 3.82617L8.83301 10.8295V14.9962" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.4948 9.02957V7.70957C30.4949 7.003 30.0494 6.37319 29.3832 6.1379L16.9782 1.7579C16.564 1.61479 16.1102 1.64054 15.7148 1.82957L3.12317 7.74623C2.54177 8.01999 2.16954 8.60361 2.1665 9.24623V22.0979C2.16618 22.7609 2.55886 23.361 3.1665 23.6262L14.7115 28.6862C15.2063 28.9027 15.7753 28.8661 16.2382 28.5879L19.6615 26.5296" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M2.45801 8.31299L15.4997 13.4747" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.5 13.4746V28.8213" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M32.4568 34.0581V34.0581C32.6169 34.0581 32.7467 34.1879 32.7467 34.348V34.348C32.7467 34.508 32.6169 34.6378 32.4568 34.6378V34.6378C32.2968 34.6378 32.167 34.508 32.167 34.348V34.348C32.167 34.1879 32.2968 34.0581 32.4568 34.0581" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.8335 13.6665C23.8335 12.5619 24.7289 11.6665 25.8335 11.6665H38.5002C39.6047 11.6665 40.5002 12.5619 40.5002 13.6665V36.3332C40.5002 37.4377 39.6047 38.3332 38.5002 38.3332H25.8335C24.7289 38.3332 23.8335 37.4377 23.8335 36.3332V13.6665Z" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M37.1665 21.6665C37.1665 24.4279 34.9279 26.6665 32.1665 26.6665C29.4051 26.6665 27.1665 24.4279 27.1665 21.6665C27.1665 18.9051 29.4051 16.6665 32.1665 16.6665" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M37.1665 17.9165L32.609 22.474C32.4917 22.5916 32.3325 22.6576 32.1665 22.6576C32.0005 22.6576 31.8413 22.5916 31.724 22.474L30.2915 21.0415" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);
export default icon;
