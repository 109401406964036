import { useState, useRef, useEffect } from "react";
import moment from "moment";
import { ReactComponent as Logo } from "../../../assets/icons/OneOplogo.svg";
import NotificationIcon from "../../../assets/icons/notification_icon";
import SettingIcon from "../../../assets/icons/setting_icon";
import NotificationsData from "../../common/notifications/notificationsData";
import FullscreenIcon from "../../../assets/icons/fullscreen-icon";
import FavouriteIcon from "../../../assets/icons/favourite-icon";
import { useCentralStore } from "../../../store/appContext";
import { isEmpty, toPascalCase } from "../../../utils/functionUtils";
import FavPlusIcon from "../../../assets/icons/fav-plus-icon";
import SavedDashboards from "../savedDashboard.js/savedDashboard";
import ActionTypes from "../../../store/actionTypes";
import API from "../../../api";
import DashboardMenu from "../dashboardMenu/dashboardMenu";
import SiteMapIcon from "../../../assets/icons/siteMapIcon";
import CrossIcon from "../../../assets/icons/cross-icon";
import { getUniqueValuesFromArray } from "../../../utils/functionUtils";
import SiteMap from "../../../pages/dashboard/sitemap";
const HeaderOps = ({ className, userDetails, resources }) => {
  const [
    {
      clientList,
      selectedTab,
      selectedClient,
      clientData,
      programName,
      projectData,
      selectedDashboard,
      userData,
      FavouriteList,
      fullscreenMode,
      projectName,
      selectedCategory,
      isTradeDashboardOpen,
      MasterData,
      country_Data,
      unique_Clients,
      program_Data,
      clientsResultData,
      mapSelected,
      uniqueClientsList,
      favouriteData,
      SiteMap,
      uniqueClientsData,
      notificationsData,
      mapsPage
    },
    dispatch,
  ] = useCentralStore();

  let clientChangeTimerId = "";
  // const clients = uniqueClientsData?.map((c) => c.value);
  const ref = useRef();
  const refDashboard = useRef();

  const [notificationOpen, openNotification] = useState(false);
  const [dashboardOpen, openDashboardList] = useState(false);
  const [favouriteAdded, setFavouriteAdded] = useState(false);
  const [loading, setLoading] = useState(undefined);
  const [removeDashboard, setRemoveDashboard] = useState(undefined);
  const [fullScreen, setFullScreenMode] = useState(fullscreenMode);
  const [name, setName] = useState(null);
  const [nameFlag, setNameFlag] = useState(false);
  const [favData, setFavouriteData] = useState({});

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  }
  useOnClickOutside(ref, () => openNotification(false));
  useOnClickOutside(refDashboard, () => openDashboardList(false));

  let clientName = clientData
    ?.find((v) => v?.ClientId === selectedClient)
    ?.Client?.replaceAll("_", " ");
  clientName = clientName ? toPascalCase(clientName) : "";

  useEffect(() => {
    if ((selectedTab || clientData || selectedClient) && projectName === resources.BPM_PROJECT_NAME) {
      if(selectedTab?.toLowerCase() == "map" || mapsPage){
        setName(resources.ONE_OPS_HEADER);
        setNameFlag(false)
      }else {
        setName((selectedTab == 'Trade-In BPM Performance' ? 'Trade-in Business Process Performance' : selectedTab == 'Home Plus BPM Performance' ? 'Home Plus Business Process Performance' : selectedTab)  + " for " + clientName)
        setNameFlag(true)
      }
    }else{
      setNameFlag(false)
    }
  }, [selectedTab, clientData, selectedClient, projectName]);

  const addUpdateFavourite = (fav, action) => {
    let getClientName = clientData?.find((v) => v?.ClientId === selectedClient);
    let payload = {};

    if (fav) {
      payload = {
        DashboardId: selectedDashboard,
        DashboardName: fav?.DashboardName,
        Channel: "all",
        ClientId: fav?.ClientId,
        Client: fav?.ClientName,
        Duration: resources.LAST_90_DAYS_VALUE,
        Username: fav?.Username,
        OneOpsAddFavouriteDetailId: fav?.OneOpsAddFavouriteDetailId,
      };
    } else {
      payload = {
        DashboardId: selectedDashboard,
        DashboardName: selectedTab,
        Channel: "all",
        ClientId: selectedClient,
        Client: getClientName?.Client,
        Duration: resources.LAST_90_DAYS_VALUE,
        Username: userDetails && userDetails?.email?.split("@")?.[0],
        Country: mapSelected,
      };
    }

    action === "remove" ? setRemoveDashboard(true) : setLoading(true);
    API[ActionTypes.ADD_FAVOURITE](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
        action === "remove" ? setRemoveDashboard(true) : setLoading(false);
        if (data) {
          setFavouriteAdded(true);
          if (action === "remove") {
            let payload = {
              // Username: userDetails && userDetails?.email?.split("@")?.[0],
              idToken: userData?.access_token,
            };
            API[ActionTypes.GET_ROLE_BASES_MATRICS](payload, userDetails && userDetails?.email?.split("@")?.[0])
              .then(({ data }) => {
                setRemoveDashboard(false);
                if (data) {
                  dispatch({
                    type: ActionTypes.GET_ROLE_BASES_MATRICS_SUCCESS,
                    payload: data,
                  });
                }
              })
              .catch((err) => {
                dispatch({
                  type: ActionTypes.GET_ROLE_BASES_MATRICS_FAILURE,
                  payload: err,
                });
                dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
                let _payload = {
                  level: resources.POST_LOGGER_ERROR,
                  category: resources.POST_LOGGER_API,
                  ApiName: resources.POST_LOGGER_APINAME,
                  message: {
                    error: err,
                    info: {
                      idToken: "",
                    },
                  },
                };
                API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
              });
          }
        }
      })
      .catch((err) => {
        action === "remove" ? setRemoveDashboard(false) : setLoading(false);
        dispatch({
          type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: payload,
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
  };

  useEffect(() => {
    if (
      favouriteAdded ||
      loading ||
      !loading ||
      removeDashboard ||
      !removeDashboard
    ) {
      setTimeout(() => {
        setFavouriteAdded(false);
        setLoading(undefined);
        setRemoveDashboard(undefined);
      }, 1500);
    }
  }, [favouriteAdded, loading]);

  const myInterval = useRef();

  function exitHandler() {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setFullScreenMode(!fullScreen);
      dispatch({ type: ActionTypes.SET_FULLSCREEN, payload: false });
      dispatch({
        type: ActionTypes.SET_REFRESHED_ON,
        payload: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      clearInterval(myInterval.current);
      myInterval.current = null;
    }
  }

  // useEffect(() => {
  //   if(!isEmpty(projectData)){
  //     clearInterval(myInterval.current);
  //     myInterval.current = null;
  //   }
  // }, [projectData])

  function fullscreen() {
    setFullScreenMode(!fullScreen);
    dispatch({ type: ActionTypes.SET_FULLSCREEN, payload: true });
    dispatch({
      type: ActionTypes.SET_REFRESHED_ON,
      payload: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    var isInFullScreen =
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);

    var docElm = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  }

  useEffect(() => {
    if (!isEmpty(selectedClient) && fullscreenMode) {
      let finalData = uniqueClientsData?.filter(client => client.name !== "Telstra");
      let clients = selectedTab === "Trade-In BPM Performance" ? (finalData?.map((c) => c.value)) : (uniqueClientsData?.map((c) => c.value))
      document.addEventListener("fullscreenchange", exitHandler);
      document.addEventListener("webkitfullscreenchange", exitHandler);
      document.addEventListener("mozfullscreenchange", exitHandler);
      document.addEventListener("MSFullscreenChange", exitHandler);
      myInterval.current = setTimeout(() => {
        const nextClientIndex =
          clients.indexOf(selectedClient) === clients.length - 1
            ? 0
            : clients.indexOf(selectedClient) + 1;
        const nextClient = clients[nextClientIndex];
        if(!mapsPage){
          if (selectedTab === "Trade-In BPM Performance" && nextClient === "Telstra") {
            dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: "harvey_norman" });
          }
      
          dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: nextClient });

          if(mapSelected?.toLowerCase() !== 'australia'){
            let setDashboard = uniqueClientsList?.find(v => v?.ClientId === nextClient)?.Channels[0]?.AllDashboards[0]
            dispatch({ type: ActionTypes.SET_SELECTED_DASHBOARD, payload: setDashboard?.DashboardId })
            dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: setDashboard?.Label })
          }
        }
      }, 600000);
    } else {
      // const samsungClient = clientList.find((c) => c.name === "Samsung");
      // const defaultClient = samsungClient?.value || clientList[0]?.value;
      // defaultClient &&
      //   dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: defaultClient });
    }
  }, [selectedClient, fullscreenMode, uniqueClientsData]);

  useEffect(() => {
    if(mapsPage){
      clearInterval(myInterval.current);
      // clearTimeout(myInterval.current);
      myInterval.current = null;
    }
  }, [mapsPage])

  useEffect(() => {
    if (mapSelected) {
      dispatch({
        type: ActionTypes.SET_COUNTRYDATA,
        payload: MasterData?.filter((u) => mapSelected === u?.Country),
      });
    }
  }, [mapSelected]);

  useEffect(() => {
    if (mapSelected && !isEmpty(country_Data)) {
      dispatch({
        type: ActionTypes.SET_UNIQUE_CLIENTS,
        payload: getUniqueValuesFromArray(country_Data, "ClientName")?.map(
          (v) => v?.ClientName
        ),
      });

      let result = country_Data.reduce(function (r, a) {
        r[a.ClientName] = r[a.ClientName] || [];
        r[a.ClientName].push({ Program: a.Program, Color: a.CountryColor });
        return r;
      }, Object.create(null));
      dispatch({ type: ActionTypes.SET_PROGRAM_DATA, payload: result });
    }
  }, [mapSelected, country_Data]);

  useEffect(() => {
    if (!isEmpty(unique_Clients) && !isEmpty(clientsResultData)) {
      let unique_Clients_name = unique_Clients?.map((c) =>
        c?.toLowerCase()?.replace(" ", "")
      );
      dispatch({
        type: ActionTypes.SET_UNIQUE_CLIENS_DATA,
        payload: clientsResultData.filter((c) =>
          unique_Clients_name.includes(
            c.Client?.toLowerCase()?.replace(" ", "")
          )
        ),
      });
    }
  }, [unique_Clients, clientsResultData]);

  useEffect(() => {
    if (
      !isEmpty(uniqueClientsList) &&
      (!isEmpty(favouriteData) || !isEmpty(favData))
    ) {
      let allDashboardsArray = [];
      // uniqueClientsList
      //   ?.filter(
      //     (v) => v?.ClientId == favouriteData?.ClientId || favData?.ClientId
      //   )[0]
      //   ?.Channels?.map((v) =>
      //     v?.AllDashboards?.map((a) => allDashboardsArray.push(a))
      //   );
      // let allDashboards = allDashboardsArray?.filter(
      //   (v, i, a) => a.findIndex((v2) => v2.Label === v.Label) === i
      // );
      uniqueClientsList?.map(c => allDashboardsArray.push(c?.Channels?.[0]?.AllDashboards))
      let allDashboards = allDashboardsArray.flat()?.filter((v,i,a)=>a.findIndex(v2=>(v2.Label===v.Label))===i)
      dispatch({
        type: ActionTypes.SET_ALL_DASHBOARDS,
        payload: allDashboards,
      });
    }
  }, [uniqueClientsList]);

  const goToDashboard = (fav) => {
    dispatch({ type: ActionTypes.SET_MAP_SELECTED, data: fav?.Country });
    setFavouriteData(fav);

    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: resources.BPM_PROJECT_NAME,
    });
    dispatch({
      type: ActionTypes.SET_SELECTED_TAB,
      payload: fav?.DashboardName,
    });
    dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: fav?.ClientId });
    const payload = {
      Channel: "all",
      Client: toPascalCase(fav?.ClientName),
      ClientId: fav?.ClientId,
      DashboardId: "all",
      DashboardName: fav?.DashboardName,
      Duration: resources.LAST_90_DAYS_VALUE,
    };
    setLoading(true);
    API[ActionTypes.GET_DASHBOARD_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
        if (data) {
          setLoading(undefined);
          dispatch({
            type: ActionTypes.SET_REFRESHED_ON,
            payload: moment().format("YYYY-MM-DD HH:mm:ss"),
          });
          dispatch({
            type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
            payload: data?.GetDashboardResponse,
          });
          dispatch({
            type: ActionTypes.GET_DASHBOARD_DETAILS_DATA_SUCCESS,
            payload: true,
          });
        }
      })
      .catch((err) => {
        setLoading(undefined);
        dispatch({
          type: ActionTypes.GET_DASHBOARD_DETAILS_FAILURE,
          payload: err,
        });
        // dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        dispatch({
          type: ActionTypes.GET_DASHBOARD_DETAILS_DATA_SUCCESS,
          payload: true,
        });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: payload,
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      })
      .finally(() => {
        setLoading(undefined);
      });
  };

  let uniqueFavouriteList = FavouriteList?.filter(
    (v, i, a) =>
      a.findIndex(
        (v2) =>
          v2.ClientName === v.ClientName && v2.DashboardName === v.DashboardName
      ) === i
  );

  const expandDashboardOption = (payload) => {
    dispatch({
      type: ActionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS,
      payload: payload,
    });
  };

  const openSiteMap = () => {
    dispatch({
      type: ActionTypes.SET_SITE_MAP,
      payload: true,
    });
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: "Sitemap",
    });
    dispatch({
      type: ActionTypes.SET_CATEGORY,
      payload: null,
    });
    setName(undefined)
    setNameFlag(undefined)
    dispatch({
      type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
      payload: null,
    });
  };

  return (
    <div className={`mb-8`}>
      <div
        className={`px-2 ${className} flex flex-row justify-between items-center w-full`}
      >
        <div className={`flex ${!nameFlag ? 'justify-start items-center w-full' : ''}`}>
          <Logo className="w-14" />
          {!nameFlag && 
          <p
            className={`${
              selectedCategory === null
                ? "font-bold text-2xl"
                : selectedCategory === ""
                ? "font-bold text-2xl"
                : "font-normal text-lg"
            } ml-4  justify-start text-white`}
          >
            {`${
              projectName === "Transactional Reporting"
                ? "Transactional Reporting"
                : projectName == "Sitemap"
                ? "Sitemap"
                : name
            }`}
            <span className="ml-12 mb-5 text-2xl font-bold">
              {`${
                selectedCategory === null
                  ? " "
                  : selectedCategory === "CS"
                  ? "Customer Solutions"
                  : selectedCategory === "SCM"
                  ? "Supply Chain"
                  : selectedCategory
              }`}{" "}
            </span>
          </p>
          }
        </div>
        {nameFlag &&
        <p className="font-bold text-[32px]">
          {
            selectedTab === "Samsung BPM Performance"
            ? "Device Protection Business Process Performance for Samsung"
            : selectedTab === "Samsung Operational Performance"
            ? "Device Protection Operational Performance for Samsung"
            : selectedTab === "Telstra BPM Performance" 
            ? "Device Protection Business Process Performance for Telstra"
            : selectedTab === "Telstra Operational Performance"
            ? "Device Protection Operational Performance for Telstra"
            : selectedTab === "Harvey_Norman BPM Performance"
            ? "Device Protection Business Process Performance for Harvey Norman"
            : selectedTab === "Harvey_Norman Operational Performance"
            ? "Device Protection Operational Performance for Harvey Norman"
            : selectedTab === "StarHub BPM Performance" 
            ? "Device Care Business Process Performance for Starhub" 
            : selectedTab === "StarHub Operational Performance" 
            ? "Device Care Operational Performance for Starhub" 
            : selectedTab === "Celcom BPM Performance"
            ? "Device Care Business Process Performance for Celcom"
            : selectedTab === "Celcom Operational Performance"
            ? "Device Care Operational Performance for Celcom"
            : selectedTab === "AIS BPM Performance"
            ? "Device Care Business Process Performance for AIS"
            : selectedTab === "AIS Operational Performance"
            ? "Device Care Operational Performance for AIS"
            : selectedTab === "TRUE BPM Performance"
            ? "Device Care Business Process Performance for TRUE"
            : selectedTab === "TRUE Operational Performance"
            ? "Device Care Operational Performance for TRUE"
            : selectedTab === "Asurion_TechCare BPM Performance"
            ? "Device Care Business Process Performance for Asurion_TechCare"
            : selectedTab === "Asurion_TechCare Operational Performance"
            ? "Device Care Operational Performance for Asurion_TechCare"
            : selectedTab === "Singtel BPM Performance"
            ? "Device Care Business Process Performance for Singtel"
            : selectedTab === "Singtel Operational Performance"
            ? "Device Care Operational Performance for Singtel"
            : selectedTab === "M1 BPM Performance"
            ? "Device Care Business Process Performance for M1"
            : selectedTab === "M1 Operational Performance"
            ? "Device Care Operational Performance for M1"
            : selectedTab === "FET BPM Performance"
            ? "Device Care Business Process Performance FET"
            : selectedTab === "FET Operational Performance"
            ? "Device Care Operational Performance for FET"
            : selectedTab === "AIS_Smart_Stock BPM Performance" 
            ? "Device Care Business Process Performance for AIS"
            : name
          }
        </p>
          // <marquee behavior="alternate" className="font-bold text-2xl">{name}</marquee>
        }
        <div className={`flex flex-row justify-end ${!nameFlag ? 'w-full' : 'min-w-max'}`}>
          {!isEmpty(projectData) && (
            <FullscreenIcon
              className="mr-7 cursor-pointer"
              fill="#D5D6DA"
              onClick={() => fullscreen()}
            />
          )}
          {/* {projectData?.Notifications?.length > 0 && ( */}
          <div className="relative">
            {(notificationsData?.length > 0 && selectedTab === 'MAP') && (
              <div
                class="relative inline-flex items-center text-sm font-medium text-white mr-7"
                onClick={() => openNotification(true)}
              >
                <NotificationIcon className="mr-2 cursor-pointer" />
                <div class="cursor-pointer animate-bounce absolute inline-flex items-center justify-center text-10px text-white bg-red-500 border border-white rounded-full -top-1 -right-0.5 dark:border-gray-900 h-5 w-5">
                  {notificationsData?.length}
                </div>
              </div>
            )}
            {notificationOpen && (
              <div
                ref={ref}
                className={`absolute right-20% w-max flex flex-col text-14 z-50 max-h-550px overflow-y-scroll scroll-body`}
              >
                <div className="rounded-2xl p-1 text-sm leading-20 bg-card-bgGray mb-px w-92  flex flex-col justify-between ">
                  <div className="border border-[#D5D6DA] shadow-lg rounded-2xl py-2 px-3 text-sm leading-20 bg-card-bgGray mb-px  flex flex-col justify-between ">
                    <div className="py-2 flex justify-between static mb-2">
                      <div className="text-lg">Notifications</div>
                      <div
                        className="cursor-pointer px-2 py-1 ml-3 hover:bg-card-bgGray hover:rounded-lg"
                        onClick={() => openNotification(false)}
                      >
                        <CrossIcon fill="#A5AAAF" className="w-3 h-3" />
                      </div>
                    </div>
                    {notificationsData?.map((item) => (
                      <NotificationsData
                        notifications={item}
                        resources={resources}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {!isEmpty(projectData) && (
            <div
              className="mr-7 relative"
              onClick={() =>
                isEmpty(projectData)
                  ? openDashboardList(true)
                  : addUpdateFavourite()
              }
            >
              <FavouriteIcon
                className={`${
                  uniqueFavouriteList?.length > 0 || !isEmpty(projectData)
                    ? "cursor-pointer"
                    : ""
                }`}
                fill={!isEmpty(projectData) ? "#CB5AFF" : "none"}
              />
              {!isEmpty(projectData) && (
                <FavPlusIcon className="absolute -top-2 -right-1" />
              )}
              <div
                ref={refDashboard}
                className={`absolute  top-7 -right-3 w-max flex flex-col text-14 z-50 max-h-230px overflow-y-scroll scroll-body`}
              >
                <p className="text-green-500">
                  {loading === true && loading !== undefined ? "Saving..." : ""}{" "}
                  {loading === false && loading !== undefined ? "Saved!" : ""}
                </p>
              </div>
              {/* <div
                ref={refDashboard}
                className={`absolute ${
                  dashboardOpen && isEmpty(projectData) ? "visible" : "hidden"
                }  top-7 -right-12 w-max flex flex-col text-14 z-50 max-h-230px overflow-y-scroll scroll-body`}
              >
                <SavedDashboards
                  refDashboard={refDashboard}
                  favourite={uniqueFavouriteList}
                  goToDashboard={(fav) => goToDashboard(fav)}
                  addUpdateFavourite={(fav) => addUpdateFavourite(fav, "remove")}
                  openDashboardList={() => openDashboardList(false)}
                  removeDashboard={removeDashboard}
                />
              </div> */}
            </div>
          )}
          {/* {!isEmpty(projectData) && <SettingIcon className="mr-7 cursor-pointer" />}*/}
          <SiteMapIcon className="mr-7 cursor-pointer" onClick={openSiteMap} />
          <div className="flex flex-row items-center">
            <div className="bg-white p-1 text-center rounded-full text-black mr-2 font-bold text-sm">
              {userDetails &&
                userDetails?.firstName?.split()?.[0]?.charAt(0)?.toUpperCase()}
              {userDetails &&
                userDetails?.lastName?.split()?.[0]?.charAt(0)?.toUpperCase()}
            </div>
            <span className="mr-5 font-bold">
              {userDetails && toPascalCase(userDetails?.firstName)}{" "}{userDetails && toPascalCase(userDetails?.lastName)}
            </span>
          </div>
        </div>
      </div>
      <DashboardMenu resources={resources} />
    </div>
  );
};

export default HeaderOps;