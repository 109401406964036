import PropType from "prop-types";
import loaderGif from '../../../assets/icons/OneOpsNewLogo.gif'
import config from "../../../config/config";
const Spinner = ({ showOverlay, ...rest }) => {
  return showOverlay ? (
    <div
      className={`fixed w-full h-full bg-black bg-opacity-25 z-30 flex backdrop-blur-sm`}
      onScroll={undefined}
    >
      <SpinnerLoader {...rest} />
    </div>
  ) : (
    <SpinnerLoader {...rest} />
  );
};

export const SpinnerLoader = ({
  height,
  width,
  primaryColor,
  secondaryColor,
  borderThickness,
  loadingText,
  margin
}) => {
  return (
    // <div
    //   className={`${height} ${width} ${primaryColor} ${secondaryColor} ${borderThickness} rounded-full animate-spin m-auto z-20`}
    // ></div>
    <>
      <div className={`m-auto z-20 center w-56 h-56 ${margin}`}>
        {config.isSGP === 'false' ?
          <img
          src={config.loadingImageUrl}
          alt="loading..."
        />
        :
        <>
          <div class="wave"></div>
          <div class="wave"></div>
          <div class="wave"></div>
          <div class="wave"></div>
          <div class="wave"></div>
          <div class="wave"></div>
          <div class="wave"></div>
          <div class="wave"></div>
          <div class="wave"></div>
          <div class="wave"></div>
        </>
        }
      </div>
      <div className="spinner-text">{loadingText}</div>
    </>
  );
};

SpinnerLoader.propTypes = {
  height: PropType.string,
  width: PropType.string,
  primaryColor: PropType.string,
  secondaryColor: PropType.string,
  backdropColor: PropType.string,
};

SpinnerLoader.defaultProps = {
  height: "h-14",
  width: "w-14",
  primaryColor: "border-t-primary",
  secondaryColor: "border-white",
  borderThickness: "border-4",
};

Spinner.propTypes = {
  showOverlay: PropType.bool,
  height: PropType.string,
  width: PropType.string,
  primaryColor: PropType.string,
  secondaryColor: PropType.string,
  backdropColor: PropType.string,
};

Spinner.defaultProps = {
  showOverlay: false,
  height: "h-14",
  width: "w-14",
  primaryColor: "border-t-white",
  secondaryColor: "border-app-border",
  borderThickness: "border-4",
};

export default Spinner;
