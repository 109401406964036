import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { isEmpty } from "../../../utils/functionUtils";

const Popup = ({
  show,
  title,
  onClose,
  children,
  global = false,
  className,
  fitContent = false,
  customeStyle = "",
  transform = "translate(-50%, -50%)",
  flag = false,
  handleModalSubmitFinalCall,
  submatricsDetailsData,
  definations = false,
  submatrics = false,
  definationsPopup = false,
  emailPopup = false,
  downloadReport = false
}) => {
  const extraClasses = !isEmpty(className) ? className.split(" ") : [];

  return (
    <div
      className={classNames(
        "top-0 left-0 right-0 bottom-0 bg-black bg-opacity-25 z-10 w-full m-auto",
        { hidden: !show },
        { fixed: global },
        { absolute: !global },
        ...extraClasses
      )}
    >
      <div
        className={classNames(
          `absolute rounded-md
          ${definationsPopup ? "bg-white text-black w-2/3" : ""}
          ${submatrics ? "bg-app-lightWhite" : ""}
          ${downloadReport ? "bg-white text-black w-1/4 h-auto" : ""}
          ${
            submatrics && submatricsDetailsData?.length > 0
              ? "w-90%"
              : definations
              ? "100vh w-full"
              : definationsPopup
              ? "h-auto"
              : "h-1/6"
          }
          ${emailPopup ? 'bg-white h-auto' : ''}
           ${isEmpty(customeStyle) ? "top-50% left-50% " : customeStyle}`
        )}
        style={{
          transform,
          //   width: fitContent ? "fit-content" : undefined,
        }}
      >
        {!isEmpty(title) && (
          <div className="text-2xl text-center my-2">{title}</div>
        )}
        <div className="text-xl">{children}</div>
      </div>
    </div>
  );
};

Popup.defaultProps = {
  show: false,
  global: false,
};

Popup.propTypes = {
  show: PropTypes.bool.isRequired,
  global: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  className: PropTypes.string,
  flag: PropTypes.bool,
};

export default Popup;
