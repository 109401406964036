import { useEffect, useState } from "react";
import API from "../../api";
import Performance from "../../components/performance/performance";
import ActionTypes from "../../store/actionTypes";
import { useCentralStore } from "../../store/appContext";
import { isEmpty } from "../../utils/functionUtils";
import StoreSelection from "./storeSelection";
import StoreSelectionSea from "./storeSelectionSea"
import WelcomeSection from "./welcomeSection";
import Header from "../../components/common/header/header";
import HamburgerMenu from "../../components/navigation/hamburger";
import MapsContainer from "../MapsContainer/MapsContainer";
import Spinner, {
  SpinnerLoader,
} from "../../components/common/spinner/spinner";
import LandingPage from "../../components/landing/landingPage";
import resources from "../../components/common/resources.json";
import ReportMenu from "../transaction/reportMenu";
import SiteMapDashboard from "./sitemap";
import BillingData from "../Reconciliation/BillingData";
import BilligRevenue from "../Reconciliation/BillingRevenue";
// import { useNavigate, useLocation } from "react-router-dom";
import ReportSGP from "../operationalReport/ReportSGP";
import TrendCompoent from "../StarHubContainer/StarHubReports/TrendCompoent";
import StarhubContainer from "../StarHubContainer/StarhubContainer";
import config from "../../config/config";

const Dashboard = () => {
  const [
    {
      clientData,
      userData,
      userDetails,
      selectedTab,
      programName,
      loadingPer,
      FavouriteList,
      projectData,
      projectName,
      selectedCategory,
      selectedClient,
      childSubmatricsData,
      submatricsDetailsData,
      SiteMap,
      projectDataCheck,
      mapsPage,
      reportCountSuccess,
      mapSelected,
      chartReportCountSuccess
    },
    dispatch,
  ] = useCentralStore();
  // const history = useNavigate();
  // const location = useLocation();

  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING, payload: action });
  };
  
  useEffect(() => {
    if (isEmpty(clientData) && config.isSGP === 'false') {
      // API[ActionTypes.GET_CLIENTS](dispatch);
      let payload = {
        // Username: userDetails && userDetails?.email?.split("@")?.[0],
        idToken: userData?.access_token,
      };
      setLoading(true);
      API[ActionTypes.GET_ROLE_BASES_MATRICS](payload, userDetails && userDetails?.email?.split("@")?.[0])
        .then(({ data }) => {
          setLoading(false);
          if (data) {
            dispatch({
              type: ActionTypes.GET_ROLE_BASES_MATRICS_SUCCESS,
              payload: data,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: ActionTypes.GET_ROLE_BASES_MATRICS_FAILURE,
            payload: err,
          });
          dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
          let payload = {
            level: resources.POST_LOGGER_ERROR,
            category: resources.POST_LOGGER_API,
            ApiName: resources.POST_LOGGER_APINAME,
            message: {
              error: err,
              info: {
                idToken: "",
              },
            },
          };
          API[ActionTypes.POST_LOGGER](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
        });
    }
  }, []);

  let allDashboardsArray = [];
  clientData
    ?.filter((v) => v?.ClientId == selectedClient)[0]
    ?.Channels?.map((v) =>
      v?.AllDashboards?.map((a) => allDashboardsArray.push(a))
    );
  let allDashboards = allDashboardsArray?.filter(
    (v, i, a) => a.findIndex((v2) => v2.Label === v.Label) === i
  );

  const goToHomePage = () => {
    let payload = {
      // Username: userDetails && userDetails?.email?.split("@")?.[0],
      idToken: userData?.access_token,
    };
    API[ActionTypes.GET_ROLE_BASES_MATRICS](payload, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          dispatch({
            type: ActionTypes.GET_ROLE_BASES_MATRICS_SUCCESS,
            payload: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.GET_ROLE_BASES_MATRICS_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
  };

  const [disableScroll, setDisableScroll] = useState(false);
  useEffect(() => {
    if (!isEmpty(childSubmatricsData) || !isEmpty(submatricsDetailsData))
      setDisableScroll(true);
    else setDisableScroll(false);
  }, [childSubmatricsData, submatricsDetailsData]);

  // useEffect(() => {
  //   history(location.href);
  //   window.onpopstate = function () {
  //     history("/login");
  //   };
  // },[]);
  return (
    <div className={`w-full ${config.isSGP === 'true' ? isEmpty(projectName) ? ' min-h-screen bg-app-starhubGreen starhubBackgroundImg' :  'min-h-screen bg-white' : 'h-full'} ${disableScroll ? "overflow-hidden" : ""}`}>
      {/* <div className={`w-full ${config.isSGP === 'true' && isEmpty(projectName) ? 'min-h-screen bg-app-starhubGreen starhubBackgroundImg' : 'h-full'} ${disableScroll ? "overflow-hidden" : ""}`}></div> */}
      {!isEmpty(projectName) && (!resources.M1_Portal.includes(projectName)) && (
        <Header
          className=""
          userDetails={userDetails}
          FavouriteList={FavouriteList}
          resources={resources}
        />
      )}
      {/* <WelcomeSection className="p-2" /> */}

      {isEmpty(projectName) && (
        <LandingPage
          ActionTypes={ActionTypes}
          dispatch={dispatch}
          resources={resources}
          selectedCategory={selectedCategory}
        />
        // <SiteMapDashboard/>
      )}

      {projectName === resources.BPM_PROJECT_NAME && (
        <>
          {(isEmpty(programName) && (selectedTab == "MAP" || mapsPage)) ? (
            <MapsContainer resources={resources} />
          ) : (mapSelected?.toLowerCase() === "australia" ?
            <>
              <StoreSelection
                className=""
                selectedTab={selectedTab}
                resources={resources}
                goToHomePage={() => goToHomePage()}
                allDashboards={allDashboards}
                programName={programName}
              />
              {!projectDataCheck && <Spinner />}
              {projectData && (
                <Performance className="px-4" resources={resources} />
              )}
              {/* {selectedTab === 'TREND' &&
                <TrendCompoent />
              } */}

              {/* <HamburgerMenu
              className="fixed bottom-0 right-0"
              clientData={clientData}
              userDetails={userDetails}
              resources={resources}
            /> */}
            </>
            :
            <>
              <StoreSelectionSea
                className=""
                selectedTab={selectedTab}
                resources={resources}
                goToHomePage={() => goToHomePage()}
                allDashboards={allDashboards}
              />
              {!projectDataCheck && <Spinner />}
              {projectData && (
                <Performance className="px-4" resources={resources} />
              )}
              {/* {selectedTab === 'TREND' &&
                <TrendCompoent />
              } */}

              {/* <HamburgerMenu
              className="fixed bottom-0 right-0"
              clientData={clientData}
              userDetails={userDetails}
              resources={resources}
              /> */}
            </>

          )}
        </>
      )}

      {projectName === "Sitemap" && <SiteMapDashboard/>}
      {projectName === resources.Billing_Data && <BillingData/>}
      {projectName === resources.Billing_Revenue && <BilligRevenue/>}

      {/* {site} */}

      {projectName === resources.TRANSACTIONAL_REPORTING_PROJECT_NAME && (
        <ReportMenu />
      )}

      {['Real Time Service Request Report', 'Customer Service SLA Report', 'Technical Service SLA Report', 'Program Summary', 'Finance Reconciliation & Invoice', 'Download Reports']?.includes(projectName) && (
          <>
            <StarhubContainer />
          </>
      )}
      {projectName === resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME && (
        <ReportSGP />
      )}
    </div>
  );
};

export default Dashboard;
