import { isEmpty } from "../../utils/functionUtils";
import { DatePicker } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import API from "../../api";
import { useCentralStore } from "../../store/appContext";
import ActionTypes from "../../store/actionTypes";
import resources from "../../components/common/resources.json";
const { RangePicker } = DatePicker;

const SGPReportsFilter = () => {
  const [
    {
      selectedReportFilter,
      userData,
      userDetails,
      ssReportFilterDuration,
    },
    dispatch,
  ] = useCentralStore();

  let filterOptions = [
    {
      label: "Real Time",
      value: "real time",
    }
  ];

  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  const [changedRealTime, setChangedRealTime] = useState(true);
  const [dateDiff, setDateDiff] = useState(null);
  const [startDateToShow, setStartDateToShow] = useState(null);
  const [endDateToShow, setEndDateToShow] = useState(null);

  useEffect(() => {
    if (dates && dates.length === 2) {
      const startDate = dates[0];
      const endDate = dates[1];
      const diffInDays = endDate.diff(startDate, 'days');
      setDateDiff(diffInDays);
      setStartDateToShow(startDate.format("DD-MM-YYYY"));
      setEndDateToShow(endDate.format("DD-MM-YYYY"));
    }
  }, [dates]);

  const handleDateChange = (dates, dateString, type) => {    
    dispatch({
      type: ActionTypes.SS_SET_REPORT_FILTER_DURATION,
      payload: {
        type: type,
        startDate: type === "dates" ? moment(dateString[0]).format("YYYY-MM-DD 00:00:00") : moment(dateString[0]).format("YYYY-MM-DD 00:00:00"),
        endDate: type === "dates" ? moment(dateString[1]).format("YYYY-MM-DD 23:59:59") : moment(dateString[1]).format("YYYY-MM-DD 23:59:59"),
        duration: type === "dates" ? dateString : dateString,
      },
    });
    setDates(dates);
    const setLoading = (action) => {
      dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
    };
    setLoading(true);
    let payload = {
      StartDate: moment(dateString[0]).format("YYYY-MM-DD 00:00:00"),//ssReportFilterDuration?.startDate,
      EndDate: moment(dateString[1]).format("YYYY-MM-DD 23:59:59")//ssReportFilterDuration?.endDate,
    };
    API[ActionTypes.GET_SS_REPORT_COUNT](
      payload,
      userData?.access_token,
      userDetails && userDetails?.email?.split("@")?.[0]
    )
      .then((data) => {
        setLoading(false);
        setChangedRealTime(true)
          dispatch({
            type: ActionTypes.GET_SS_REPORT_COUNT_SUCCESS,
            payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.GET_SS_REPORT_COUNT_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](
          _payload,
          userData?.access_token,
          userDetails && userDetails?.email?.split("@")?.[0]
        );
      });

    dispatch({
      type: ActionTypes.GET_SS_REPORT_COUNT_SUCCESS,
      payload: null,
    });
    dispatch({
      type: ActionTypes.GET_SS_REPORT_DETAILS_SUCCESS,
      payload: null,
    });
    dispatch({
      type: ActionTypes.GET_SS_REPORT_DETAILS_SUCCESS,
      payload: null,
    });
  };

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") >= 30;
    const tooEarly = dates[1] && dates[1].diff(current, "days") >= 30;
    const today = current && current > moment().endOf("day");
    return !!today || !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const handleFilterChange = (val) => {
    let currentDate;
    let firstDayofMonth;
    if (val === "real time") {
      let today = new Date();
      currentDate = moment(new Date()).format("YYYY-MM-DD 23:59:59");
      firstDayofMonth = moment(
        new Date(today.getFullYear(), today.getMonth(), 1)
      ).format("YYYY-MM-DD 00:00:00");
    }

    dispatch({ type: ActionTypes.SS_SET_REPORT_FILTER, payload: val });
    dispatch({
      type: ActionTypes.SS_SET_REPORT_FILTER_DURATION,
      payload: {
        type: val === "real time" ? "realtime" : "realtime",
        startDate: val === "real time" ? firstDayofMonth : firstDayofMonth,
        endDate: val === "real time" ? currentDate : currentDate,
        duration: "2023-04",
      },
    });
    setDates(null);
    setValue(null);
    setChangedRealTime(false);
  };

  return (
    <div className={``}>
      <div>
        <div className="flex my-2">
          {(isEmpty(selectedReportFilter) ||
            selectedReportFilter === "real time") &&
            filterOptions?.map((v) => (
              <div
                className={`text-black relative rounded-md text-[16px] cursor-pointer py-2 px-3 flex justify-center items-center border-b bg-app-lightGreen border-app-lightGreen mr-5 font-bold
                  ${ selectedReportFilter === v.value ? "border border-app-starhubGreen" : "" }`}
                onClick={() => handleFilterChange(v.value)}
              >
                {v.label}
              </div>
            ))}
        </div>
      </div>
      {selectedReportFilter === "real time" && (
        <div>
          { changedRealTime ? (
            <div>
              {(dates && dates.length === 2) ? (
                <p className="text-[11px] text-white" onClick={() => setChangedRealTime(false)}>
                  Showing data from <b>{startDateToShow}</b> to <b>{endDateToShow}</b>.
                  <span>
                    <a
                      className="text-black underline"
                      onClick={() => setChangedRealTime(false)}
                    >
                      change dates
                    </a>
                  </span>
                </p>
              ) : (
                <p className="text-[11px] text-white" onClick={() => setChangedRealTime(false)}>
                  Showing data for last <b>90</b> days.
                  <span>
                    <a
                      className="text-black underline"
                      onClick={() => setChangedRealTime(false)}
                    >
                      change dates
                    </a>
                  </span>
                </p>
              )}
            </div>) :
            <RangePicker
              allowClear={false}
              className="bg-white hover:bg-white"
              value={dates}
              onChange={handleDateChange}
            /> 
          }
        </div> 
      )}  
    </div>
  );
};

export default SGPReportsFilter;
