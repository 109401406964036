import { useState, useEffect, useRef } from "react";
import ActionTypes from "../../store/actionTypes";
import { useCentralStore } from "../../store/appContext";
import SSReportsFilter from "../SmartStockContailer/SSReportsFilter";
import SSDashboardData from "../SmartStockContailer/SSDashboardData";
import SSDashboardMenu from "../SmartStockContailer/SSDashboardMenu";
import AsurionLogo from "../../assets/icons/asurionLogo";
import { toPascalCase, setCursorPositioning } from "../../utils/functionUtils";
import { ReactComponent as Logo } from "../../assets/icons/OneOplogo.svg";
import FullscreenIcon from "../../assets/icons/fullscreen-icon";
import moment from "moment";
import Spinner from "../../components/common/spinner/spinner";
import WelcomeSection from "../dashboard/welcomeSectionSS";
import API from "../../api";
import { useNavigate } from "react-router-dom";

const SmartStockContainer = ({ className, resources }) => {
  const [{ loading, loadingData, userData }] = useCentralStore();
  const [
    {
      userDetails,
      ssSelectedReportFilter,
      ssreportCountSuccess,
      fullscreenMode, 
      ssReportFilterDuration,
    },
    dispatch,
  ] = useCentralStore();
  const [fullScreen, setFullScreenMode] = useState(fullscreenMode);
  const ref = useRef();
  const refDashboard = useRef();
  const navigate = useNavigate();
  const [classInOut, setClassInOut] = useState(null);
  const myInterval = useRef();

  function exitHandler() {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      setFullScreenMode(!fullScreen);
      dispatch({ type: ActionTypes.SET_FULLSCREEN, payload: false });
      dispatch({
        type: ActionTypes.SET_REFRESHED_ON,
        payload: moment().format("YYYY-MM-DD HH:mm:ss"),
      });
      clearInterval(myInterval.current);
      myInterval.current = null;
    }
  }

  function fullscreen() {
    setFullScreenMode(!fullScreen);
    dispatch({ type: ActionTypes.SET_FULLSCREEN, payload: true });
    dispatch({
      type: ActionTypes.SET_REFRESHED_ON,
      payload: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    var isInFullScreen =
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null);

    var docElm = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  }

  useEffect(() => {
    if (fullscreenMode) {
      // let clients = uniqueClientsData?.map((c) => c.value)
      document.addEventListener("fullscreenchange", exitHandler);
      document.addEventListener("webkitfullscreenchange", exitHandler);
      document.addEventListener("mozfullscreenchange", exitHandler);
      document.addEventListener("MSFullscreenChange", exitHandler);
      myInterval.current = setTimeout(() => {
        // const nextClientIndex =
        //   clients.indexOf(selectedClient) === clients.length - 1
        //     ? 0
        //     : clients.indexOf(selectedClient) + 1;
        // const nextClient = clients[nextClientIndex];
        // if(!mapsPage){
          dispatch({
            type: ActionTypes.GET_SUBMATRICS_DETAILS_SUCCESS,
            payload: [],
          });
          // dispatch({
          //   type: ActionTypes.GET_SS_REPORT_COUNT,
          //   payload: [],
          // })
          let startDate = moment(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD 00:00:00')
          let endDate = moment(new Date()).format('YYYY-MM-DD 23:59:59')

          let payload = {
            StartDate: ssReportFilterDuration?.startDate,
            EndDate: ssReportFilterDuration?.endDate,
          };
          // setLoading(true)
          API[ActionTypes.GET_SS_REPORT_COUNT](
            payload,
            userData?.access_token,
            userDetails && userDetails?.email?.split("@")?.[0]
          )
            .then((data) => {
              // setLoading(false);
              navigate('/smartstock')
                // openPopup(true);
                setClassInOut("modal-container modal-space-in");
                dispatch({
                  type: ActionTypes.GET_SS_REPORT_COUNT_SUCCESS,
                  payload: data,
                });
                dispatch({
                  type: ActionTypes.SET_REFRESHED_ON,
                  payload: moment().format("YYYY-MM-DD HH:mm:ss"),
                });
            })
            .catch((err) => {
              // openPopup(false);
              dispatch({
                type: ActionTypes.GET_SS_REPORT_COUNT_FAILURE,
                payload: err,
              });
              dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
              let _payload = {
                level: resources.POST_LOGGER_ERROR,
                category: resources.POST_LOGGER_API,
                ApiName: resources.POST_LOGGER_APINAME,
                message: {
                  error: err,
                  info: {
                    idToken: "",
                  },
                },
              };
              API[ActionTypes.POST_LOGGER](
                _payload,
                userData?.access_token,
                userDetails && userDetails?.email?.split("@")?.[0]
              );
            });


          // dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: nextClient });

          // if(mapSelected?.toLowerCase() !== 'australia'){
            // let setDashboard = uniqueClientsList?.find(v => v?.ClientId === nextClient)?.Channels[0]?.AllDashboards[0]
            // dispatch({ type: ActionTypes.SET_SELECTED_DASHBOARD, payload: setDashboard?.DashboardId })
            // dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: setDashboard?.Label })
          // }
        // }
      }, 600000);
      return () => {
        clearInterval(myInterval);
      };
    } else {
    }
  }, [ fullscreenMode, ssreportCountSuccess ]);


  return (
    <div className="w-full h-full">
      {(loading || loadingData) && <Spinner showOverlay={true} />}
      <div id="header" className={`mb-8 flex flex-col overflow-x-hidden`}>
        <div className={`px-2 ${className} flex flex-row justify-between items-center w-full`}>
          <div className={`flex justify-start items-center w-full`}>
            <Logo className="w-14" />
            <p className="flex justify-center my-5 mx-auto font-bold text-[32px]"> Smart Stock for AIS </p>
          </div>
          <div className={` flex flex-row justify-end min-w-max `}>
            <FullscreenIcon
              className="mr-7 cursor-pointer"
              fill="#D5D6DA"
              onClick={() => fullscreen()}
            />
            <div className="flex items-center">
              <div className="bg-white p-1 text-center rounded-full text-black mr-2 font-bold text-sm">
                {userDetails &&
                  userDetails?.firstName?.split("")[0]?.toUpperCase()}
                {userDetails &&
                  userDetails?.lastName?.split("")[0]?.toUpperCase()}
              </div>
              <span className="mr-5 font-bold text-white">
                {userDetails && toPascalCase(userDetails?.firstName)}{" "}
                {userDetails && toPascalCase(userDetails?.lastName)}
              </span>
            </div>
          </div>
        </div>
        <WelcomeSection resources={resources} />

        <div className="overflow-x-hidden flex justify-between items-center mx-[40px]">
          <SSDashboardMenu ssreportCountSuccess={ssreportCountSuccess} />
          <SSReportsFilter
            ssSelectedReportFilter={ssSelectedReportFilter}
            dispatch={dispatch}
            ActionTypes={ActionTypes}
          />
        </div>
      </div>
      <div className="sticky top-0 overflow-x-auto  h-[80vh]">
        <SSDashboardData />
      </div>
    </div>
  );
};

export default SmartStockContainer;
