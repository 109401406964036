import React from "react";

const icon = ({ className, fill, onClick }) => (
  <svg className={className}  width="90" height="90" viewBox="0 0 90 90" fill={fill} xmlns="http://www.w3.org/2000/svg" >
<path d="M79.48 11.9999C79.48 12.5599 79.48 12.9599 79.48 13.3699C79.48 38.1199 79.48 62.8699 79.48 87.6099C79.48 89.6499 79.13 89.9999 77.09 89.9999C55.03 89.9999 32.98 89.9999 10.92 89.9999C5.62997 89.9999 1.46997 86.8199 0.289971 81.8599C0.139971 81.2299 0.0599714 80.5699 0.0199714 79.9199C-0.0500286 78.5799 0.509971 78.0099 1.82997 78.0099C4.16997 78.0099 6.50997 78.0099 8.99997 78.0099C8.99997 77.5799 8.99997 77.1899 8.99997 76.7999C8.99997 53.8299 8.99997 30.8699 8.99997 7.89988C8.99997 4.18988 11.05 1.29988 14.4 0.339883C15.14 0.129883 15.94 0.0198828 16.71 0.0198828C37.58 -0.000117187 58.45 -0.000117187 79.32 0.0198828C84.92 0.0198828 89.31 3.96988 89.95 9.49988C90.17 11.4199 89.65 12.0099 87.73 12.0099C85.02 12.0099 82.31 12.0099 79.5 12.0099L79.48 11.9999ZM12 77.9999C12.49 77.9999 12.86 77.9999 13.23 77.9999C30.03 77.9999 46.84 77.9999 63.64 77.9999C63.95 77.9999 64.27 77.9799 64.58 78.0099C65.36 78.0899 65.91 78.5399 65.95 79.3099C66.05 81.4099 67.23 82.8499 68.76 84.0699C70.99 85.8399 73.51 86.8599 76.43 86.9999V2.99988H75.29C55.89 2.99988 36.5 2.99988 17.1 2.99988C13.74 2.99988 11.99 4.75988 11.99 8.15988C11.99 31.0599 11.99 53.9599 11.99 76.8599V77.9999H12ZM67.6 86.9999C65.61 85.5799 64.23 83.9999 63.5 81.9499C63.24 81.2199 62.91 80.9799 62.13 80.9799C42.84 80.9999 23.54 80.9999 4.24997 80.9999C3.91997 80.9999 3.57997 80.9999 3.18997 80.9999C3.23997 81.2399 3.24997 81.3999 3.28997 81.5399C4.22997 84.8599 7.14997 86.9999 10.81 86.9999C29.36 86.9999 47.9 86.9999 66.45 86.9999C66.73 86.9999 67.01 86.9999 67.6 86.9999ZM86.76 8.96988C86.12 5.50988 82.64 2.70988 79.54 3.09988V8.96988H86.76Z" fill={fill}/>
<path d="M14.9998 24C14.9998 20.5 14.9998 17 14.9998 13.5C14.9998 10.74 16.7398 9 19.5198 9C26.5198 9 33.5198 9 40.5098 9C43.2198 9 44.9798 10.75 44.9798 13.45C44.9798 20.48 44.9798 27.51 44.9798 34.54C44.9798 37.24 43.2198 38.99 40.5098 38.99C33.4798 38.99 26.4498 38.99 19.4198 38.99C16.7698 38.99 14.9998 37.22 14.9898 34.59C14.9798 31.06 14.9898 27.53 14.9898 24H14.9998ZM25.4998 12C23.4898 12 21.6198 12 19.7498 12C18.3498 12 18.0098 12.35 18.0098 13.78C18.0098 20.59 18.0098 27.4 18.0098 34.21C18.0098 35.64 18.3598 35.99 19.7598 35.99C26.5998 35.99 33.4398 35.99 40.2798 35.99C41.6298 35.99 41.9998 35.62 41.9998 34.28C41.9998 30.91 41.9998 27.53 41.9998 24.16C41.9998 20.63 41.9998 17.1 41.9998 13.57C41.9998 12.8 41.7998 12.08 40.9698 12.04C38.8298 11.96 36.6898 12.01 34.4898 12.01C34.4898 12.46 34.4898 12.8 34.4898 13.14C34.4898 17.17 34.4898 21.2 34.4898 25.23C34.4898 25.99 34.2498 26.58 33.5098 26.88C32.7998 27.17 32.2298 26.88 31.7398 26.38C31.1798 25.8 30.6298 25.22 29.9698 24.53C29.3598 25.18 28.8498 25.72 28.3398 26.27C27.8198 26.83 27.2298 27.21 26.4298 26.88C25.6498 26.55 25.4698 25.9 25.4798 25.12C25.4998 22.53 25.4798 19.93 25.4798 17.34C25.4798 15.6 25.4798 13.85 25.4798 12H25.4998ZM31.4498 21.68V12.06H28.5798V21.72C29.7998 20.77 30.0998 20.77 31.4498 21.68Z" fill={fill}/>
<path d="M73.5201 17.96H70.5301C70.5001 17.73 70.4701 17.5 70.4201 17.26C69.9401 14.84 67.8201 13.25 65.4601 13.52C63.1301 13.79 61.3901 15.83 61.4801 18.19C61.5701 20.55 63.4901 22.4 65.9401 22.49C69.2701 22.62 71.8601 24.4 72.9801 27.33C74.4801 31.27 72.4201 35.69 68.4401 37.07C68.1501 37.17 67.8401 37.26 67.4901 37.36V40.41H64.5001V37.42C60.4501 35.9 58.4401 33.41 58.5101 30.04H61.4201C61.6301 32.06 62.5901 33.59 64.5801 34.24C66.3801 34.83 68.0001 34.38 69.2701 32.99C70.5101 31.63 70.8101 30.01 70.1001 28.29C69.3701 26.54 68.0001 25.58 66.1201 25.51C63.0501 25.4 60.7001 24.1 59.2801 21.37C57.1801 17.33 59.2301 12.38 63.5501 10.89C63.8401 10.79 64.1301 10.71 64.4801 10.6V7.54004H67.4201V10.57C71.1401 11.65 73.1601 14.03 73.5101 17.95L73.5201 17.96Z" fill={fill}/>
<path d="M73.4398 49.5703V52.4603H36.0698V49.5703H73.4398Z" fill={fill}/>
<path d="M36.04 67.4398V64.5498H73.41V67.4398H36.04Z" fill={fill}/>
<path d="M20.9801 57.0002C17.6701 57.0002 14.9801 54.2802 15.0001 50.9602C15.0201 47.6502 17.7401 44.9702 21.0601 45.0002C24.3701 45.0302 27.0301 47.7602 26.9901 51.0902C26.9601 54.3502 24.2501 57.0103 20.9801 57.0002ZM23.9901 50.9703C23.9701 49.3303 22.6201 48.0002 20.9901 48.0102C19.3201 48.0102 17.9601 49.4103 18.0001 51.0803C18.0401 52.7103 19.4101 54.0203 21.0501 54.0002C22.6701 53.9802 24.0101 52.6003 23.9901 50.9703Z" fill={fill}/>
<path d="M20.9102 71.9998C17.6102 71.9598 14.9502 69.2198 15.0002 65.8998C15.0502 62.5998 17.8002 59.9498 21.1202 60.0098C24.4202 60.0698 27.0502 62.8298 26.9902 66.1598C26.9302 69.4098 24.1902 72.0398 20.9102 72.0098V71.9998ZM21.0502 68.9998C22.6702 68.9798 24.0102 67.5998 23.9902 65.9698C23.9702 64.3298 22.6302 62.9998 20.9902 62.9998C19.3202 62.9998 17.9602 64.3998 18.0002 66.0698C18.0402 67.6998 19.4102 69.0098 21.0502 68.9898V68.9998Z" fill={fill}/>
<path d="M51.18 17.9799H48.04V15.0499H51.18C50.66 14.2299 50.24 13.5599 49.77 12.8199C50.59 12.2699 51.38 11.7399 52.24 11.1699C53.27 12.7299 54.32 14.1999 55.24 15.7499C55.46 16.1199 55.46 16.8599 55.24 17.2299C54.32 18.7799 53.28 20.2599 52.24 21.8199C51.39 21.2499 50.6 20.7199 49.76 20.1599C50.23 19.4399 50.65 18.7999 51.18 17.9799Z" fill={fill}/>
<path d="M53.9702 29.9699V32.9399H50.8102C51.3302 33.7599 51.7402 34.4299 52.2202 35.1799C51.4002 35.7299 50.6102 36.2499 49.7502 36.8299C48.7002 35.2399 47.6502 33.7399 46.7102 32.1599C46.5002 31.8099 46.5302 31.1199 46.7402 30.7699C47.6702 29.2199 48.7102 27.7499 49.7502 26.1699C50.6002 26.7399 51.3902 27.2699 52.2302 27.8299C51.7602 28.5499 51.3402 29.1899 50.8202 29.9699H53.9702Z" fill={fill}/>
<path d="M32.93 52.4601H30.04V49.5801H32.93V52.4601Z" fill={fill}/>
<path d="M32.9401 67.4596H30.0601V64.5596H32.9401V67.4596Z" fill={fill}/>
</svg>

);
export default icon;
