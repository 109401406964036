import { useEffect, useState } from "react"
import ActionTypes from "../../../store/actionTypes"
import { useCentralStore } from "../../../store/appContext"
import CsSLAData from './CsSlaData'
import SLATrends from "./SLATrends"
import TsSLAData from './TsSlaData'
import CsSLADataTab2 from './CsSLADataTab2'
import GlossaryReport from "../GlossaryReport"

const SLAReportContainer = () => {

    const [ { slaReportCountSuccess, projectName, selectedTab, chartReportCountSuccess }, dispatch ] = useCentralStore()
    const Data = (projectName === "Customer Service SLA Report") ? slaReportCountSuccess?.GetSLADetailsResponse?.CustomerServiceSLA : slaReportCountSuccess?.GetSLADetailsResponse?.TechnicalSLA
    const newData = (projectName === "Customer Service SLA Report") ? Data?.map(({ CalculationMethodology, CustomerServiceSLA, ...rest }) => rest) : Data?.map(({ Descriptions, TechnicalSLA, ...rest }) => rest);

    const [slaData, setSLAData] = useState(null);
    const [slaReportData, setSLAReportData] = useState(null);

    useEffect(() => {
        if(slaReportCountSuccess) setSLAData(newData)
    }, [slaReportCountSuccess])

    useEffect(() => {
        if(chartReportCountSuccess){
            const reportData = projectName === "Customer Service SLA Report" ? chartReportCountSuccess?.CustomerServiceSLA : chartReportCountSuccess?.TechnicalSLA
            setSLAReportData(reportData)
        }
    }, [chartReportCountSuccess])

    return(
        <div>
            {(projectName === "Customer Service SLA Report" && selectedTab === 'Prev Month') &&
                <CsSLAData csSlaData={slaData} />
            }
            {projectName === "Customer Service SLA Report" && selectedTab ==="Glossary" &&
                <CsSLADataTab2 />
            }
            {(projectName === 'Customer Service SLA Report' && selectedTab === 'SLA Calculation Methodology') &&
                <SLATrends />
            }
            {projectName === "Technical Service SLA Report" && selectedTab === 'Prev Month' &&
                <TsSLAData tsSlaData={slaData} />
            }
            {projectName === "Technical Service SLA Report" && selectedTab ==="Glossary" &&
                <GlossaryReport selectedTab={selectedTab} projectName={projectName}/>
            }
            {((projectName === 'Customer Service SLA Report' || projectName === "Technical Service SLA Report") && selectedTab === 'Monthly Trends') &&
                <SLATrends reportName={projectName} slaReportData={slaReportData} />
            }
        </div>
    )
}

export default SLAReportContainer