import React from "react";
import ActionTypes from "../../store/actionTypes";
import { useCentralStore } from "../../store/appContext";
import { isEmpty } from "../../utils/functionUtils";
import ReportSGPView from "./ReportSGPView";
import resources from '../../components/common/resources.json'
import API from "../../api";
import moment from "moment";

const ReportSGP = ({}) => {
    const [ { selectedStarhubReport, reportFilterDuration, userData, userDetails }, dispatch] = useCentralStore()

    const setLoading = (action) => {
        dispatch({ type: ActionTypes.SET_LOADING, payload: action });
    };

    const handleReportClick = (rpt) => {
        dispatch({ type: ActionTypes.SET_PROJECT_NAME, payload: resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME });
        // goToTransaction(config.isSGP);
        dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER, payload: 'real time'})
        dispatch({ type: ActionTypes.SGP_SET_REPORT_NAME, payload: {
            reportLabel: rpt.label,
            reportValue: rpt.value
        } })

        // let startDate = moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('DD-MM-YYYY 00:00:00')
        // let endDate = moment(new Date()).format('DD-MM-YYYY 23:59:59')
        let startDate = moment(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)).format('DD-MM-YYYY 00:00:00')
        let endDate = moment(new Date()).format('DD-MM-YYYY 23:59:59')

        dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION, payload: {
            type: 'dates',
            startDate: startDate,
            endDate: endDate,
            duration: 'dates'
        }})

        let payload = {
            //enrollment
            StartDate: startDate,
			      EndDate: endDate,

            //servicerequest
            // StartDate: '2023-05-01 00:00:00',
			// EndDate: "2023-05-31 23:59:59",
			// "ReportType": "servicerequest",
			ReportType: rpt.value
			// Reason: ""
        };
        setLoading(true)
        API[ActionTypes.GET_STARHUB_REPORT_COUNT](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
        .then(({ data }) => {
            setLoading(false);
            if (data) {
              dispatch({
                type: ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS,
                payload: data,
              });
            }
          })
          .catch((err) => {
            dispatch({
              type: ActionTypes.GET_STARHUB_REPORT_COUNT_FAILURE,
              payload: err,
            });
            dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
            let _payload = {
              level: resources.POST_LOGGER_ERROR,
              category: resources.POST_LOGGER_API,
              ApiName: resources.POST_LOGGER_APINAME,
              message: {
                error: err,
                info: {
                  idToken: "",
                },
              },
            };
            API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
          });


    }

    const reportsList = [
        // { label: 'Monthly Enrollment Reports And Subscription Reports', value: 'enrollment'},
        // { label: 'Service Request Report', value: 'servicerequest'},
        { label: 'Incentives Report', value: 'Incentives Report'},
        { label: 'Rebates To StarHub', value: 'Rebates To StarHub'},
        { label: 'Settlement And Reconciliation', value: 'Settlement And Reconciliation'},
        { label: 'SLA Report', value: 'SLA Report'},
        // { label: 'StarHub - Quarterly Report', value: 'StarHub - Quarterly Report'}
    ]

    return(
        <div>
            {isEmpty(selectedStarhubReport?.starHubReportName) ?
                <div className="mt-4 mx-4">
                    {reportsList?.map(v => 
                        <div className="rounded-lg cursor-pointer w-44 md:w-64 flex flex-col justify-start border-1 border-gray-700 bg-app-landingColor2 mb-1 text-white text-sm mt-2 p-2 pl-4 hover:bg-app-landingColor1"
                            onClick={() => handleReportClick(v)}>
                            <a target="_blank">{v.label}</a>
                        </div>
                    )}
                </div>
                :
                <ReportSGPView />
            }
        </div>
    )
}

export default ReportSGP;