import { v4 as uuid } from "uuid";
import axios from "axios"
import config from "../config/config";

 export const bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };

 export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        resolve(reader.result.split("base64,")[1]);
      };
    });
  };

 export const dataURItoBlob = (dataURI, contentType = null, shouldConvertToBin = false) => {
    var binary;
    if (shouldConvertToBin) binary = atob(dataURI.split(",")[1]);
    else binary = atob(dataURI);
    var array = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    if (contentType)
        return new Blob([new Uint8Array(array)], { type: contentType });
    return new Blob([new Uint8Array(array)]);
}

export const uploadToS3 = (url, document, formData, userDetails) => {
    return axios({
        method: "put",
        url: url,
        headers: {
          Accept: "application/json",
          "Asurion-client": "samsung", //(window.client || "").toLowerCase(),
          'Asurion-apikey': config?.xApiKey,
          "Content-Type": "text/csv",
          "Asurion-channel": "agent",
          "Asurion-region": "APAC-SEA",
          "Asurion-lineofbusiness": "Mobility",
          'Asurion-correlationid':generateUniqueId(),
          'Accept-language': "en-US",
          'Asurion-enduser': userDetails,
          "pragma": "no-cache",
          "X-Requested-With": "XMLHttpRequest",
          // "Expires": "-1",
          // "Cache-Control": "no-cache,no-store,must-revalidate,max-age=-1,private",
        },
        data: document,
        formData: formData,
    //     // OnUploadProgressBar: () => { <ProgressBar isActive={this.props.state.isLoading == true} /> }
    });
};

export const generateUniqueId = () => {
  // return uuid();
  let id = uuid();
  let hexId = id.toUpperCase().toString('hex').replace(/-/g, "")
  return hexId;
};

export const exportJSONToCSV = (objArray, selectedAccountingMonth, client) => {
  let arr = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  let str =
    `${Object.keys(arr[0])
      .map((value) => `"${value}"`)
      .join(",")}` + "\r\n";
  let csvContent = arr.reduce((st, next) => {
    st +=
      `${Object.values(next)
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    return st;
  }, str);
  let element = document.createElement("a");
  element.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
  element.target = "_blank";
  element.download = !client ? `M1_reconcileReport_${selectedAccountingMonth}.csv` : `Starhub_Report_${selectedAccountingMonth}.csv`;
  element.click();
};

// export const formattedDate = (accMonth) => {
//   let date = `01-${accMonth}`;
//   let d = new Date(date),
//     month = "" + (d.getMonth() + 1),
//     day = "" + d.getDate(),
//     year = d.getFullYear();

//   if (month.length < 2) month = "0" + month;
//   if (day.length < 2) day = "0" + day;

//   return [year, month, day].join("-");
// };

export const formattedDate = (accMonth, client) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const [monthPart, yearPart] = accMonth.split('-');
  const monthIndex = monthNames.indexOf(monthPart);
  const year = '20' + yearPart; 
  const month = String(monthIndex + 1).padStart(2, '0');
  let date = new Date(`${year}-${month}-01`);
  if (client === 'Celcom') {
    date.setMonth(date.getMonth() + 1, 0);
  }
  let day = String(date.getDate()).padStart(2, '0');
  let finalMonth = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  let finalYear = date.getFullYear();
  return [finalYear, finalMonth, day].join("-");
};