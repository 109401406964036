import React from "react";

const icon = ({ className, fill, onClick }) => (
  <svg
    className={className}
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00016 15.333C10.9457 15.333 13.3335 12.9452 13.3335 9.99967C13.3335 7.05416 10.9457 4.66634 8.00016 4.66634C5.05464 4.66634 2.66683 7.05416 2.66683 9.99967C2.66683 12.9452 5.05464 15.333 8.00016 15.333ZM8.00016 16.6663C11.6821 16.6663 14.6668 13.6816 14.6668 9.99967C14.6668 6.31778 11.6821 3.33301 8.00016 3.33301C4.31826 3.33301 1.3335 6.31778 1.3335 9.99967C1.3335 13.6816 4.31826 16.6663 8.00016 16.6663ZM7.3335 11.9997V5.99967H8.66683V11.9997H7.3335ZM8.00016 13.9997C8.36835 13.9997 8.66683 13.7012 8.66683 13.333C8.66683 12.9648 8.36835 12.6663 8.00016 12.6663C7.63197 12.6663 7.3335 12.9648 7.3335 13.333C7.3335 13.7012 7.63197 13.9997 8.00016 13.9997Z"
      fill="#EDB800"
    />
  </svg>
);
export default icon;
