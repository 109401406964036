import React, { useEffect, useState } from 'react';
import ArrowDown from '../../../assets/icons/arrow-down'
import ArrowUp from '../../../assets/icons/arrow-up'


import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import faker from 'faker';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const SLATrends = ({
    reportName,
    slaReportData
}) => {

    let getBgColor = {0: '#1ED760', 1: '#685BC7', 2: '#C800A1', 3: '#500878', 4: '#1CD4B0', 5: '#09411D', 6: '#8CBDFA', 7: '#C800A1', 8: '#8A2252', 9: '#878787', 10: '#DC3545', 11: '#1ED760'}

    const options = {
        plugins: {
          title: {
            display: true,
            text: reportName,
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      };



      // const _slaReportData = {
      //   Months: slaReportData?.map(v => v?.Month),
      //   ['Acceptance/ Rejection of SR']: slaReportData?.map(v => v?.['Acceptance/ Rejection of Service Requests with communication to Subscriber']),
      //   ['Delivery']: slaReportData?.map(v => v?.['Delivery']),
      //   ['Device Repair']: slaReportData?.map(v => v?.['Device repair']),
      //   ['Customer complaints management']: slaReportData?.map(v => v?.['Customer complaints management']),
      //   ['Rejection rate']: slaReportData?.map(v => v?.['Rejection rate']),
      //   ['ActualSLA%']: slaReportData?.map(v => v?.['ActualSLA%']),
      //   Category: slaReportData?.map(v => v?.Category),
      //   ['ExpectedSLA%']: slaReportData?.map(v => v?.['ExpectedSLA%']),
      // }
      
      const [labels, setLabels] = useState(null)
      const [accRejSRData, setAccRejSRData] = useState(null)
      const [deliveryData, setDeliveryData] = useState(null)
      const [deviceRepairData, setDeviceRepairData] = useState(null)
      const [custComplMgmtData, setCustComplMgmtData] = useState(null)
      const [rejectionRateData, setRejectionRateData] = useState(null)


      const [srPortal, setSRPortal] = useState(null)
      const [p1Data, setP1Data] = useState(null)
      const [p2Data, setP2Data] = useState(null)
      const [p3Data, setP3Data] = useState(null)

      useEffect(() => {
        if(slaReportData !== null){
          let labels = Object.keys(slaReportData) //_slaslaReportData?.Months
          setLabels(labels)

          let dataValues = Object.values(slaReportData)?.flat()

          setAccRejSRData(dataValues.filter(v => v?.Category === 'Acceptance/ Rejection of Service Requests with communication to Subscriber'))
          setDeliveryData(dataValues.filter(v => v?.Category === 'Delivery'))
          setDeviceRepairData(dataValues.filter(v => v?.Category === 'Device Repair'))
          setCustComplMgmtData(dataValues.filter(v => v?.Category === 'Customer complaints management'))
          setRejectionRateData(dataValues.filter(v => v?.Category === 'Rejection rate'))

          setSRPortal(dataValues.filter(v => v?.Category === 'Service Request Portal/ App'))
          setP1Data(dataValues.filter(v => v?.Category === 'Priority 1 (P1) – Critical Incident'))
          setP2Data(dataValues.filter(v => v?.Category === 'Priority 2 (P2) – Minor Incident'))
          setP3Data(dataValues.filter(v => v?.Category === 'Priority 3 (P3) – Improvement'))

        }
      }, [slaReportData])

      const _accRejSRData = {
        Months: accRejSRData?.map(v => v?.Month),
        ['ActualSLA%']: accRejSRData?.map(v => v?.['ActualSLA%']),
        ['ExpectedSLA%']: accRejSRData?.map(v => v?.['ExpectedSLA%']),
      }

      const _deliveryData = {
        Months: deliveryData?.map(v => v?.Month),
        ['ActualSLA%']: deliveryData?.map(v => v?.['ActualSLA%']),
        ['ExpectedSLA%']: deliveryData?.map(v => v?.['ExpectedSLA%']),
      }

      const _deviceRepairData = {
        Months: deviceRepairData?.map(v => v?.Month),
        ['ActualSLA%']: deviceRepairData?.map(v => v?.['ActualSLA%']),
        ['ExpectedSLA%']: deviceRepairData?.map(v => v?.['ExpectedSLA%']),
      }

      const _custComplMgmtData = {
        Months: custComplMgmtData?.map(v => v?.Month),
        ['ActualSLA%']: custComplMgmtData?.map(v => v?.['ActualSLA%']),
        ['ExpectedSLA%']: custComplMgmtData?.map(v => v?.['ExpectedSLA%']),
      }

      const _rejectionRateData = {
        Months: rejectionRateData?.map(v => v?.Month),
        ['ActualSLA%']: rejectionRateData?.map(v => v?.['ActualSLA%']),
        ['ExpectedSLA%']: rejectionRateData?.map(v => v?.['ExpectedSLA%']),
      }

      const _srPortal = {
        Months: srPortal?.map(v => v?.Month),
        ['ActualSLA%']: srPortal?.map(v => v?.['ActualSLA%']),
        ['ExpectedSLA%']: srPortal?.map(v => v?.['ExpectedSLA%']),
      }

      const _p1 = {
        Months: p1Data?.map(v => v?.Month),
        ['ActualSLA%']: p1Data?.map(v => v?.['ActualSLA%']),
        ['ExpectedSLA%']: p1Data?.map(v => v?.['ExpectedSLA%']),
      }

      const _p2 = {
        Months: p2Data?.map(v => v?.Month),
        ['ActualSLA%']: p2Data?.map(v => v?.['ActualSLA%']),
        ['ExpectedSLA%']: p2Data?.map(v => v?.['ExpectedSLA%']),
      }

      const _p3 = {
        Months: p3Data?.map(v => v?.Month),
        ['ActualSLA%']: p3Data?.map(v => v?.['ActualSLA%']),
        ['ExpectedSLA%']: p3Data?.map(v => v?.['ExpectedSLA%']),
      }

      // let slaCategory = _slaReportData?.Category

      // let _reportData =[]
      // for(let i=0; i < (slaCategory?.length); i++){
      //   _reportData.push({
      //       label: slaCategory[i],
      //       data: _slaReportData[slaCategory[i]],
      //       backgroundColor: getBgColor[i],
      //       stack: 0
      //   })
      // }

      const accRejSRChart = {
        labels,
        datasets: [
          {
            type: 'line',
            label: 'ExpectedSLA%',
            borderColor: getBgColor[3],
            borderWidth: 2,
            fill: false,
            data: _accRejSRData['ExpectedSLA%'],
          },
          {
            type: 'bar',
            label: 'Acceptance/ Rejection of SR',
            backgroundColor: getBgColor[0],
            data: _accRejSRData['ActualSLA%'],
            borderColor: 'white',
            borderWidth: 2,
          }
        ],
      }

      const deliveryChart = {
        labels,
        datasets: [
          {
            type: 'line',
            label: 'ExpectedSLA%',
            borderColor: getBgColor[3],
            borderWidth: 2,
            fill: false,
            data: _deliveryData['ExpectedSLA%'],
          },
          {
            type: 'bar',
            label: 'Delivery',
            backgroundColor: getBgColor[0],
            data: _deliveryData['ActualSLA%'],
            borderColor: 'white',
            borderWidth: 2,
          }
        ],
      }

      const deviceRepairChart = {
        labels,
        datasets: [
          {
            type: 'line',
            label: 'ExpectedSLA%',
            borderColor: getBgColor[3],
            borderWidth: 2,
            fill: false,
            data: _deviceRepairData['ExpectedSLA%'],
          },
          {
            type: 'bar',
            label: 'Device Repair',
            backgroundColor: getBgColor[0],
            data: _deviceRepairData['ActualSLA%'],
            borderColor: 'white',
            borderWidth: 2,
          }
        ],
      }

      const custComplMgmtChart = {
        labels,
        datasets: [
          {
            type: 'line',
            label: 'ExpectedSLA%',
            borderColor: getBgColor[3],
            borderWidth: 2,
            fill: false,
            data: _custComplMgmtData['ExpectedSLA%'],
          },
          {
            type: 'bar',
            label: 'Customer complaints management',
            backgroundColor: getBgColor[0],
            data: _custComplMgmtData['ActualSLA%'],
            borderColor: 'white',
            borderWidth: 2,
          }
        ],
      }

      const rejectionRateChart = {
        labels,
        datasets: [
          {
            type: 'line',
            label: 'ExpectedSLA%',
            borderColor: getBgColor[3],
            borderWidth: 2,
            fill: false,
            data: _rejectionRateData['ExpectedSLA%'],
          },
          {
            type: 'bar',
            label: 'Rejection Rate',
            backgroundColor: getBgColor[0],
            data: _rejectionRateData['ActualSLA%'],
            borderColor: 'white',
            borderWidth: 2,
          }
        ],
      }

      const _srPortalChart = {
        labels,
        datasets: [
          {
            type: 'line',
            label: 'ExpectedSLA%',
            borderColor: getBgColor[3],
            borderWidth: 2,
            fill: false,
            data: _srPortal['ExpectedSLA%'],
          },
          {
            type: 'bar',
            label: 'Service Request Portal/ App',
            backgroundColor: getBgColor[0],
            data: _srPortal['ActualSLA%'],
            borderColor: 'white',
            borderWidth: 2,
          }
        ],
      }

      const _p1Chart = {
        labels,
        datasets: [
          {
            type: 'line',
            label: 'ExpectedSLA%',
            borderColor: getBgColor[3],
            borderWidth: 2,
            fill: false,
            data: _p1['ExpectedSLA%'],
          },
          {
            type: 'bar',
            label: 'Priority 1 (P1) - Critical Incident',
            backgroundColor: getBgColor[0],
            data: _p1['ActualSLA%'],
            borderColor: 'white',
            borderWidth: 2,
          }
        ],
      }

      const _p2Chart = {
        labels,
        datasets: [
          {
            type: 'line',
            label: 'ExpectedSLA%',
            borderColor: getBgColor[3],
            borderWidth: 2,
            fill: false,
            data: _p2['ExpectedSLA%'],
          },
          {
            type: 'bar',
            label: 'Priority 2 (P2) - Minor Incident',
            backgroundColor: getBgColor[0],
            data: _p2['ActualSLA%'],
            borderColor: 'white',
            borderWidth: 2,
          }
        ],
      }

      const _p3Chart = {
        labels,
        datasets: [
          {
            type: 'line',
            label: 'ExpectedSLA%',
            borderColor: getBgColor[3],
            borderWidth: 2,
            fill: false,
            data: _p3['ExpectedSLA%'],
          },
          {
            type: 'bar',
            label: 'Priority 3 (P3) - Improvement',
            backgroundColor: getBgColor[0],
            data: _p3['ActualSLA%'],
            borderColor: 'white',
            borderWidth: 2,
          }
        ],
      }

      const[isOpenDeviceProtSLA, setOpenDeviceProtSLA] = useState(true)
      const[isOpenRepaiSLA, setOpenRepaiSLA] = useState(true)

      const accRejSROptions = {
        layout: {
            // padding: 20
        },
        plugins: {
          // beforeInit: (chart, options) => {
          //   chart.legend.afterFit = () => {
          //     if (chart.legend.margins) {
          //       // Put some padding around the legend/labels
          //       chart.legend.options.labels.padding = 220;
          //       // Because you added 20px of padding around the whole legend,
          //       // you will need to increase the height of the chart to fit it
          //       chart.height += 240;
          //     }
          //   };
          // },
          legend: {
            // position: "top",
            labels: {
                usePointStyle: false,
                // padding: 20
            },
          },
          title: { 
            display: true, 
            text: 'Acceptance/ Rejection of SR',
            // padding: 10
           },
           datalabels: {
            formatter: function(value, context) {
              return value > 0 ? value+'%' : null
            },
            align: "bottom",
            labels: {
                usePointStyle: false,
                    font: {
                        weight: 700,
                        color: 'black',
                        size: 12,
                    }
            },
          },
          // dataset: {
          //   padding: 10
          // }
        }, 
        responsive: true,
        
      }


    return(
        <div className='p-10'>
          {reportName === 'Customer Service SLA Report' ?
            <>
            <div className="flex flex-col justify-center items-center rounded-md">
            {/* <div className="flex flex-col justify-center items-center border border-gray-300 rounded-md"> */}
              {/* <div className='flex justify-between items-center bg-gray-300 w-full px-5 py-2 rounded-md cursor-pointer' 
                onClick={() => setOpenDeviceProtSLA(!isOpenDeviceProtSLA)}>
                <p className='text-center text-black font-semibold text-lg'>Device Protection Plan</p>
                {!isOpenDeviceProtSLA ?
                  <ArrowDown fill="black" className="cursor-pointer" onClick={() => setOpenDeviceProtSLA(!isOpenDeviceProtSLA)} />
                  :
                  <ArrowUp fill="black" className="cursor-pointer" onClick={() => setOpenDeviceProtSLA(!isOpenDeviceProtSLA)} />
                }
              </div>
              {isOpenDeviceProtSLA && */}
                <div className='flex flex-wrap'>
                  <div className='w-[50%] mx-auto p-3' style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray'}} >
                    <Chart options={accRejSROptions} type='bar' data={accRejSRChart} />
                  </div>
                  <div className='w-[50%] mx-auto p-3' style={{ borderBottom: '1px solid gray'}}>
                    <Chart options={{ plugins: { datalabels: {
                        formatter: function(value, context) {
                          return value > 0 ? value+'%' : null
                        },
                        align: "bottom",
                        labels: {
                            usePointStyle: false,
                                font: {
                                    weight: 700,
                                    color: 'black',
                                    size: 12,
                                }
                        },
                      },title: { display: true, text: 'Delivery', }, }, responsive: true }} 
                      type='bar' 
                      data={deliveryChart} />
                  </div>
                  <div className='w-[50%] mx-auto p-3' style={{ borderRight: '1px solid gray'}}>
                    <Chart options={{ plugins: { datalabels: {
                            formatter: function(value, context) {
                              return value > 0 ? value+'%' : null
                            },
                            align: "bottom",
                            labels: {
                                usePointStyle: false,
                                    font: {
                                        weight: 700,
                                        color: 'black',
                                        size: 12,
                                    }
                            },
                          },
                          title: { display: true, text: 'Customer complaints management', }, }, 
                          responsive: true }} 
                          type='bar' 
                          data={custComplMgmtChart} />
                  </div>
                  <div className='w-[50%] mx-auto p-3'>
                    <Chart options={{ plugins: { datalabels: {
                              formatter: function(value, context) {
                                return value > 0 ? value+'%' : null
                              },
                              align: "bottom",
                              labels: {
                                  usePointStyle: false,
                                      font: {
                                          weight: 700,
                                          color: 'black',
                                          size: 12,
                                      }
                              },
                            },
                            title: { display: true, text: 'Rejection rate', }, }, 
                            responsive: true }} 
                            type='bar' 
                            data={rejectionRateChart} />
                  </div>
                  <div className='w-[50%] mx-auto p-3' style={{ borderBottom: '1px solid gray'}}>
                    <Chart options={{ plugins: { datalabels: {
                                      formatter: function(value, context) {
                                        return value > 0 ? value+'%' : null
                                      },
                                      align: "bottom",
                                      labels: {
                                          usePointStyle: false,
                                              font: {
                                                  weight: 700,
                                                  color: 'black',
                                                  size: 12,
                                              }
                                      },
                                    },
                                    title: { display: true, text: 'Device Repair', }, }, 
                                    responsive: true }} 
                                    type='bar' 
                                    data={deviceRepairChart}
                                    />
                  </div>
                </div>
              {/* }
          </div> */}
          {/* <div className="flex flex-col justify-center items-center border border-gray-300 rounded-md mt-7">
              <div className='flex justify-between items-center bg-gray-300 w-full px-5 py-2 rounded-md cursor-pointer' 
                onClick={() => setOpenRepaiSLA(!isOpenRepaiSLA)}>
                <p className='text-center text-black font-semibold text-lg'>Device Repair Plan</p>
                {!isOpenRepaiSLA ?
                  <ArrowDown fill="black" className="cursor-pointer" onClick={() => setOpenRepaiSLA(!isOpenRepaiSLA)} />
                  :
                  <ArrowUp fill="black" className="cursor-pointer" onClick={() => setOpenRepaiSLA(!isOpenRepaiSLA)} />
                }
              </div>
            {isOpenRepaiSLA &&
              <div className='flex flex-wrap'>
                <div className='w-[50%] mx-auto p-3' style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray'}} >
                  <Chart options={{ plugins: { title: { display: true, text: 'Acceptance/ Rejection of SR', }, }, responsive: true }} type='bar' data={accRejSRChart} />
                </div>
                <div className='w-[50%] mx-auto p-3' style={{ borderBottom: '1px solid gray'}}>
                  <Chart options={{ plugins: { title: { display: true, text: 'Device Repair', }, }, responsive: true }} type='bar' data={deviceRepairChart} />
                </div>
                <div className='w-[50%] mx-auto p-3' style={{ borderRight: '1px solid gray'}}>
                  <Chart options={{ plugins: { title: { display: true, text: 'Customer complaints management', }, }, responsive: true }} type='bar' data={custComplMgmtChart} />
                </div>
                <div className='w-[50%] mx-auto p-3'>
                  <Chart options={{ plugins: { title: { display: true, text: 'Rejection rate', }, }, responsive: true }} type='bar' data={rejectionRateChart} />
                </div>
              </div>
            } */}
          </div>
          </>
          :
          <>
            <div className='flex flex-wrap'>
              <div className='w-[50%] mx-auto p-3' style={{ borderRight: '1px solid gray', borderBottom: '1px solid gray'}} >
                <Chart options={{ plugins: { datalabels: {
                                      formatter: function(value, context) {
                                        return value > 0 ? value+'%' : null
                                      },
                                      align: "bottom",
                                      labels: {
                                          usePointStyle: false,
                                              font: {
                                                  weight: 700,
                                                  color: 'black',
                                                  size: 12,
                                              }
                                      },
                                    },title: { display: true, text: 'Service Request Portal/ App', }, }, responsive: true }} 
                                    type='bar' 
                                    data={_srPortalChart} />
              </div>
              <div className='w-[50%] mx-auto p-3' style={{ borderBottom: '1px solid gray'}}>
                <Chart options={{ plugins: { datalabels: {
                                      formatter: function(value, context) {
                                        return value > 0 ? value+'%' : null
                                      },
                                      align: "bottom",
                                      labels: {
                                          usePointStyle: false,
                                              font: {
                                                  weight: 700,
                                                  color: 'black',
                                                  size: 12,
                                              }
                                      },
                                    },
                                    title: { display: true, text: 'Priority 1 (P1) - Critical Incident', }, }, responsive: true }} 
                                    type='bar' 
                                    data={_p1Chart} />
              </div>
              <div className='w-[50%] mx-auto p-3' style={{ borderRight: '1px solid gray'}}>
                <Chart options={{ plugins: { datalabels: {
                                      formatter: function(value, context) {
                                        return value > 0 ? value+'%' : null
                                      },
                                      align: "bottom",
                                      labels: {
                                          usePointStyle: false,
                                              font: {
                                                  weight: 700,
                                                  color: 'black',
                                                  size: 12,
                                              }
                                      },
                                    },title: { display: true, text: 'Priority 2 (P2) - Minor Incident', }, }, responsive: true }} 
                                    type='bar' 
                                    data={_p2Chart} />
              </div>
              <div className='w-[50%] mx-auto p-3'>
                <Chart options={{ plugins: { datalabels: {
                                      formatter: function(value, context) {
                                        return value > 0 ? value+'%' : null
                                      },
                                      align: "bottom",
                                      labels: {
                                          usePointStyle: false,
                                              font: {
                                                  weight: 700,
                                                  color: 'black',
                                                  size: 12,
                                              }
                                      },
                                    },title: { display: true, text: 'Priority 3 (P3) - Improvement', }, }, responsive: true }} 
                                    type='bar' 
                                    data={_p3Chart} />
              </div>
            </div>
          </>
          }
        </div>
    )
}

export default SLATrends