import { useEffect, useState } from "react"
import ActionTypes from "../../../store/actionTypes"
import API from "../../../api"
import { useCentralStore } from "../../../store/appContext"
import resources from'../../../components/common/resources.json'
import SLADataTable from "../../../components/common/dataTable/SLADataTable"

const SHDashboardData = ({
    tsSlaData
}) => {

    return(
        <div className="flex text-black">
            
                    <SLADataTable
                        reportDetailsSuccess={tsSlaData}
                        resources={resources}
                    />

        </div>
    )
}

export default SHDashboardData