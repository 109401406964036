import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCentralStore } from "../../store/appContext";
import ActionTypes from "../../store/actionTypes";
import Selector from "../../components/common/selector/selector";
import { Button, Table } from "antd";
import { DownloadOutlined, FileExcelTwoTone, FilePdfTwoTone } from "@ant-design/icons";
import { exportJSONToCSV } from "../../utils/upload-utils";
import reconExcelFile from "../../assets/excelFiles/Reconciliation_Sample.xlsx"
import incentiveExcelFile from "../../assets/excelFiles/Sales_Incentives_sample.xlsx"

const FinanceReconReport = () => {
  const [months, setMonths] = useState([]);

  let reconData = [
    {
      item: "Supply Fee",
      totalGrossAds: 345,
      supplyFee: "$ 12",
      subTotal: "$ 4140",
    },
    {
      item: "Incentive",
      totalGrossAds: 333,
      supplyFee: "$ 4",
      subTotal: "$ 1332",
    },
    { item: "", totalGrossAds: "", supplyFee: "Gross Total", subTotal: "$ 5472" },
  ];

  const [{ selectedAccountingMonth }, dispatch] =
    useCentralStore();

  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    const today = new Date();
    let d;
    let month = [];
    for (let i = 2; i >= 0; i -= 1) {
      d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      console.log("d", d);
      month.unshift({
        name: `${monthNames[d.getMonth()]}-${d
          .getFullYear()
          .toString()
          .substr(-2)}`,
        value: `${monthNames[d.getMonth()]}-${d.getFullYear().toString()}`,
      });
      dispatch({
        type: ActionTypes.SELECTED_ACCOUNTING_MONTH,
        payload: selectedAccountingMonth
          ? selectedAccountingMonth
          : month[0]?.value,
      });
      setMonths(month);
    }
  }, []);

  const handleChange = (name, value) => {
    dispatch({
      type: ActionTypes.SELECTED_ACCOUNTING_MONTH,
      payload: value,
    });
  };
  let tableColumns = [
    {
      title: "ITEM",
      dataIndex: "item",
      key: "item",
      align: "center",
      width: 200,
    },
    {
      title: "TOTAL CUSTOMER COUNT",
      dataIndex: "totalGrossAds",
      key: "totalGrossAds",
      align: "center",
      width: 250,
    },
    {
      title: "SUPPLY FEE",
      dataIndex: "supplyFee",
      key: "supplyFee",
      align: "center",
      width: 150,
    },
    {
      title: "TOTAL",
      dataIndex: "subTotal",
      key: "subTotal",
      align: "center",
      width: 150,
    },
  ];

  const reportColumn =[
    {
        title: "REPORT",
        dataIndex: "report",
        key: "report",
        align: "center",
        width: 200,
    },
    {
        title: (
            <>
                {/* <span>Path </span>&nbsp; */}
                <DownloadOutlined style={{ fontSize: "20px"}} />
            </>
        ),
        dataIndex: "path",
        key: "path",
        align: "center",
        width: 100,
    },
  ]

  const reportData = [
    {
        report: `${selectedAccountingMonth?.replace("-", " ")} Reconciliation Report`,
        path: <Button icon={
          <FileExcelTwoTone
            style={{ fontSize: "25px" }}
            theme="outlined"/> }
        type=""
        onClick={(e) => handleClick(e, "Reconciliation Report")} />,
      },
    {
        report: `${selectedAccountingMonth?.replace("-", " ")} Incentive Report`,
        path: <Button icon={
          <FileExcelTwoTone
            style={{ fontSize: "25px" }}
            theme="outlined"/> }
        type=""
        onClick={(e) => handleClick(e, "Incentive Report")} />,
      },
    // {
    //     report: `${selectedAccountingMonth?.replace("-", " ")} Invoice`,
    //     path: <Button icon={
    //       <FilePdfTwoTone
    //         style={{ fontSize: "25px" }}
    //         theme="outlined"/> }
    //     type=""
    //     onClick={(e) => handleClick(e, "")} />,
    //   },
      
  ]

  const handleClick = (e, placeholder) => {
    // exportJSONToCSV(reconReport, selectedAccountingMonth, "starhub");
    const link = document.createElement('a');
    link.href = placeholder === 'Reconciliation Report' ? reconExcelFile : incentiveExcelFile;
    link.setAttribute('download', placeholder === 'Reconciliation Report' ? `ReconReport_${selectedAccountingMonth}` : `IncentiveReport_${selectedAccountingMonth}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  let reconReport = [
    {
      PROCESS_NO: 110,
      RECON_FLAG: "G1",
      CLIENT_NAME: "M1",
      ACCTPERIOD: "2022/08/01",
      ASU_TOTALCOUNT: 153189,
      CLIENT_TOTALCOUNT: 0,
      ASU_BILLINGUNIT: 140554,
      CLIENT_BILLINGUNIT: 0,
      ASU_TOTALAMT: 932220.9261,
      CLIENT_TOTALAMT: 0,
      VAR_TOTALAMT: 932220.9261,
    },
    {
      PROCESS_NO: 110,
      RECON_FLAG: "G2",
      CLIENT_NAME: "M1",
      ACCTPERIOD: "2022/08/01",
      ASU_TOTALCOUNT: 0,
      CLIENT_TOTALCOUNT: 3,
      ASU_BILLINGUNIT: 0,
      CLIENT_BILLINGUNIT: 0,
      ASU_TOTALAMT: 0,
      CLIENT_TOTALAMT: 0,
      VAR_TOTALAMT: 0,
    },
    {
      PROCESS_NO: 110,
      RECON_FLAG: "V1",
      CLIENT_NAME: "M1",
      ACCTPERIOD: "2022/08/01",
      ASU_TOTALCOUNT: 35,
      CLIENT_TOTALCOUNT: 35,
      ASU_BILLINGUNIT: 34,
      CLIENT_BILLINGUNIT: 0,
      ASU_TOTALAMT: 239.5094,
      CLIENT_TOTALAMT: 0,
      VAR_TOTALAMT: 239.5094,
    },
    {
      PROCESS_NO: 110,
      RECON_FLAG: "V3",
      CLIENT_NAME: "M1",
      ACCTPERIOD: "2022/08/01",
      ASU_TOTALCOUNT: 2,
      CLIENT_TOTALCOUNT: 1,
      ASU_BILLINGUNIT: 1,
      CLIENT_BILLINGUNIT: 0,
      ASU_TOTALAMT: 6.5916,
      CLIENT_TOTALAMT: 0,
      VAR_TOTALAMT: 6.5916,
    },
  ];
  return (
    <>
      <div className="flex justify-end w-4/5 h-full mx-auto" style={{marginTop:"-30px"}}>
        <div className="flex items-center justify-end text-black">
          <Selector
            key="Channel Dropdown"
            options={months}
            label="Month"
            value={selectedAccountingMonth}
            onSelect={(name, val) => handleChange(name, val)}
            placeholder="Select month"
            isStarhub={true}
          />
        </div>
      </div>

      <div className="w-1/2 pb-2 mx-auto mt-2">
        <p className="flex flex-row items-center justify-center mt-5 mb-4 font-bold text-black">
          Finance Reconciliation & Invoice -{" "}
          {selectedAccountingMonth?.replace("-", " ")}
        </p>
        <Table
          columns={tableColumns}
          dataSource={reconData}
          rowKey="data"
          key="data"
          pagination={false}
          className="border-current border-white table-css hz-scrollbar"
        />
      </div>
      <div className="w-1/2 mx-auto mt-4">
        <Table
          columns={reportColumn}
          dataSource={reportData}
          rowKey="data"
          key="data"
          pagination={false}
          className="pb-4 border-current border-white table-css hz-scrollbar"
        />
      </div>
    </>
  );
};

export default FinanceReconReport;
