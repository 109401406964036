import React from 'react'

const icon = ({ className, fill, onClick }) => 
(
    <svg className={className} onClick={onClick} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6" cy="6" r="5.5" fill="#0A1822" stroke="#37E7A7"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.3335 3.33337L6.3335 8.66671L5.66683 8.66671L5.66683 3.33337L6.3335 3.33337Z" fill="#37E7A7"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.66683 5.66671L3.3335 5.66671L3.3335 6.33337L8.66683 6.33338L8.66683 5.66671Z" fill="#37E7A7"/>
    </svg>
)
export default icon


