import React from "react";

const icon = ({ className, fill, onClick, stroke }) => (
  <svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.8334 32.5H4.16675" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.0002 35.417C15.2303 35.417 15.4168 35.6035 15.4168 35.8337V35.8337C15.4168 36.0638 15.2303 36.2503 15.0002 36.2503V36.2503C14.77 36.2503 14.5835 36.0638 14.5835 35.8337V35.8337C14.5835 35.6035 14.77 35.417 15.0002 35.417" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.8334 25.8335V35.8335C25.8334 37.6744 24.341 39.1668 22.5001 39.1668H7.50008C5.65913 39.1668 4.16675 37.6744 4.16675 35.8335V4.16683C4.16675 2.32588 5.65913 0.833496 7.50008 0.833496H12.5001" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.8335 1.8335C15.8335 1.28121 16.2812 0.833496 16.8335 0.833496H34.8335C35.3858 0.833496 35.8335 1.28121 35.8335 1.8335V19.8335C35.8335 20.3858 35.3858 20.8335 34.8335 20.8335H16.8335C16.2812 20.8335 15.8335 20.3858 15.8335 19.8335V1.8335Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.8335 5.8335H30.8335" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.8335 10.8335H30.8335" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.8335 15.8335H24.5835" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);
export default icon;
