import React from 'react'

const icon = ({ className, fill, onClick }) => 
(
    <svg className={className} onClick={onClick} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 5.99902V18.999" stroke="#D5D6DA" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 2.99902C4.5 2.44674 4.94772 1.99902 5.5 1.99902H18.5C19.0523 1.99902 19.5 2.44674 19.5 2.99902V4.99902C19.5 5.55131 19.0523 5.99902 18.5 5.99902H5.5C4.94772 5.99902 4.5 5.55131 4.5 4.99902V2.99902Z" stroke="#D5D6DA" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.5 19.999C9.5 19.4467 9.94772 18.999 10.5 18.999H13.5C14.0523 18.999 14.5 19.4467 14.5 19.999V22.999C14.5 23.5513 14.0523 23.999 13.5 23.999H10.5C9.94772 23.999 9.5 23.5513 9.5 22.999V19.999Z" stroke="#D5D6DA" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 19.999C0.5 19.4467 0.947715 18.999 1.5 18.999H4.5C5.05228 18.999 5.5 19.4467 5.5 19.999V22.999C5.5 23.5513 5.05228 23.999 4.5 23.999H1.5C0.947715 23.999 0.5 23.5513 0.5 22.999V19.999Z" stroke="#D5D6DA" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5 19.999C18.5 19.4467 18.9477 18.999 19.5 18.999H22.5C23.0523 18.999 23.5 19.4467 23.5 19.999V22.999C23.5 23.5513 23.0523 23.999 22.5 23.999H19.5C18.9477 23.999 18.5 23.5513 18.5 22.999V19.999Z" stroke="#D5D6DA" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20.5 19V14C20.5 13.4477 20.0523 13 19.5 13H4.5C3.94772 13 3.5 13.4477 3.5 14V19" stroke="#D5D6DA" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
)
export default icon


