import React from 'react'

const icon = ({ className, fill, onClick }) => 
(
 <svg className={className} onClick={onClick} width="24" height="25" viewBox="0 0 24 25" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_792_1706)">
    <path d="M24 6.69002C23.94 6.78002 23.89 6.87002 23.82 6.95002C23.63 7.15002 23.4 7.21002 23.14 7.13002C22.87 7.04002 22.7 6.85002 22.69 6.56002C22.67 6.08002 22.68 5.61002 22.68 5.13002C22.68 4.44002 22.68 3.76002 22.68 3.07002C22.68 2.99002 22.68 2.92002 22.68 2.84002C22.64 2.81002 22.61 2.79002 22.57 2.76002C22.53 2.84002 22.51 2.93002 22.45 2.99002C20.72 4.72002 18.99 6.45002 17.27 8.18002C16.88 8.57002 16.49 8.61002 16.19 8.30002C15.9 7.99002 15.94 7.62002 16.33 7.24002C18.05 5.52002 19.78 3.79002 21.5 2.07002C21.56 2.01002 21.63 1.96002 21.67 1.84002C21.59 1.84002 21.51 1.84002 21.44 1.84002C20.35 1.84002 19.27 1.84002 18.18 1.84002C18.05 1.84002 17.91 1.84002 17.79 1.80002C17.54 1.72002 17.39 1.53002 17.36 1.27002C17.33 1.00002 17.43 0.78002 17.66 0.63002C17.72 0.59002 17.77 0.56002 17.83 0.52002C19.72 0.52002 21.61 0.52002 23.5 0.52002C23.75 0.62002 23.92 0.79002 24.02 1.04002V6.71002L24 6.69002Z" fill={fill}/>
    <path d="M17.8099 24.5001C17.7199 24.4301 17.6099 24.3801 17.5299 24.3001C17.3399 24.1201 17.2899 23.8801 17.3699 23.6301C17.4499 23.3701 17.6399 23.2101 17.9099 23.1801C18.0499 23.1601 18.1899 23.1701 18.3299 23.1701C19.3499 23.1701 20.3799 23.1701 21.3999 23.1701C21.4799 23.1701 21.5699 23.1701 21.6499 23.1701C21.6799 23.1301 21.7099 23.1001 21.7299 23.0601C21.6499 23.0201 21.5599 23.0001 21.4999 22.9501C19.7599 21.2201 18.0299 19.4901 16.2999 17.7401C16.1599 17.6001 16.0599 17.3901 16.0199 17.2001C15.9699 16.9301 16.1199 16.7101 16.3599 16.5901C16.5899 16.4701 16.8299 16.4801 17.0499 16.6301C17.1399 16.6901 17.2199 16.7701 17.2899 16.8501C18.9999 18.5601 20.7099 20.2701 22.4299 21.9901C22.4899 22.0501 22.5299 22.1301 22.5799 22.2001C22.6099 22.1901 22.6399 22.1801 22.6599 22.1601C22.6599 22.0801 22.6599 22.0001 22.6599 21.9301C22.6599 20.8101 22.6599 19.7001 22.6599 18.5801C22.6599 18.0701 23.0399 17.7501 23.4899 17.8601C23.7399 17.9201 23.8699 18.1201 23.9899 18.3201V23.9901C23.8899 24.2401 23.7199 24.4101 23.4699 24.5101H17.7999L17.8099 24.5001Z" fill={fill}/>
    <path d="M0 18.31C0.15 18.06 0.31 17.85 0.64 17.84C1 17.83 1.29 18.07 1.32 18.44C1.33 18.6 1.32 18.75 1.33 18.91C1.33 19.91 1.33 20.91 1.33 21.91V22.23C1.43 22.13 1.49 22.07 1.55 22.02C3.27 20.3 5 18.57 6.72 16.85C7.1 16.47 7.51 16.42 7.8 16.72C8.09 17.02 8.04 17.41 7.67 17.79C5.95 19.51 4.24 21.23 2.52 22.94C2.46 23 2.38 23.04 2.32 23.1C2.33 23.13 2.34 23.15 2.35 23.18C2.43 23.18 2.51 23.18 2.58 23.18C3.69 23.18 4.8 23.18 5.91 23.18C6.43 23.18 6.74 23.52 6.66 23.97C6.61 24.24 6.41 24.39 6.19 24.51H0.52C0.27 24.41 0.1 24.24 0 23.99V18.32V18.31Z" fill={fill}/>
    <path d="M6.19 0.5C6.28 0.57 6.39 0.62 6.47 0.7C6.66 0.88 6.71 1.12 6.63 1.37C6.55 1.63 6.36 1.79 6.09 1.82C5.97 1.83 5.84 1.83 5.72 1.83C4.68 1.83 3.64 1.83 2.6 1.83H2.35C2.35 1.83 2.3 1.9 2.27 1.94C2.35 1.98 2.44 2 2.5 2.06C4.23 3.79 5.96 5.51 7.68 7.25C7.8 7.37 7.91 7.52 7.96 7.67C8.05 7.94 7.93 8.23 7.69 8.39C7.45 8.55 7.14 8.53 6.9 8.35C6.83 8.3 6.77 8.24 6.71 8.17C4.99 6.45 3.27 4.74 1.56 3.02C1.5 2.96 1.46 2.88 1.41 2.81C1.38 2.82 1.35 2.83 1.33 2.85C1.33 2.93 1.33 3.01 1.33 3.09C1.33 4.21 1.33 5.32 1.33 6.44C1.33 6.94 0.98 7.25 0.54 7.17C0.27 7.12 0.12 6.92 0 6.7V1.02C0.1 0.77 0.27 0.6 0.52 0.5C2.41 0.5 4.3 0.5 6.19 0.5Z" fill={fill}/>
    </g>
    <defs>
    <clipPath id="clip0_792_1706">
    <rect width="24" height="24" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
    </defs>
</svg>   
)
export default icon


