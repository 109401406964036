import { isEmpty } from "../../utils/functionUtils";
import { DatePicker } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import API from "../../api";
import { useCentralStore } from "../../store/appContext";
import ActionTypes from "../../store/actionTypes";
import resources from "../../components/common/resources.json";
const { RangePicker } = DatePicker;

const ScoreCardSummaryFilter = ({ selectedClient, getRefresheState }) => {
  const [
    {
      selectedReportFilter,
      userData,
      userDetails,
      selectedTab,
      startDateToShow,
      endDateToShow,
      ssReportFilterDuration,
      aiGraphResponseInquirySuccess,
      aiResponseInquirySuccess,
    },
    dispatch,
  ] = useCentralStore();

  let filterOptions = [
    {
      label: "Select Dates",
      value: "real time",
    }
  ];

  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  const [changedRealTime, setChangedRealTime] = useState(true);
  const [dateDiff, setDateDiff] = useState(null);
  useEffect(() => {
    dispatch({
      type: ActionTypes.SS_SET_REPORT_FILTER_DURATION,
      payload: {
        type: null,
        startDate: ssReportFilterDuration?.startDate ? ssReportFilterDuration?.startDate : moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD 00:00:00'),
        endDate: ssReportFilterDuration?.endDate ? ssReportFilterDuration?.endDate : moment(new Date()).format('YYYY-MM-DD 23:59:59'),
        duration: null,
      },
    });
  }, []);

  useEffect(() => {
    if (dates && dates.length === 2) {
      const startDate = dates[0];
      const endDate = dates[1];
      // const diffInDays = endDate.diff(startDate, 'days');
      // setDateDiff(diffInDays);
      dispatch({
        type: ActionTypes.SET_START_DATE,
        payload: startDate.format("YYYY-MM-DD 00:00:00"),
      })
      dispatch({
        type: ActionTypes.SET_END_DATE,
        payload: endDate.format("YYYY-MM-DD 23:59:59"),
      })
    }
  }, [dates]);

  const handleDateChange = (dates, dateString, type) => {
    // getRefresheState(true)
    dispatch({
      type: ActionTypes.SS_SET_REPORT_FILTER_DURATION,
      payload: {
        type: type,
        startDate: moment(dateString[0]).format("YYYY-MM-DD 00:00:00"),
        endDate: moment(dateString[1]).format("YYYY-MM-DD 23:59:59"),
        duration: dateString,
      },
    });
    setDates(dates);
    const setLoading = (action) => {
      dispatch({ type: ActionTypes.SET_LOADING, payload: action });
    };
    setLoading(true);
    let payload = {
      StartDate: moment(dateString[0]).format("YYYY-MM-DD 00:00:00"),
      EndDate: moment(dateString[1]).format("YYYY-MM-DD 23:59:59"),
      ClientName: selectedClient,
    };
    // dispatch({
    //   type: ActionTypes.SET_START_DATE,
    //   payload: payload.StartDate,
    // });
    // dispatch({
    //   type: ActionTypes.SET_END_DATE,
    //   payload: payload.EndDate,
    // });
    API[ActionTypes.GET_INQUIRY_DATA](
      payload,
      userData?.access_token,
      userDetails && userDetails?.email?.split("@")?.[0]
    )
      .then((data) => {
        // setLoading(false);
        setChangedRealTime(true);
        dispatch({
          type: ActionTypes.GET_INQUIRY_DATA_SUCCESS,
          payload: data,
        });
        API[ActionTypes.GET_INQUIRY_AI_RESPONSE](
          payload,
          userData?.access_token,
          userDetails && userDetails?.email?.split("@")?.[0]
        )
          .then((data) => {
            // setLoading(false);
            dispatch({
              type: ActionTypes.GET_INQUIRY_AI_RESPONSE_SUCCESS,
              payload: data,
            });
          })
          .catch((err) => {
            dispatch({
              type: ActionTypes.GET_INQUIRY_AI_RESPONSE_FAILURE,
              payload: err,
            });
          })
        API[ActionTypes.GET_INQUIRY_AI_GRAPH_RESPONSE](
          payload,
          userData?.access_token,
          userDetails && userDetails?.email?.split("@")?.[0]
        )
          .then((data) => {
            setLoading(false);
            dispatch({
              type: ActionTypes.GET_INQUIRY_AI_GRAPH_RESPONSE_SUCCESS,
              payload: data,
            });
          })
          .catch((err) => {
            dispatch({
              type: ActionTypes.GET_INQUIRY_AI_GRAPH_RESPONSE_FAILURE,
              payload: err,
            });
          });
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.GET_INQUIRY_DATA_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](
          _payload,
          userData?.access_token,
          userDetails && userDetails?.email?.split("@")?.[0]
        );
      });
    dispatch({
      type: ActionTypes.GET_SCORECARD_SUMMARY_SUCCESS,
      payload: null,
    });
    dispatch({
      type: ActionTypes.GET_SCORECARD_SUMMARY_SUCCESS,
      payload: null,
    });
    dispatch({
      type: ActionTypes.GET_SCORECARD_SUMMARY_SUCCESS,
      payload: null,
    });
  };

  const handleFilterChange = (val) => {
    let startDate = ssReportFilterDuration?.startDate ? ssReportFilterDuration?.startDate : moment(new Date(Date.now() - 12 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD 00:00:00");
    let endDate = ssReportFilterDuration?.endDate ? ssReportFilterDuration?.endDate : moment(new Date()).format("YYYY-MM-DD 23:59:59");
    dispatch({
      type: ActionTypes.SET_START_DATE,
      payload: startDate,
    });
    dispatch({
      type: ActionTypes.SET_END_DATE,
      payload: endDate,
    });
    setDates(null);
    setValue(null);
    setChangedRealTime(false);
  };

  const disabledDate = (current) => {
    return (
      (current && current > moment().endOf("day")) ||
      (current && current < moment().subtract(90, "days"))
    );
  };

  const formattedStartDate = moment(startDateToShow).format("DD-MM-YYYY");
  const formattedEndDate = moment(endDateToShow).format("DD-MM-YYYY");

  return (
    <>
      <div className={`pr-4`}>
        <div>
          <div className="flex justify-end">
            {(isEmpty(selectedReportFilter) ||
              selectedReportFilter === "real time") &&
              filterOptions?.map((v) => (
                <div
                  className={`text-white relative rounded-md text-[16px] cursor-pointer py-2 px-3 flex justify-center items-center border-b bg-black border-app-border font-bold
                    ${
                      selectedReportFilter === v.value
                        ? "border border-app-border"
                        : ""
                    }`}
                  onClick={() => handleFilterChange(v.value)}
                >
                  {v.label}
                </div>
              ))}
          </div>
        </div>
        {selectedReportFilter === "real time" && (
          <div>
            {changedRealTime ? (
              <div>
                {dates && dates.length === 2 ? (
                  <p
                    className="text-[18px] text-white mt-2"
                    onClick={() => setChangedRealTime(false)}
                  >
                    Showing data from <b>{formattedStartDate}</b> to{" "}
                    <b>{formattedEndDate}</b>.
                  </p>
                ) : (
                  <p
                    className="text-[18px] text-white mt-2"
                    onClick={() => setChangedRealTime(false)}
                  >
                    Showing data for last <b>7</b> days.
                  </p>
                )}
              </div>
            ) : (
              <RangePicker
                allowClear={true}
                className="bg-white hover:bg-white mt-2"
                value={dates}
                onChange={handleDateChange}
                disabledDate={disabledDate}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ScoreCardSummaryFilter;
