import React, { useEffect, useState } from "react";
import Selector from "../../components/common/selector/selector";
import ActionTypes from "../../store/actionTypes";
import { useCentralStore } from "../../store/appContext";
import { EyeFilled } from "@ant-design/icons";
import { Button, Table } from "antd";
import { isEmpty, toPascalCase } from "../../utils/functionUtils";
import styles from "./iconStyle.module.css";
import API from "../../api";
import { formattedDate} from "../../utils/upload-utils"

const CelcomBillingData = () => {
  const [
    { reconciliationClientCelcom, selectedAccountingMonth, userData, userDetails, GetCelcomBillingReconcileSuccess, GetCelcomBillingReconcileSuccessPass2, isPass2Inserted},
    dispatch,
  ] = useCentralStore();

  const [months, setMonths] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [firstPassData, setFirstPassData] = useState("");
  const [secondPassData, setSecondPassData] = useState();
  const [carrierBillingData, setCarrierBillingData] = useState("");
  const [clawbackData, setClawbackData] = useState();
  const [reconData, setReconData] = useState();
  const [reconFlag, setReconFlag] = useState("");
  const [reconSummaryDetails, setReconSummaryDetails] = useState([]);
  const [tableColumns, setTableColumns] = useState()
  const [secondPassClick, setSecondPassClick] = useState()
  let idToken = userData?.access_token;
  const passOneDate = GetCelcomBillingReconcileSuccess?.[0]?.[0]?.loaded_date || GetCelcomBillingReconcileSuccessPass2?.[0]?.[0]?.loaded_date
  const passOneData = GetCelcomBillingReconcileSuccess?.[0]?.[0]?.no_of_records || GetCelcomBillingReconcileSuccessPass2?.[0]?.[0]?.no_of_records
  const passTwoDate = GetCelcomBillingReconcileSuccess?.[0]?.[1]?.loaded_date || GetCelcomBillingReconcileSuccessPass2?.[0]?.[1]?.loaded_date
  const passTwoData = GetCelcomBillingReconcileSuccess?.[0]?.[1]?.no_of_records || GetCelcomBillingReconcileSuccessPass2?.[0]?.[1]?.no_of_records
  let dateone = new Date(passOneDate);
  let dayone = dateone.getDate().toString().padStart(2, '0');
  let monthone = (dateone.getMonth() + 1).toString().padStart(2, '0');
  let yearone = dateone.getFullYear();
  let formattedFinalOneDate = `${dayone}-${monthone}-${yearone}`

  let datetwo = new Date(passTwoDate);
  let daytwo = datetwo.getDate().toString().padStart(2, '0');
  let monthtwo = (datetwo.getMonth() + 1).toString().padStart(2, '0');
  let yeartwo = datetwo.getFullYear();
  let formattedFinalTwoDate = `${daytwo}-${monthtwo}-${yeartwo}`;
  const[passdata, setPassdata] = useState(false);
  let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  useEffect(() => {
    const today = new Date();
    let d;
    let month = [];
    for (let i = 15; i > 0; i -= 1) {
      d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      month.unshift({
        name: `${monthNames[d.getMonth()]}-${d
          .getFullYear()
          .toString()
          .substr(-2)}`,
        value: `${monthNames[d.getMonth()]}-${d
          .getFullYear()
          .toString()
          .substr(-2)}`,
      });
      dispatch({
        type: ActionTypes.SELECTED_ACCOUNTING_MONTH,
        payload: selectedAccountingMonth ? selectedAccountingMonth : month[0]?.value,
      });
      setMonths(month);
    }
  }, []);

  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING, payload: action });
  };

  const handleChange = (name, value, placeholder) => {
    switch (placeholder) {
      case "selectedClient":
        dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: value });
        break;
      case "selectedMonth":
        dispatch({
          type: ActionTypes.SELECTED_ACCOUNTING_MONTH,
          payload: value,
        });
        setPassdata("");
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    selectedAccountingMonth && callReconcileSummaryApi(selectedAccountingMonth);
  }, [selectedAccountingMonth]);

  const callReconcileSummaryApi = (month) => {
    setFirstPassData("");
    setSecondPassData("");
    setCarrierBillingData("");
    setClawbackData("");
    setReconData("");
    setReconSummaryDetails("");
    setReconFlag("");
    setLoading(true);
    setSecondPassClick("")
  };

  const isButtonDisable = () => {
    return !passOneData || passTwoData || isPass2Inserted
  };

  useEffect(() => {
    let colData =
      !isEmpty(reconSummaryDetails) &&
      Object.keys(reconSummaryDetails[0]).map((val) => {
        return {
          title: val,
          dataIndex: val,
          key: val,
          align: "center",
          width: 175,
        };
      });
   setTableColumns(colData)
  }, [reconSummaryDetails])

  useEffect(() => {
    selectedAccountingMonth && celcomPortal(selectedAccountingMonth);
  }, [selectedAccountingMonth]);

  const celcomPortal = () => {
    setLoading(true);
    let payload = {
      GetCelcomBillingReconcileRequest: {
        Client: "Celcom",
        StartDate:
          selectedAccountingMonth && formattedDate(selectedAccountingMonth, "Celcom"),
      },
    };
    API[ActionTypes.GET_CELCOM_BILLING_RECONCILE_DATA](payload, idToken, userDetails && userDetails?.email?.split("@")?.[0])
    .then((data) => {
      setLoading(false);
      dispatch({
        type: ActionTypes.GET_CELCOM_BILLING_RECONCILE_DATA_SUCCESS,
        payload: data,
      });
      setBtnLoader(false);
    })
    .catch((error) => {
      setBtnLoader(false);
      console.log("ReconcilieProcess", error);
    });
  }

  const secondPassCelcomPortal = () => {
    setLoading(true);
    let payload = {
      GetCelcomBillingReconcileRequest: {
        Client: "Celcom",
        StartDate: selectedAccountingMonth && formattedDate(selectedAccountingMonth, "Celcom"),
        IsSecondPass: true
      },
    };
    API[ActionTypes.GET_CELCOM_BILLING_RECONCILE_DATA](payload, idToken, userDetails && userDetails?.email?.split("@")?.[0])
    .then((data) => {
      setLoading(false);
      dispatch({
        type: ActionTypes.GET_CELCOM_BILLING_RECONCILE_DATA_SUCCESS,
        payload: data,
      });
      setBtnLoader(false);
    })
    .catch((error) => {
      setBtnLoader(false);
      console.log("ReconcilieProcess", error);
    });
  }
  
  return (
    <>
      <div className="flex justify-between w-full h-full mt-10">
        <div className="flex items-center justify-start px-4">
          <Selector
            key="Client Dropdown"
            options={[{ name: "Celcom", value: "Celcom" }]}
            label="Client"
            value={reconciliationClientCelcom}
            onSelect={(name, val) => handleChange(name, val, "selectedClient")}
            placeholder="Select Client"
          />
          <Selector
            key="Channel Dropdown"
            options={months}
            label="Accounting Month"
            value={selectedAccountingMonth}
            onSelect={(name, val) => handleChange(name, val, "selectedMonth")}
            placeholder="Select month"
          />
        </div>
        <div className="flex flex-row items-center">
            <div className="p-1 mr-2 text-sm font-bold text-center text-black bg-white rounded-full">
              {userDetails &&
                userDetails?.firstName?.split()?.[0]?.charAt(0)?.toUpperCase()}
              {userDetails &&
                userDetails?.lastName?.split()?.[0]?.charAt(0)?.toUpperCase()}
            </div>
            <span className="mr-5 font-bold">
              {userDetails && toPascalCase(userDetails?.firstName)}{" "}
              {userDetails && toPascalCase(userDetails?.lastName)}
            </span>
          </div>
      </div>
      <div className="mt-12">
        <table
          className="w-2/3 px-5 mx-auto"
          cellpadding="5"
          cellspacing="5"
          style={{ borderSpacing: "0px 10px", borderCollapse: "collapse" }}
        >
          <tr className="" style={{ borderBottom: "2px solid", borderColor:"#cb5aff"}}>
            <td></td>
            <td className="font-medium">Asurion Billing Data</td>
            <td className="font-medium">Loaded Date</td>
            <td className="font-medium">No of records</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr className="">
            <td className=""></td>
            <td className="">1st Pass-MEC Accrual </td>
            <td>{formattedFinalOneDate === "NaN-NaN-NaN" ? "" : formattedFinalOneDate}</td>
            <td>{passOneData}</td>
          </tr>
          <tr className="">
            <td className="flex justify-center"></td>
            <td>2nd Pass-Billing Reconciliation</td>
            <td>{formattedFinalTwoDate === "NaN-NaN-NaN" ? "" : formattedFinalTwoDate}</td> 
            <td>{passTwoData}</td> 
          </tr>
          
          <tr style={{ borderBottom: "2px solid", borderColor:"#cb5aff"}}>
            <td></td>
          </tr>
          
          <tr className="">
            <td className="flex justify-center mt-8">
              {reconFlag === "" && (
                <Button
                  icon={reconData ? <EyeFilled /> : ""}
                  onClick={(e) => {secondPassCelcomPortal(); setPassdata(true);}}
                  loading={btnLoader}
                  style={{ display: "inline-flex", alignItems: "baseline" }}
                  ghost
                  className={
                    isButtonDisable()
                      ? `${styles.disabledBtn2}`
                      : "text-black transition duration-500 ease-in-out delay-350 hover:-translate-y-0 hover:scale-110"
                  }
                  disabled={isButtonDisable()}
                >
                  Execute Pass 2
                </Button>
              )}
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};

export default CelcomBillingData;
