import { useEffect, useState } from "react"
import ActionTypes from "../../../store/actionTypes"
import API from "../../../api"
import { useCentralStore } from "../../../store/appContext"
import resources from'../../../components/common/resources.json'
import SLADataTable from "../../../components/common/dataTable/SLADataTable"

const SHDashboardData = ({
    csSlaData
}) => {

    const data = csSlaData
    const filteredData = data?.filter(item => item.Category === "Device Repair");
    const filteredDataTwo = data?.filter(item => item.Category !== "Device Repair");

    return(
        <div className="flex flex-col text-black">
            {/* <p className="text-black font-bold mx-auto mt-5">Device Protection Plan </p> */}
            <SLADataTable
                reportDetailsSuccess={filteredDataTwo}
                resources={resources}
            />
            <p className="text-black font-bold mx-auto">Device Repair Plan </p>
            <SLADataTable
                reportDetailsSuccess={filteredData}
                resources={resources}
            />
        </div>
    )
}

export default SHDashboardData