import React, { useState, useEffect, useRef } from 'react'
import { Table } from "antd";
import { useCentralStore } from '../../store/appContext';
import ActionTypes from '../../store/actionTypes';
import SGPReportsFilter from './SGPReportsFilter';
import { DatePicker } from 'antd';
import moment from 'moment';
import API from '../../api';
import resources from  '../../components/common/resources.json'
import { getColumnSearchProps } from "../../components/common/dataTable/AntTableSearchFilter";
import { isEmpty } from '../../utils/functionUtils';
import exportToCSVInventory from '../../components/common/hepler/export-to-csv-submatricsData';
import EnrollmentReport from './EnrollmentReport';
import ServiceReqReport from './ServiceReqReport';

const { RangePicker } = DatePicker;

const ReportSGPView = () => {

    const [ { selectedStarhubReport, selectedReportFilter, reportCountSuccess, userData, userDetails, reportCountDetailsSuccess, reportDetailsSuccess, reportFilterDuration }, dispatch ] = useCentralStore()
    
    let tableColumns = [];
  const totalKeys = {};

  reportDetailsSuccess?.reduce((acc, current) => {
    const keys = Object.keys(current);
    for (var i in keys) {
      totalKeys[keys[i]] = 1;
    }
  }, totalKeys);

  var keys = Object.keys(totalKeys);
  for (var i in keys) {
    tableColumns.push({
      title: keys[i]?.replace(/([a-z0-9])([A-Z])/g, "$1 $2")?.toUpperCase(),
      dataIndex: keys[i],
      key: keys[i],
      width: 220
    });
  }

  const handleTableChange = (currentPagination, filters, sorter, extra) => {
  }

  const inputRef = useRef(null);
  const resetRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [column, setColumn] = useState("");

  const getPartsColumns = () => {
    let headerColumns = [];
    tableColumns.forEach((c, i) => {
      if (i < 5) {
        headerColumns.push({
          ...c,
          ...getColumnSearchProps(
            c.key,
            inputRef,
            resetRef,
            searchText,
            setSearchText,
            column,
            setColumn
          ),
        });
      } else {
        headerColumns.push({
          ...c,
        });
      }
    });
    return headerColumns;
  };

    const [reportAction, setReportAction] = useState(null)
    const [__type, setType] = useState(null)

  const setLoading = (action) => {
        dispatch({ type: ActionTypes.SET_LOADING, payload: action });
    };

  const getReportCountDetails = (type) => {
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS_SUCCESS, payload: null})
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS, payload: null})
    setType(type)
    let payload ={
        StartDate: reportFilterDuration?.startDate,
        EndDate: reportFilterDuration?.endDate,
        Type: type
    }
    setLoading(true)
    API[ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
        .then(({ data }) => {
            setLoading(false);
            if (data) {
              dispatch({
                type: ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS_SUCCESS,
                payload: data,
              });
            }
          })
          .catch((err) => {
            dispatch({
              type: ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS_FAILURE,
              payload: err,
            });
            dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
            let _payload = {
              level: resources.POST_LOGGER_ERROR,
              category: resources.POST_LOGGER_API,
              ApiName: resources.POST_LOGGER_APINAME,
              message: {
                error: err,
                info: {
                  idToken: "",
                },
              },
            };
            API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
          });
    }

    const getReportDetails = (action) => {
        setReportAction(action)
        let payload = {
            StartDate: reportFilterDuration?.startDate,
            EndDate: reportFilterDuration?.endDate,
            ReportType: selectedStarhubReport?.starHubReportValue,
            Type: selectedStarhubReport?.starHubReportValue === 'servicerequest' ? undefined : __type,
            Action: selectedStarhubReport?.starHubReportValue === 'servicerequest' ? undefined : action,
            StatusCode: selectedStarhubReport?.starHubReportValue === 'servicerequest' ? action : undefined,
            Reason: selectedStarhubReport?.starHubReportValue === 'servicerequest' ? undefined : action
        }
        setLoading(true)
        API[ActionTypes.GET_STARHUB_REPORT_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
        .then(({ data }) => {
            setLoading(false);
            if (data) {
              dispatch({
                type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS,
                payload: data,
              });
            }
          })
          .catch((err) => {
            dispatch({
              type: ActionTypes.GET_STARHUB_REPORT_DETAILS_FAILURE,
              payload: err,
            });
            dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
            let _payload = {
              level: resources.POST_LOGGER_ERROR,
              category: resources.POST_LOGGER_API,
              ApiName: resources.POST_LOGGER_APINAME,
              message: {
                error: err,
                info: {
                  idToken: "",
                },
              },
            };
            API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
          });
    }

    useEffect(() => {
        setReportAction(null)
        setType(null)
    }, [selectedReportFilter, reportFilterDuration])
    
    const exportReportData = () => {
        exportToCSVInventory(
            tableColumns,
            reportDetailsSuccess,
            "",
            "",
            selectedStarhubReport?.starHubReportName,
            dispatch,
            ActionTypes
        )
    }

    return(
        <div className=' mx-16'>
            <p className="text-xl font-bold">{selectedStarhubReport?.starHubReportName}</p>
            <div className='flex flex-row justify-between'>
                <SGPReportsFilter 
                    selectedReportFilter={selectedReportFilter} 
                    dispatch={dispatch}
                    ActionTypes={ActionTypes}
                />
                <div className={``} onClick={() => exportReportData()}>
                    <p className={`${!isEmpty(reportDetailsSuccess) ? 'cursor-pointer hover:text-white hover:bg-app-deepGreen1' : 'cursor-not-allowed'} rounded-lg text-sm font-semibold px-3 py-2 text-black bg-app-greenBorder`}>
                        Export Data
                    </p>
                </div>
            </div>

            {selectedStarhubReport?.starHubReportValue === 'enrollment' &&
              <EnrollmentReport 
                __type={__type} 
                reportCountSuccess={reportCountSuccess}
                getReportCountDetails={(type) => getReportCountDetails(type)}
                reportCountDetailsSuccess={reportCountDetailsSuccess}
                getReportDetails={(action) => getReportDetails(action)}
                reportAction={reportAction}
              />
            }

            {selectedStarhubReport?.starHubReportValue === 'servicerequest' &&
              <ServiceReqReport 
                reportCountSuccess={reportCountSuccess} 
                getReportDetails={(SrStatus) => getReportDetails(SrStatus)} 
                reportAction={reportAction}
              />
            }
            
            {!isEmpty(reportDetailsSuccess) && 
              <Table
                  columns={getPartsColumns()}
                  dataSource={reportDetailsSuccess}
                  style={{ fontSize: "12px !important" }}
                  rowKey="data"
                  key="data"
                  pagination={false}
                  className="table-css border-current border-white hz-scrollbar pb-2 mt-4"
                  scroll={{
                  y: "80vh",
                  }}
                  onChange={handleTableChange}
              />
            }
        </div>
    )
}

export default ReportSGPView