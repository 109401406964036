import React from "react";

const icon = ({ className, fill, onClick }) => (

<svg width="90" height="90" fill={fill} viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg">
<path d="M0 83.67V6.33C0.49 4.6 1.12 2.9 2.64 1.85C3.76 1.08 5.09 0.6 6.32 0C32.1 0 57.88 0 83.66 0C83.93 0.09 84.19 0.19 84.46 0.27C86.95 1 88.64 2.59 89.55 5.01C89.73 5.49 89.85 6 89.99 6.5V83.49C89.92 83.74 89.85 83.99 89.78 84.24C89.12 86.68 87.65 88.44 85.31 89.43C84.72 89.68 84.09 89.81 83.48 90H6.5C6.28 89.94 6.06 89.87 5.84 89.81C3.1 89.09 1.26 87.41 0.35 84.72C0.23 84.37 0.12 84.02 0 83.67ZM2.99 24.05C2.99 24.42 2.99 24.73 2.99 25.04C2.99 43.99 2.99 62.95 2.99 81.9C2.99 85.2 4.79 86.99 8.1 86.99C32.71 86.99 57.32 86.99 81.92 86.99C85.18 86.99 87 85.18 87 81.95C87 62.97 87 43.98 87 25V24.04H2.99V24.05ZM86.91 14.98C86.96 14.78 86.99 14.73 86.99 14.67C86.99 12.5 87 10.34 87 8.17C87 4.74 85.24 2.99 81.81 2.99C57.27 2.99 32.72 2.99 8.18 2.99C7.86 2.99 7.53 2.99 7.21 3C5.39 3.12 3.68 4.46 3.42 5.98C3.78 5.98 4.13 5.98 4.48 5.98C11.42 5.98 18.36 5.98 25.3 5.98C29.78 5.98 34.26 5.98 38.74 5.98C41.55 5.98 43.41 7.83 43.48 10.64C43.5 11.34 43.48 12.05 43.48 12.75C43.48 14.58 43.87 14.97 45.69 14.97C59.1 14.97 72.52 14.97 85.93 14.97H86.9L86.91 14.98ZM3.04 8.99V20.92H86.95V17.99H85.78C72.51 17.99 59.24 17.99 45.97 17.99C45.59 17.99 45.21 17.99 44.83 17.98C42.54 17.86 40.8 16.27 40.56 13.97C40.46 13.04 40.51 12.1 40.5 11.16C40.49 9.41 40.07 8.99 38.32 8.99C26.89 8.99 15.47 8.99 4.04 8.99H3.05H3.04Z" fill={fill}/>
<path d="M55.4599 31.4905C62.2899 31.4905 69.1099 31.4905 75.9399 31.4905C79.1799 31.4905 81.0099 33.3105 81.0199 36.5405C81.0199 38.2705 81.0299 40.0005 81.0199 41.7205C80.9999 44.5805 79.1599 46.4505 76.2899 46.4905C74.5299 46.5205 72.7699 46.4905 71.0199 46.4905C59.0399 46.4905 47.0599 46.4905 35.0799 46.4905C32.5099 46.4905 30.7799 45.3305 30.1999 43.1805C30.0599 42.6505 30.0399 42.0805 30.0399 41.5305C30.0199 39.7705 30.0199 38.0105 30.0399 36.2605C30.0599 33.3405 31.9099 31.4805 34.8299 31.4805C41.7099 31.4805 48.5999 31.4805 55.4799 31.4805L55.4599 31.4905ZM55.4999 43.5005C62.3199 43.5005 69.1499 43.5005 75.9699 43.5005C77.5299 43.5005 78.0099 43.0105 78.0099 41.4405C78.0099 39.7405 78.0199 38.0405 78.0099 36.3405C77.9999 35.0605 77.4499 34.4905 76.1999 34.4905C62.3999 34.4905 48.6099 34.4905 34.8099 34.4905C33.5399 34.4905 33.0199 35.0405 33.0199 36.3405C33.0199 38.0105 33.0199 39.6805 33.0199 41.3505C33.0199 43.1105 33.4199 43.5005 35.2099 43.5005C41.9799 43.5005 48.7399 43.5005 55.5099 43.5005H55.4999Z" fill={fill}/>
<path d="M55.5301 49.5103C62.3301 49.5103 69.1201 49.5103 75.9201 49.5103C79.2501 49.5103 81.0101 51.2803 81.0101 54.6103C81.0101 56.3403 81.0201 58.0703 81.0101 59.7903C80.9801 62.5703 79.0701 64.4903 76.3001 64.4903C62.4401 64.4903 48.5901 64.4903 34.7301 64.4903C31.9201 64.4903 30.0501 62.6103 30.0201 59.8203C30.0001 57.9703 30.0101 56.1303 30.0201 54.2803C30.0401 51.3303 31.8401 49.5103 34.7901 49.5103C41.7001 49.5003 48.6201 49.5103 55.5301 49.5103ZM55.5501 61.5103C62.3201 61.5103 69.0801 61.5103 75.8501 61.5103C77.5701 61.5103 78.0201 61.0503 78.0201 59.3003C78.0201 57.7503 78.0201 56.2003 78.0201 54.6403C78.0201 52.9503 77.5801 52.5103 75.8901 52.5103C67.5701 52.5103 59.2501 52.5103 50.9301 52.5103C45.6601 52.5103 40.3901 52.5103 35.1101 52.5103C33.4401 52.5103 33.0201 52.9203 33.0101 54.5603C33.0101 56.1703 33.0101 57.7803 33.0101 59.3903C33.0101 61.0703 33.4501 61.5003 35.1601 61.5003C41.9601 61.5003 48.7501 61.5003 55.5501 61.5003V61.5103Z" fill={fill}/>
<path d="M65.9499 79.4802C62.9299 79.4802 59.9199 79.5102 56.8999 79.4802C54.3399 79.4502 52.1699 77.8702 51.3399 75.5102C50.5099 73.1602 51.1799 70.6402 53.0899 68.9702C54.1699 68.0302 55.4299 67.5202 56.8599 67.5202C62.9499 67.5102 69.0399 67.4802 75.1299 67.5202C78.3899 67.5402 81.0199 70.2902 80.9999 73.5302C80.9799 76.7502 78.4099 79.4202 75.1699 79.4702C72.0999 79.5202 69.0199 79.4802 65.9499 79.4802ZM65.9299 76.4902C68.8299 76.4902 71.7299 76.4902 74.6299 76.4902C75.0399 76.4902 75.4599 76.4802 75.8399 76.3702C77.2599 75.9702 78.1799 74.5402 77.9699 73.1402C77.7299 71.5602 76.5199 70.5202 74.8499 70.5102C68.9599 70.5002 63.0699 70.5002 57.1899 70.5102C55.3499 70.5102 53.9799 71.8302 53.9899 73.5302C54.0099 75.2402 55.3499 76.4902 57.2299 76.4902C60.1299 76.5002 63.0299 76.4902 65.9299 76.4902Z" fill={fill}/>
<path d="M16.5199 64.5002C15.0799 64.5002 13.6499 64.5002 12.2099 64.5002C9.44991 64.4802 7.55991 62.6302 7.51991 59.8902C7.49991 57.9602 7.48991 56.0202 7.51991 54.0902C7.55991 51.4102 9.36991 49.5602 12.0499 49.5202C15.0099 49.4802 17.9699 49.4802 20.9299 49.5202C23.6399 49.5502 25.4799 51.4402 25.4999 54.1502C25.5099 56.0502 25.5099 57.9602 25.4999 59.8602C25.4799 62.5802 23.5699 64.4702 20.8299 64.5002C19.3899 64.5102 17.9599 64.5002 16.5199 64.5002ZM10.7699 54.7602C10.6899 54.8002 10.6199 54.8402 10.5399 54.8802C10.5399 56.6602 10.4999 58.4302 10.5599 60.2102C10.5899 61.0202 11.1999 61.4902 11.9799 61.4902C14.9899 61.5202 17.9999 61.5202 21.0199 61.4902C21.8099 61.4902 22.4299 61.0102 22.4699 60.2302C22.5499 58.4402 22.4999 56.6402 22.4999 54.5602C21.6199 55.4702 20.9299 56.2202 20.2099 56.9202C18.0199 59.0402 15.0199 59.0502 12.8399 56.9202C12.1299 56.2302 11.4699 55.4802 10.7899 54.7602H10.7699ZM12.9099 52.5602C12.8599 52.6402 12.8099 52.7202 12.7699 52.8002C12.9999 52.9802 13.2499 53.1402 13.4599 53.3402C13.9899 53.8402 14.4799 54.3802 15.0199 54.8702C15.7899 55.5702 16.8299 55.7502 17.5899 55.1502C18.5299 54.4102 19.3499 53.5202 20.3599 52.5602H12.9099Z" fill={fill}/>
<path d="M20.1501 38.7396C21.3901 39.7696 22.1601 41.0396 22.4301 42.6096C22.8301 44.8696 21.5201 46.4696 19.2201 46.4996C17.4601 46.5196 15.7101 46.5096 13.9501 46.4996C11.4201 46.4896 10.1001 44.8296 10.6201 42.3296C10.9301 40.8696 11.6901 39.6896 12.8301 38.7596C11.6101 35.6696 11.9401 33.7096 13.9101 32.3196C15.6101 31.1196 17.8601 31.2296 19.4401 32.5796C21.1701 34.0696 21.4301 36.1196 20.1501 38.7296V38.7396ZM19.4601 43.4296C19.3401 41.6296 17.9901 40.3896 16.3401 40.4996C14.7201 40.6096 13.4801 41.9296 13.6301 43.4296H19.4601ZM16.4701 37.4696C17.3301 37.4896 17.9701 36.8596 17.9801 36.0096C17.9801 35.1696 17.3201 34.4896 16.5001 34.4996C15.7301 34.4996 15.0501 35.1596 15.0101 35.9396C14.9701 36.7596 15.6401 37.4596 16.4701 37.4696Z" fill={fill}/>
<path d="M42.0001 79.5605C41.0701 79.4705 40.1201 79.5005 39.2301 79.2705C37.3301 78.7905 36.0701 77.1005 36.0301 75.1405C36.0001 74.0305 36.0001 72.9205 36.0301 71.8105C36.1001 69.4805 37.9601 67.6005 40.2901 67.5105C41.4301 67.4705 42.5701 67.4705 43.7101 67.5105C46.0701 67.6005 47.9301 69.5105 47.9901 71.8905C48.0201 72.9405 48.0101 74.0005 47.9901 75.0505C47.9401 77.5305 46.0701 79.4005 43.5901 79.4805C43.0601 79.5005 42.5401 79.4805 42.0101 79.4805C42.0101 79.5105 42.0101 79.5405 42.0101 79.5605H42.0001ZM45.0001 73.4905C45.0001 70.6105 44.8801 70.4905 41.9901 70.4905C39.1101 70.4905 39.0101 70.6005 39.0101 73.5005C39.0101 76.4005 39.0901 76.4805 42.0101 76.4805C44.9101 76.4805 45.0001 76.3805 45.0001 73.4805V73.4905Z" fill={fill}/>
<path d="M79.4601 7.52975C80.3001 7.50975 80.9901 8.15975 81.0001 8.98975C81.0001 9.78975 80.3001 10.5097 79.5001 10.5097C78.7201 10.5097 78.0601 9.85975 78.0301 9.06975C78.0001 8.20975 78.6101 7.55975 79.4701 7.53975L79.4601 7.52975Z" fill={fill}/>
<path d="M73.49 7.53027C74.33 7.53027 75 8.20027 74.99 9.02027C74.99 9.83027 74.26 10.5303 73.46 10.5003C72.67 10.4803 72.03 9.83027 72.02 9.03027C72 8.17027 72.63 7.53027 73.49 7.53027Z" fill={fill}/>
<path d="M68.98 8.96034C69 9.79034 68.34 10.4903 67.52 10.5003C66.71 10.5103 66.02 9.83034 66.02 9.00034C66.02 8.19034 66.63 7.56034 67.44 7.53034C68.29 7.50034 68.96 8.12034 68.98 8.97034V8.96034Z" fill={fill}/>
<path d="M17.25 13.5098C19.89 13.5098 22.52 13.5098 25.16 13.5098C26.3 13.5098 26.96 14.0498 26.99 14.9498C27.02 15.8898 26.32 16.4998 25.16 16.4998C19.89 16.4998 14.62 16.4998 9.33997 16.4998C8.15997 16.4998 7.49997 15.9298 7.51997 14.9498C7.53997 14.0098 8.15997 13.5098 9.32997 13.5098C11.97 13.5098 14.6 13.5098 17.24 13.5098H17.25Z" fill={fill}/>
<path d="M32.2999 15.0201C31.3899 13.8101 31.2999 13.1401 31.9599 12.4701C32.6099 11.8101 33.3299 11.8901 34.5099 12.7801C35.6699 11.8601 36.3699 11.7701 37.0399 12.4401C37.6899 13.0801 37.5999 13.8101 36.7299 14.9901C37.5899 16.1801 37.6699 16.9101 37.0199 17.5401C36.3699 18.1701 35.5899 18.0701 34.5399 17.2201C33.1699 18.1201 32.4599 18.1901 31.8499 17.4801C31.2499 16.7801 31.3699 16.1001 32.2999 15.0201Z" fill={fill}/>
</svg>

);
export default icon;