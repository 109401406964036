import React from 'react'

const icon = ({ className, fill, onClick }) => 
(
    <svg className={className} onClick={onClick} width="24" height="25" viewBox="0 0 24 25" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 18C0.5 17.4477 0.947715 17 1.5 17H9.5C10.0523 17 10.5 17.4477 10.5 18V23C10.5 23.5523 10.0523 24 9.5 24H1.5C0.947716 24 0.5 23.5523 0.5 23V18Z" stroke="#D5D6DA" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5 23C23.5 23.5523 23.0523 24 22.5 24H14.5C13.9477 24 13.5 23.5523 13.5 23L13.5 12C13.5 11.4477 13.9477 11 14.5 11L22.5 11C23.0523 11 23.5 11.4477 23.5 12V23Z" stroke="#D5D6DA" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5 7C23.5 7.55228 23.0523 8 22.5 8L14.5 8C13.9477 8 13.5 7.55228 13.5 7V2C13.5 1.44772 13.9477 1 14.5 1L22.5 1C23.0523 1 23.5 1.44772 23.5 2V7Z" stroke="#D5D6DA" stroke-linecap="round" stroke-linejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 2C0.5 1.44772 0.947715 1 1.5 1H9.5C10.0523 1 10.5 1.44772 10.5 2V13C10.5 13.5523 10.0523 14 9.5 14H1.5C0.947716 14 0.5 13.5523 0.5 13V2Z" stroke="#D5D6DA" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
)
export default icon


