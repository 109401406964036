import React from "react";

const icon = ({ className, fill, onClick ,stroke}) => (
  <svg
    className={className}
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.6512 12.7251C18.5431 12.644 18.4115 12.6001 18.2763 12.6001H11.7375C11.6023 12.6001 11.4707 12.644 11.3625 12.7251C8.65375 14.7926 5.625 18.7951 5.625 21.9713C5.625 26.4601 8.125 28.8463 15 28.8463C21.875 28.8463 24.375 26.4601 24.375 21.9713C24.375 18.7951 21.3587 14.7926 18.6512 12.7251Z"
      stroke={stroke}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.6299 10.0962H19.3799"
      stroke={stroke}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.6736 2.96763C19.8027 2.73343 19.7685 2.44306 19.5885 2.24528C19.4085 2.0475 19.1227 1.98614 18.8774 2.09263L16.2524 3.21763L15.5836 1.54263C15.4889 1.30491 15.2589 1.14893 15.003 1.14893C14.7471 1.14893 14.5171 1.30491 14.4224 1.54263L13.7499 3.22138L11.1249 2.09638C10.8796 1.98989 10.5937 2.05125 10.4137 2.24903C10.2338 2.44681 10.1995 2.73718 10.3286 2.97138C11.06 4.23547 11.5727 5.61403 11.8449 7.04888C11.8842 7.36349 12.1528 7.59882 12.4699 7.59638H17.5511C17.8682 7.59882 18.1368 7.36349 18.1761 7.04888C18.441 5.61358 18.9473 4.23359 19.6736 2.96763V2.96763Z"
      stroke={stroke}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.6299 16.9712V15.7212"
      stroke={stroke}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.5002 16.9712H14.964C14.1589 16.9686 13.4656 17.5389 13.3129 18.3294C13.1601 19.1199 13.5909 19.9073 14.339 20.205L16.9177 21.2375C17.6629 21.5373 18.091 22.3233 17.9386 23.112C17.7861 23.9007 17.096 24.4706 16.2927 24.4712H13.7502"
      stroke={stroke}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.6299 25.7212V24.4712"
      stroke={stroke}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default icon;
