
import React, { useContext, useEffect } from 'react'
// import  { components } from 'react-select'
// import AsyncSelect from 'react-select/async'
// import { AsyncPaginate } from 'react-select-async-paginate'

// import { Controller } from 'react-hook-form'
// import Cleave from 'cleave.js/react'

//  import Validator from './schemas'
//  import { store } from '../Store'
//  import errorMessages from './error-messages'
// import TooltipIcon from '../Icons/icon-tooltip'
// import NameOnCardIcon from '../Icons/icon-nameoncard'
// import CreditCardIcon from '../Icons/icon-creditcardnumber'
// import DatePicker from 'react-datepicker'
// import { isEmpty } from './helper'

const moment = require('moment')
// const { ValueContainer, Placeholder } = components

const FloatLabel = (() => {
    // add active class and placeholder
    const handleFocus = (e) => {
      const target = e.target
      target.parentNode.classList.add('active')
      target.parentNode.classList.remove('hasValue')
      target.setAttribute('placeholder', target.getAttribute('data-placeholder'))
    }
  
    // remove active class and placeholder
    const handleBlur = (e) => {
      const target = e.target
      if (!target.value) {
        target.parentNode.classList.remove('active')
      }
      target.value && target.parentNode.classList.add('hasValue')
      target.removeAttribute('placeholder')
    }
  
    // remove active class and placeholder
    const keyUpHandler = ({ target }) => {
      target.parentNode.classList.add('active')
      !target.value && target.removeAttribute('placeholder') // removing placeholder when input is empty
    }
  
    // register events
    const bindEvents = (element) => {
      const floatField = element.querySelector('input,select,textarea')
      floatField.addEventListener('focus', handleFocus)
      floatField.addEventListener('blur', handleBlur)
      floatField.addEventListener('keyup', keyUpHandler) // this is a fix for existing issue, when emptying input will show both placeholders & label
    }
  
    // get DOM elements
    const init = () => {
      const floatContainers = document.querySelectorAll('#floatContainer1')
  
      floatContainers.forEach((element) => {
        if (element.querySelector('input,select,textarea').value) {
          //element.classList.add('active')
          element.classList.add('hasValue')
          element.removeAttribute('placeholder')
        }
  
        bindEvents(element)
      })
    }
  
    return {
      init: init,
    }
  })()
export const  FloatLabelInputField = ({
    type,
    name,
    value,
    label,
    hideLabel,
    placeholder,
    disabled,
    className,
    onChange,
    onClick,
    register,
    errors,
    schema,
    customeValidator,
    touched,
    dirtyFields,
    Mandatory,
    labelClass,
    defaultValue,
    restrictInput,
    onKeyPress,
    errorClass,
    onBlur,
    enableBlurEvent,
    maxLength,
    eyeIcon,
    imgOnCkick,
    singleLine,
    showInputIcon,
    showSearchIcon = false,
    inputImgPath,
    masked,
    errorClassName,
    handleSearch,
    customFieldError,
  }) => {
    //  const { state } = useContext(store)
    // const { resources } = state
    // const validator = customeValidator || Validator(schema, name)
    useEffect(() => {
      FloatLabel.init()
    }, [])
    // const disable = !isEmpty(errors) || defaultValue?.length < 1 || isEmpty(defaultValue)
    // console.log('errors==========>', errors, defaultValue)
    return (
      <>
        <div
          id="floatContainer1"
          className={`${errorClassName} ${className} ${
            singleLine ? 'float-container-line' : 'float-container bg-Asurion-White'
          } h-14 ${
            errors?.[name] && !defaultValue
              ? singleLine
                ? 'input_box_error_line h-14'
                : 'input_box_error h-14'
              : errors?.[name] && defaultValue
              ? singleLine
                ? 'input_box_error_line active'
                : 'input_box_error active'
              : disabled === true
              ? singleLine
                ? 'disabled_box_line'
                : 'disabled_box'
              : defaultValue
              ? 'active'
              : ''
          }`}
        >
          {!hideLabel && (
            <label htmlFor={name} className={`${labelClass}`}>
              {
            //   (resources && resources[label]) || 
              label}
              {Mandatory && <span className="text-red-600 ml-1">*</span>}
            </label>
          )}
          {/* {showInputIcon && <img className="absolute top-15 left-10" src={require(`../assets/${inputImgPath}`)} />} */}
          {/* {showSearchIcon && (
            <div style={{ pointerEvents: `${disable ? 'none' : 'all'}` }}>
              <img
                style={{ top: '16px', height: '30px' }}
                className={`${!disable && 'cursor-pointer'} absolute right-10 top-[20px]`}
                src={require(`../assets/${!disable ? 'search_icon.png' : 'search_icon_disabled.png'}`)}
                onClick={handleSearch}
              />
            </div>
          )} */}
          <input
            id={name}
            type={type}
            name={name}
            //value={value}
            disabled={disabled}
            data-placeholder={ placeholder}
            maxLength={maxLength}
            //className={`${className} ${errors[name] ? 'input_box_error' : ''}`}
            className={`${className} ${showInputIcon ? ' input-padding' : ''} ${masked ? 'mask' : ''}`}
            onChange={(e) => onChange(e.target.value)}
            // ref={register(validator)}
            defaultValue={defaultValue}
            onKeyPress={(e) => restrictInput && onKeyPress(e)}
            onBlur={(e) => enableBlurEvent && onBlur(e.target.value)}
          />
          {/* {eyeIcon && (
            <img
              className={imgOnCkick ? 'Password hidePassword' : 'Password showPassword'}
              src={imgOnCkick ? require('../assets/hide.png') : require('../assets/show.png')}
              onClick={(e) => onClick(e.target.value)}
            />
          )} */}
          {/* { errors[name] && errors[name].type == 'required' && <p className={`${errorClass ? errorClass : ''} error-text`}>{errorMessages('required', label, validator, resources)}</p>} */}
        </div>
        {/* {errors[name] && errors[name].type == 'required' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {errorMessages('required', label, validator, resources)}
          </p>
        )}
        {errors[name] && errors[name].type == 'maxLength' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {errorMessages('maxLength', label, validator, resources)}
          </p>
        )}
        {errors[name] && errors[name].type == 'onlySpace' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {errorMessages('onlySpace', label, validator, resources)}
          </p>
        )}
        {errors[name] && errors[name].type == 'onlyChar' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {errorMessages('onlyChar', label, validator, resources)}
          </p>
        )}
        {errors[name] && errors[name].type == 'alpha' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {errorMessages('alpha', label, validator, resources)}
          </p>
        )}
        {errors[name] && errors[name].type == 'validatehyphen' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {errorMessages('validatehyphen', label, validator, resources)}
          </p>
        )}
        {errors[name] && errors[name].type == 'email' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {errorMessages('email', label, validator, resources)}
          </p>
        )}
        {errors[name] && errors[name].type == 'numeric' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {errorMessages('numeric', label, validator, resources)}
          </p>
        )}
        {errors[name] && errors[name].type == 'minLength' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {errorMessages('minLength', label, validator, resources)}
          </p>
        )}
        {errors[name] && errors[name].type == 'invalidMobilenum' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {errorMessages('invalidMobilenum', label, validator, resources)}
          </p>
        )}
        {errors[name] && errors[name].type == 'numberlength' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {errorMessages('numberlength', label, validator, resources)}
          </p>
        )}
        {errors[name] && errors[name].type == 'invalidExpiryDate' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {errorMessages('invalidExpiryDate', label, validator, resources)}
          </p>
        )}
        {errors[name] && errors[name].type == 'password' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {' '}
            {errorMessages('password', label, validator, resources)}{' '}
          </p>
        )}
        {errors[name] && errors[name].type == 'emailormobile' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {' '}
            {errorMessages('emailormobile', label, validator, resources)}{' '}
          </p>
        )}
        {errors[name] && errors[name].type == 'checkBatteryCapacity' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {errorMessages('checkBatteryCapacity', label, validator, resources)}
          </p>
        )}
        {errors[name] && errors[name].type == 'alphanumeric' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {errorMessages('alphanumeric', label, validator, resources)}
          </p>
        )}
        {errors[name] && errors[name].type == 'maxCharLength' && (
          <p className={`${errorClass ? errorClass : ''} error-text`}>
            {errorMessages('maxCharLength', label, validator, resources)}
          </p>
        )} */}
        {customFieldError && <p className={`${errorClass ? errorClass : ''} error-text`}>{customFieldError}</p>}
      </>
    )
  }
  export default FloatLabelInputField;
  
