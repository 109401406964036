import { isEmpty } from "../../utils/functionUtils";
import { DatePicker } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import API from "../../api";
import { useCentralStore } from "../../store/appContext";
import ActionTypes from "../../store/actionTypes";
import resources from "../../components/common/resources.json";
const { RangePicker } = DatePicker;

const ScoreCardSummaryFilter = ({selectedClient}) => {
  const [
    {
      selectedReportFilter,
      userData,
      userDetails,
      selectedTab
    },
    dispatch,
  ] = useCentralStore();

  let filterOptions = [
    {
      label: "Select Dates",
      value: "real time",
    }
  ];

  const [dates, setDates] = useState(null);
  const [value, setValue] = useState(null);
  const [changedRealTime, setChangedRealTime] = useState(true);
  const [dateDiff, setDateDiff] = useState(null);
  const [startDateToShow, setStartDateToShow] = useState(null);
  const [endDateToShow, setEndDateToShow] = useState(null);

  useEffect(() => {
    if (dates && dates.length === 2) {
      const startDate = dates[0];
      const endDate = dates[1];
      // const diffInDays = endDate.diff(startDate, 'days');
      // setDateDiff(diffInDays);
      setStartDateToShow(startDate.format("DD-MM-YYYY"));
      setEndDateToShow(endDate.format("DD-MM-YYYY"));
    }
  }, [dates]);

  const handleDateChange = (dates, dateString, type) => {    
    dispatch({
      type: ActionTypes.SS_SET_REPORT_FILTER_DURATION,
      payload: {
        type: type,
        startDate: type === "dates" ? moment(dateString[0]).format("YYYY-MM-DD 00:00:00") : moment(dateString[0]).format("YYYY-MM-DD 00:00:00"),
        endDate: type === "dates" ? moment(dateString[1]).format("YYYY-MM-DD 23:59:59") : moment(dateString[1]).format("YYYY-MM-DD 23:59:59"),
        duration: type === "dates" ? dateString : dateString,
      },
    });
    setDates(dates);
    const setLoading = (action) => {
      dispatch({ type: ActionTypes.SET_LOADING, payload: action });
    };
    setLoading(true);
    let pagetittle = selectedTab === "Summary One" ? "ScoreCardSummary1" : selectedTab === "Summary Two" ? "ScoreCardSummary2" : selectedTab === "Summary Three" ? "ScoreCardSummary3" : selectedTab === "Summary Four" ? "ScoreCardSummary4" : "ScoreCardSummary1";
    let payload = {
      StartDate: moment(dateString[0]).format("YYYY-MM-DD 00:00:00"),
      EndDate: moment(dateString[1]).format("YYYY-MM-DD 23:59:59"),
      ClientName: selectedClient,
      PageTitle: pagetittle
    };
    API[ActionTypes.GET_SCORECARD_SUMMARY](
      payload,
      userData?.access_token,
      userDetails && userDetails?.email?.split("@")?.[0]
    )
      .then((data) => {
        setLoading(false);
        setChangedRealTime(true)
          dispatch({
            type: ActionTypes.GET_SCORECARD_SUMMARY_SUCCESS,
            payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.GET_SCORECARD_SUMMARY_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](
          _payload,
          userData?.access_token,
          userDetails && userDetails?.email?.split("@")?.[0]
        );
      });
    dispatch({
      type: ActionTypes.GET_SCORECARD_SUMMARY_SUCCESS,
      payload: null,
    });
    dispatch({
      type: ActionTypes.GET_SCORECARD_SUMMARY_SUCCESS,
      payload: null,
    });
    dispatch({
      type: ActionTypes.GET_SCORECARD_SUMMARY_SUCCESS,
      payload: null,
    });
  };

  const handleFilterChange = (val) => {
    let currentDate;
    let firstDayofMonth;
    if (val === "real time") {
      let today = new Date();
      currentDate = moment(new Date()).format("YYYY-MM-DD 23:59:59");
      firstDayofMonth = moment(
        new Date(today.getFullYear(), today.getMonth(), 1)
      ).format("YYYY-MM-DD 00:00:00");
    }
    dispatch({ type: ActionTypes.SS_SET_REPORT_FILTER, payload: val });
    dispatch({
      type: ActionTypes.SS_SET_REPORT_FILTER_DURATION,
      payload: {
        type: val === "real time" ? "realtime" : "realtime",
        startDate: val === "real time" ? firstDayofMonth : firstDayofMonth,
        endDate: val === "real time" ? currentDate : currentDate,
        duration: "2023-04",
      },
    });
    setDates(null);
    setValue(null);
    setChangedRealTime(false);
  };

  const disabledDate = current => {
    return current && current > moment().endOf('day');
  };

  return (
    <>
      <div className={`pr-4`}>
        <div>
          <div className="flex justify-end">
            {(isEmpty(selectedReportFilter) ||
              selectedReportFilter === "real time") &&
              filterOptions?.map((v) => (
                <div
                  className={`text-white relative rounded-md text-[16px] cursor-pointer py-2 px-3 flex justify-center items-center border-b bg-black border-app-border font-bold
                    ${ selectedReportFilter === v.value ? "border border-app-border" : "" }`}
                  onClick={() => handleFilterChange(v.value)}
                >
                  {v.label}
                </div>
              ))}
          </div>
        </div>
        {selectedReportFilter === "real time" && (
          <div>
            { changedRealTime ? (
              <div>
                {(dates && dates.length === 2) ? (
                  <p className="text-[11px] text-white mt-2" onClick={() => setChangedRealTime(false)}>
                    Showing data from <b>{startDateToShow}</b> to <b>{endDateToShow}</b>.
                  </p>
                ) : (
                  <p className="text-[11px] text-white mt-2" onClick={() => setChangedRealTime(false)}>
                    Showing data for last <b>90</b> days.
                  </p>
                )}
              </div>) :
              <RangePicker
                allowClear={true}
                className="bg-white hover:bg-white mt-2"
                value={dates}
                onChange={handleDateChange}
                disabledDate={disabledDate}
              /> 
            }
          </div> 
        )}  
      </div>
    </>
  );
};

export default ScoreCardSummaryFilter;
