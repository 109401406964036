import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ActionTypes from "../../store/actionTypes";
import config from "../../config/config";
import RealtimeSRSIcon from '../../assets/gifs/RealTimeSSR.gif'
import CustomerSLAIcon from '../../assets/gifs/customerSLA.gif'
import TechnicalSLAIcon from '../../assets/gifs/technicalSLA.gif'
import ProgramSummaryIcon from '../../assets/gifs/programSummary.gif'
import FiancanceReconIcon from '../../assets/gifs/Finance Reconciliation & Invoice.gif'
import wordFile from '../../assets/wordFiles/Reporting_tool-Device_protection_plan_User_Manual.docx'
import AsurionLogo from '../../assets/icons/asurionLogo'
import Logout from '../../assets/icons/icon-logout'
import StarhubLogo from '../../assets/icons/starhubLogo'
import { toPascalCase } from "../../utils/functionUtils"
import { Button } from 'antd'
import { useCentralStore } from "../../store/appContext";
import { hover } from "@testing-library/user-event/dist/hover";
import { FileExcelTwoTone } from "@ant-design/icons";
import Download from "../../assets/icons/downloadNew";
import API from "../../api";

const SGPLanding = ({
    resources,
    gotoDashboard,
    userDetails
}) => {
    const [{ userLoggedIn, authCodeSearch, email, password, loadingData}, dispatch] = useCentralStore();
    
    let setions = [
        {label:'Real Time Service Request Status', value: 'Real Time Service Request Report', icon: <img src={RealtimeSRSIcon} />, 
            subLabels: [
                {label: 'Real Time', value: 'Real Time', parentLabel:'Real Time Service Request Report'},
                {label: 'Monthly Trends', value: 'Monthly Trends', parentLabel:'Real Time Service Request Report'},
                {label: 'Glossary', value: 'Glossary', parentLabel:'Real Time Service Request Report'},
                // {label: 'Report', value: 'DownloadReport', parentLabel:'Real Time Service Request Report'},
            ]},
        {label:'Customer SLA', value: 'Customer Service SLA Report', icon: <img src={CustomerSLAIcon} />, 
        subLabels: [
            {label: 'Prev Month', value: 'Prev Month', parentLabel:'Customer Service SLA Report'},
            {label: 'Monthly Trends', value: 'Monthly Trends', parentLabel:'Customer Service SLA Report'},
            // {label: 'SLA Calculation Methodology', value: 'SLA calculation methodology', parentLabel:'Customer Service SLA Report'},
            {label: 'Glossary', value: 'Glossary', parentLabel:'Customer Service SLA Report'}
        ]},
        {label:'Technical SLA ', value: 'Technical Service SLA Report', icon: <img src={TechnicalSLAIcon} />, 
        subLabels: [
            {label: 'Prev Month', value: 'Prev Month', parentLabel:'Technical Service SLA Report'},
            {label: 'Monthly Trends', value: 'Monthly Trends', parentLabel:'Technical Service SLA Report'},
            {label: 'Glossary', value: 'Glossary', parentLabel:'Technical Service SLA Report'}
        ]},
        {label:'Program Summary', value: 'Program Summary',parentLabel: 'Program Summary', icon: <img src={ProgramSummaryIcon} />},
        {label:'Finance Reconciliation & Invoice', value: 'Finance Reconciliation & Invoice', icon: <img src={FiancanceReconIcon} />,
        subLabels: [
            {label: 'Finance Recon & Invoice', value: 'Finance Reconciliation & Invoice', parentLabel:'Finance Reconciliation & Invoice'},
            {label: 'Rebate To Starhub', value: 'SLA Report', parentLabel:'Finance Reconciliation & Invoice'},
            {label: 'Glossary', value: 'Glossary', parentLabel:'Finance Reconciliation & Invoice'}
        ]}
    ]

    const setLoading = (action) => {
        dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
      };

    const navigate = useNavigate();
    const handleClick = ()=>{
        setLoading(true)
        API[ActionTypes.SIGNOUT_REQUEST]()
        .then((data) => {
            setLoading(false)
            navigate("/login");
            dispatch({
                type: ActionTypes.SET_USER_LOGGED_IN,
                payload: false,
            });
            dispatch({
                type: ActionTypes.SET_EMAIL_ADDRESS,
                payload: "",
            });
            dispatch({
                type: ActionTypes.SET_PASSWORD,
                payload: "",
            });
            dispatch({
                type: ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS,
                payload: undefined,
            });
            dispatch({
                type: ActionTypes.SET_SELECTED_TAB,
                payload: undefined,
            });
            dispatch({
                type: ActionTypes.SET_PROJECT_NAME,
                payload: undefined,
            });
            dispatch({
                type: ActionTypes.SET_PROGRAM_NAME,
                payload: undefined,
            });
            })
        .catch((err) => {
            setLoading(false)
            console.log(`error is ${err}`);
            });
        
        // navigate("/login");
    }

    const handleDownload = (e, placeholder) => {
        const link = document.createElement('a');
        link.href = wordFile;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleDownloadReports = (e, placeholder) => {
        // navigate("/downloadReports");
        dispatch({ type: ActionTypes.SET_PROJECT_NAME, payload: 'Download Reports' });
        dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: undefined });
        dispatch({ type: ActionTypes.SET_PROJECT_SUB_MENU, payload: undefined });
    };   

    return(
        <div className="p-5 pt-0 flex flex-col w-full">
            <div className="flex flex-row items-center justify-end">
                <div className={`${config.isSGP === 'true' ? ' ' : '' } text-center rounded-full text-black mr-2 font-bold text-sm`}>
                {userDetails && userDetails?.firstName?.split()?.[0]?.charAt(0)?.toUpperCase()}
                {userDetails && userDetails?.lastName?.split()?.[0]?.charAt(0)?.toUpperCase()}
                </div>
                <span className={`${config.isSGP === 'true' ? 'text-black  text-base' : ''} mr-5 font-bold`}>
                {userDetails && toPascalCase(userDetails?.firstName)}{" "}{userDetails && toPascalCase(userDetails?.lastName)}
                {userDetails && toPascalCase(userDetails?.split('@')[0]?.split('.')[0]) + ' ' + toPascalCase(userDetails?.split('@')[0]?.split('.')[1])}
                </span>
                <Button className='bg-white flex items-center cursor-pointer text-black font-bold border-red-600 hover:border-card-greenBorder hover:text-white border-2' onClick={() => handleClick()}>Logout <Logout className="ml-2"/></Button>
            </div>
            <div className="flex flex-row justify-end mt-2 cursor-pointer gap-1">
                <p onClick={handleDownloadReports} className="text-sm font-bold flex float-right text-right text-black hover:text-blue-800 cursor-pointer underline">Download Reports Here!! </p>
                <Download onClick={handleDownloadReports} className="pt-2px h-5 w-5 mr-2"/>
                <p onClick={handleDownload} className="text-sm font-bold flex text-right text-black hover:text-blue-800 cursor-pointer underline">Download user manual here!! </p>
                <Download onClick={handleDownload} className="pt-2px h-5 w-5"/>
            </div>
            <div className="flex flex-row justify-center items-center mb-2 mt-3">
                <StarhubLogo className="w-14 h-[65px] mr-3" />
                <p className="text-[40px] text-black">Device Protection Program</p>
            </div>
            <p className={`mb-4 text-2xl text-center text-[#3D3D3B]`}>
                {resources.LANDING_HEADER.replace(
                    "{username}",userDetails?.firstName?
                    toPascalCase(userDetails?.firstName):"Guest"
                )}
                </p>
            <div className="flex flex-row flex-wrap justify-center mx-5">
                {setions?.map(v => 
                    {return(
                        <div className="cursor-pointer w-[220px] ml-5 flex flex-col justify-center items-center text-black border border-app-starhubGreen rounded-xl py-10 px-2 font-semibold text-sm transition duration-500 ease-in-out delay-350 hover:-translate-y-0 hover:scale-y-110 hover:scale-x-110"
                            onClick={() => gotoDashboard(v?.value, v)}>
                                {v.icon}
                                <p className="mt-5 text-center">{v.label}</p>
                        </div>
                    )}
                )}
                {/* <div className="items-center px-6 py-5 ml-5 cursor-pointer landingScreenStyle1 rounded-xl">
                    <p className="my-3 text-xl font-semibold text-center">
                        {resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME}
                    </p>
                    <ReportSGP />
                </div> */}
            </div>
            <div className="flex justify-center">
                <AsurionLogo className="text-center my-4" />
            </div>
        </div>
    )
}

export default SGPLanding