import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import faker from 'faker';
import { useState } from 'react';
import { useCentralStore } from '../../../store/appContext';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const NPSReport = ({
    chartReportCountSuccess,
    reportName,
    getBgColor
}) => {

    const options = {
        plugins: {
          title: {
            display: true,
            text: reportName,
          },
          datalabels: {
            formatter: function(value, context) {
              // return value > 0 ? value+'%' : null;
            },
            // align: "top",
            labels: {
                usePointStyle: true,
                    font: {
                        weight: 700,
                        color: 'black',
                        size: 16,
                    }
            },
          }
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      };
      const npsReportCount = {
        Months: chartReportCountSuccess['NPSCount']?.map(v => v?.Month),
        ['Promoters']: chartReportCountSuccess['NPSCount']?.map(v => v?.['Promoters']),
        ['Detractors']: chartReportCountSuccess['NPSCount']?.map(v => v?.['Detractors']),
        ['Passive']: chartReportCountSuccess['NPSCount']?.map(v => v?.['Passive']),
        // ['OnlineNpsCount']: chartReportCountSuccess['NPSCount']?.map(v => v?.['OnlineNpsCount'])
      }
      let labels = npsReportCount?.Months

  const sorter = (a, b) => {
    a = a.split("-");
    b = b.split("-");
    if (a[0] !== b[0]) {
      return a[0] - b[0];
    } else {
      return a[1] - b[1];
    }
  };
//  const labels = labelssorting.sort(sorter);

      let npsLabel = ['Promoters', 'Detractors', 'Passive'] //'OnlineNpsCount'

       let _reportData =[]
      for(let i=0; i < (npsLabel.length); i++){
        _reportData.push({
            label: npsLabel[i],
            data: npsReportCount[npsLabel[i]],
            backgroundColor: getBgColor[i],
            stack: 0
        })
      }

       const reportData = {
        labels,
        datasets: _reportData,
      };

    return(
        <div>
            <Bar options={options} data={reportData} />
        </div>
    )
}

export default NPSReport