const EnrollmentReport = ({ __type, reportCountSuccess, getReportCountDetails, reportCountDetailsSuccess, getReportDetails, reportAction}) => {

    return(
        <div>
            <div className='flex gap-6 my-6'>
                {/* <p className="cursor-pointer w-44 md:w-64 flex flex-col justify-start border-1 border-gray-700 bg-app-border mb-1 text-white mt-2 px-4 py-2 ">Month: June</p> */}
                <p className={`${__type === 'totalcount' ? 'bg-app-greenBorder text-black' : 'hover:text-app-greenBorder text-white'} rounded-lg text-sm cursor-pointer font-semibold w-44 md:w-64 flex flex-col justify-start border-1 border-gray-700 bg-app-border mb-1 mt-2 px-4 py-2`}
                    onClick={() => getReportCountDetails('totalcount')}>
                    Total Subscription Count: 
                    <a className="mt-1" target='_blank'>
                        {reportCountSuccess?.[0]?.TotalCount || 0}
                    </a>
                </p>
                <p className={`${__type === 'acceptedcount' ? 'bg-app-greenBorder text-black' : 'hover:text-app-greenBorder text-white'} rounded-lg text-sm cursor-pointer font-semibold w-44 md:w-64 flex flex-col justify-start border-1 border-gray-700 bg-app-border mb-1 mt-2 px-4 py-2`}
                    onClick={() => getReportCountDetails('acceptedcount')}>
                    Accepted Count: 
                    <a className="mt-1" target='_blank'>
                        {reportCountSuccess?.[0]?.AcceptedCount || 0}
                    </a>
                </p>
                <p className={`${__type === 'rejectedcount' ? 'bg-app-greenBorder text-black' : 'hover:text-app-greenBorder text-white'} rounded-lg text-sm cursor-pointer font-semibold w-44 md:w-64 flex flex-col justify-start border-1 border-gray-700 bg-app-border mb-1 mt-2 px-4 py-2`}
                    onClick={() => getReportCountDetails('rejectedcount')}>
                    Rejected Count: 
                    <a className="mt-1" target='_blank'>
                        {reportCountSuccess?.[0]?.RejectedCount || 0}
                    </a>
                </p>
            </div>
            <div className={`flex flex-wrap gap-3 my-2`}>
                {__type === 'acceptedcount' && reportCountDetailsSuccess?.map(v => {
                    return v?.Action && <p className={`${reportAction === v?.Action ? 'bg-app-greenBorder text-black' : 'hover:text-app-greenBorder text-white'} rounded-lg text-sm cursor-pointer font-semibold w-44 md:w-64 flex flex-col justify-start border-1 border-gray-700 bg-app-border mb-1 mt-2 px-4 py-2`}
                        onClick={() => getReportDetails(v?.Action)}>
                        {v?.Action} 
                        <a className="mt-1" target='_blank'>
                            {v?.Count}
                        </a>
                        </p>
                    })
                }
                {__type === 'rejectedcount' && reportCountDetailsSuccess?.map(v => {
                    return v?.ErrorData && <p className={`${reportAction === v?.ErrorData ? 'bg-app-greenBorder text-black' : 'hover:text-app-greenBorder text-white'} rounded-lg text-sm cursor-pointer font-semibold max-w-[190px] flex flex-col justify-start border-1 border-gray-700 bg-app-border mb-1 mt-2 px-4 py-2`}
                        onClick={() => getReportDetails(v?.ErrorData)}>
                        {v?.ErrorData}: 
                        <a className="mt-1" target='_blank'>
                        {v?.Count}
                        </a></p>
                    })
                }
            </div>
        </div>
    )
}

export default EnrollmentReport