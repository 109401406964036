import React, { useEffect, useState } from "react";
import Selector from "../../components/common/selector/selector";
import ActionTypes from "../../store/actionTypes";
import { useCentralStore } from "../../store/appContext";
import { DatabaseFilled, EyeFilled, FileAddFilled } from "@ant-design/icons";
import { Button, Table } from "antd";
import { isEmpty, toPascalCase } from "../../utils/functionUtils";
import styles from "./iconStyle.module.css";
import API from "../../api";
import moment from "moment/moment";
import {exportJSONToCSV, formattedDate} from "../../utils/upload-utils"
import resources from "../../components/common/resources.json"
import Popup from "../../components/common/popup/popup"
import CrossIcon from "../../assets/icons/cross-icon"

const BillingData = () => {
  const [
    { reconciliationClient, selectedAccountingMonth, loading, userData, userDetails },
    dispatch,
  ] = useCentralStore();

  const [months, setMonths] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  const [firstPassData, setFirstPassData] = useState("");
  const [secondPassData, setSecondPassData] = useState();
  const [carrierBillingData, setCarrierBillingData] = useState("");
  const [clawbackData, setClawbackData] = useState();
  const [reconData, setReconData] = useState();
  const [reconFlag, setReconFlag] = useState("");
  const [reconSummaryDetails, setReconSummaryDetails] = useState([]);
  const [tableColumns, setTableColumns] = useState()
  const [isOpen, openPopup] = useState(false);
  const [secondPassClick, setSecondPassClick] = useState()
  let idToken = userData?.access_token;

  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    const today = new Date();
    let d;
    let month = [];
    for (let i = 15; i > 0; i -= 1) {
      d = new Date(today.getFullYear(), today.getMonth() - i, 1);
      month.unshift({
        name: `${monthNames[d.getMonth()]}-${d
          .getFullYear()
          .toString()
          .substr(-2)}`,
        value: `${monthNames[d.getMonth()]}-${d
          .getFullYear()
          .toString()
          .substr(-2)}`,
      });
      dispatch({
        type: ActionTypes.SELECTED_ACCOUNTING_MONTH,
        payload: selectedAccountingMonth ? selectedAccountingMonth : month[0]?.value,
      });
      setMonths(month);
    }
  }, []);

  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING, payload: action });
  };

  const setProjectName = (name) => {
    dispatch({ type: ActionTypes.SET_PROJECT_NAME, payload: name });
  };

  const handleChange = (name, value, placeholder) => {
    switch (placeholder) {
      case "selectedClient":
        dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: value });
        break;
      case "selectedMonth":
        dispatch({
          type: ActionTypes.SELECTED_ACCOUNTING_MONTH,
          payload: value,
        });
        break;
      default:
        return;
    }
  };

  const handleClick = (e, placeholder) => {
    switch (placeholder) {
      case "2ndPassClick":
        // openPopup(true)
        break;
      case "billingDataClick":
        let payload={
          selectedFileType: "Billing",
          selectedAccountingYear: selectedAccountingMonth,
          selectedAccountingMonth: selectedAccountingMonth,
          selectedAccountingClient: reconciliationClient
        }
        dispatch({ type: ActionTypes.UPLOAD_FILE_CLICK, payload: payload });
        setProjectName(resources.Billing_Revenue)
        break;
        case "clawbackDataClick":
          let clawbackPayload={
            selectedFileType: "Clawback",
            selectedAccountingYear: selectedAccountingMonth,
            selectedAccountingMonth: selectedAccountingMonth,
            selectedAccountingClient: reconciliationClient
          }
          dispatch({ type: ActionTypes.UPLOAD_FILE_CLICK, payload: clawbackPayload });
          setProjectName(resources.Billing_Revenue)
        break;
      case "recconDataClick":
        console.log("recconDataClick");
        !isEmpty(reconData)
          ? callReconcileSummaryDetailsApi()
          : startReconcileProcess();
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    selectedAccountingMonth && callReconcileSummaryApi(selectedAccountingMonth);
  }, [selectedAccountingMonth]);



  const callReconcileSummaryApi = (month) => {
    let payload = {
      GetM1BillingReconcileSummaryRequest: {
        Client: "M1",
        StartDate: month && formattedDate(month),
      },
    };

    setFirstPassData("");
    setSecondPassData("");
    setCarrierBillingData("");
    setClawbackData("");
    setReconData("");
    setReconSummaryDetails("");
    setReconFlag("");
    setLoading(true);
    setSecondPassClick("")

    API[ActionTypes.GET_M1_RECONCILE_SUMMARY](payload, idToken, userDetails && userDetails?.email?.split("@")?.[0])
      .then((data) => {
        setLoading(false);
        data?.data?.GetM1BillingSummaryReconcile?.map((val) => {
          if (val.Asurion_Billing_Matrix === "1_pass_count") {
            return setFirstPassData(val);
          } else if (val.Asurion_Billing_Matrix === "2_pass_count") {
            return setSecondPassData(val);
          } else if (val.Asurion_Billing_Matrix === "carrier_billing") {
            return setCarrierBillingData(val);
          } else if (val.Asurion_Billing_Matrix === "carrier_clawack") {
            return setClawbackData(val);
          } else if (val.Asurion_Billing_Matrix === "recon_report") {
            return setReconData(val);
          }
          return null;
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log("M1SummaryApi", error);
      });
  };

  const startReconcileProcess = () => {
    let payload = {
      GetM1BillingReconcileRequest: {
        Client: "M1",
        StartDate:
          selectedAccountingMonth && formattedDate(selectedAccountingMonth),
      },
    };
    setBtnLoader(true);
    API[ActionTypes.GET_M1_BILLING_RECONCILE_DATA](payload, idToken, userDetails && userDetails?.email?.split("@")?.[0])
      .then((data) => {
        setReconFlag(data?.data?.GetM1BillingReconcile);
        setBtnLoader(false);
      })
      .catch((error) => {
        setBtnLoader(false);
        console.log("ReconcilieProcess", error);
      });
  };

  const callReconcileSummaryDetailsApi = () => {
    let payload = {
      GetM1BillingReconcileSummaryDataRequest: {
        Client: "M1",
        StartDate:
          selectedAccountingMonth && formattedDate(selectedAccountingMonth),
      },
    };
    setBtnLoader(true);
    API[ActionTypes.GET_M1_BILLING_RECONCILE_SUMMARY_DATA_REQUEST](
      payload,
      idToken,
      userDetails && userDetails?.email?.split("@")?.[0]
    )
      .then((data) => {
        setReconSummaryDetails(data?.data?.GetM1BillingSummaryDataReconcile);
        if (data && data?.data?.GetM1BillingSummaryDataReconcile.length > 0) {
          exportJSONToCSV(data?.data?.GetM1BillingSummaryDataReconcile, selectedAccountingMonth);
        }
        setBtnLoader(false);
      })
      .catch((error) => {
        setBtnLoader(false);
        console.log("ReconcilieSummaryDetails", error);
      });
  };

  const handleSecondPassClick = () => {
    setLoading(true);
    let payload = {
      GetM1BillingReconcileRequest: {
        Client: "M1",
        StartDate:
          selectedAccountingMonth && formattedDate(selectedAccountingMonth),
          IsSecondPass: true
      },
    };
    API[ActionTypes.GET_M1_BILLING_RECONCILE_DATA](payload, idToken, userDetails && userDetails?.email?.split("@")?.[0])
      .then((data) => {
        openPopup(false)
        setLoading(false);
        setSecondPassClick(data)
      })
      .catch((error) => {
        setLoading(false);
        console.log("ReconcilieProcess", error);
      });
  }
  const isButtonDisable = () => {
    if (
      !isEmpty(firstPassData) &&
      !isEmpty(carrierBillingData) &&
      isEmpty(reconData)
    ) {
      return false;
    } else if (!isEmpty(reconData)) {
      return false;
    } else return true;
  };


  useEffect(() => {
    let colData =
      !isEmpty(reconSummaryDetails) &&
      Object.keys(reconSummaryDetails[0]).map((val) => {
        return {
          title: val,
          dataIndex: val,
          key: val,
          align: "center",
          width: 175,
        };
      });
   setTableColumns(colData)
  }, [reconSummaryDetails])
  
  return (
    <>
      <div className="flex justify-between w-full h-full mt-10">
        <div className="flex items-center justify-start px-4">
          <Selector
            key="Client Dropdown"
            options={[{ name: "M1", value: "M1" }]}
            label="Client"
            value={reconciliationClient}
            onSelect={(name, val) => handleChange(name, val, "selectedClient")}
            placeholder="Select Client"
          />
          <Selector
            key="Channel Dropdown"
            options={months}
            label="Accounting Month"
            value={selectedAccountingMonth}
            onSelect={(name, val) => handleChange(name, val, "selectedMonth")}
            placeholder="Select month"
          />
        </div>
        <div className="flex flex-row items-center">
            <div className="p-1 mr-2 text-sm font-bold text-center text-black bg-white rounded-full">
              {userDetails &&
                userDetails?.firstName?.split()?.[0]?.charAt(0)?.toUpperCase()}
              {userDetails &&
                userDetails?.lastName?.split()?.[0]?.charAt(0)?.toUpperCase()}
            </div>
            <span className="mr-5 font-bold">
              {userDetails && toPascalCase(userDetails?.firstName)}{" "}
              {userDetails && toPascalCase(userDetails?.lastName)}
            </span>
          </div>
      </div>
      <div className="mt-12">
        <table
          className="w-2/3 px-5 mx-auto"
          cellpadding="5"
          cellspacing="5"
          style={{ borderSpacing: "0px 10px", borderCollapse: "collapse" }}
          // style={{ borderSpacing: "0px 10px", borderCollapse: "collapse", border:"2px solid", borderColor:"#cb5aff" }}
        >
          <tr className="" style={{ borderBottom: "2px solid", borderColor:"#cb5aff"}}>
            <td></td>
            <td className="font-medium">Asurion Billing Data</td>
            <td className="font-medium">Loaded Date</td>
            <td className="font-medium">No of records</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr className="">
            <td className=""></td>
            <td className="">1st Pass-MEC Accrual </td>
            <td>
              {!isEmpty(firstPassData) &&
                moment(firstPassData?.Loaded_Date).utc().format("YYYY/MM/DD")}
            </td>
            <td>{!isEmpty(firstPassData) && firstPassData?.No_Of_Records}</td>
          </tr>
          <tr className="">
            <td className="flex justify-center">
              <Button
                icon={
                  <DatabaseFilled
                    style={{ fontSize: "30px" }}
                    theme="outlined"
                    className={!isEmpty(secondPassClick) || !isEmpty(secondPassData) || isEmpty(carrierBillingData) ? styles.anticon : ""}
                  />
                }
                type=""
                className={
                   !isEmpty(carrierBillingData) && isEmpty(secondPassData) && isEmpty(secondPassClick)
                    ? "transition duration-500 ease-in-out cursor-pointer delay-350 hover:-translate-y-0 hover:scale-125"
                    : ""
                }
                onClick={(e) => handleClick(e, "2ndPassClick")}
                disabled={!isEmpty(secondPassClick) || !isEmpty(secondPassData) || isEmpty(carrierBillingData)}
              />
            </td>
            <td>2nd Pass-Billing Reconciliation</td>
            <td>
              {!isEmpty(secondPassData) &&
                moment(secondPassData?.Loaded_Date).utc().format("YYYY/MM/DD")}
            </td>
            <td>{!isEmpty(secondPassData) && secondPassData?.No_Of_Records}</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr style={{ borderBottom: "2px solid", borderColor:"#cb5aff"}}>
            <td></td>
          </tr>
          <tr className="">
            <td></td>
            <td></td>
            <td className="font-medium">Loaded Date</td>
            <td className="font-medium">No of records</td>
          </tr>
          <tr className="">
            <td className="flex justify-center">
              <Button
                icon={
                  <FileAddFilled
                    style={{ fontSize: "30px" }}
                    theme="outlined"
                    className={
                      !isEmpty(reconData) ? styles.anticon : ""
                    }
                  />
                }
                onClick={(e) => handleClick(e, "billingDataClick")}
                type=""
                disabled={!isEmpty(reconData)}
                className={
                  isEmpty(reconData)
                    ? "transition duration-500 ease-in-out cursor-pointer delay-350 hover:-translate-y-0 hover:scale-125"
                    : ""
                }
              />
            </td>
            <td className="font-bold">Carrier Billing Data</td>
            <td>
              {!isEmpty(carrierBillingData) &&
                moment(carrierBillingData?.Loaded_Date)
                  .utc()
                  .format("YYYY/MM/DD")}
            </td>
            <td>
              {!isEmpty(carrierBillingData) &&
                carrierBillingData?.No_Of_Records}
            </td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr style={{ borderBottom: "2px solid", borderColor:"#cb5aff"}}>
            <td></td>
          </tr>
          <tr className="n">
            <td></td>
            <td></td>
            <td className="font-medium">Loaded Date</td>
            <td className="font-medium">No of records</td>
          </tr>
          <tr className="">
            <td className="flex justify-center">
              <Button
                icon={
                  <FileAddFilled
                    style={{ fontSize: "30px"}}
                    theme="outlined"
                    className={!isEmpty(reconData) ? styles.anticon : styles.anticon1}
                  />
                }
                onClick={(e) => handleClick(e, "clawbackDataClick")}
                className={
                  isEmpty(reconData)
                    ? "transition duration-500 ease-in-out cursor-pointer delay-350 hover:-translate-y-0 hover:scale-125"
                    : ""
                }
                type=""
                disabled={!isEmpty(reconData)}
              />
            </td>
            <td className="font-bold">Carrier Clawback Data</td>
            <td>
              {!isEmpty(clawbackData) &&
                moment(clawbackData?.Loaded_Date).utc().format("YYYY/MM/DD")}
            </td>
            <td>{!isEmpty(clawbackData) && clawbackData?.No_Of_Records}</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr style={{ borderBottom: "2px solid", borderColor:"#cb5aff"}}>
            <td></td>
          </tr>
          <tr className="">
            <td></td>
            <td></td>
            <td className="font-medium">Reconciliation Date</td>
            <td className="font-medium">No of records</td>
          </tr>
          <tr className="">
            <td className="flex justify-center">
              {reconFlag === "" && (
                <Button
                  icon={reconData ? <EyeFilled /> : ""}
                  onClick={(e) => handleClick(e, "recconDataClick")}
                  loading={btnLoader}
                  style={{ display: "inline-flex", alignItems: "baseline" }}
                  ghost
                  className={
                    isButtonDisable()
                      ? `${styles.disabledBtn2}`
                      : "transition duration-500 ease-in-out cursor-pointer delay-350 hover:-translate-y-0 hover:scale-110"
                  }
                  disabled={isButtonDisable()}
                >
                  {reconFlag === false
                    ? "Recon in Progress"
                    : reconData
                    ? "Recon Details"
                    : "Process Recon"}
                </Button>
              )}
            </td>
            <td className="font-bold">
              {reconFlag === true
                ? "Reconciliation Process Started" :
                reconFlag === false ?
                  "Reconciliation Process already in progress" :
                  reconData
                    ? "Reconciliation Report"
                    : "Start Reconciliation Process"}
            </td>
            <td>
              {" "}
              {!isEmpty(reconData) &&
                moment(reconData?.Loaded_Date).utc().format("YYYY/MM/DD")}
            </td>
            <td>{!isEmpty(reconData) && reconData?.No_Of_Records}</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td></td>
          </tr>
        </table>
       <div className="mx-5 mt-10">
       {reconSummaryDetails && <>
            <div>
              <a href="https://enterprise-confluence.asurion.com/display/FSCA/Reconciliation+Summary" rel="noreferrer" target="_blank" style={{textDecoration:"underline",color:"#cb5aff"}}>Click here</a> for definitions Of various flags in recon details.
            </div>
          <Table
            columns={tableColumns}
            dataSource={reconSummaryDetails || []}
            style={{ fontSize: "12px !important" }}
            rowKey="data"
            key="data"
            pagination={{
            position: ["topRight"],
            pageSizeOptions: ["10", "20", "30", "40", "50"],
            showSizeChanger: true,
            // size: "default",
          }}
            className="px-2 border-current border-white table-css hz-scrollbar"
            scroll={{
              y: "80vh",
            }}
          />
        </>}
       </div>
        <Popup
          show={isOpen}
          global={true}
          fitContent={true}
          definationsPopup={true}
          className={"w-1/2"}
        >
          <div className="p-4 pb-6 border-2 rounded-md border-app-purple" style={{ height: "auto !important" }}>
            <div className="flex justify-end">
              <CrossIcon className="cursor-pointer" onClick={() => openPopup(false)} />
            </div>
            <p className="px-4 my-2 font-semibold text-center text-app-purple">
            Are you sure, want to reconcile again ?
              <br />
              
            </p>
            <div className="flex mt-5 justify-evenly">
              <Button
               style={{
                  backgroundColor: "#cb5aff",
                  color: "white",
                }}
                onClick={() => handleSecondPassClick()}
                >
                Continue
              </Button>
              <Button onClick={() => openPopup(false)}
                style={{
                  backgroundColor: "#cb5aff",
                  color: "white",
                }}
              >Cancel
              </Button>
            </div>
          </div>
        </Popup>
      </div>
    </>
  );
};

export default BillingData;
