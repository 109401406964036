import { useState } from "react";
import { Table } from "antd";
import Popup from "./../../components/common/popup/popup";
import CrossIcon from "../../assets/icons/cross-icon";
import resources from "../../components/common/resources.json";
import DefinitionsData from "../../data/definitionsData.json";

const Definitions = ({ onClose, selectedTab }) => {
  const [isOpen, openPopup] = useState(false);
  const [currentHowText, setCurrentHowText] = useState(null);

  const getDashboardData = () => {
    switch (selectedTab) {
      case resources.BPM_DASHBOARD_LBL:
        return DefinitionsData["bpmAusDashboardData"];
      case resources.OPS_DASHBOARD_LBL:
        return DefinitionsData["opsAusDashboardData"];
      case resources.HOME_PLUS_BPM_DASHBOARD_LBL:
        return DefinitionsData["bpmHomePlusData"];
      case resources.HOME_PLUS_OPS_DASHBOARD_LBL:
        return DefinitionsData["bpmHomePlusData"];
      case resources.STARHUB_BPM_DASHBOARD_LBL:
      case resources.SINGTEL_BPM_DASHBOARD_LBL:
      case resources.M1_BPM_DASHBOARD_LBL:
      case resources.AIS_BPM_DASHBOARD_LBL:
      case resources.TRUE_BPM_DASHBOARD_LBL:
      case resources.ASURION_TECHCARE_BPM_DASHBOARD_LBL:
      case resources.CELECOM_BPM_DASHBOARD_LBL:
      case resources.FET_BPM_DASHBOARD_LBL:
        return DefinitionsData["SEABPMDefinitions"];
      case resources.STARHUB_OPS_DASHBOARD_LBL:
      case resources.SINGTEL_OPS_DASHBOARD_LBL:
      case resources.M1_OPS_DASHBOARD_LBL:
      case resources.AIS_OPS_DASHBOARD_LBL:
      case resources.TRUE_OPS_DASHBOARD_LBL:
      case resources.ASURION_TECHCARE_OPS_DASHBOARD_LBL:
      case resources.CELECOM_OPS_DASHBOARD_LBL:
      case resources.FET_OPS_DASHBOARD_LBL:
        return DefinitionsData["SEAOpsDefinitions"];
      case resources.HNDP_BPM_DASHBOARD_LBL:
      case resources.SCDP_BPM_DASHBOARD_LBL:
      case resources.TELSTRADP_BPM_DASHBOARD_LBL:
        return DefinitionsData["HnDpBpmDefinitios"]
      case resources.HNDP_OPS_DASHBOARD_LBL:
      case resources.SCDP_OPS_DASHBOARD_LBL:
      case resources.TELSTRADP_OPS_DASHBOARD_LBL:
        return DefinitionsData["HnDpOpsDefinitios"];

      default:
        return null;
    }
  };

  const data = getDashboardData();

  let tableColumns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 220,
      className: "!rounded-none",
    },
    // {
    //   title: "Description",
    //   dataIndex: "businessLogic",
    //   key: "businessLogic",
    //   width: 220,
    // },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 220,
    },
    // {
    //   title: 'Details',
    //   dataIndex: 'moreDetails',
    //   key: 'moreDetails',
    //   width: 120,
    //   render: (text, record) => (
    //     <a className="text-blue-300 underline" onClick={()=> (openPopup(true),setCurrentHowText(record?.how))}>
    //       {"more details"}
    //     </a>
    //    ),
    // }
  ];

  return (
    <div className="bg-app-lightWhite w-3/5 mx-auto rounded-xl">
      <CrossIcon
        className="float-right my-2 mr-5 cursor-pointer "
        fill="black"
        onClick={onClose}
      />
      <Table
        columns={tableColumns}
        dataSource={data}
        style={{ fontSize: "12px !important" }}
        rowKey="data"
        key="data"
        pagination={false}
        className="table-css border-current border-white hz-scrollbar pb-2"
        scroll={{
          y: "80vh",
        }}
      />

      <Popup
        show={isOpen}
        global={true}
        className=""
        fitContent={true}
        onClose={() => openPopup(false)}
        definationsPopup={true}
      >
        <div className="flex flex-col mt-2 px-4 pt-4 pb-8 relative">
          <CrossIcon
            className="mr-5 cursor-pointer absolute right-0"
            fill="black"
            onClick={() => openPopup(false)}
          />
          <p className="mt-5 text-base text-center w-3/5">{currentHowText}</p>
        </div>
      </Popup>
    </div>
  );
};

export default Definitions;
