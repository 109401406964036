import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { sleep } from '../../utils/functionUtils';
import sanitize from 'sanitize-html';

export const OtpInput = ({
  otpLength,
  label,
  // eslint-disable-next-line no-unused-vars
  value,
  onChange,
  disabled,
  isAlphaNumeric,
  marginClasses,
  fontClasses,
  alignmentClass,
  errorText,
  getotpvalue
}) => {
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(new Array(otpLength).fill(''));
  const inputsRef = useRef([...Array(otpLength)].map(() => React.createRef()));
  const setFocus = (index) => {
    inputsRef?.current[index]?.current?.focus();
    inputsRef?.current[index]?.current?.click();
  };
  const numberRegex = /^(\s*|\d+)$/;

  useEffect(() => {
    if (!value) {
      let otpArray = new Array(otpLength).fill('');
      setOTPValues(otpArray);
      inputsRef.current = [...Array(otpLength)].map(() => React.createRef());
    }
  }, [value]);

  const setValue = (value, index) => {
    if (value === '' || numberRegex.test(value)) {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[index] = value || '';
      setOTPValues(updatedOTPValues);
      getotpvalue(updatedOTPValues)
      onChange(updatedOTPValues);
    }
  };

  const onKeyPress = async (e) => {
    if (
      e.key !== 'Tab' &&
      otpValues[activeInput] &&
      otpValues[activeInput] === e.target.value &&
      numberRegex.test(e.key)
    ) {
      setFocus(activeInput + 1);
    } else if (e.keyCode === 8 && activeInput !== 0) {
      await sleep(10);
      setFocus(activeInput - 1);
    }
  };

  const onFocus = (e) => {
    setActiveInput(e.target.tabIndex - 1);
    if (otpValues[e.target.tabIndex - 1]) {
      e.target.select();
    }
  };

  return (
    <div className={`flex flex-col my-4 ${marginClasses} `}>
      <label className={`${alignmentClass} ${fontClasses}`}>{label}</label>
      <div className="flex justify-center mt-4">
        {otpValues.map((v, i) => {
          return (
            <div key={i} className="mr-2 last:mr-0">
              <input
                type="text"
                id={`input_${i}`}
                ref={inputsRef.current[i]}
                className={`w-14 h-14  text-center rounded border-[2px] focus:border-darkBlue ${
                  errorText ? 'border-error' : 'border-border outline-none'
                }`}
                value={v}
                readOnly={disabled}
                maxLength="1"
                tabIndex={i + 1}
                onChange={(e) => setValue(sanitize(e?.target?.value), i)}
                onFocus={onFocus}
                onKeyDown={onKeyPress}
                autoFocus={i === 0}
                inputMode={isAlphaNumeric ? 'text' : 'numeric'}
              />
            </div>
          );
        })}
      </div>
      {errorText && (
        <div className="py-2 text-sm text-error max-w-[17rem] mx-auto">{errorText}</div>
      )}
    </div>
  );
};

OtpInput.propTypes = {
  otpLength: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isAlphaNumeric: PropTypes.bool,
  marginClasses: PropTypes.string,
  fontClasses: PropTypes.string,
  alignmentClass: PropTypes.string,
  errorText: PropTypes.string,
  value: PropTypes.string
};

OtpInput.defaultProps = {
  otpLength: 4,
  label: '',
  onChange: () => {},
  disabled: false,
  isAlphaNumeric: false,
  marginClasses: '',
  fontClasses: '',
  alignmentClass: 'text-center',
  errorText: ''
};

export default OtpInput;
