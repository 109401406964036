import { useEffect, useState } from "react"
import ActionTypes from "../../../store/actionTypes"
import API from "../../../api"
import { useCentralStore } from "../../../store/appContext"
import resources from'../../../components/common/resources.json'
import SLADataTable from "../../../components/common/dataTable/SLADataTable"

const CsSLADataTab2 = () => {

    const [ { slaReportCountSuccess }, dispatch ] = useCentralStore()
    const Data = slaReportCountSuccess?.GetSLADetailsResponse?.CustomerServiceSLA
    
    const newData = Data.map(obj => {
        return Object.keys(obj)
          .filter(key => key !== 'ActualSLA%' && key !== 'ExpectedSLA%' && key !== 'Month')
          .reduce((acc, key) => {
            acc[key] = obj[key];
            return acc;
        }, {});
    });
      
    const setLoading = (action) => {
        dispatch({ type: ActionTypes.SET_LOADING, payload: action });
    };

    const [csSlaData, setCsSlaDta] = useState(null);

    useEffect(() => {
        if(slaReportCountSuccess) setCsSlaDta(newData)
    }, [slaReportCountSuccess])

    const [_label, setLabel] = useState(null);
    const [_index, setIndex] = useState(null);
    const [_metricsLabel, setMetricsLabel] = useState(null);

    return(
        <div className="flex text-black">
            
                    <SLADataTable
                        reportDetailsSuccess={csSlaData}
                        // reportDetailsSuccess={`${selectedTab === "Customer Service SLA Report" ? customerServiceSLA : selectedTab === "Technical Service SLA Report" ? technicalServiceSLA : ""}`}

                        // label={_label}
                        // value={getSubmetricsValue(_label, _index)}
                        // MetricsLabel={_metricsLabel}
                        // onClose={(e) => ( openPopup1(), setClassInOut("modal-container modal-space-out"), e.stopPropagation()
                        // )}
                        resources={resources}
                    />

        </div>
    )
}

export default CsSLADataTab2