import React, { useEffect, useState } from "react";
import { Table  } from "antd";
import CrossIcon from "../../../assets/icons/cross-icon";
import API from "../../../api";
import moment from "moment";
import ActionTypes from "../../../store/actionTypes";
import { useCentralStore } from "../../../store/appContext";
import Button from "../button/button";
import exportToCSVInventory from "../hepler/export-to-csv-submatricsData";
import Popup from "../popup/popup";
import Selector from "../selector/selector";
import resources from "../resources.json";
import { isEmpty } from "../../../utils/functionUtils";
import "./table.css"
import { exportcolumns, feedback, columns, status, rejectionReason } from "./constants"

const ReportTable = ({
  reportDetailsSuccess
}) => {
  const [
    {
      userData,
      userDetails,
      GetAIPerformanceReportSuccess,
      AIReportFilterDuration,
      selectedAIReportProgramType,
      selectedAIReportClient
    },
    dispatch,
  ] = useCentralStore();
  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
  };

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [selectedAIFeedBack, setSelectedAIFeedBack] = useState("");
  const [selectedWorkqueueStatus, setSelectedWorkqueueStatus] = useState("");
  const [selectedRejectionReasonCode, setSelectedRejectionReasonCode] = useState("");
  const [recoreMoreThanThousand, setRecoreMoreThanThousand] = useState(false);
  const [currentSearchedData, setCurrentSearchedData] = useState(undefined);
  
  let tableColumns = [];
  const totalKeys = {};

  reportDetailsSuccess?.reduce((acc, current) => {
    const keys = Object.keys(current);
    for (var i in keys) {
      totalKeys[keys[i]] = 1;
    }
  }, totalKeys);

  var keys = Object.keys(totalKeys);
  for (var i in keys) {
    tableColumns.push({
      title: keys[i]?.replace(/([a-z0-9])([A-Z])/g, "$1 $2")?.toUpperCase(),
      dataIndex: keys[i],
      key: keys[i],
      width: 280
    });
  }

  const exportData = async () => {
    
    let startDate = AIReportFilterDuration?.startDate ? AIReportFilterDuration?.startDate : moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD 00:00:00");
    let endDate = AIReportFilterDuration?.endDate ? AIReportFilterDuration?.endDate : moment(new Date()).format("YYYY-MM-DD 23:59:59");
    let exportStartDate = AIReportFilterDuration?.startDate ? AIReportFilterDuration?.startDate : moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD");
    let exportEndDate = AIReportFilterDuration?.endDate ? AIReportFilterDuration?.endDate : moment(new Date()).format("YYYY-MM-DD");
    let payload = {
        "ClientName": selectedAIReportClient,
        "StartDate": startDate,
        "EndDate": endDate,
        "WorkItemStatus": selectedWorkqueueStatus ? (selectedWorkqueueStatus === "CLOSED" ? "CLSD" : selectedWorkqueueStatus === "IN-PROGRESS" ? "IPGRS" : selectedWorkqueueStatus ) : "",
        "ResponseStatus": selectedAIFeedBack ?? "",
        "RejectionType": selectedRejectionReasonCode ?? "",
        "ProgramType": selectedAIReportProgramType
    }
   if(selectedAIReportClient && selectedAIReportProgramType)
   {
    setLoading(true)
    let columns = selectedAIReportProgramType === 'DP' ? exportcolumns?.filter((item)=> item?.key !== 'TradeQuoteNumber') : exportcolumns?.filter((item)=> item?.key !== 'MobileDeviceNbr')
    let report_title =`${selectedAIReportClient}_${selectedAIReportProgramType}_AI_Report_${moment(exportStartDate).format("YYYY-MM-DD")}_${moment(exportEndDate).format("YYYY-MM-DD")}`
    API[ActionTypes.GET_AI_PERFORMANCE_REPORT](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
    .then(({ data }) => {
        setLoading(false);
          if (data) {
            exportToCSVInventory(
              columns,
              data,
              "",
              "",
              report_title,
              dispatch,
              ActionTypes,
              true
            )
    
          }
      })
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: ActionTypes.GET_AI_PERFORMANCE_REPORT_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
    }
  }

  const handleTableChange = (filters, extra) => {

    let isFilter = false;
    for (let objKey in filters) {
      setCurrentSearchedData(extra?.currentDataSource);

      if (filters[objKey] && filters[objKey].length) {
        isFilter = true;
        const totalCount = currentSearchedData?.filter(
          (element) => element.objKey === filters[objKey][0]
        ).length;
        setPagination({
          ...pagination,
          current: 1,
          pageSize: pagination.pageSize,
          total: totalCount,
        });
      }
    }
    if (isFilter === false) {
      setCurrentSearchedData(undefined);
      setPagination({
        ...pagination,
        current: Number(pagination.current),
        pageSize: Number(pagination.pageSize),
        total: currentSearchedData?.length,
      });
    }
  };


 
  useEffect(()=>{
      let startDate = AIReportFilterDuration?.startDate ? AIReportFilterDuration?.startDate : moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD 00:00:00");
      let endDate = AIReportFilterDuration?.endDate ? AIReportFilterDuration?.endDate : moment(new Date()).format("YYYY-MM-DD 23:59:59");
      let payload = {
          "ClientName": selectedAIReportClient,
          "StartDate": startDate,
          "EndDate": endDate,
          "WorkItemStatus": selectedWorkqueueStatus ? (selectedWorkqueueStatus === "CLOSED" ? "CLSD" : selectedWorkqueueStatus === "IN-PROGRESS" ? "IPGRS" : selectedWorkqueueStatus ) : "",
          "ResponseStatus": selectedAIFeedBack ?? "",
          "RejectionType": selectedRejectionReasonCode ?? "",
          "ProgramType": selectedAIReportProgramType,
          "isSummary": true
      }
    
      
     if(AIReportFilterDuration ||  selectedWorkqueueStatus || selectedAIFeedBack || selectedRejectionReasonCode && ((selectedAIReportClient && selectedAIReportProgramType)) )
     {
      setLoading(true)
      API[ActionTypes.GET_AI_PERFORMANCE_REPORT](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
          setLoading(false);
          if (data) {
            dispatch({
              type: ActionTypes.GET_AI_PERFORMANCE_REPORT_SUCCESS,
              payload: data,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          dispatch({
            type: ActionTypes.GET_AI_PERFORMANCE_REPORT_FAILURE,
            payload: err,
          });
          dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
          let _payload = {
            level: resources.POST_LOGGER_ERROR,
            category: resources.POST_LOGGER_API,
            ApiName: resources.POST_LOGGER_APINAME,
            message: {
              error: err,
              info: {
                idToken: "",
              },
            },
          };
          API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
        });
      }
  }, [AIReportFilterDuration, selectedWorkqueueStatus, selectedAIFeedBack, selectedRejectionReasonCode, selectedAIReportClient])


  const handleFeedBackSelect=(value)=>{
    setSelectedAIFeedBack(value)
  }

  const handleWorkqueueStatusSelect=(value)=>{
    setSelectedWorkqueueStatus(value)
  }

  const handleRejectionReasonCode=(value)=>{
    setSelectedRejectionReasonCode(value)
  }
 
  const finalData  = GetAIPerformanceReportSuccess?.data?.map((item, index)=>{
    return{...item,"key":index}})
  const filteredColumns = selectedAIReportProgramType === 'DP' ? columns?.filter((item)=> item?.key !== 'TradeQuoteNumber') : columns?.filter((item)=> item?.key !== 'MobileDeviceNbr')

  return (
    <div className="py-3">
     {GetAIPerformanceReportSuccess?.summary &&   
      <div className="flex flex-row justify-start ml-4 my-2">
      <div className="flex flex-row gap-2 my-4 border-card-greenBorder shadow-card-greenBorder border-3px mr-8 px-4 h-1/2 rounded-lg"> 
        <div className={`flex flex-col text-left px-2 justify-center cursor-pointer transition ease-in-out delay-350 w-[168px] hover:bg-white duration-500 bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 rounded-lg my-4 h-24 mr-2`}>Total Engagements
          <div className="text-black pt-3 hover:font-bold text-xl text-left ">{GetAIPerformanceReportSuccess?.summary?.totalEngagements || 0}</div>
        </div>
        <div className={`flex flex-col text-left px-2 justify-center cursor-pointer transition ease-in-out w-[168px] delay-350 hover:bg-white duration-500 bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 rounded-lg my-4 h-24 mr-2`}>AI Response
          <div className="text-black pt-3 hover:font-bold text-xl text-left ">{GetAIPerformanceReportSuccess?.summary?.aiResponse || 0} ({GetAIPerformanceReportSuccess?.percentageSummary?.aiResponse && GetAIPerformanceReportSuccess?.percentageSummary?.aiResponse !== 'NaN' ? GetAIPerformanceReportSuccess?.percentageSummary?.aiResponse  : 0}%)</div>
        </div>
        <div className={`flex flex-col text-left px-2 justify-center cursor-pointer transition ease-in-out w-[168px] delay-350 hover:bg-white duration-500 bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 rounded-lg my-4 h-24 mr-2`}>No Response 
          <div className="text-black pt-3 hover:font-bold text-xl text-left ">{GetAIPerformanceReportSuccess?.summary?.noResponse || 0} ({GetAIPerformanceReportSuccess?.percentageSummary?.noResponse && GetAIPerformanceReportSuccess?.percentageSummary?.noResponse !== 'NaN' ? GetAIPerformanceReportSuccess?.percentageSummary?.noResponse : 0}%)</div>
        </div>
        <div className={`flex flex-col text-left px-2 justify-center cursor-pointer transition ease-in-out w-[168px] delay-350 hover:bg-white duration-500 bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 rounded-lg my-4 h-24 mr-2`}>Approved/Edited
          <div className="text-black pt-3 hover:font-bold text-xl text-left ">{GetAIPerformanceReportSuccess?.summary?.approvedResponse || 0} ({GetAIPerformanceReportSuccess?.percentageSummary?.approvedResponse && GetAIPerformanceReportSuccess?.percentageSummary?.approvedResponse !== 'NaN'? GetAIPerformanceReportSuccess?.percentageSummary?.approvedResponse : 0}%)</div>
        </div>
        <div className={`flex flex-col text-left px-2 justify-center cursor-pointer transition ease-in-out w-[168px] delay-350 hover:bg-white duration-500 bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 rounded-lg my-4 h-24`}>Rejected
          <div className="text-black pt-3 hover:font-bold text-xl text-left ">{GetAIPerformanceReportSuccess?.summary?.rejectedResponse || 0} ({GetAIPerformanceReportSuccess?.percentageSummary?.rejectedResponse && GetAIPerformanceReportSuccess?.percentageSummary?.rejectedResponse !== 'NaN' ? GetAIPerformanceReportSuccess?.percentageSummary?.rejectedResponse : 0}%)</div>
        </div>
        <div className={`flex flex-col text-left px-2 justify-center cursor-pointer transition ease-in-out w-[168px] delay-350 hover:bg-white duration-500 bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 rounded-lg my-4 h-24`}>Pending Feedback
          <div className="text-black pt-3 hover:font-bold text-xl text-left">{GetAIPerformanceReportSuccess?.summary?.openPendingAIFeedback || 0} ({GetAIPerformanceReportSuccess?.percentageSummary?.openPendingAIFeedback && GetAIPerformanceReportSuccess?.percentageSummary?.openPendingAIFeedback !== 'NaN' ? GetAIPerformanceReportSuccess?.percentageSummary?.openPendingAIFeedback : 0}%)</div>
        </div>
        <div className={`flex flex-col text-left px-2 justify-center cursor-pointer transition ease-in-out w-[204px] delay-350 hover:bg-white duration-500 bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 rounded-lg my-4 h-24`}><p>Closed Without Feedback</p>
          <div className="text-black pt-3 hover:font-bold text-xl text-left">{GetAIPerformanceReportSuccess?.summary?.closedWithoutAIFeedback || 0} ({GetAIPerformanceReportSuccess?.percentageSummary?.closedWithoutAIFeedback && GetAIPerformanceReportSuccess?.percentageSummary?.closedWithoutAIFeedback !== 'NaN' ? GetAIPerformanceReportSuccess?.percentageSummary?.closedWithoutAIFeedback : 0}%)</div>
        </div>
       
      </div>
    </div>
      }
      <div style={{
        display:'flex',
        margin: "16px 16px 16px"
      }}>
        <Selector
              key="AI FeedBack"
              options={feedback}
              label="AI FeedBack"
              value={selectedAIFeedBack}
              onSelect={(name, val) => handleFeedBackSelect(name, val)}
              placeholder="AI FeedBack"
              isAIReport={true}
        />
        <Selector
              key="Workqueue Status"
              options={status}
              label="Workqueue Status"
              value={selectedWorkqueueStatus}
              onSelect={(name, val) => handleWorkqueueStatusSelect(name, val)}
              placeholder="Workqueue Status"
              isAIReport={true}
        />
        <Selector
              key="Rejection Reason Code"
              options={rejectionReason}
              label="Rejection Reason Code"
              value={selectedRejectionReasonCode}
              onSelect={(name, val) => handleRejectionReasonCode(name, val)}
              placeholder="Rejection Reason Code"
              isAIReport={true}
        />
         <Button
              label={'Clear Filters'}
              className={`text-sm px-5 py-1 rounded-3xl ${isEmpty(selectedRejectionReasonCode) && isEmpty(selectedAIFeedBack) && isEmpty(selectedWorkqueueStatus) ? 'bg-gray-300 text-gray-400 cursor-not-allowed' : "!bg-[#1ED760]"} text-black hover:text-slate-500`}
              onClick={() => {
                setSelectedAIFeedBack("");
                setSelectedRejectionReasonCode("");
                setSelectedWorkqueueStatus("")
              }}
            />
             {GetAIPerformanceReportSuccess?.data && GetAIPerformanceReportSuccess?.data?.length > 0 && selectedAIReportClient && selectedAIReportProgramType && (
            <Button
              label={'Export All'}
              className="text-sm px-5 py-2 ml-4 rounded-3xl !bg-[#1ED760] text-black hover:text-slate-500"
              onClick={() => exportData()}
            />
          )}
      </div>
      <Table
        id="aireporttable"
        columns={filteredColumns}
        dataSource={finalData}
        onChange={handleTableChange}
        style={{ fontSize: "10px !important", margin: "0px 16px" }}
        rowkey={(record) => record?.key}
        scroll={{
          y: "65vh"
          }}
        expandable={{
          expandedRowRender: (record) => (
            <div className="text-sm" style={{marginLeft: '32PX'}}>
               <p
              style={{
                marginBottom: '4px'
              }}
            >
             <span className="font-bold">Customer Inquiry:</span> <span >{record?.CustomerInquiry}</span>
            </p>
            <p
              style={{
                marginBottom: '4px'
              }}
            >
             <span className="font-bold">AI Summary:</span> {record?.AISummary}
            </p>
            <p  style={{
                marginBottom: '4px'
              }}>
            <span className="font-bold">AI Response:</span>  {record?.AIResponse}
            </p>
            <p  style={{
                marginBottom: '4px'
              }}>
            <span className="font-bold">Agent Response:</span>  {record?.AgentResponse}
            </p >
            {record?.ResponseStatus?.toUpperCase() === "REJECTED" && <p  style={{
                marginBottom: '4px'
              }}>
            <span className="font-bold">Rejection Reason Code:</span>  {record?.RejectionType}
            </p>}
            {record?.ResponseStatus?.toUpperCase() === "REJECTED" &&<p>
            <span className="font-bold">Rejection Comments:</span>  {record?.AgentComments}
            </p>}
            </div>
          ),
          rowExpandable: (record)=> record["AIResponse"],
          expandRowByClick: true
        }}
        className="aireporttable table-css border-current border-white"
        locale={{ emptyText: "No data" }}
        pagination={{
          position: ["bottomRight"],
          pageSizeOptions: ["10", "20", "30", "40", "50","100"],
          showSizeChanger: true,
          size: "default"
        }}        
      />
    
      {recoreMoreThanThousand && (
            <>
              <p className="text-card-greenBorder">
                {resources.EMAIL_SEND_MSG}
              </p>
              <Popup
                show={recoreMoreThanThousand}
                global={true}
                fitContent={true}
                emailPopup={true}
              >
                <div className="p-4 pb-6 rounded-xl" style={{height: "auto !important"}}>
                  <div className="flex justify-end">
                    <CrossIcon className="cursor-pointer" onClick={() => setRecoreMoreThanThousand(false)} />
                  </div>
                  <p className="text-black font-semibold inline-block mt-2 px-4">
                    <p className="text-sm text-center">Total records downloaded: 1000</p>
                  </p>
                </div>
              </Popup>
            </>
          )}
    </div>
  );
};

export default ReportTable;
