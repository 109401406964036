import React, { useState, useEffect } from "react";
import IconClaim from "../../assets/transaction/claim";
import IconCustomerSolutions from "../../assets/transaction/customerSolutions";
import IconEnrollment from "../../assets/transaction/enrollment";
import IconFinance from "../../assets/transaction/finance";
import IconSupplyChain from "../../assets/transaction/supplychain";
import ActionTypes from "../../store/actionTypes";
import { useCentralStore } from "../../store/appContext";
import { isEmpty } from "../../utils/functionUtils";
import ReportFinance from './reportFinance'

export default function ReportMenu() {
  const [{ selectedCategory, DataSourceList }, dispatch] = useCentralStore();

  const handleClick = (category) => {
    dispatch({
      type: ActionTypes.SET_CATEGORY,
      payload: category,
    });
  };

  let [over,setOver]= useState(false);
  let [category, setCategory] = useState();
  let fill =  over ? '#FFFFFF' : '#CB5AFF'

  return (
    <>
    {isEmpty(selectedCategory) ?
      <div className="w-full pb-10 flex flex-col justify-center ">
      <div className=" flex mt-5 ">
        <p className="text-4xl leading-54 md:leading-5 mt-2 font-light mx-auto">
          <b>Select from the options below</b>
        </p>
      </div>

      <div className=" mt-16 center">
        <div className="flex flex-row justify-between items-center">
          <div onMouseOver={()=>(setOver(true), setCategory('Claims'))} onMouseOut={()=>setOver(false)}
            className={` w-48 flex flex-col justify-center items-center  border py-4  rounded-2xl  px-8 mr-7 cursor-pointer  
      transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125  duration-500 reportMenu`}
            onClick={() => handleClick("Claims")}
          >
            <p className={` text-center my-4 text-xl`}>Claim</p>
            <IconClaim className="mx-auto" fill={category === "Claims" ? fill : "#CB5AFF"} />
          </div>{" "}
          <div onMouseOver={()=>(setOver(true), setCategory('CS'))} onMouseOut={()=>setOver(false)}
            className={` w-52 flex flex-col justify-center items-center  border py-4  rounded-2xl  mr-7 cursor-pointer  
      transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125  duration-500 reportMenu`}
            onClick={() => handleClick("CS")}
          >
            <p className={`text-center my-4 text-xl`}>Customer Solutions</p>
            <IconCustomerSolutions className="mx-auto" fill={category === "CS" ? fill : "#CB5AFF"} />
          </div>{" "}
          <div onMouseOver={()=>(setOver(true), setCategory('Enrollment'))} onMouseOut={()=>setOver(false)}
            className={` w-52 flex flex-col justify-center items-center  border py-4  rounded-2xl  px-8 mr-7 cursor-pointer  
      transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125  duration-500 reportMenu`}
            onClick={() => handleClick("Enrollment")}
          >
            <div className={`text-center my-4 text-xl hover:text-purple-700`}>
              Enrollment
            </div>
            <IconEnrollment className=" mx-auto" fill={category === "Enrollment" ? fill : "#CB5AFF"} />
          </div>{" "}
          <div onMouseOver={()=>(setOver(true), setCategory('Finance'))} onMouseOut={()=>setOver(false)}
            className={` w-52 flex flex-col justify-center items-center  border py-4  rounded-2xl  px-8 mr-7 cursor-pointer  
      transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125  duration-500 reportMenu`}
            onClick={() => handleClick("Finance")}
          >
            <p className={` text-center my-4 text-xl`}>Finance</p>
            <IconFinance className=" mx-auto" fill={category === "Finance" ? fill : "#CB5AFF"} />
          </div>{" "}
          <div onMouseOver={()=>(setOver(true), setCategory('SC'))} onMouseOut={()=>setOver(false)}
            className={` w-52 flex flex-col justify-center items-center  border py-4  rounded-2xl  px-8 mr-7 cursor-pointer  
      transition ease-in-out delay-3  50 hover:-translate-y-0 hover:scale-125  duration-500 reportMenu`}
            onClick={() => handleClick("SCM")}
          >
            <p className={`text-center my-4 text-xl`}>Supply Chain</p>
            <IconSupplyChain className=" mx-auto" fill={category === "SC" ? fill : "#CB5AFF"} />
          </div>{" "}
        </div>
      </div>
    </div>
    :
    <>
      {/* <div className='flex flex-row relative left-25%'>
          <p className={`mr-2 p-2 border border-app-lightBlue1 rounded-lg ${selectedCategory === 'Finance' ? 'text-white bg-app-lightBlue' : 'text-app-lightBlue1'} `}>Finance</p>
          <p className={`mr-2 p-2 border border-app-lightBlue1 rounded-lg ${selectedCategory === 'Enrollments' ? 'text-white bg-app-lightBlue' : 'text-app-lightBlue1'}`}>Enrollments</p>
          <p className={`mr-2 p-2 border border-app-lightBlue1 rounded-lg ${selectedCategory === 'Claim' ? 'text-white bg-app-lightBlue' : 'text-app-lightBlue1'}`}>Claims</p>
          <p className={`mr-2 p-2 border border-app-lightBlue1 rounded-lg ${selectedCategory === 'Customer solutions' ? 'text-white bg-app-lightBlue' : 'text-app-lightBlue1'}`}>Customer Solutions</p>
          <p className={`mr-2 p-2 border border-app-lightBlue1 rounded-lg ${selectedCategory === 'Supply chain' ? 'text-white bg-app-lightBlue' : 'text-app-lightBlue1'}`}>Supply Chain</p>
      </div> */}
      <ReportFinance DataSourceList={DataSourceList}/>
      </>
    }
    </>
  );
}
