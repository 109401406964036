import { Table } from "antd";
import DefinitionsData from '../../data/definitionsData.json'

const GlossaryReport = ({ onClose, selectedTab, projectName }) => {

    const data = DefinitionsData['StarhubGlossaryData']
    const tsData = DefinitionsData['StarhubTsData']
    const financeData = DefinitionsData['FinanceData']
    const finalData = (projectName === "Technical Service SLA Report" && selectedTab === "Glossary") ? tsData : (projectName === "Finance Reconciliation & Invoice" && selectedTab === "Glossary") ? financeData : data

    let tableColumns = [
        {
          title: "Report/Metric",
          dataIndex: "report/metric",
          key: "report/metric",
          width: 220,
          className: "!rounded-none",
        },
        {
          title: 'Definition',
          dataIndex: 'definition',
          key: 'definition',
          width: 220
        },
    ];

    // let tableColumnsTechSLA = [
    //     {
    //       title: "Report/Metric",
    //       dataIndex: "report/metric",
    //       key: "report/metric",
    //       width: 220,
    //       className: "!rounded-none",
    //     },
    //     {
    //       title: 'Definition',
    //       dataIndex: 'definition',
    //       key: 'definition',
    //       width: 220
    //     },
    // ];

      const tableColumnsTechSLA = [
        {
          title: 'Category',
          width: 220,
          dataIndex: 'category',
          onCell: (_, index) => {
            if (index === 1) {
              return {
                rowSpan: 6,
              };
            }
            if (index === 7) {
                return {
                  rowSpan: 4,
                };
              }
              if (index === 2 || index === 3 || index === 4 || index === 5 || index === 6 || index === 8 || index === 9 || index === 10) {
                return {
                  rowSpan: 0,
                };
              }
            return {};
          },
        },
        {
          title: 'Description',
          dataIndex: 'description',
          width: 280
        },
        {
          title: 'Technical SLA',
          dataIndex: 'technicalSLA',
          width: 220
        },
        {
            title: 'Minimum Service Performance',
            dataIndex: 'minimumServicePerformance',
            width: 220
        },
      ];

    return (
        <div className={`bg-app-lightWhite ${(projectName === "Technical Service SLA Report" && selectedTab === "Glossary") ? 'w-3/4' : 'w-3/5'} mx-auto rounded-xl`}>
                <Table
                    columns={(projectName === "Technical Service SLA Report" && selectedTab === "Glossary") ? tableColumnsTechSLA : tableColumns}
                    dataSource={finalData}
                    style={{ fontSize: "12px !important" }}
                    rowKey="data"
                    key="data"
                    pagination={false}
                    className="table-css border-current border-white hz-scrollbar pb-2 mt-5"
                    // scroll={{
                    //     y: "80vh",
                    // }}
                />
        </div>
    );
};

export default GlossaryReport;
