import React, { useState, useEffect, useRef } from "react";
import ActionTypes from "../../store/actionTypes";
import { useCentralStore } from "../../store/appContext";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/icons/OneOplogo.svg";
import API from "../../api";
import resources from "../../components/common/resources.json";
import moment from "moment";
import Spinner from "../../components/common/spinner/spinner";
// import InquiryTradeIn from "./InquiryDeviceCare";
import InquiryTradeIn from "./InquiryTradeIn";
import InquiryDeviceCare from "./InquiryDeviceCare";
import config from "../../config/config";
import BPMIcon from "../../assets/homeImg/BPM_small_v2";
import Transactional_ReportingIcon from "../../assets/homeImg/Transactional_Reporting_small_v2";
import Application_Health_MonitoringIcon from "../../assets/homeImg/Application_Health_Monitoring_small_v2";
import { toPascalCase } from "../../utils/functionUtils";
import AIReport from "../AIReport/AITradeIn"
import InquiryReportOption from  "./inquiryReportOption"

export default function InquiryMenu({  }) {
  const [{ selectedCategory, RoleData, DataSourceList, aiGraphResponseInquirySuccess, aiResponseInquirySuccess, userData, loading, projectName, userDetails, isTradeDashboardOpen, projectDat, projectDataError, programName, projectData, selectedTab, ssReportFilterDuration }, dispatch] = useCentralStore();
  let [category, setCategory] = useState(null);
  let [over,setOver]= useState(false);
  const navigate = useNavigate();
  const refBPM = useRef();
  const refTransactional = useRef();
  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING, payload: action });
  };
  const escoUserFilter = RoleData.filter(item => item === "ESCO_USER");
  const gotoDashBoard = (name) => {
    let startDate = ssReportFilterDuration?.startDate ? ssReportFilterDuration?.startDate : moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD 00:00:00");
    let endDate = ssReportFilterDuration?.endDate ? ssReportFilterDuration?.endDate : moment(new Date()).format("YYYY-MM-DD 23:59:59");

    let payload = {
      StartDate: startDate,
      EndDate: endDate,
      ClientName: "Samsung",
    };
    dispatch({
      type: ActionTypes.SET_START_DATE,
      payload: startDate
    })      ;
    dispatch({
      type: ActionTypes.SET_END_DATE,
      payload: endDate
    })
    setLoading(true);
    API[ActionTypes.GET_INQUIRY_DATA](
      payload,
      userData?.access_token,
      userDetails && userDetails?.email?.split("@")?.[0]
    )
    .then((data) => {
      // setLoading(false);
      dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "Trade-In" })
      dispatch({
        type: ActionTypes.GET_INQUIRY_DATA_SUCCESS,
        payload: data,
      });
      API[ActionTypes.GET_INQUIRY_AI_RESPONSE](
        payload,
        userData?.access_token,
        userDetails && userDetails?.email?.split("@")?.[0]
      )
      .then((data) => {
        // setLoading(false);
        dispatch({
          type: ActionTypes.GET_INQUIRY_AI_RESPONSE_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.GET_INQUIRY_AI_RESPONSE_FAILURE,
          payload: err,
        });
      })
      API[ActionTypes.GET_INQUIRY_AI_GRAPH_RESPONSE](
        payload,
        userData?.access_token,
        userDetails && userDetails?.email?.split("@")?.[0]
      )
      .then((data) => {
        setLoading(false);
        dispatch({
          type: ActionTypes.GET_INQUIRY_AI_GRAPH_RESPONSE_SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.GET_INQUIRY_AI_GRAPH_RESPONSE_FAILURE,
          payload: err,
        });
      })
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.GET_INQUIRY_DATA_FAILURE,
        payload: err,
      });
      dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
      let _payload = {
        level: resources.POST_LOGGER_ERROR,
        category: resources.POST_LOGGER_API,
        ApiName: resources.POST_LOGGER_APINAME,
        message: {
          error: err,
          info: {
          idToken: "",
          },
        },
      };
      API[ActionTypes.POST_LOGGER](
        _payload,
        userData?.access_token,
        userDetails && userDetails?.email?.split("@")?.[0]
      );
    });
  }

  const gotoDashBoardTwo = (name) => {
    let startDate = moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD 00:00:00')
    let endDate = moment(new Date()).format('YYYY-MM-DD 23:59:59')

    let payload = {
      StartDate: startDate,
      EndDate: endDate,
      ClientName: "Samsung",
    };
    setLoading(true);
    API[ActionTypes.GET_INQUIRY_DATA](
      payload,
      userData?.access_token,
      userDetails && userDetails?.email?.split("@")?.[0]
    )
    .then((data) => {
      setLoading(false);
      dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "Device Care" })
      dispatch({
        type: ActionTypes.GET_INQUIRY_DATA_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.GET_INQUIRY_DATA_FAILURE,
        payload: err,
      });
      dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
      let _payload = {
        level: resources.POST_LOGGER_ERROR,
        category: resources.POST_LOGGER_API,
        ApiName: resources.POST_LOGGER_APINAME,
        message: {
          error: err,
          info: {
          idToken: "",
          },
        },
      };
      API[ActionTypes.POST_LOGGER](
        _payload,
        userData?.access_token,
        userDetails && userDetails?.email?.split("@")?.[0]
      );
    });
  }

  const expandDashboardOption = (payload) => {
    dispatch({
      type: ActionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS,
      payload: payload,
    });
  };

  const goToHomePage = () => {
    let payload = {
      idToken: userData?.access_token,
    };
    setLoading(true);
    dispatch({
      type: ActionTypes.GET_DASHBOARD_DETAILS_DATA_SUCCESS,
      payload: false,
    });
    API[ActionTypes.GET_ROLE_BASES_MATRICS](payload, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          dispatch({ type: ActionTypes.SELECTED_STORE, payload: "all" });
          dispatch({
            type: ActionTypes.SELECTED_DASHBOARD_DATE,
            payload: {
              name: resources.LAST_90_DAYS_VALUE,
              value: resources.LAST_90_DAYS_VALUE,
            },
          });
          dispatch({
            type: ActionTypes.GET_ROLE_BASES_MATRICS_SUCCESS,
            payload: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.GET_ROLE_BASES_MATRICS_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
  };

  const goToTransaction = () =>{
    let payload = {
      idToken: userData?.access_token,
    };
    setLoading(true);
    API[ActionTypes.GET_TRANSACTION_REPORTS](payload, userDetails && userDetails?.email?.split("@")?.[0])
    .then(({ data }) => {
      setLoading(false);
      if (data) {
        dispatch({
          type: ActionTypes.GET_TRANSACTION_REPORTS_SUCCESS,
          payload: data?.TransactionalReportsResponse?.DataSourceList,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.GET_TRANSACTION_REPORTS_FAILURE,
        payload: err,
      });
      dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
      let _payload = {
        level: resources.POST_LOGGER_ERROR,
        category: resources.POST_LOGGER_API,
        ApiName: resources.POST_LOGGER_APINAME,
        message: {
          error: err,
          info: {
            idToken: "",
          },
        },
      };
      API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
    });
  }

  const handleBPMClick = (isSGP) => {
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: isSGP === 'true' ? null : resources.BPM_PROJECT_NAME,
    });
    dispatch({ type: ActionTypes.SET_CATEGORY, payload: null });
    dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: isSGP === 'true' ? null : "MAP" });
    dispatch({ type: ActionTypes.SET_PROGRAM_NAME, data: "" });
    dispatch({
      type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
      payload: null,
    });
    dispatch({ type: ActionTypes.SET_MAP_SELECTED, data: null });
    expandDashboardOption(undefined);
    dispatch({
      type: ActionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS,
      payload: false,
    });
    dispatch({
      type: ActionTypes.EXPAND_TRANSACTIONAL_OPTIONS,
      payload: false,
    });
    dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: null });
    dispatch({ type: ActionTypes.GO_TO_MAPS_PAGE, payload: isSGP === 'true' ? null : true });
    dispatch({ type: ActionTypes.SET_SELECTED_DASHBOARD, payload: undefined })
    dispatch({ type: ActionTypes.SGP_SET_REPORT_NAME, payload: {
      reportLabel: null,
      reportValue: null
    }})
    dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION, payload: {
      type: null,
      startDate: null,
      endDate: null,
      duration: null
    }})
    
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS, payload: null})
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS_SUCCESS, payload: null})
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS, payload: null})

    dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER, payload: 'real time'})
    goToHomePage();
  };

  const handleTransactionalReporting = (isSGP) => {
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: isSGP === 'true' ? null : resources.TRANSACTIONAL_REPORTING_PROJECT_NAME,
    });
    dispatch({ type: ActionTypes.SET_CATEGORY, payload: null });
    dispatch({
      type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
      payload: null,
    });
    dispatch({
      type: ActionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS,
      payload: false,
    });
    dispatch({
      type: ActionTypes.EXPAND_TRANSACTIONAL_OPTIONS,
      payload: false,
    });
    dispatch({ type: ActionTypes.SGP_SET_REPORT_NAME, payload: {
      reportLabel: null,
      reportValue: null
    }})
    dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION, payload: {
      type: null,
      startDate: null,
      endDate: null,
      duration: null
    }})
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS, payload: null})
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS_SUCCESS, payload: null})
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS, payload: null})
    dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER, payload: 'real time'})

    if(isSGP === 'false') goToTransaction()
    else goToHomePage()
  };

  const handlePRMClick = (isSGP) => {
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: "PRM Ops Scorecard Summary",
    });
    dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "PRM Scoreboard" });
  };

  const handleInquiryClick = (isSGP) => {
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: "Inquiry Portal",
    });
    dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "Trade-In" });
    dispatch({ type: ActionTypes.SET_SELECTED_REPORT_TYPE, payload: null });
  };

  return (
    <>
      {loading && <Spinner showOverlay={true} />}
      {selectedTab === "Inquiry Portal" &&
      <div className=" text-white bgImage h-60vh">
        <div className={`px-4 flex pb-2 flex-row justify-between w-screen items-center`}> 
          <div className="flex justify-start items-center">
            <Logo className="w-14 cursor-pointer" onClick={() => handlePRMClick()} />
            <p className="text-white text-2xl pb-3 ml-4 font-bold">Inquiry Portal</p>
          </div>
          <div className="flex flex-row items-center">
            <div className="flex justify-end bg-white p-1 text-center rounded-full text-black mr-2 font-bold text-sm">
              {userDetails &&
                userDetails?.firstName?.split()?.[0]?.charAt(0)?.toUpperCase()}
              {userDetails &&
                userDetails?.lastName?.split()?.[0]?.charAt(0)?.toUpperCase()}
            </div>
            <span className="mr-5 font-bold">
              {userDetails && toPascalCase(userDetails?.firstName)}{" "}{userDetails && toPascalCase(userDetails?.lastName)}
            </span>
          </div>
        </div>

        <div className="flex justify-start mb-8 ml-6">
          <div className="relative mr-4 w-fit" ref={refBPM}>
            <div
              className={`text-app-lightGreen cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5 ${
                projectName === resources.BPM_PROJECT_NAME
                  ? "bg-black font-bold rounded-lg "
                  : ""
              }`}
            >
              <div
                className="flex items-center mr-2"
                onClick={() => handleBPMClick(config.isSGP)}
              >
                <BPMIcon
                  className=""
                  fill={
                    projectName === resources.BPM_PROJECT_NAME
                      ? "#37E7A7"
                      : "#37E7A7"
                  }
                />
                <div
                  className={`${
                    projectName === resources.BPM_PROJECT_NAME
                      ? "text-app-lightGreen"
                      : "text-app-lightGreen"
                  } text-xs ml-1`}
                >
                  {resources.BPM_PROJECT_NAME}
                </div>
              </div>
            </div>
          </div>

          <div className="relative mr-4 w-fit" ref={refTransactional}>
            <div
              className={`cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5 ${
                (projectName === resources.TRANSACTIONAL_REPORTING_PROJECT_NAME || projectName === resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME)
                  ? "bg-black font-bold rounded-lg"
                  : ""
              }`}
            >
              <div
                className="flex items-center mr-2"
                onClick={() => handleTransactionalReporting(config.isSGP)}
              >
                <Transactional_ReportingIcon
                  className=""
                  fill={
                    (projectName === resources.TRANSACTIONAL_REPORTING_PROJECT_NAME || projectName === resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME)
                      ? "#37E7A7"
                      : "#37E7A7"
                  }
                />
                <div
                  className={`${
                    (projectName === resources.TRANSACTIONAL_REPORTING_PROJECT_NAME || projectName === resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME)
                      ? "text-app-lightGreen"
                      : "text-app-lightGreen"
                  } text-xs ml-1`}
                >
                  {config.isSGP === 'true' ? resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME : resources.TRANSACTIONAL_REPORTING_PROJECT_NAME}
                </div>
              </div>
            </div>
          </div>

          {userDetails && resources.PRM_Scorecard_Users?.map((user)=>user?.toLowerCase())?.includes(userDetails?.email?.toLowerCase()) &&
            <div className="cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5" onClick={() => handlePRMClick()}>
              <Application_Health_MonitoringIcon
                className=""
                fill={
                  projectName ===
                  "PRM Ops Scorecard Summary"
                    ? "#37E7A7"
                    : "#37E7A7"
                }
              />
              <p
                className={`${
                  projectName ===
                  "PRM Ops Scorecard Summary"
                    ? "text-app-lightGreen"
                    : "text-app-lightGreen"
                } text-xs ml-1`}
              >
                {"PRM"}
              </p>
            </div>
          }

            {((userDetails && escoUserFilter.length > 0) || (userDetails && resources.Inquiry_Portal_Users?.map((user)=>user?.toLowerCase())?.includes(userDetails?.email?.toLowerCase()))) &&
              <div className="cursor-pointer py-2 px-3 flex justify-center items-center border rounded-lg border-app-lightGreen bg-app-lightGreen mr-5" onClick={() => handleInquiryClick()}>
                <Application_Health_MonitoringIcon
                  className=""
                  fill={
                    projectName ===
                    "Inquiry Portal"
                      ? "#000000"
                      : "#000000"
                  }
                />
                <p
                  className={`${
                    projectName ===
                    "Inquiry Portal"
                      ? "text-black font-bold"
                      : "text-black font-bold"
                  } text-xs ml-1`}
                >
                  {"Inquiry Portal"}
                </p>
              </div>
            }

        </div>

        <div className="w-full pb-10 flex flex-col justify-center ">
          <div className=" flex mt-5 ">
            <p className="text-2xl leading-54 md:leading-5 mt-2 font-light mx-auto">
              <b>Select from the options below</b>
            </p>
          </div>

          <div className=" mt-16 center">
            <div className="flex flex-row justify-between items-center">
              <div className={` w-52 flex flex-col justify-center items-center  border py-4  rounded-2xl  px-8 mr-7 cursor-pointer  
                transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125  duration-500 border-app-lightGreen`}
                onClick={() => {setCategory('Trade-In'); 
                gotoDashBoard('Trade-In'); 
              }}
              >
                <p className={`text-center my-4 text-lg`}>Trade-In</p>
              </div>{" "}
              <div className={` w-52 flex flex-col justify-center items-center  border py-4  rounded-2xl  mr-7 cursor-not-allowed  
                transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125  duration-500 border-app-lightGreen`}
                // onClick={() => {setCategory('Device Care'); gotoDashBoardTwo('Device Care')}}      
              >
                <p className={`text-center my-4 text-lg`}>Device Care</p>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
      }
      {selectedTab === "Trade-In" &&
         <InquiryReportOption />
      }
      {selectedTab === "Device Care" &&
        <InquiryDeviceCare />
      }
    </>
  );
}
