import { useEffect, useRef, useState } from "react";
import { numberWithCommas, getGuageIcons, getTypeIndicator } from "../../../utils/functionUtils";
import { useCentralStore } from "../../../store/appContext";
import config from "../../../config/config";

const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

const describeArc = (x, y, radius, startAngle, endAngle) => {
  var start = polarToCartesian(x, y, radius, endAngle);
  var end = polarToCartesian(x, y, radius, startAngle);

  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  var d = [
    "M",
    start.x,
    start.y,
    "A",
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(" ");

  return d;
};

const ProgressBar = ({
  value,
  width,
  lineWidth,
  label,
  description,
  primaryColor,
  secondaryColor,
  barLabel,
  metricsTypeIndicator,
  textSecondaryColor,
  mapSelected
}) => {
  const [degVal, setDegVal] = useState(-92);
  const pathRef = useRef();

  const rotateThis = (endVal, isFirst) => {
    let deg = isFirst ? -90 : degVal;
    const ri = setInterval(() => {
      if (deg < endVal) {
        deg = deg + 1;
        setDegVal(deg);
        // console.log(description, deg, endVal);
      } else if (deg > endVal) {
        deg = deg - 1;
        setDegVal(deg);
        // console.log(description, deg, endVal);
      } else if (deg === endVal) {
        clearInterval(ri);
      }
    }, 15);
  };

  const [{ selectedTab }, dispatch ] = useCentralStore();

  useEffect(() => {
    // console.log("pathRef.current", pathRef.current);
    rotateThis(Math.round(value * 1.8 - 86), degVal === -92);
  }, [value]);

  let hideGuage = barLabel === "Settlement" || barLabel === "Charges($)";

  return (
    <div className="relative">
      <div
        className="text-center"
        // className="absolute text-center"
        // style={{
        //   top: "50%",
        //   right: "50%",
        //   transform: "translate(60%, -40%)",
        // }}
      >
        {/* {hideGuage && (
            <div className="flex justify-center mb-1">
              <Dollar stroke={primaryColor} />
            </div>
          )} */}
          
        <div className={`text-3xl font-bold mb-1 ${config.isSGP === 'true' ? 'text-black ' : textSecondaryColor} flex justify-center items-center`}>
          {/* {barLabel?.includes("$") || description?.includes("$") ? "$" : ""} */}
          {getGuageIcons(barLabel, secondaryColor)}
          {/* {hideGuage && <Dollar stroke={primaryColor} />} */}
          {/* {(selectedTab === "StarHub BPM Performance" || selectedTab === "StarHub Operational Performance" || selectedTab === 'Singtel BPM Performance' || selectedTab === 'M1 BPM Performance' || selectedTab === 'AIS BPM Performance' || selectedTab === 'TRUE BPM Performance' || selectedTab === 'Asurion_TechCare BPM Performance' || selectedTab === 'Celcom BPM Performance' || selectedTab === "FET BPM Performance") ?
            <p>
              {getTypeIndicator(metricsTypeIndicator, mapSelected)}
              {label ? numberWithCommas(Math?.round(label)) : 0}
            </p>
           : */}
          <p>
            {getTypeIndicator(metricsTypeIndicator, mapSelected)}
            {label == 'NA' ? 'NA' : label ? numberWithCommas(Math?.round(label)) : 0}
          </p>
          {/* } */}
        </div>
        <div className={`text-11px px-3 font-bold ${config.isSGP === 'true' ? 'text-black hidden' : 'text-app-label'} whitespace-nowrap overflow-hidden text-ellipsis`} title={description}>{config.isSGP === 'true' ? 'Total count' : description}</div>
        {/* <div className="text-11px px-3 font-bold text-app-label h-9 flex items-center justify-center" title={description}>{description}</div> */}
      </div>
      {/* <div className="relative">
        <svg
          height="100%"
          width={width * 2}
          className="absolute bg-transparent"
        >
          {value > 0 && !hideGuage && (
            <defs>
              <linearGradient
                id={`primary-to-secondary_${primaryColor}`}
                x1="0"
                x2="1"
                y1="0"
                y2="0"
              >
                {value > 5 ? (
                  <>
                    <stop offset="0%" stop-color={primaryColor} />
                    <stop offset="100%" stop-color={secondaryColor} />
                  </>
                ) : (
                  <>
                    <stop offset="0%" stop-color={primaryColor} />
                    <stop offset="100%" stop-color={secondaryColor} />
                  </>
                )}
              </linearGradient>
            </defs>
          )}
          <path
            ref={pathRef}
            fill="none"
            stroke-width={lineWidth}
            style={{
              stroke: `url(#primary-to-secondary_${primaryColor})`,
              strokeLinecap: "round",
              pointerEvents: "none",
            }}
            d={`${describeArc(width, width, width - lineWidth, -92, degVal)}`}
          />
        </svg>
        <svg
          height="100%"
          width={width * 2}
          className="relative bg-transparent"
        >
          {!hideGuage && (
            <path
              fill="none"
              stroke-width={lineWidth}
              style={{
                stroke: `${secondaryColor}`,
                strokeLinecap: "round",
                pointerEvents: "none",
                opacity: "0.3",
              }}
              d={`${describeArc(width, width, width - lineWidth, -92, 92)}`}
            />
          )}
        </svg>
      </div> */}
      {/* <stop offset="0%" stop-color="#DB1B1B" />
                    <stop offset="30%" stop-color="#EDB800" />
                    <stop offset="100%" stop-color="#00B574" /> */}
    </div>
  );
};

ProgressBar.defaultProps = {
  value: 100,
  width: 120,
  lineWidth: 15,
};

export default ProgressBar;