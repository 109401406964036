import React from "react";

const icon = ({ className, fill, onClick }) => (
<svg className={className} width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_1917_78093)">
<circle cx="19.488" cy="19.5" r="6" fill="#DB1B1B"/>
<circle cx="19.488" cy="19.5" r="5.75" stroke="#FF5070" stroke-width="0.5"/>
</g>
<defs>
<filter id="filter0_d_1917_78093" x="0.488037" y="0.5" width="38" height="38" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="3" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_1917_78093"/>
<feOffset/>
<feGaussianBlur stdDeviation="5"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.314974 0 0 0 0 0.440562 0 0 0 0.5 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1917_78093"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1917_78093" result="shape"/>
</filter>
</defs>
</svg>
)
export default icon;

