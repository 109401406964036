import React from 'react'

const icon = ({ className, fill, onClick }) => 
(
<svg className={className} width="10" height="10" onClick={onClick} viewBox="0 0 155 155" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.1421 0L154.142 140L140 154.142L0 14.1421L14.1421 0Z" fill={fill}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M154.142 14.1421L14.1421 154.142L0 140L140 0L154.142 14.1421Z" fill={fill}/>
</svg>
)
export default icon


