/* eslint-disable max-len */
import React from 'react'

const icon = ({ className ,click, fill}) => (
<svg className={className} onClick={click} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M21.6969 11.268L17.7956 7.368C17.6019 7.17425 17.3381 7.06425 17.0644 7.06425C16.7894 7.06425 16.5269 7.17425 16.3331 7.368C15.9281 7.7705 15.9281 8.42675 16.3331 8.8305L18.4681 10.9655H7.17187C6.60062 10.9655 6.13812 11.4292 6.13812 12.0005C6.13812 12.5717 6.60062 13.0342 7.17187 13.0342H18.4681L16.3331 15.1692C15.9281 15.573 15.9281 16.2292 16.3331 16.633C16.7369 17.0367 17.3919 17.0367 17.7956 16.633L21.6969 12.7317C22.1006 12.328 22.1006 11.6717 21.6969 11.268Z" fill="black"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2 2.001H15.1036V22H2V2.001Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.0686 17.8622C13.4974 17.8622 13.0349 18.3247 13.0349 18.896V19.931H4.06863V4.06975H13.0349V5.1035C13.0349 5.67475 13.4974 6.13725 14.0686 6.13725C14.6411 6.13725 15.1036 5.67475 15.1036 5.1035V3.7235C15.1036 2.77225 14.3311 2.001 13.3799 2.001H3.72488C2.77113 2.001 1.99988 2.77225 1.99988 3.7235V20.276C1.99988 21.2272 2.77113 22.001 3.72488 22.001H13.3799C14.3311 22.001 15.1036 21.2272 15.1036 20.276V18.896C15.1036 18.3247 14.6411 17.8622 14.0686 17.8622Z" fill="black"/>
</svg>
)

export default icon
