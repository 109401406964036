import React, { useEffect, useState, useRef } from 'react'
import { useCentralStore } from "../../store/appContext"
import GreenDotFav from '../../assets/icons/greenDotFav'
import RedDotFav from '../../assets/icons/redDotFav'
import YellowDotFav from '../../assets/icons/yellowDotFav'
import CrossIcon from '../../assets/icons/cross-icon'
import { isEmpty, toPascalCase } from "../../utils/functionUtils"
import ActionTypes from "../../store/actionTypes"
import DashboardArrow  from '../../assets/icons/dashboardArrow'
import { getUniqueValuesFromArray } from '../../utils/functionUtils'
import resources from '../../components/common/resources.json'
import API from '../../api'
import moment from "moment";

const FavouriteCards = () => {
    const [{ FavouriteList, favArryCnt, favArray, userData, userDetails, selectedDashboard }, dispatch] = useCentralStore()
    const [favList, setFavList] = useState([])
    const [openPopup, setOpenpopup] = useState(false)
    const [deleteFav, setDeleteFav] = useState({})
    const [isDeleted, setDeleted] = useState(false)

    const numberOfFavtoShow = 5
    const enableNext = favList?.length > numberOfFavtoShow - 1 ? favArryCnt != favList?.length - numberOfFavtoShow : false
    const enablePrevious = favArryCnt > 0

    const getNextImg = () => {
        if (enableNext) {
          favArray.shift()
          favArray.push(favList[numberOfFavtoShow + favArryCnt])
          dispatch({ type: ActionTypes.SET_FAV_ARRAY_CNT, data: favArryCnt + 1 });
          dispatch({ type: ActionTypes.SET_FAV_ARRAY, data: favArray });
        }
      }
      const getPreviousImg = () => {
        if (enablePrevious) {
          favArray.pop()
          favArray.unshift(favList[favArryCnt - 1])
          dispatch({ type: ActionTypes.SET_FAV_ARRAY_CNT, data: favArryCnt - 1 });
          dispatch({ type: ActionTypes.SET_FAV_ARRAY, data: favArray });
        }
      }

      useEffect(() =>{
        if(!isEmpty(FavouriteList) && FavouriteList?.length > 0){
            let uniqueFavouriteList = FavouriteList?.filter(
                (v, i, a) =>
                  a.findIndex(
                    (v2) =>
                      v2.ClientName === v.ClientName && v2.DashboardName === v.DashboardName
                  ) === i
              );
              setFavList(uniqueFavouriteList)
            const endIndex = uniqueFavouriteList?.length > numberOfFavtoShow ? numberOfFavtoShow : uniqueFavouriteList?.length
            const newArray = uniqueFavouriteList && uniqueFavouriteList?.slice(0, endIndex)

            dispatch({ type: ActionTypes.SET_FAV_ARRAY, data: newArray });
            dispatch({ type: ActionTypes.SET_FAV_ARRAY_CNT, data: 0 });
        }else {
            dispatch({ type: ActionTypes.SET_FAV_ARRAY, data: [] });
            setFavList([])
        }
      }, [FavouriteList])

  const goToDashboard = (fav) => {
    dispatch({ type: ActionTypes.SET_MAP_SELECTED, data: fav?.Country });
    dispatch({ type: ActionTypes.SET_SELECTED_FAV_DATA, data: fav });
    // dispatch({ type: ActionTypes.SELECTED_CLIENT, data: fav?.ClientName });
    dispatch({ type: ActionTypes.SELECTED_STORE, data: "All" });
    dispatch({ 
      type: ActionTypes.SELECTED_DASHBOARD_DATE, 
      payload: {
        name: resources.LAST_90_DAYS_VALUE,
        value: resources.LAST_90_DAYS_VALUE,
      },
    });
    dispatch({ type: ActionTypes.SET_SELECTED_TAB, data: fav?.DashboardName })
    // setFavouriteData(fav)

    dispatch({ type: ActionTypes.SET_PROJECT_NAME, payload: resources.BPM_PROJECT_NAME });
    dispatch({
      type: ActionTypes.SET_SELECTED_TAB,
      payload: fav?.DashboardName,
    });
    dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: fav?.ClientId });
    dispatch({ type: ActionTypes.GO_TO_MAPS_PAGE, payload: false });
    const payload = {
      Channel: "all",
      Client: toPascalCase(fav?.ClientName),
      ClientId: fav?.ClientId,
      DashboardId: "all",
      DashboardName: fav?.DashboardName,
      Duration: resources.LAST_90_DAYS_VALUE,
    };
    // setLoading(true);
    // API[ActionTypes.GET_DASHBOARD_DETAILS](payload, userData?.access_token)
    //   .then(({ data }) => {
    //     if (data) {
    //       // setLoading(undefined);
    //       dispatch({
    //         type: ActionTypes.SET_REFRESHED_ON,
    //         payload: moment().format("YYYY-MM-DD HH:mm:ss"),
    //       });
    //       dispatch({
    //         type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
    //         payload: data?.GetDashboardResponse,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     // setLoading(undefined);
    //     dispatch({
    //       type: ActionTypes.GET_DASHBOARD_DETAILS_FAILURE,
    //       payload: err,
    //     });
    //     dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
    //     let _payload = {
    //       level: resources.POST_LOGGER_ERROR,
    //       category: resources.POST_LOGGER_API,
    //       ApiName: resources.POST_LOGGER_APINAME,
    //       message: {
    //         error: err,
    //         info: payload,
    //       },
    //     };
    //     API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
    //   })
    //   .finally(() => {
    //     // setLoading(undefined);
    //   });
  };

  const addUpdateFavourite = (fav, action) => {
    let payload = {
        DashboardId: selectedDashboard,
        DashboardName: fav?.DashboardName,
        Channel: "all",
        ClientId: fav?.ClientId,
        Client: fav?.ClientName,
        Duration: resources.LAST_90_DAYS_VALUE,
        Username: userDetails && userDetails?.email?.split("@")?.[0],
        OneOpsAddFavouriteDetailId: fav?.OneOpsAddFavouriteDetailId,
      };
      setDeleted(true)
    API[ActionTypes.ADD_FAVOURITE](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
        if (data) {
            let payload = {
              // Username: userDetails && userDetails?.email?.split("@")?.[0],
              idToken: userData?.access_token,
            };
            API[ActionTypes.GET_ROLE_BASES_MATRICS](payload, userDetails && userDetails?.email?.split("@")?.[0])
              .then(({ data }) => {
                if (data) {
                setDeleted(false)
                  dispatch({
                    type: ActionTypes.GET_ROLE_BASES_MATRICS_SUCCESS,
                    payload: data,
                  });
                }
              })
              .catch((err) => {
                dispatch({
                  type: ActionTypes.GET_ROLE_BASES_MATRICS_FAILURE,
                  payload: err,
                });
                dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
                let payload = {
                  level: resources.POST_LOGGER_ERROR,
                  category: resources.POST_LOGGER_API,
                  ApiName: resources.POST_LOGGER_APINAME,
                  message: {
                    error: err,
                    info: {
                      idToken: "",
                    },
                  },
                };
                API[ActionTypes.POST_LOGGER](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
              });
        }
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.GET_SUBMATRICS_DETAILS_FAILURE,
          payload: err,
        });
        // setDeleted(false)
        // setDeleteFav(null)
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: payload,
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
  };

  const DeleteFavourite = (fav) =>{
    return(
        <div className='text-white text-center ml-4'>
            <p className='text-sm'>{resources.REMOVE_FAVOURITE_LBL}</p>
            <div className='flex flex-row justify-between text-xs mx-5'>
                <p className={`cursor-pointer ${fav?.fav?.Color?.toLowerCase() === 'green' ? 'hover:bg-app-greenBorder' : fav?.fav?.Color?.toLowerCase() === 'amber' ? 'hover:bg-yellow-200' : 'hover:bg-red-400' } text-white hover:text-black p-1 rounded-lg`} onClick={() => addUpdateFavourite(fav?.fav, 'remove')}>Yes</p>
                <p className={`cursor-pointer ${fav?.fav?.Color?.toLowerCase() === 'green' ? 'hover:bg-app-greenBorder' : fav?.fav?.Color?.toLowerCase() === 'amber' ? 'hover:bg-yellow-200' : 'hover:bg-red-400' } text-white hover:text-black p-1 rounded-lg`} onClick={() => (setOpenpopup(false), setDeleteFav(null))}>No</p>
            </div>
        </div>
    )
  }

    return(
    <div className='mb-12'>
        {favList?.length > 0 && <p className={`flex mb-3 relative ${favList?.length > numberOfFavtoShow ? 'left-3%' : '' }`}>{resources.FAVOURITE_LBL}</p>}
        <div className="flex flex-row w-full justify-center">
        {favList?.length > numberOfFavtoShow &&
          <div className="flex flex-row text-center items-center justify-center rotated-img mr-5 rotate-180">
              <DashboardArrow onClick={() => getPreviousImg()}
                className={`inline-block ml-m10 h-5 w-5 ${
                    enablePrevious ? 'hover:cursor-pointer cursor-pointer' : 'opacity-25 cursor-not-allowed'
              }`}
              fill="#fff" />
          </div>
        }
        {favArray?.map(fav => 
            <div className={`w-60 min-h-[110px] ${fav?.Color?.toLowerCase() === 'green' ? 'bg-[#125443] border border-app-greenBorder' : fav?.Color?.toLowerCase() === 'amber' ? 'bg-[#4e4318] border border-yellow-600' : 'bg-[#4b2020] border border-red-600'} shadow-xl rounded-xl flex flex-row mr-4 py-4 px-2 transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-110 duration-500`}>
              {/* <div className="w-60 min-h-[110px] bg-[#4e4318] border border-yellow-600 shadow-xl rounded-xl flex flex-row mr-4 py-4 px-2 transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-110 duration-500"> */}
              {/* <div className="w-60 min-h-[110px] bg-[#4b2020] border border-red-600 shadow-xl rounded-xl flex flex-row mr-4 py-4 px-2 transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-110 duration-500"> */}
                {(openPopup && deleteFav?.OneOpsAddFavouriteDetailId === fav?.OneOpsAddFavouriteDetailId) ?
                    <>
                    {isDeleted ?
                        <p className='text-white text-sm ml-4'>{resources.REMOVING_FAV_LBL}</p>
                        :
                        <DeleteFavourite fav={fav} />
                    }
                    </>
                    :
                    <div className='w-full cursor-pointer' onClick={(e) => (goToDashboard(fav))}>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-row'>
                                {fav?.Color?.toLowerCase() === 'green' && <GreenDotFav className="relative -top-2.5" />}
                                {fav?.Color?.toLowerCase() === 'red' && <RedDotFav className="relative -top-2.5" />}
                                {fav?.Color?.toLowerCase() === 'amber' && <YellowDotFav className="relative -top-2.5" />}
                                <div className={`cursor-pointer text-sm leading-20 text-white`}>
                                <span className='font-bold'>{(fav?.ClientName?.toUpperCase() === 'TRUE' || fav?.ClientName?.toUpperCase() === 'AIS' || fav?.ClientName?.toUpperCase() === 'FET') ? fav?.ClientName : toPascalCase(fav?.ClientName?.replace('_', ' '))}</span>
                                    {/* {toPascalCase(fav?.ClientName?.replace('_', ' '))} - {fav?.DashboardName} */}
                                </div> 
                            </div>
                            <div className="cursor-pointer px-2 py-1 ml-3 hover:bg-app-deepGreen hover:rounded-lg h-fit" 
                                onClick={(e) => (e.stopPropagation(),setDeleteFav(fav), setOpenpopup(true))} >
                                <CrossIcon fill='white' className=""/>
                            </div>
                        </div>
                        <div className='font-normal text-sm mx-3'>
                            {fav?.DashboardName}
                        </div>
                    </div>
                }
            </div>
        )}
        {favList?.length > numberOfFavtoShow &&
            <div className="flex flex-row text-center items-center justify-center">
                <DashboardArrow onClick={() => getNextImg()}
                className={`inline-block ml-m10 h-5 w-5 ${
                    enableNext ? 'hover:cursor-pointer cursor-pointer' : 'opacity-25 cursor-not-allowed'
                }`}
                fill="#fff" />
            </div>
        }
        </div>
    </div>
    )
}

export default FavouriteCards