import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import faker from 'faker';
import { useState } from 'react';
import { useCentralStore } from '../../../store/appContext';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
ChartJS.defaults.set('plugins.datalabels', {
  color: '#000000'
});
// Chart.register(ChartDataLabels);

const ServiceRequestRevenueReport = ({
    chartReportCountSuccess,
    reportName,
    getBgColor
}) => {

  const abbreviateNumberFactory = (symbols) => (
    (number, minDigits, maxDigits) => {

        if (number >= 0.01 && number <= 0.99) return number;

        if (number === 0) return number;

        // determines SI symbol
        const tier = Math.floor(Math.log10(Math.abs(number)) / 3);

        // get suffix and determine scale
        const suffix = symbols[tier];
        const scale = 10 ** (tier * 3);

        // scale the number
        const scaled = number / scale;

        // format number and add suffix
        return scaled.toLocaleString(undefined, {
            minimumFractionDigits: minDigits,
            maximumFractionDigits: maxDigits,
        }) + suffix;
    }
);

      const SI_SYMBOLS = ["", "k", "M", "G", "T", "P", "E"];
      const SHORT_SYMBOLS = ["", "K", "M", "B", "T", "Q"];
      const LONG_SYMBOLS = ["", " thousand", " million", " billion", " trillion", " quadrillion"];

      // const abbreviateNumberSI = abbreviateNumberFactory(SI_SYMBOLS);
      const abbreviateNumberShort = abbreviateNumberFactory(SHORT_SYMBOLS);
      // const abbreviateNumberLong = abbreviateNumberFactory(LONG_SYMBOLS);

      let abbreviateNumberShortConvert = (value) => {
          return value ? abbreviateNumberShort(value, 0, 2) : 0;
      }

    const options = {
        // plugins: {
        //   title: {
        //     display: true,
        //     text: reportName,
        //   },
        // },
        plugins: {
          datalabels: {
            formatter: function(value, context) {
              // return Number(value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
              return abbreviateNumberShortConvert(value);
            },
            // align: "top",
            labels: {
                usePointStyle: true,
                    font: {
                        weight: 700,
                        color: 'black',
                        size: 16,
                    }
            },
          },
          title: {
            display: true,
            text: reportName,
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      };
      
      const serviceRequestRevenueCount = {
        Months: chartReportCountSuccess['ServiceRequestRevenueCount']?.map(v => v?.Month),
        ['SR Revenue']: chartReportCountSuccess['ServiceRequestRevenueCount']?.map(v => v?.SrChargedAmount)
      }
      
      let labels = serviceRequestRevenueCount?.Months

      let serviceRequestRevenueCountLabel = ['SR Revenue']

       let _reportData =[]
      for(let i=0; i < (serviceRequestRevenueCountLabel.length); i++){
        _reportData.push({
            label: serviceRequestRevenueCountLabel[i],
            data: serviceRequestRevenueCount[serviceRequestRevenueCountLabel[i]],
            backgroundColor: getBgColor[i],
            stack: 0
        })
      }

       const reportData = {
        labels,
        datasets: _reportData,
      };

    return(
        <div>
            <Bar options={options} data={reportData} />
        </div>
    )
}

export default ServiceRequestRevenueReport