import { useState, useEffect, useRef } from "react";
import ActionTypes from "../../store/actionTypes";
import API from "../../api";
import { useCentralStore } from "../../store/appContext";
import resources from "../../components/common/resources.json";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/icons/OneOplogo.svg";
import Spinner from "../../components/common/spinner/spinner";
import Selector from "../../components/common/selector/selectorNew";
import ScorecardSummaryFilter from "./ScorecardSummaryFilter";
import { ReactComponent as DownArrow } from "../../assets/icons/down-arrow.svg";
import config from "../../config/config";
import BPMIcon from "../../assets/homeImg/BPM_small_v2";
import Transactional_ReportingIcon from "../../assets/homeImg/Transactional_Reporting_small_v2";
import Application_Health_MonitoringIcon from "../../assets/homeImg/Application_Health_Monitoring_small_v2";
import { toPascalCase, getMyColors } from "../../utils/functionUtils";
import Button from "../../components/common/button/button";
import exportToCSVInventory from "../../components/common/hepler/export-to-csv-prmdata";

const PRMDashboardDataSummaryThree = ({label}) => {

  const [{ ssSelectedReportFilter, RoleData, userData, scorecardSummarySuccess, loading, selectedCategory, projectName, isTransactionOptionsOpen, userDetails }, dispatch] = useCentralStore();
  const navigate = useNavigate();
  const refBPM = useRef();
  const refTransactional = useRef();
  const [selectedClient, setSelectedClient] = useState("Starhub");
  const [exportErrMsg, setExportErrMsg] = useState(null);
  let tableColumns = [];
  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING, payload: action });
  };
  const escoUserFilter = RoleData.filter(item => item === "ESCO_USER");
  useEffect(() => {
    const fetchData = async () => {
      if (!selectedClient) {
        return;
      }
      let startDate = moment(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD 00:00:00");
      let endDate = moment(new Date()).format("YYYY-MM-DD 23:59:59");
      let payload = {
        StartDate: startDate,
        EndDate: endDate,
        ClientName: selectedClient,
        PageTitle:"ScoreCardSummary3"
      };
 
      setLoading(true);
      try {
        const data = await API[ActionTypes.GET_SCORECARD_SUMMARY](
          payload,
          userData?.access_token,
          userDetails && userDetails?.email?.split("@")?.[0]
        );
        
        setLoading(false);
        dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "Summary Three" })
        dispatch({
          type: ActionTypes.GET_SCORECARD_SUMMARY_SUCCESS,
          payload: data,
        });
      } catch (err) {
        dispatch({
          type: ActionTypes.GET_SCORECARD_SUMMARY_FAILURE,
          payload: err,
        });
        dispatch({
          type: ActionTypes.SET_API_ERROR,
          data: true
        });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 10 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [selectedClient, dispatch, navigate, userData, userDetails]);

  const mapRecordCountAndPercent = (keyLabel, category) => {
    const record = scorecardSummarySuccess?.find(entry => entry?.KeyLabel === keyLabel && entry?.Category === category);
    return {
      recordCount: record ? (isNaN(record?.Record_Count) || record?.Record_Count === null ? 0 : record?.Record_Count) : 0,
      percent: record ? (isNaN(record?.percent) || record?.percent === null ? 0 :  record?.percent) : 0
    };
  };

  const gotoDashBoard = () => {
    if (!selectedClient) {
      return;
    }
    let startDate = moment(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD 00:00:00");
    let endDate = moment(new Date()).format("YYYY-MM-DD 23:59:59");

    let payload = {
      StartDate: startDate,
      EndDate: endDate,
      ClientName: selectedClient,
      PageTitle: "ScoreCardSummary3"
    };
    setLoading(true);
    API[ActionTypes.GET_SCORECARD_SUMMARY](
      payload,
      userData?.access_token,
      userDetails && userDetails?.email?.split("@")?.[0]
    )
    .then((data) => {
      setLoading(false);
      dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "Summary Three" })
      dispatch({
        type: ActionTypes.GET_SCORECARD_SUMMARY_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.GET_SCORECARD_SUMMARY_FAILURE,
        payload: err,
      });
      dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
      let _payload = {
        level: resources.POST_LOGGER_ERROR,
        category: resources.POST_LOGGER_API,
        ApiName: resources.POST_LOGGER_APINAME,
        message: {
          error: err,
          info: {
          idToken: "",
          },
        },
      };
      API[ActionTypes.POST_LOGGER](
        _payload,
        userData?.access_token,
        userDetails && userDetails?.email?.split("@")?.[0]
      );
    });
  }

  const gotoDashBoardOne = () => {
    if (!selectedClient) {
      return;
    }
    let startDate = moment(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD 00:00:00");
    let endDate = moment(new Date()).format("YYYY-MM-DD 23:59:59");

    let payload = {
      StartDate: startDate,
      EndDate: endDate,
      ClientName: selectedClient,
      PageTitle: "ScoreCardSummary1"
    };
    setLoading(true);
    API[ActionTypes.GET_SCORECARD_SUMMARY](
      payload,
      userData?.access_token,
      userDetails && userDetails?.email?.split("@")?.[0]
    )
    .then((data) => {
      setLoading(false);
      dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "Summary One" })
      dispatch({
        type: ActionTypes.GET_SCORECARD_SUMMARY_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.GET_SCORECARD_SUMMARY_FAILURE,
        payload: err,
      });
      dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
      let _payload = {
        level: resources.POST_LOGGER_ERROR,
        category: resources.POST_LOGGER_API,
        ApiName: resources.POST_LOGGER_APINAME,
        message: {
          error: err,
          info: {
          idToken: "",
          },
        },
      };
      API[ActionTypes.POST_LOGGER](
        _payload,
        userData?.access_token,
        userDetails && userDetails?.email?.split("@")?.[0]
      );
    });
  }

  const gotoDashBoardTwo = () => {
    if (!selectedClient) {
      return;
    }
    let startDate = moment(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD 00:00:00");
    let endDate = moment(new Date()).format("YYYY-MM-DD 23:59:59");

    let payload = {
      StartDate: startDate,
      EndDate: endDate,
      ClientName: selectedClient,
      PageTitle: "ScoreCardSummary2"
    };
    setLoading(true);
    API[ActionTypes.GET_SCORECARD_SUMMARY](
      payload,
      userData?.access_token,
      userDetails && userDetails?.email?.split("@")?.[0]
    )
    .then((data) => {
      setLoading(false);
      dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "Summary Two" })
      dispatch({
        type: ActionTypes.GET_SCORECARD_SUMMARY_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.GET_SCORECARD_SUMMARY_FAILURE,
        payload: err,
      });
      dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
      let _payload = {
        level: resources.POST_LOGGER_ERROR,
        category: resources.POST_LOGGER_API,
        ApiName: resources.POST_LOGGER_APINAME,
        message: {
          error: err,
          info: {
          idToken: "",
          },
        },
      };
      API[ActionTypes.POST_LOGGER](
        _payload,
        userData?.access_token,
        userDetails && userDetails?.email?.split("@")?.[0]
      );
    });
  }

  const gotoDashBoardFour = () => {
    if (!selectedClient) {
      return;
    }
    let startDate = moment(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD 00:00:00");
    let endDate = moment(new Date()).format("YYYY-MM-DD 23:59:59");

    let payload = {
      StartDate: startDate,
      EndDate: endDate,
      ClientName: selectedClient,
      PageTitle: "ScoreCardSummary4"
    };
    setLoading(true);
    API[ActionTypes.GET_SCORECARD_SUMMARY](
      payload,
      userData?.access_token,
      userDetails && userDetails?.email?.split("@")?.[0]
    )
    .then((data) => {
      setLoading(false);
      dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "Summary Four" })
      dispatch({
        type: ActionTypes.GET_SCORECARD_SUMMARY_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.GET_SCORECARD_SUMMARY_FAILURE,
        payload: err,
      });
      dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
      let _payload = {
        level: resources.POST_LOGGER_ERROR,
        category: resources.POST_LOGGER_API,
        ApiName: resources.POST_LOGGER_APINAME,
        message: {
          error: err,
          info: {
          idToken: "",
          },
        },
      };
      API[ActionTypes.POST_LOGGER](
        _payload,
        userData?.access_token,
        userDetails && userDetails?.email?.split("@")?.[0]
      );
    });
  }

  const expandTransactionOptions = (payload) => {
    dispatch({
      type: ActionTypes.EXPAND_TRANSACTIONAL_OPTIONS,
      payload: payload,
    });
  };
  const transactionalOptions = [];
  transactionalOptions.push(
    {name: 'PRM scorecard report 1', value: 'Summary One'},
    {name: 'PRM scorecard report 2', value: 'Summary Two'},
    {name: 'PRM scorecard report 3', value: 'Summary Three'},
    {name: 'PRM scorecard report 4', value: 'Summary Four'}
  )
  const Option = ({ name, value, isSelected, onSelect, disabled }) => {
    return (
      <div
        className={`p-2 text-sm last:rounded-b-md cursor-pointer w-full border bg-gray-300 hover:text-black hover:font-bold  ${
          isSelected
            ? "text-black font-bold"
            : "text-black"
        } ${disabled ? "bg-gray-300 text-gray-400 cursor-not-allowed" : ""}`}
        onClick={disabled ? undefined : (e) => onSelect(e, value)}
      >
        {name}
      </div>
    );
  };
  const handleTransactionalOptionSelection = (e, value) => {
    dispatch({ type: ActionTypes.SET_CATEGORY, payload: value });
    dispatch({
      type: ActionTypes.EXPAND_TRANSACTIONAL_OPTIONS,
      payload: false,
    });
    if (value === "Summary One") {
      gotoDashBoardOne();
    } else if (value === 'Summary Two') {
      gotoDashBoardTwo();
    } else if (value === 'Summary Three') {
      gotoDashBoard();
    } else if (value === 'Summary Four') {
      gotoDashBoardFour();
    }
  };

  const handlePRMClick = (isSGP) => {
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: "PRM Ops Scorecard Summary",
    });
    dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "PRM Scoreboard" });
  };

  const handleInquiryClick = (isSGP) => {
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: "Inquiry Portal",
    });
    dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "Trade-In" });
    dispatch({
      type: ActionTypes.SET_START_DATE,
      payload: moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD 00:00:00')
    })      ;
    dispatch({
      type: ActionTypes.SET_END_DATE,
      payload: moment(new Date()).format('YYYY-MM-DD 23:59:59')
    })
    dispatch({
      type: ActionTypes.SS_SET_REPORT_FILTER_DURATION,
      payload: {
        type: null,
        startDate: moment(
          new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
        ).format("YYYY-MM-DD 00:00:00"),
        endDate: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        duration: null,
      },
    });
  };

  const expandDashboardOption = (payload) => {
    dispatch({
      type: ActionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS,
      payload: payload,
    });
  };

  const goToHomePage = () => {
    let payload = {
      idToken: userData?.access_token,
    };
    setLoading(true);
    dispatch({
      type: ActionTypes.GET_DASHBOARD_DETAILS_DATA_SUCCESS,
      payload: false,
    });
    API[ActionTypes.GET_ROLE_BASES_MATRICS](payload, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          dispatch({ type: ActionTypes.SELECTED_STORE, payload: "all" });
          dispatch({
            type: ActionTypes.SELECTED_DASHBOARD_DATE,
            payload: {
              name: resources.LAST_90_DAYS_VALUE,
              value: resources.LAST_90_DAYS_VALUE,
            },
          });
          dispatch({
            type: ActionTypes.GET_ROLE_BASES_MATRICS_SUCCESS,
            payload: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.GET_ROLE_BASES_MATRICS_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
  };

  const goToTransaction = () =>{
    let payload = {
      idToken: userData?.access_token,
    };
    setLoading(true);
    API[ActionTypes.GET_TRANSACTION_REPORTS](payload, userDetails && userDetails?.email?.split("@")?.[0])
    .then(({ data }) => {
      setLoading(false);
      if (data) {
        dispatch({
          type: ActionTypes.GET_TRANSACTION_REPORTS_SUCCESS,
          payload: data?.TransactionalReportsResponse?.DataSourceList,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: ActionTypes.GET_TRANSACTION_REPORTS_FAILURE,
        payload: err,
      });
      dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
      let _payload = {
        level: resources.POST_LOGGER_ERROR,
        category: resources.POST_LOGGER_API,
        ApiName: resources.POST_LOGGER_APINAME,
        message: {
          error: err,
          info: {
            idToken: "",
          },
        },
      };
      API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
    });
  }

  const handleBPMClick = (isSGP) => {
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: isSGP === 'true' ? null : resources.BPM_PROJECT_NAME,
    });
    dispatch({ type: ActionTypes.SET_CATEGORY, payload: null });
    dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: isSGP === 'true' ? null : "MAP" });
    dispatch({ type: ActionTypes.SET_PROGRAM_NAME, data: "" });
    dispatch({
      type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
      payload: null,
    });
    dispatch({ type: ActionTypes.SET_MAP_SELECTED, data: null });
    expandDashboardOption(undefined);
    dispatch({
      type: ActionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS,
      payload: false,
    });
    dispatch({
      type: ActionTypes.EXPAND_TRANSACTIONAL_OPTIONS,
      payload: false,
    });
    dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: null });
    dispatch({ type: ActionTypes.GO_TO_MAPS_PAGE, payload: isSGP === 'true' ? null : true });
    dispatch({ type: ActionTypes.SET_SELECTED_DASHBOARD, payload: undefined })
    dispatch({ type: ActionTypes.SGP_SET_REPORT_NAME, payload: {
      reportLabel: null,
      reportValue: null
    }})
    dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION, payload: {
      type: null,
      startDate: null,
      endDate: null,
      duration: null
    }})
    
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS, payload: null})
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS_SUCCESS, payload: null})
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS, payload: null})
  
    dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER, payload: 'real time'})
    goToHomePage();
  };
  
  const handleTransactionalReporting = (isSGP) => {
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: isSGP === 'true' ? null : resources.TRANSACTIONAL_REPORTING_PROJECT_NAME,
    });
    dispatch({ type: ActionTypes.SET_CATEGORY, payload: null });
    dispatch({
      type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
      payload: null,
    });
    dispatch({
      type: ActionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS,
      payload: false,
    });
    dispatch({
      type: ActionTypes.EXPAND_TRANSACTIONAL_OPTIONS,
      payload: false,
    });
    dispatch({ type: ActionTypes.SGP_SET_REPORT_NAME, payload: {
      reportLabel: null,
      reportValue: null
    }})
    dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION, payload: {
      type: null,
      startDate: null,
      endDate: null,
      duration: null
    }})
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS, payload: null})
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS_SUCCESS, payload: null})
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS, payload: null})
    dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER, payload: 'real time'})
  
    if(isSGP === 'false') goToTransaction()
    else goToHomePage()
  };

  const exportDataOriginal = async () => {
    setExportErrMsg(null)
    let startDate = moment(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD 00:00:00");
    let endDate = moment(new Date()).format("YYYY-MM-DD 23:59:59");
    const payload = {
      StartDate: startDate,
      EndDate: endDate,
      ClientName: selectedClient,
      "isExport": true
    };
    setLoading(true);
    API[ActionTypes.GET_PRM_EXPORT_DATA](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          exportToCSVInventory(
            tableColumns,
            data?.GetPrmDataDetailsResponse,
            "",
            "",
            label,
            dispatch,
            ActionTypes,
            selectedClient
          );
          dispatch({
            type: ActionTypes.GET_PRM_EXPORT_DATA_SUCCESS,
            payload: data?.GetPrmDataDetailsResponse,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: ActionTypes.GET_PRM_EXPORT_DATA_FAILURE,
          payload: err,
        });
        setExportErrMsg('Error occurred while exporting data...')
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: payload,
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
  };

  return (
    <>
      {loading && <Spinner showOverlay={true} />}
      <div className=" bg-black min-h-screen min-w-min pb-4">
        <div className="my-auto bg-black" style={{ display: "flex", flexDirection: "column", overflow: "-moz-hidden-unscrollable" }}>
          <div className={`bg-black px-4 flex pb-2 flex-row w-screen fixed justify-between items-center`}> 
            <div className="flex justify-start items-center">
              <Logo className="w-14 cursor-pointer" onClick={() => handlePRMClick()} />
              <p className="text-white text-2xl pb-3 ml-4 font-bold">PRM Ops Scorecard Summary</p>
            </div>
            <div className="flex flex-row items-center">
              <div className="flex justify-end bg-white p-1 text-center rounded-full text-black mr-2 font-bold text-sm">
                {userDetails &&
                  userDetails?.firstName?.split()?.[0]?.charAt(0)?.toUpperCase()}
                {userDetails &&
                  userDetails?.lastName?.split()?.[0]?.charAt(0)?.toUpperCase()}
              </div>
              <span className="mr-5 font-bold">
                {userDetails && toPascalCase(userDetails?.firstName)}{" "}{userDetails && toPascalCase(userDetails?.lastName)}
              </span>
            </div>
          </div>

          <div className="bg-black pb-10 flex mt-20 px-4 w-screen fixed">
            <div className="relative mr-4 w-fit" ref={refBPM}>
              <div
                className={`cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5 ${
                  projectName === resources.BPM_PROJECT_NAME
                    ? "bg-black font-bold rounded-lg"
                    : ""
                }`}
              >
                <div
                  className="flex items-center mr-2"
                  onClick={() => handleBPMClick(config.isSGP)}
                >
                  <BPMIcon
                    className=""
                    fill={
                      projectName === resources.BPM_PROJECT_NAME
                        ? "#37E7A7"
                        : "#37E7A7"
                    }
                  />
                  <div
                    className={`${
                      projectName === resources.BPM_PROJECT_NAME
                        ? "text-app-lightGreen"
                        : "text-app-lightGreen"
                    } text-xs ml-1`}
                  >
                    {resources.BPM_PROJECT_NAME}
                  </div>
                </div>
              </div>
            </div>

            <div className="relative mr-4 w-fit" ref={refTransactional}>
              <div
                className={`cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5 ${
                  (projectName === resources.TRANSACTIONAL_REPORTING_PROJECT_NAME || projectName === resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME)
                    ? "bg-black font-bold rounded-lg"
                    : ""
                }`}
              >
                <div
                  className="flex items-center mr-2"
                  onClick={() => handleTransactionalReporting(config.isSGP)}
                >
                  <Transactional_ReportingIcon
                    className=""
                    fill={
                      (projectName === resources.TRANSACTIONAL_REPORTING_PROJECT_NAME || projectName === resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME)
                        ? "#37E7A7"
                        : "#37E7A7"
                    }
                  />
                  <div
                    className={`${
                      (projectName === resources.TRANSACTIONAL_REPORTING_PROJECT_NAME || projectName === resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME)
                        ? "text-app-lightGreen"
                        : "text-app-lightGreen"
                    } text-xs ml-1`}
                  >
                    {config.isSGP === 'true' ? resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME : resources.TRANSACTIONAL_REPORTING_PROJECT_NAME}
                  </div>
                </div>
              </div>
            </div>

            <div className="cursor-pointer py-2 px-3 flex justify-center items-center border rounded-lg border-app-lightGreen bg-app-lightGreen mr-5" onClick={() => handlePRMClick()}>
              <Application_Health_MonitoringIcon
                className=""
                fill={
                  projectName ===
                  "PRM Ops Scorecard Summary"
                    ? "#000000"
                    : "#000000"
                }
              />
              <p
                className={`${
                  projectName ===
                  "PRM Ops Scorecard Summary"
                    ? "text-black"
                    : "text-black"
                } text-xs ml-1`}
              >
                {"PRM"}
              </p>
            </div>

            {((userDetails && escoUserFilter.length > 0) || (userDetails && resources.Inquiry_Portal_Users?.map((user)=>user?.toLowerCase())?.includes(userDetails?.email?.toLowerCase()))) &&
              <div className="cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5" onClick={() => handleInquiryClick()}>
                <Application_Health_MonitoringIcon
                  className=""
                  fill={
                    projectName ===
                    "Inquiry Portal"
                      ? "black"
                      : "#37E7A7"
                  }
                />
                <p
                  className={`${
                    projectName ===
                    "Inquiry Portal"
                      ? "text-black"
                      : "text-app-lightGreen"
                  } text-xs ml-1`}
                >
                  {"Inquiry Portal"}
                </p>
              </div>
            }

          </div>

          <div className="w-screen bg-black fixed px-4 mt-40 mb-8 flex justify-between">
            <div className=" flex gap-4">
              <Selector
                key="Client Dropdown"
                options={[
                  { name: "Starhub", value: "Starhub" },
                  { name: "Singtel", value: "Singtel" },
                  { name: "M1", value: "M1" },
                  { name: "Celcom", value: "Celcom" },
                  { name: "Compasia", value: "Compasia" },
                  { name: "FET", value: "FET" },
                  { name: "Synnex", value: "Synnex" },
                  { name: "AIS", value: "AIS" },
                  { name: "TRUE", value: "TRUE" },
                  { name: "Asurion_TechCare", value: "Asurion_TechCare" },
                  { name: "Maxis", value: "Maxis" }
                ]}
                label="Client"
                value={selectedClient} 
                onSelect={(val) => {
                  setSelectedClient(val);
                  gotoDashBoard();
                }}
                placeholder="Starhub"
                className="absolute top-0 mt-10"
                style={{ zIndex: 1000 }}
              />
              <div className="relative w-fit -ml" ref={refTransactional}>
                <div className={`cursor-pointer py-2 px-3 flex justify-center items-center rounded-lg border border-app-border text-white font-bold`}> PRM scorecard report 3 
                  <>
                    {projectName !== "PRM Ops Scorecard Summary" ? (
                      <DownArrow className="cursor-not-allowed" fill="#000000" />
                      ) : (
                      <div className="flex cursor-pointer ml-5">
                          <DownArrow onClick={() => expandTransactionOptions(!isTransactionOptionsOpen)} fill="black" />
                      </div>
                    )}
                  </>
                </div>
                <div className="absolute z-20 w-full">
                  {isTransactionOptionsOpen && (
                    <>
                      {transactionalOptions.map(({ name, value }) => (
                        <Option
                          key={`key-${value}`}
                          isSelected={selectedCategory === value}
                          value={value}
                          name={name}
                          onSelect={(e, value) => handleTransactionalOptionSelection(e, value)}
                        />
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
            <ScorecardSummaryFilter
              ssSelectedReportFilter={ssSelectedReportFilter}
              dispatch={dispatch}
              ActionTypes={ActionTypes}
              selectedClient={selectedClient}
            />
          </div>
          <div className="flex flex-row justify-end px-4 py-4 w-screen gap-4 bg-black mt-60">
            <Button
              label={resources.EXPORT_ALL}
              className="bg-purple-color text-sm w-28 px-5 py-4 rounded-3xl mr-5 bg-app-purple text-white hover:text-slate-300"
              onClick={() => exportDataOriginal()}
            />
          </div>
        </div>

        <div className="flex px-4 py-4 flex-row gap-4 bg-black w-full mt-4">

          <div className="flex flex-col gap-4">
            <div className="flex text-white text-center justify-center bg-scorecard-lightblue p-4 w-auto rounded-lg">Approval Status</div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Straight Thru <br /> {mapRecordCountAndPercent("Straight_thru_approval", "Requests_Received").recordCount}, {mapRecordCountAndPercent("Straight_thru_approval", "Requests_Received")?.percent}%</div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Risk Holds <br /> {mapRecordCountAndPercent("Risk_Holds", "Risk_Holds").recordCount}, {mapRecordCountAndPercent("Straight_thru_approval", "Requests_Received")?.percent}%</div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Approved <br /> {mapRecordCountAndPercent("Approved_No_HOLD", "Requests_Received").recordCount}, {mapRecordCountAndPercent("Approved_No_HOLD", "Requests_Received")?.percent}%</div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Approved <br /> {mapRecordCountAndPercent("Approved_HOLD", "Risk_Holds").recordCount}, {mapRecordCountAndPercent("Approved_HOLD", "Risk_Holds")?.percent}%</div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Cancelled <br /> {mapRecordCountAndPercent("Cancelled_No_HOLD", "Requests_Received").recordCount}, {mapRecordCountAndPercent("Cancelled_No_HOLD", "Requests_Received")?.percent}%</div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">EH Approval <br /> {mapRecordCountAndPercent("EH_Approval_HOLD", "Risk_Holds").recordCount}, {mapRecordCountAndPercent("EH_Approval_HOLD", "Risk_Holds")?.percent}%</div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Open <br /> {mapRecordCountAndPercent("Open_No_HOLD", "Requests_Received").recordCount}, {mapRecordCountAndPercent("Open_No_HOLD", "Requests_Received")?.percent}%</div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Denied <br /> {mapRecordCountAndPercent("Denied_HOLD", "Risk_Holds").recordCount}, {mapRecordCountAndPercent("Denied_HOLD", "Risk_Holds")?.percent}%</div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Voids <br /> {mapRecordCountAndPercent("Void_No_HOLD", "Requests_Received").recordCount}, {mapRecordCountAndPercent("Void_No_HOLD", "Requests_Received")?.percent}%</div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Cstr Cancelled <br /> {mapRecordCountAndPercent("Cstr_Cancelled_HOLD", "Risk_Holds").recordCount}, {mapRecordCountAndPercent("Cstr_Cancelled_HOLD", "Risk_Holds")?.percent}%</div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="w-40 h-24"></div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Open <br /> {mapRecordCountAndPercent("Open_HOLD", "Risk_Holds").recordCount}, {mapRecordCountAndPercent("Open_HOLD", "Risk_Holds")?.percent}%</div>
            </div>
          </div>

          <div className="flex flex-col gap-4 ml-6">
            <div className="flex text-white text-center justify-center bg-scorecard-greencard p-4 w-auto rounded-lg">Complaints</div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Straight Thru <br /> {mapRecordCountAndPercent("Straight_thru_approval", "Requests_Received").recordCount}, {mapRecordCountAndPercent("Straight_thru_approval", "Requests_Received")?.percent}%</div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Risk Holds <br /> {mapRecordCountAndPercent("Risk_Holds", "Risk_Holds").recordCount}, {mapRecordCountAndPercent("Risk_Holds", "Risk_Holds")?.percent}%</div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Approved <br /> {mapRecordCountAndPercent("Approved_No_HOLD_Complaints_Straight_thru_approval", "Complaints_Straight_thru_approval").recordCount}, {mapRecordCountAndPercent("Approved_No_HOLD_Complaints_Straight_thru_approval", "Complaints_Straight_thru_approval")?.percent}%</div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Approved <br /> {mapRecordCountAndPercent("Approved_HOLD_Complaints_Risk_Holds", "Complaints_Risk_Holds").recordCount}, {mapRecordCountAndPercent("Approved_HOLD_Complaints_Risk_Holds", "Complaints_Risk_Holds")?.percent}%</div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Cancelled <br /> {mapRecordCountAndPercent("Cancelled_No_HOLD_Complaints_Straight_thru_approval", "Complaints_Straight_thru_approval").recordCount}, {mapRecordCountAndPercent("Cancelled_No_HOLD_Complaints_Straight_thru_approval", "Complaints_Straight_thru_approval")?.percent}%</div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">EH Approval <br /> {mapRecordCountAndPercent("EH_Approval_HOLD_Complaints_Risk_Holds", "Complaints_Risk_Holds").recordCount}, {mapRecordCountAndPercent("EH_Approval_HOLD_Complaints_Risk_Holds", "Complaints_Risk_Holds")?.percent}%</div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Open <br /> {mapRecordCountAndPercent("Open_No_HOLD_Complaints_Straight_thru_approval", "Complaints_Straight_thru_approval").recordCount}, {mapRecordCountAndPercent("Open_No_HOLD_Complaints_Straight_thru_approval", "Complaints_Straight_thru_approval")?.percent}%</div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Denied <br /> {mapRecordCountAndPercent("Denied_HOLD_Complaints_Risk_Holds", "Complaints_Risk_Holds").recordCount}, {mapRecordCountAndPercent("Denied_HOLD_Complaints_Risk_Holds", "Complaints_Risk_Holds")?.percent}%</div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Voids <br /> {mapRecordCountAndPercent("Void_No_HOLD_Complaints_Straight_thru_approval", "Complaints_Straight_thru_approval").recordCount}, {mapRecordCountAndPercent("Void_No_HOLD_Complaints_Straight_thru_approval", "Complaints_Straight_thru_approval")?.percent}%</div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Cstr Cancelled <br /> {mapRecordCountAndPercent("Cstr_Cancelled_HOLD_Complaints_Risk_Holds", "Complaints_Risk_Holds").recordCount}, {mapRecordCountAndPercent("Cstr_Cancelled_HOLD_Complaints_Risk_Holds", "Complaints_Risk_Holds")?.percent}%</div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="w-40 h-24"></div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Open <br /> {mapRecordCountAndPercent("Open_HOLD_Complaints_Risk_Holds", "Complaints_Risk_Holds").recordCount}, {mapRecordCountAndPercent("Open_HOLD_Complaints_Risk_Holds", "Complaints_Risk_Holds")?.percent}%</div>
            </div>
          </div>

          <div className="flex flex-col gap-4 ml-6">
            <div className="flex text-white text-center justify-center bg-scorecard-lightgreentwo p-4 w-auto rounded-lg">NPS</div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Straight Thru <br /> {mapRecordCountAndPercent("Straight_thru_approval", "Requests_Received").recordCount}, {mapRecordCountAndPercent("Straight_thru_approval", "Requests_Received")?.percent}% </div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Risk Holds <br /> {mapRecordCountAndPercent("Risk_Holds", "Risk_Holds").recordCount}, {mapRecordCountAndPercent("Risk_Holds", "Risk_Holds")?.percent}% </div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Approved <br /> {mapRecordCountAndPercent("NPS_No_HOLD_Approved", "Customer_Experience_NPS").recordCount}, {mapRecordCountAndPercent("NPS_No_HOLD_Approved", "Customer_Experience_NPS")?.percent}%</div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Approved <br /> {mapRecordCountAndPercent("NPS_HOLD_Approved", "Customer_Experience_NPS").recordCount}, {mapRecordCountAndPercent("NPS_HOLD_Approved", "Customer_Experience_NPS")?.percent}%</div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center p-4 w-40 h-24 rounded-lg"></div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">EH Approval <br />{mapRecordCountAndPercent("NPS_HOLD_EH_Approval", "Customer_Experience_NPS").recordCount}, {mapRecordCountAndPercent("NPS_HOLD_EH_Approval", "Customer_Experience_NPS")?.percent}%</div>
            </div>
          </div>

          <div className="flex flex-col gap-4 pr-10 bg-black ml-6">
            <div className="flex text-white text-center justify-center bg-scorecard-bluecard p-4 w-auto rounded-lg">Voluntary Churns</div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">STP <br /> {mapRecordCountAndPercent("Straight_thru_approval", "Requests_Received").recordCount}, {mapRecordCountAndPercent("Straight_thru_approval", "Requests_Received")?.percent}%</div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">With Holds <br /> {mapRecordCountAndPercent("Risk_Holds", "Risk_Holds").recordCount}, {mapRecordCountAndPercent("Risk_Holds", "Risk_Holds")?.percent}% </div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Approved <br /> {mapRecordCountAndPercent("Approved_Churn_No_HOLD", "Voluntary_Churns_Straight_Thru_Approval").recordCount}, {mapRecordCountAndPercent("Approved_Churn_No_HOLD", "Voluntary_Churns_Straight_Thru_Approval")?.percent}%</div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Approved <br /> {mapRecordCountAndPercent("Approved_Churn_HOLD", "Voluntary_Churns_Risk_Holds").recordCount}, {mapRecordCountAndPercent("Approved_Churn_HOLD", "Voluntary_Churns_Risk_Holds")?.percent}%</div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Cancelled <br /> {mapRecordCountAndPercent("Cancelled_Churn_No_HOLD", "Voluntary_Churns_Straight_Thru_Approval").recordCount}, {mapRecordCountAndPercent("Cancelled_Churn_No_HOLD", "Voluntary_Churns_Straight_Thru_Approval")?.percent}%</div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">EH Approval <br /> {mapRecordCountAndPercent("EH_Approval_Churn_HOLD", "Voluntary_Churns_Risk_Holds").recordCount}, {mapRecordCountAndPercent("EH_Approval_Churn_HOLD", "Voluntary_Churns_Risk_Holds")?.percent}%</div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Voids <br /> {mapRecordCountAndPercent("Voids_Churn_No_HOLD", "Voluntary_Churns_Straight_Thru_Approval").recordCount}, {mapRecordCountAndPercent("Voids_Churn_No_HOLD", "Voluntary_Churns_Straight_Thru_Approval")?.percent}%</div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Denied <br /> {mapRecordCountAndPercent("Denied_HOLD", "Voluntary_Churns_Risk_Holds").recordCount}, {mapRecordCountAndPercent("Denied_HOLD", "Voluntary_Churns_Risk_Holds")?.percent}%</div>
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex justify-center items-center text-black text-center p-4 w-40 h-24 rounded-lg"></div>
              <div className="flex justify-center items-center text-black text-center bg-card-greenBg border-3px border-card-greenBorder p-4 w-40 h-24 rounded-lg">Cancelled <br /> {mapRecordCountAndPercent("Cancelled_Churn_HOLD", "Voluntary_Churns_Risk_Holds").recordCount}, {mapRecordCountAndPercent("Cancelled_Churn_HOLD", "Voluntary_Churns_Risk_Holds")?.percent}%</div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default PRMDashboardDataSummaryThree;
