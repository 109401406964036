import React from "react";

const icon = ({ className, fill, onClick, stroke }) => (
  <svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39.1666 36.6667L37.4999 33.4433V25.8333C37.5699 22.87 34.0316 20.2283 30.8333 17.5" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M33.365 29.167L29.1133 24.822C28.5975 24.2851 27.8334 24.0657 27.1113 24.2472C26.3891 24.4287 25.8196 24.9832 25.6188 25.7003C25.4181 26.4174 25.617 27.187 26.14 27.717L30.8333 32.5004V35.0004C31.0886 36.4913 31.6566 37.9113 32.5 39.167" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M0.833252 3.3335L2.49992 6.55683V14.1668C2.42992 17.1302 5.96825 19.7718 9.16659 22.5002" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.63501 10.8335L10.8867 15.1785C11.4026 15.7154 12.1666 15.9348 12.8888 15.7533C13.6109 15.5718 14.1805 15.0173 14.3812 14.3002C14.5819 13.5832 14.383 12.8135 13.86 12.2835L9.16668 7.50016V5.00016C8.91146 3.5092 8.34344 2.08917 7.50001 0.833496" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.1665 13.4199V38.3333C9.1665 38.7935 9.5396 39.1666 9.99984 39.1666H28.3332" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.3333 4.16699H12.5" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.8333 26.5803V16.667" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21.6667 25.8335H12.5" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M27.5 20.8335H12.5" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20 30.8335H12.5" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.1666 7.50016C34.1666 11.1821 31.1818 14.1668 27.4999 14.1668C23.818 14.1668 20.8333 11.1821 20.8333 7.50016C20.8333 3.81826 23.818 0.833496 27.4999 0.833496" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34.1667 2.5L28.09 8.57667C27.9337 8.7334 27.7214 8.82149 27.5 8.82149C27.2786 8.82149 27.0663 8.7334 26.91 8.57667L25 6.66667" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);
export default icon;
