import { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useCentralStore } from "../../store/appContext";
import Spinner from '../../components/common/spinner/spinner';
import ActionTypes from '../../store/actionTypes';
import API from './../../api';
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {

    const [{ userLoggedIn, authCodeSearch, email, password, loadingData}, dispatch] = useCentralStore();

    const [passworderror,setPasswordError] =useState()
    const [newPassword,setNewPassword] =useState()
    const [confirmPassword,setConfirmPassword] =useState()

    const navigate = useNavigate();

    const setLoading = (action) => {
        dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
      };

    const validatePassword = (values) => {
        const errors = {}
      
        if (!values) {
          setPasswordError('Password is Required')
        } else if (!/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/i.test(values)){
            setPasswordError('Password is not strong')
        }else{
          setPasswordError('')
        }
        return errors
      }

      const handleNewPassword  = (e) => {
        setNewPassword(e.target.value,)
        validatePassword(e.target.value)
      };

      const handleConfirmPassword  = (e) => {
        setConfirmPassword(e.target.value,)
        validatePassword(e.target.value)
      };

      const changePassword = () => {
            if(newPassword === confirmPassword){
                setLoading(true)
                API[ActionTypes.CHANGE_PASSWORD](newPassword)
                .then((data) => {
                    setLoading(false)
                    navigate("/");
                    })
                .catch((err) => {
                    setLoading(false)
                    console.log(`error is ${err}`);
                    });

            }else {
                setPasswordError('Password not matching')
            }
      }

    return(
        <>
        {loadingData && <Spinner showOverlay={true} />}
            <div className="bg-white h-screen text-black">
            <div className="background-container">
            <div className="boxone"></div>
            <div className=" box   "></div>
            <div className="flex justify-evenly">
                <div className="content">
                <img className="mx-auto mt-28 h-32 w-32" src={require('../../assets/Starhub/otpStar.png')}/>
                <div className={``}>
                    <div className={`Logintitle text-center`}>
                    <p className={`texts`}>
                        Change Password
                    </p>
                </div>
           
        <br/>
        <div class="relative">
            <input 
            type="password" 
            id="floating_outlined"
            class="block  px-2.5 pb-2.5 pt-4 w-[300px] text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black-300 appearance-none dark:text-white dark:border-black-600 dark:focus:border-black-500 focus:outline-none focus:ring-0 focus:border-black-600 peer" 
            placeholder="Password"  
            defaultValue={newPassword}
            onChange={handleNewPassword}
            />
            <label for="floating_outlined" class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 color:gray">Enter New Password</label>
        </div>
        <div class="relative mt-5">
            <input 
            type="password" 
            id="floating_outlined"
            class="block  px-2.5 pb-2.5 pt-4 w-[300px] text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black-300 appearance-none dark:text-white dark:border-black-600 dark:focus:border-black-500 focus:outline-none focus:ring-0 focus:border-black-600 peer" 
            placeholder="Password"  
            defaultValue={confirmPassword}
            onChange={handleConfirmPassword}
            />
            <label for="floating_outlined" class="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 color:gray">Confirm Password</label>
        </div>
        <div className="mt-5 mx-m15 flex justify-center">
                            <button
                                id="continue"
                                onClick={() => changePassword()}
                                className="px-4 py-2 bg-app-lightGreen w-full rounded-lg"
                                type="submit"
                                // disabled={logindisable}
                                >
                                Change Password
                            </button>
                        </div>
                        {passworderror && <p className="error">{passworderror}</p>}
                </div>
                </div>
                <div className="flex justify-end">
                <LazyLoadImage
                        className="h-screen"
                    // alt={image.alt}
                    loading="lazy" 
                    src={require('../../assets/icons/LaptopLogin.png')} />
                </div>
                </div>
            </div>
            </div>
            </>
    )
}

export default ChangePassword