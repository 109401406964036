import React from "react";

const icon = ({ className, fill, onClick, stroke }) => (
  <svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M33.333 34.4199L26.7847 35.7299" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M27.5 28.3335L24.41 29.6019C23.4132 30.0044 22.2766 29.5471 21.8364 28.5663C21.3962 27.5856 21.8101 26.4324 22.7733 25.9552L25.8617 24.4552C26.5403 24.1158 27.3287 24.0706 28.0417 24.3302L33.68 26.4869" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15 35.8337H17.3333L21.2167 38.7853C21.6233 39.2415 22.3173 39.2959 22.79 38.9087L28.6233 34.2303C29.1009 33.8377 29.1785 33.1361 28.7983 32.6487L25.595 29.1187" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M22.5167 26.125L22.1 25.7816C21.6795 25.4731 21.1716 25.3067 20.65 25.3066C20.3388 25.306 20.0304 25.3654 19.7417 25.4816L15 27.5" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M10 37.5002H13.3333C14.2538 37.5002 15 36.754 15 35.8335V27.5002C15 26.5797 14.2538 25.8335 13.3333 25.8335H10V37.5002Z" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M38.333 37.5002H34.9997C34.0792 37.5002 33.333 36.754 33.333 35.8335V27.5002C33.333 26.5797 34.0792 25.8335 34.9997 25.8335H38.333V37.5002Z" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M27.4998 0.833496H6.6665C3.90508 0.833496 1.6665 3.07207 1.6665 5.8335V38.3335C1.6665 38.7937 2.0396 39.1668 2.49984 39.1668H6.6665" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.6665 7.5H18.3332" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.6665 12.5H18.3332" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M6.6665 17.5H12.4998" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M23.333 21.6668V5.00016C23.333 2.69898 25.1985 0.833496 27.4997 0.833496C29.7997 0.833496 31.6663 2.6985 31.6663 5.8335H23.333" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
);
export default icon;
