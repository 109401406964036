import { useState, useEffect } from "react";
import ActionTypes from "../../store/actionTypes";
import API from "../../api";
import { useCentralStore } from "../../store/appContext";
import resources from "../../components/common/resources.json";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Popup from "../../components/common/popup/popup";
import CrossIcon from "../../assets/icons/cross-icon";
import exportToCSVInventory from "../../components/common/hepler/export-to-csv-inquirydata";
import { Pie } from 'react-chartjs-2';

const InquiryTradeIn = ({label}) => {

  const [{ userData, userDetails, aiGraphResponseInquirySuccess, aiResponseInquirySuccess, inquiryDataSuccess, ssReportFilterDuration }, dispatch] = useCentralStore();
  const [selectedClient, setSelectedClient] = useState("Samsung");
  const [emailPopup, setEmailPopup] = useState(false);
  const [noDataEmailPopup, setNoDataEmailPopup] = useState(false);
  const [exportErrMsg, setExportErrMsg] = useState(null);
  const navigate = useNavigate();
  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING, payload: action });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedClient) {
        return;
      }
      let startDate = ssReportFilterDuration?.startDate ? ssReportFilterDuration?.startDate : moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD 00:00:00");
      let endDate = ssReportFilterDuration?.endDate ? ssReportFilterDuration?.endDate : moment(new Date()).format("YYYY-MM-DD 23:59:59");
      let payload = {
        StartDate: startDate,
        EndDate: endDate,
        ClientName: selectedClient,
      };
 
      setLoading(true);
      try {
        const data = await API[ActionTypes.GET_INQUIRY_DATA](
          payload,
          userData?.access_token,
          userDetails && userDetails?.email?.split("@")?.[0]
        );
        
        // setLoading(false);
        dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "Trade-In" })
        dispatch({
          type: ActionTypes.GET_INQUIRY_DATA_SUCCESS,
          payload: data,
        });
        API[ActionTypes.GET_INQUIRY_AI_RESPONSE](
          payload,
          userData?.access_token,
          userDetails && userDetails?.email?.split("@")?.[0]
        )
        .then((data) => {
          // setLoading(false);
          dispatch({
            type: ActionTypes.GET_INQUIRY_AI_RESPONSE_SUCCESS,
            payload: data,
          });
        })
        .catch((err) => {
          dispatch({
            type: ActionTypes.GET_INQUIRY_AI_RESPONSE_FAILURE,
            payload: err,
          });
        })
        API[ActionTypes.GET_INQUIRY_AI_GRAPH_RESPONSE](
          payload,
          userData?.access_token,
          userDetails && userDetails?.email?.split("@")?.[0]
        )
        .then((data) => {
          setLoading(false);
          dispatch({
            type: ActionTypes.GET_INQUIRY_AI_GRAPH_RESPONSE_SUCCESS,
            payload: data,
          });
        })
        .catch((err) => {
          dispatch({
            type: ActionTypes.GET_INQUIRY_AI_GRAPH_RESPONSE_FAILURE,
            payload: err,
          });
        })
      } catch (err) {
        dispatch({
          type: ActionTypes.GET_INQUIRY_DATA_FAILURE,
          payload: err,
        });
        dispatch({
          type: ActionTypes.SET_API_ERROR,
          data: true
        });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      }
    };
    fetchData();
    const intervalId = setInterval(fetchData, 10 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [selectedClient, dispatch, navigate, userData, userDetails && ssReportFilterDuration]);

  const referenceSequence = ["Complaints/Escalation", "Inquiry", "Escalation"];
  const uniqueDepartmentTypes = Array.from(new Set(inquiryDataSuccess?.map(item => item.DEPARTMENT_TYPE))).sort();
  const uniqueReferenceTypes = Array.from(new Set(inquiryDataSuccess?.map(item => item.REFERENCE_TYPE)));
  const mappedValues = {};
  referenceSequence?.forEach(reference => {
    mappedValues[reference] = {};
    uniqueDepartmentTypes?.forEach(department => {
        mappedValues[reference][department] = {};
        ["OPEN", "CLSD", "IPGRS"]?.forEach(status => {
            mappedValues[reference][department][status] = {};
            ["Counts_0_to_5_Days", "COUNTS_0_TO_10_DAYS", "Counts_Greater_Than_10_Days"]?.forEach(days => {
                const filteredData = inquiryDataSuccess?.filter(item =>
                    item.REFERENCE_TYPE === reference &&
                    item.DEPARTMENT_TYPE === department &&
                    item.WORK_ITEM_STATUS === status &&
                    item.DAYS === days
                );
                const counts = filteredData?.map(item => item?.[days] ?? 0);
                mappedValues[reference][department][status][days] = counts.length > 0 ? counts : [0];
            });
        });
    });
  });

  const sumOfCountsByDepartment = {};
  inquiryDataSuccess?.forEach(entry => {
    if (entry.DEPARTMENT_TYPE && entry.SUM_OF_COUNT) {
        if (sumOfCountsByDepartment[entry.DEPARTMENT_TYPE]) {
            sumOfCountsByDepartment[entry.DEPARTMENT_TYPE] += entry.SUM_OF_COUNT;
        } else {
            sumOfCountsByDepartment[entry.DEPARTMENT_TYPE] = entry.SUM_OF_COUNT;
        }
    }
  });

  const sumOfCountsByReference = {};
  inquiryDataSuccess?.forEach(entry => {
    if (entry.REFERENCE_TYPE && entry.SUM_OF_COUNT) {
        if (sumOfCountsByReference[entry.REFERENCE_TYPE]) {
            sumOfCountsByReference[entry.REFERENCE_TYPE] += entry.SUM_OF_COUNT;
        } else {
            sumOfCountsByReference[entry.REFERENCE_TYPE] = entry.SUM_OF_COUNT;
        }
    }
  });

  const TotalCount = (sumOfCountsByReference?.['Complaints/Escalation'] || 0) + (sumOfCountsByReference?.Inquiry || 0) 
  const TotalTime = (aiGraphResponseInquirySuccess?.GetAIGraphChartForInquiryResponse?.AvgAIResponseInMinute) + (aiGraphResponseInquirySuccess?.GetAIGraphChartForInquiryResponse?.AvgAgentResponseInMinute)
  const finalTotalTime = TotalTime?.toFixed(4);
  const hours = (finalTotalTime / 60);
  const aiHours = (aiGraphResponseInquirySuccess?.GetAIGraphChartForInquiryResponse?.AvgAIResponseInMinute) / 60
  // const AIPercent = (aiHours / hours) * 100;
  const AIPercent = hours!=0? ((aiHours / hours) * 100).toFixed(3) : 0
  const AIPercentMins = ((aiGraphResponseInquirySuccess?.GetAIGraphChartForInquiryResponse?.AvgAIResponseInMinute) / finalTotalTime) * 100;
  const AIPercent2digits = AIPercentMins?.toFixed(4)
  let convertedHours=hours.toFixed(3)
  const agentAITime=hours!=0 ? convertedHours: 0
  const agentResponseTime=hours!=0 ?( Number(convertedHours) + Number(AIPercent)).toFixed(3) : 0
  let tableColumns = [];

  const exportData = async (REFERENCE_TYPE, WORKQ_ITEM_STATUS, DEPARTMENT_TYPE, AGING, value) => {
    setExportErrMsg(null)
    let startDate = ssReportFilterDuration?.startDate ? ssReportFilterDuration?.startDate : moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD 00:00:00");
    let endDate = ssReportFilterDuration?.endDate ? ssReportFilterDuration?.endDate : moment(new Date()).format("YYYY-MM-DD 23:59:59");
    const payload = {
      STARTDATE: startDate,
      ENDDATE: endDate,
      CLIENT_NAME: selectedClient,
      REFERENCE_TYPE: REFERENCE_TYPE,
      WORKQ_ITEM_STATUS: WORKQ_ITEM_STATUS,
      DEPARTMENT_TYPE: DEPARTMENT_TYPE,
      AGING: AGING,
      "isExport": true,
      // Email: userDetails?.email,
    };
    setLoading(true);
    API[ActionTypes.GET_EXPORT_INQUIRY_DATA](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
        setLoading(false);
        if (data?.ExportInquiryDetailsResponse && data?.ExportInquiryDetailsResponse.length > 0) {
          exportToCSVInventory(
            tableColumns,
            data?.ExportInquiryDetailsResponse,
            "",
            "",
            label,
            dispatch,
            ActionTypes,
            selectedClient,
            AGING
          );
          dispatch({
            type: ActionTypes.GET_EXPORT_INQUIRY_DATA_SUCCESS,
            payload: data?.ExportInquiryDetailsResponse,
          });
          setEmailPopup(true)
          } else {
            dispatch({
              type: ActionTypes.GET_EXPORT_INQUIRY_DATA_FAILURE,
              payload: data,
            });
            setNoDataEmailPopup(true)
          }
      })
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: ActionTypes.GET_EXPORT_INQUIRY_DATA_FAILURE,
          payload: err,
        });
        setExportErrMsg('Error occurred while exporting data...')
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: payload,
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
  };

  const finalData = aiResponseInquirySuccess?.GetAIResponseForInquiryResponse
  const totalEngagements = aiResponseInquirySuccess?.GetAIResponseForInquiryResponse?.TotalEngagement
  const aiResponse = aiResponseInquirySuccess?.GetAIResponseForInquiryResponse?.AiResponse
  const pendingReview = aiResponseInquirySuccess?.GetAIResponseForInquiryResponse?.OpenReview
  const rejected = aiResponseInquirySuccess?.GetAIResponseForInquiryResponse?.Rejected
  const approved = aiResponseInquirySuccess?.GetAIResponseForInquiryResponse?.Approved 
  const manual = (totalEngagements - aiResponse)

  const AIHandledPercentage = ((aiResponse / totalEngagements) * 100) > 0 ? ((aiResponse / totalEngagements) * 100).toFixed(2) : 0
  const PendingPercentage = (pendingReview / aiResponse) * 100 > 0 ? ((pendingReview / aiResponse) * 100).toFixed(2) : 0
  const ManualPercentage = (manual / totalEngagements) * 100 > 0 ? ((manual / totalEngagements) * 100).toFixed(2) : 0
  const rejectedPercentage = (rejected / aiResponse) * 100 > 0 ? ((rejected / aiResponse) * 100).toFixed(2) : 0
  const approvedPercentage = ((approved / aiResponse) * 100) > 0 ? ((approved / aiResponse) * 100).toFixed(2) : 0
  const totalPercentage = AIHandledPercentage + PendingPercentage + ManualPercentage + rejectedPercentage + approvedPercentage

  const optionsPieChart = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
          color: '#ffffff',
          font: {
            color: '#ffffff',
            size: 18,
            weight: 600
          },
        },
      },
      datalabels: {
        display: true,
        align: 'center',
        formatter: function(value, context) {
          return value > 0 ? null : null;
        },
        labels: {
          usePointStyle: true,
          position: "outside",
          font: {
            color: '#ffffff',
            size: 40,
          },
        },
      }
    }
  };

  const dataPieChartSR = {
    type: 'outlabeledPie',
    labels: [`AI Handled ${AIHandledPercentage}%`, `No Response ${ManualPercentage}%`],
    datasets: [
      {
        label: '',
        data: [AIHandledPercentage, ManualPercentage],
        backgroundColor: [
          '#1ED760',
          '#500878',
          '#C800A1',
          '#B2B0EA',
          '#F0AB00'
        ],
        borderColor: [
            '#fff',
            '#fff',
            '#fff'
        ],
        borderWidth: 0,
        font: {
          weight: 700,
          color: 'white',
          size: 40,
        },
      },
    ],
  };

  const dataPieChartSRNew = {
    type: 'outlabeledPie',
    labels: [`Pending Review ${PendingPercentage}%`, `Approved ${approvedPercentage}%`, `Rejected ${rejectedPercentage}%`],
    datasets: [
      {
        label: '',
        data: [PendingPercentage, approvedPercentage, rejectedPercentage],
        backgroundColor: [
          '#C800A1',
          '#B2B0EA',
          '#F0AB00'
        ],
        borderColor: [
            '#fff',
            '#fff',
            '#fff'
        ],
        borderWidth: 0,
        font: {
          weight: 700,
          color: 'white',
          size: 40,
        },
      },
    ],
  };

  return (
    <>
      {/* {loading && <Spinner showOverlay={true} />} */}
      <div className=" bg-black min-h-screen">
        {/* <div className=""><HeaderInquiry /></div> */}

        <div className="flex flex-row justify-start ml-4">
          <div className="flex flex-row gap-2 mt-16 border-card-greenBorder shadow-card-greenBorder border-3px mr-8 px-4 h-1/2 rounded-lg w-1/2"> 
            <div className={`flex flex-col text-left px-2 justify-center cursor-pointer transition ease-in-out delay-350 w-1/4 hover:bg-white duration-500 bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 rounded-lg my-4 h-24 mr-2`}>Total Engagements
              <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{totalEngagements || 0}</div>
            </div>
            <div className={`flex flex-col text-left px-2 justify-center cursor-pointer transition ease-in-out  w-1/5 delay-350 hover:bg-white duration-500 bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 rounded-lg my-4 h-24 mr-2`}>AI Response
              <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{aiResponse || 0}</div>
            </div>
            <div className={`flex flex-col text-left px-2 justify-center cursor-pointer transition ease-in-out  w-1/5 delay-350 hover:bg-white duration-500 bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 rounded-lg my-4 h-24 mr-2`}>Pending Review 
              <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{pendingReview || 0}</div>
            </div>
            <div className={`flex flex-col text-left px-2 justify-center cursor-pointer transition ease-in-out  w-1/5 delay-350 hover:bg-white duration-500 bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 rounded-lg my-4 h-24 mr-2`}>Rejected
              <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{rejected || 0}</div>
            </div>
            <div className={`flex flex-col text-left px-2 justify-center cursor-pointer transition ease-in-out  w-1/5 delay-350 hover:bg-white duration-500 bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 rounded-lg my-4 h-24`}>Approved
              <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{approved || 0}</div>
            </div>
            <div className={`flex flex-col text-left px-2 justify-center cursor-pointer transition ease-in-out  w-1/5 delay-350 hover:bg-white duration-500 bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 rounded-lg my-4 h-24`}>No Response
              <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{manual || 0}</div>
            </div>
          </div>
          {totalPercentage !== 0 && 
            <div className='chart-container'>
              <Pie data={dataPieChartSR} options={optionsPieChart} />
            </div>
          }
          {totalPercentage !== 0 && 
            <div className='chart-container'>
              <Pie data={dataPieChartSRNew} options={optionsPieChart} />
            </div>
          }
        </div>

        <div className={`flex flex-col overflow-x-auto ${totalPercentage === 0 ? 'mt-16' : '-mt-16'}`}>
          <div className="flex flex-row px-4 pb-4 w-full min-w-min gap-12">
            <div>
              <div className="flex text-center justify-center items-center bg-gradient-to-r from-app-purple to-app-prmnewpurple p-4 w-52 rounded-lg mb-3 h-24 font-bold text-xl">Customer Engagements <br /> {TotalCount}</div>
              <div className="flex text-center justify-center items-center bg-gradient-to-r from-app-prmnewgrey to-app-prmnewlightgrey p-4 w-52 rounded-lg mb-3 h-52 font-bold mt-8 text-xl">Complaints/<br />Escalation <br />{sumOfCountsByReference?.['Complaints/Escalation'] || 0}</div>
              <div className="flex text-center justify-center items-center bg-gradient-to-r from-app-deepGreen1 to-app-prmdeepgreennew p-4 w-52 rounded-lg mb-3 h-52 font-bold mt-8 text-xl">Inquiries <br />{sumOfCountsByReference?.Inquiry || 0}</div>

            </div>

            <div className="flex flex-col">
              <div className="flex text-center justify-center items-center bg-gradient-to-r from-app-prmnewgrey to-app-prmnewlightgrey p-4 w-auto rounded-lg mb-3 h-24 font-bold text-xl">Care <br />{sumOfCountsByDepartment?.Care || 0}</div>
              <div className="flex flex-col">
                <div className="flex flex-row gap-4 mt-4">
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Care"] ? mappedValues["Complaints/Escalation"]["Care"]["OPEN"]["COUNTS_0_TO_10_DAYS"]?.some(value => value !== 0) && "" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Open", "Care", 10)}>{resources.OPEN_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Complaints/Escalation"]["Care"] ? (
                      mappedValues["Complaints/Escalation"]["Care"]["OPEN"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Care"] ? mappedValues["Complaints/Escalation"]["Care"]["IPGRS"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Inprogress", "Care", 10)}>{resources.IPGRS_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Complaints/Escalation"]["Care"] ? (
                      mappedValues["Complaints/Escalation"]["Care"]["IPGRS"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Care"] ? mappedValues["Complaints/Escalation"]["Care"]["CLSD"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Closed", "Care", 10)}>{resources.CLSD_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Complaints/Escalation"]["Care"] ? (
                      mappedValues["Complaints/Escalation"]["Care"]["CLSD"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                </div>
                <div className="flex flex-row gap-4">
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Care"] ? mappedValues["Complaints/Escalation"]["Care"]["OPEN"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "red-cards bg-card-redBg border-card-redBorder shadow-card-redBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Open", "Care", 11)}>{resources.OPEN_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Complaints/Escalation"]["Care"] ? (
                      mappedValues["Complaints/Escalation"]["Care"]["OPEN"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Care"] ? mappedValues["Complaints/Escalation"]["Care"]["IPGRS"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "amber-cards bg-yellow-100 border-yellow-400 shadow-yellow-400" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Inprogress", "Care", 11)}>{resources.IPGRS_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Complaints/Escalation"]["Care"] ? (
                      mappedValues["Complaints/Escalation"]["Care"]["IPGRS"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Care"] ? mappedValues["Complaints/Escalation"]["Care"]["CLSD"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Closed", "Care", 11)}>{resources.CLSD_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Complaints/Escalation"]["Care"] ? (
                      mappedValues["Complaints/Escalation"]["Care"]["CLSD"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row gap-4 mt-4">
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Care"] ? mappedValues["Inquiry"]["Care"]["OPEN"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Open", "Care", 10)}>{resources.OPEN_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Inquiry"]["Care"] ? (
                      mappedValues["Inquiry"]["Care"]["OPEN"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Care"] ? mappedValues["Inquiry"]["Care"]["IPGRS"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Inprogress", "Care", 10)}>{resources.IPGRS_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Inquiry"]["Care"] ? (
                      mappedValues["Inquiry"]["Care"]["IPGRS"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Care"] ? mappedValues["Inquiry"]["Care"]["CLSD"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Closed", "Care", 10)}>{resources.CLSD_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Inquiry"]["Care"] ? (
                      mappedValues["Inquiry"]["Care"]["CLSD"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                </div>
                <div className="flex flex-row gap-4">
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Care"] ? mappedValues["Inquiry"]["Care"]["OPEN"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "red-cards bg-card-redBg border-card-redBorder shadow-card-redBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Open", "Care", 11)}>{resources.OPEN_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Inquiry"]["Care"] ? (
                      mappedValues["Inquiry"]["Care"]["OPEN"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Care"] ? mappedValues["Inquiry"]["Care"]["IPGRS"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "amber-cards bg-yellow-100 border-yellow-400 shadow-yellow-400" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Inprogress", "Care", 11)}>{resources.IPGRS_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Inquiry"]["Care"] ? (
                      mappedValues["Inquiry"]["Care"]["IPGRS"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Care"] ? mappedValues["Inquiry"]["Care"]["CLSD"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Closed", "Care", 11)}>{resources.CLSD_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Inquiry"]["Care"] ? (
                      mappedValues["Inquiry"]["Care"]["CLSD"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="flex text-center justify-center items-center bg-gradient-to-r from-app-deepGreen1 to-app-prmdeepgreennew p-4 w-auto rounded-lg mb-3 h-24 font-bold text-xl">SCM <br /> {sumOfCountsByDepartment["Supply Chain"]  || 0}</div>
              <div className="flex flex-col">
                <div className="flex flex-row gap-4 mt-4">
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Supply Chain"] ? mappedValues["Complaints/Escalation"]["Supply Chain"]["OPEN"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Open", "Supply Chain", 10)}>{resources.OPEN_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Complaints/Escalation"]["Supply Chain"] ? (
                      mappedValues["Complaints/Escalation"]["Supply Chain"]["OPEN"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Supply Chain"] ? mappedValues["Complaints/Escalation"]["Supply Chain"]["IPGRS"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Inprogress", "Supply Chain", 10)}>{resources.IPGRS_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Complaints/Escalation"]["Supply Chain"] ? (
                      mappedValues["Complaints/Escalation"]["Supply Chain"]["IPGRS"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Supply Chain"] ? mappedValues["Complaints/Escalation"]["Supply Chain"]["CLSD"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Closed", "Supply Chain", 10)}>{resources.CLSD_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Complaints/Escalation"]["Supply Chain"] ? (
                      mappedValues["Complaints/Escalation"]["Supply Chain"]["CLSD"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                </div>
                <div className="flex flex-row gap-4">
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Supply Chain"] ? mappedValues["Complaints/Escalation"]["Supply Chain"]["OPEN"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "red-cards bg-card-redBg border-card-redBorder shadow-card-redBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Open", "Supply Chain", 11)}>{resources.OPEN_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Complaints/Escalation"]["Supply Chain"] ? (
                      mappedValues["Complaints/Escalation"]["Supply Chain"]["OPEN"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Supply Chain"] ? mappedValues["Complaints/Escalation"]["Supply Chain"]["IPGRS"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "amber-cards bg-yellow-100 border-yellow-400 shadow-yellow-400" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Inprogress", "Supply Chain", 11)}>{resources.IPGRS_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Complaints/Escalation"]["Supply Chain"] ? (
                      mappedValues["Complaints/Escalation"]["Supply Chain"]["IPGRS"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Supply Chain"] ? mappedValues["Complaints/Escalation"]["Supply Chain"]["CLSD"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Closed", "Supply Chain", 11)}>{resources.CLSD_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Complaints/Escalation"]["Supply Chain"] ? (
                      mappedValues["Complaints/Escalation"]["Supply Chain"]["CLSD"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row  gap-4 mt-4">
                </div>
                <div className="flex flex-row gap-4">
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Supply Chain"] ? mappedValues["Inquiry"]["Supply Chain"]["OPEN"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Open", "Supply Chain", 10)}>{resources.OPEN_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Inquiry"]["Supply Chain"] ? (
                      mappedValues["Inquiry"]["Supply Chain"]["OPEN"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Supply Chain"] ? mappedValues["Inquiry"]["Supply Chain"]["IPGRS"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Inprogress", "Supply Chain", 10)}>{resources.IPGRS_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Inquiry"]["Supply Chain"] ? (
                      mappedValues["Inquiry"]["Supply Chain"]["IPGRS"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Supply Chain"] ? mappedValues["Inquiry"]["Supply Chain"]["CLSD"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Closed", "Supply Chain", 10)}>{resources.CLSD_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Inquiry"]["Supply Chain"] ? (
                      mappedValues["Inquiry"]["Supply Chain"]["CLSD"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                </div>
                <div className="flex flex-row gap-4">
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Supply Chain"] ? mappedValues["Inquiry"]["Supply Chain"]["OPEN"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "red-cards bg-card-redBg border-card-redBorder shadow-card-redBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Open", "Supply Chain", 11)}>{resources.OPEN_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Inquiry"]["Supply Chain"] ? (
                      mappedValues["Inquiry"]["Supply Chain"]["OPEN"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Supply Chain"] ? mappedValues["Inquiry"]["Supply Chain"]["IPGRS"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "amber-cards bg-yellow-100 border-yellow-400 shadow-yellow-400" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Inprogress", "Supply Chain", 11)}>{resources.IPGRS_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Inquiry"]["Supply Chain"] ? (
                      mappedValues["Inquiry"]["Supply Chain"]["IPGRS"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Supply Chain"] ? mappedValues["Inquiry"]["Supply Chain"]["CLSD"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Closed", "Supply Chain", 11)}>{resources.CLSD_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Inquiry"]["Supply Chain"] ? (
                      mappedValues["Inquiry"]["Supply Chain"]["CLSD"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
            
            <div className="flex flex-col">
              <div className="flex text-center justify-center items-center bg-gradient-to-r from-app-prmnewblue1 to-app-prmnewblue2 p-4 w-auto rounded-lg mb-3 h-24 font-bold text-xl">Finance <br /> {sumOfCountsByDepartment?.Finance  || 0}</div>
              <div className="flex flex-col">
                <div className="flex flex-row gap-4 mt-4">
                </div>
                <div className="flex flex-row gap-4">
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Finance"] ? mappedValues["Complaints/Escalation"]["Finance"]["OPEN"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Open", "Finance", 10)}>{resources.OPEN_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Complaints/Escalation"]["Finance"] ? (
                      mappedValues["Complaints/Escalation"]["Finance"]["OPEN"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Finance"] ? mappedValues["Complaints/Escalation"]["Finance"]["IPGRS"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Inprogress", "Finance", 10)}>{resources.IPGRS_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Complaints/Escalation"]["Finance"] ? (
                      mappedValues["Complaints/Escalation"]["Finance"]["IPGRS"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Finance"] ? mappedValues["Complaints/Escalation"]["Finance"]["CLSD"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Closed", "Finance", 10)}>{resources.CLSD_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Complaints/Escalation"]["Finance"] ? (
                      mappedValues["Complaints/Escalation"]["Finance"]["CLSD"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                </div>
                <div className="flex flex-row gap-4">
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Finance"] ? mappedValues["Complaints/Escalation"]["Finance"]["OPEN"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "red-cards bg-card-redBg border-card-redBorder shadow-card-redBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Open", "Finance", 11)}>{resources.OPEN_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Complaints/Escalation"]["Finance"] ? (
                      mappedValues["Complaints/Escalation"]["Finance"]["OPEN"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Finance"] ? mappedValues["Complaints/Escalation"]["Finance"]["IPGRS"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "amber-cards bg-yellow-100 border-yellow-400 shadow-yellow-400" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Inprogress", "Finance", 11)}>{resources.IPGRS_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Complaints/Escalation"]["Finance"] ? (
                      mappedValues["Complaints/Escalation"]["Finance"]["IPGRS"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["Finance"] ? mappedValues["Complaints/Escalation"]["Finance"]["CLSD"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Closed", "Finance", 11)}>{resources.CLSD_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Complaints/Escalation"]["Finance"] ? (
                      mappedValues["Complaints/Escalation"]["Finance"]["CLSD"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row gap-4 mt-4">
                </div>
                <div className="flex flex-row gap-4">
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Finance"] ? mappedValues["Inquiry"]["Finance"]["OPEN"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Open", "Finance", 10)}>{resources.OPEN_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Inquiry"]["Finance"] ? (
                      mappedValues["Inquiry"]["Finance"]["OPEN"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Finance"] ? mappedValues["Inquiry"]["Finance"]["IPGRS"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Inprogress", "Finance", 10)}>{resources.IPGRS_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Inquiry"]["Finance"] ? (
                      mappedValues["Inquiry"]["Finance"]["IPGRS"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Finance"] ? mappedValues["Inquiry"]["Finance"]["CLSD"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Closed", "Finance", 10)}>{resources.CLSD_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Inquiry"]["Finance"] ? (
                      mappedValues["Inquiry"]["Finance"]["CLSD"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                </div>
                <div className="flex flex-row gap-4">
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Finance"] ? mappedValues["Inquiry"]["Finance"]["OPEN"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "red-cards bg-card-redBg border-card-redBorder shadow-card-redBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Open", "Finance", 11)}>{resources.OPEN_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Inquiry"]["Finance"] ? (
                      mappedValues["Inquiry"]["Finance"]["OPEN"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Finance"] ? mappedValues["Inquiry"]["Finance"]["IPGRS"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "amber-cards bg-yellow-100 border-yellow-400 shadow-yellow-400" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Inprogress", "Finance", 11)}>{resources.IPGRS_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Inquiry"]["Finance"] ? (
                      mappedValues["Inquiry"]["Finance"]["IPGRS"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["Finance"] ? mappedValues["Inquiry"]["Finance"]["CLSD"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Closed", "Finance", 11)}>{resources.CLSD_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Inquiry"]["Finance"] ? (
                      mappedValues["Inquiry"]["Finance"]["CLSD"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
            
            <div className="flex flex-col">
              <div className="flex text-center justify-center items-center bg-gradient-to-r from-app-prmnewlemon1 to-app-prmnewlemon2 p-4 w-auto rounded-lg mb-3 h-24 font-bold text-xl">RISK/IT <br /> {sumOfCountsByDepartment?.['IT/RISK']  || 0}</div>
              <div className="flex flex-col">
                <div className="flex flex-row gap-4 mt-4">
                </div>
                <div className="flex flex-row gap-4">
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${mappedValues["Complaints/Escalation"]["IT/RISK"] ? mappedValues["Complaints/Escalation"]["IT/RISK"]["OPEN"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Open", "IT/RISK", 10)}>{resources.OPEN_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Complaints/Escalation"]["IT/RISK"] ? (
                      mappedValues["Complaints/Escalation"]["IT/RISK"]["OPEN"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["IT/RISK"] ? mappedValues["Complaints/Escalation"]["IT/RISK"]["IPGRS"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Inprogress", "IT/RISK", 10)}>{resources.IPGRS_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Complaints/Escalation"]["IT/RISK"] ? (
                      mappedValues["Complaints/Escalation"]["IT/RISK"]["IPGRS"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["IT/RISK"] ? mappedValues["Complaints/Escalation"]["IT/RISK"]["CLSD"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Closed", "IT/RISK", 10)}>{resources.CLSD_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Complaints/Escalation"]["IT/RISK"] ? (
                      mappedValues["Complaints/Escalation"]["IT/RISK"]["CLSD"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                </div>
                <div className="flex flex-row gap-4">
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["IT/RISK"] ? mappedValues["Complaints/Escalation"]["IT/RISK"]["OPEN"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "red-cards bg-card-redBg border-card-redBorder shadow-card-redBorder": ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Open", "IT/RISK", 11)}>{resources.OPEN_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Complaints/Escalation"]["IT/RISK"] ? (
                      mappedValues["Complaints/Escalation"]["IT/RISK"]["OPEN"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["IT/RISK"] ? mappedValues["Complaints/Escalation"]["IT/RISK"]["IPGRS"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "amber-cards bg-yellow-100 border-yellow-400 shadow-yellow-400" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Inprogress", "IT/RISK", 11)}>{resources.IPGRS_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Complaints/Escalation"]["IT/RISK"] ? (
                      mappedValues["Complaints/Escalation"]["IT/RISK"]["IPGRS"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Complaints/Escalation"]["IT/RISK"] ? mappedValues["Complaints/Escalation"]["IT/RISK"]["CLSD"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Complaints/Escalation", "Closed", "IT/RISK", 11)}>{resources.CLSD_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Complaints/Escalation"]["IT/RISK"] ? (
                      mappedValues["Complaints/Escalation"]["IT/RISK"]["CLSD"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex flex-row gap-4 mt-4">
                </div>
                <div className="flex flex-row gap-4">
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["IT/RISK"] ? mappedValues["Inquiry"]["IT/RISK"]["OPEN"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Open", "IT/RISK", 10)}>{resources.OPEN_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Inquiry"]["IT/RISK"] ? (
                      mappedValues["Inquiry"]["IT/RISK"]["OPEN"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["IT/RISK"] ? mappedValues["Inquiry"]["IT/RISK"]["IPGRS"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Inprogress", "IT/RISK", 10)}>{resources.IPGRS_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Inquiry"]["IT/RISK"] ? (
                      mappedValues["Inquiry"]["IT/RISK"]["IPGRS"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["IT/RISK"] ? mappedValues["Inquiry"]["IT/RISK"]["CLSD"]["COUNTS_0_TO_10_DAYS"].some(value => value !== 0) && "perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder" : ""}  transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Closed", "IT/RISK", 10)}>{resources.CLSD_SCRIPT} <br />{resources.ZERO_TEN_DAYS}
                    { mappedValues["Inquiry"]["IT/RISK"] ? (
                      mappedValues["Inquiry"]["IT/RISK"]["CLSD"]["COUNTS_0_TO_10_DAYS"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                </div>
                <div className="flex flex-row gap-4">
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["IT/RISK"] ? mappedValues["Inquiry"]["IT/RISK"]["OPEN"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "red-cards bg-card-redBg border-card-redBorder shadow-card-redBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Open", "IT/RISK", 11)}>{resources.OPEN_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Inquiry"]["IT/RISK"] ? (
                      mappedValues["Inquiry"]["IT/RISK"]["OPEN"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["IT/RISK"] ? mappedValues["Inquiry"]["IT/RISK"]["IPGRS"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "amber-cards bg-yellow-100 border-yellow-400 shadow-yellow-400" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Inprogress", "IT/RISK", 11)}>{resources.IPGRS_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Inquiry"]["IT/RISK"] ? (
                      mappedValues["Inquiry"]["IT/RISK"]["IPGRS"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                  <div className={`flex flex-col text-left px-2 justify-center cursor-pointer ${ mappedValues["Inquiry"]["IT/RISK"] ? mappedValues["Inquiry"]["IT/RISK"]["CLSD"]["Counts_Greater_Than_10_Days"].some(value => value !== 0) && "perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder" : ""} transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover:bg-white duration-500 perf-card bg-card-greenBg border-card-greenBorder shadow-card-greenBorder border-3px text-black p-2 w-32 rounded-lg mb-4 h-24 mr-2`} onClick={() => exportData("Inquiry", "Closed", "IT/RISK", 11)}>{resources.CLSD_SCRIPT} <br />{resources.Greater_than_10_days_script}
                    { mappedValues["Inquiry"]["IT/RISK"] ? (
                      mappedValues["Inquiry"]["IT/RISK"]["CLSD"]["Counts_Greater_Than_10_Days"]?.map(value => (
                        <div className="text-black pt-3 hover:font-bold text-2xl text-left px-2">{value || 0}</div>
                      ))
                      ) : (
                        <div className="text-2xl text-left text-black pt-3 px-2 hover:font-bold">0</div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>

        <Popup
          show={emailPopup}
          global={true}
          fitContent={true}
          emailPopup={true}
          onClose={() => setEmailPopup(false)}
        >
          <div className="p-4 pb-6 border-4 border-app-purple rounded-md" style={{height: "auto !important"}}>
          <div className="flex justify-center bg-app-purple px-3 pt-6px pb-6px items-center font-bold rounded-lg -mt-9 w-20 text-center"><p className="text-white">Export</p></div>
            <div className="flex justify-end">
              <CrossIcon className="cursor-pointer" onClick={() => setEmailPopup(false)} />
            </div>
            <p className="text-app-purple text-center font-semibold inline-block mt-2 px-4">
              {resources.INQUIRY_EMAIL_ALERT_MSG}
              <br />
              <p className="text-sm text-center">{resources.EMAIL_ALERT_CLOSE_MSG}</p>
            </p>
          </div>
        </Popup> 

        <Popup
          show={noDataEmailPopup}
          global={true}
          fitContent={true}
          noDataEmailPopup={true}
          onClose={() => setNoDataEmailPopup(false)}
        >
          <div className="p-4 pb-6 border-4 border-app-purple rounded-md bg-white" style={{height: "auto !important"}}>
          <div className="flex justify-center bg-app-purple px-3 pt-6px pb-6px items-center font-bold rounded-lg -mt-9 w-20 text-center"><p className="text-white">Export</p></div>
            <div className="flex justify-end">
              <CrossIcon className="cursor-pointer" onClick={() => setNoDataEmailPopup(false)} />
            </div>
            <p className="text-app-purple text-center font-semibold inline-block mt-2 px-4">
              No data.
              <br />
              <p className="text-sm text-center">{resources.EMAIL_ALERT_CLOSE_MSG}</p>
            </p>
          </div>
        </Popup>           

      </div>
    </>
  );
};

export default InquiryTradeIn;
