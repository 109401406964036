import React from "react";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

function _parseData(jsonData) {
  return typeof jsonData != "object" ? JSON.parse(jsonData) : jsonData;
}

function _isFloat(input) {
  return +input === input && (!isFinite(input) || Boolean(input % 1));
}

function _formatData(data) {
  if (typeof data === "string") {
    data = data.replace(/"/g, '""');
    if (
      _isFloat(data) ||
      data.indexOf(",") > -1 ||
      data.indexOf("\n") > -1 ||
      data.indexOf("\r") > -1
    ) {
      data = '"' + data + '"';
    }
    return data;
  }
  if (typeof data === "boolean") {
    return data ? "TRUE" : "FALSE";
  }
  return data;
}

// expects filename
export function generateCSV(args) {
  let data, filename, link;

  let parsedData = _parseData(args.data);

  let csvContent = convertArrayOfObjectsToCSV({
    data: parsedData,
    headers: args.headers,
  });

  if (csvContent == null) return false;

  filename = (args.filename && `${args.filename}.csv`) || "export.csv";

  csvContent = "\ufeff" + csvContent; // BOM

  let blob = new Blob([csvContent], { type: "text/csv;charset=utf8;" });

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    data = URL.createObjectURL(blob);
    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
  }
}

// expects data, headers
function convertArrayOfObjectsToCSV(args) {
  if (!args.data) return null;

  let ctr;
  let { data = [], headers = [], lineDelimiter, columnDelimiter } = args;

  let keys = Object.keys(data[0]);

  columnDelimiter = columnDelimiter || ",";
  lineDelimiter = lineDelimiter || "\r\n";

  let result = "";
  result += headers.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach((item) => {
    ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      result += _formatData(item[key]);
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}



export const saveExcel = async (args) => {
  const workbook = new Excel.Workbook();
    try {
      args.dispatch({ type: args.ActionTypes.SET_LOADING, payload: true });
      const fileName = args?.workBookName;
      const worksheet = workbook.addWorksheet(args?.workSheetName);

      worksheet.columns = args?.columns;
      worksheet.getRow(1).font = { bold: true };

      worksheet.columns.forEach(column => {
        column.width = column.header.length + 5;
        // column.alignment = { horizontal: 'center' };
      });

      args?.data.forEach(singleData => {
        worksheet.addRow(singleData);
      });

      // worksheet.eachRow({ includeEmpty: false }, row => {
      //   const currentCell = row._cells;

      //   currentCell.forEach(singleCell => {
      //     const cellAddress = singleCell._address;

      //     worksheet.getCell(cellAddress).border = {
      //       top: { style: 'thin' },
      //       left: { style: 'thin' },
      //       bottom: { style: 'thin' },
      //       right: { style: 'thin' }
      //     };
      //   });
      // });

      const buf = await workbook.xlsx.writeBuffer();
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      args.dispatch({ type: args.ActionTypes.SET_LOADING, payload: false });
      // console.error('<<<ERRROR>>>', error);
      // console.error('Something Went Wrong', error.message);
    } finally {
      workbook.removeWorksheet(args?.workSheetName);
      args.dispatch({ type: args.ActionTypes.SET_LOADING, payload: false });
    }
  };
