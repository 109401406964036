import React from "react";

const icon = ({ className, fill, onClick, stroke }) => (
  <svg className={className} width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.5002 32.5H3.8335" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.6667 35.417V35.417C14.8968 35.417 15.0833 35.6035 15.0833 35.8337V35.8337C15.0833 36.0638 14.8968 36.2503 14.6667 36.2503V36.2503C14.4365 36.2503 14.25 36.0638 14.25 35.8337V35.8337C14.25 35.6035 14.4365 35.417 14.6667 35.417" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.5002 25.0002V35.8335C25.5002 37.6744 24.0078 39.1668 22.1668 39.1668H7.16683C5.32588 39.1668 3.8335 37.6744 3.8335 35.8335V4.16683C3.8335 2.32588 5.32588 0.833496 7.16683 0.833496H15.5002" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.5 5.47699V4.16699" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M28.0001 5.8335H24.6118C23.5408 5.83424 22.6206 6.59415 22.4173 7.64573C22.2141 8.6973 22.7848 9.74537 23.7785 10.1452L27.2185 11.5218C28.2121 11.9216 28.7828 12.9697 28.5796 14.0213C28.3763 15.0728 27.4562 15.8327 26.3851 15.8335H23.0001" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.5 17.4999V16.1899" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.5 20.8335C31.0228 20.8335 35.5 16.3563 35.5 10.8335C35.5 5.31065 31.0228 0.833496 25.5 0.833496C19.9772 0.833496 15.5 5.31065 15.5 10.8335C15.5 16.3563 19.9772 20.8335 25.5 20.8335Z" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);
export default icon;
