import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ActionTypes from "../../store/actionTypes";
import { useCentralStore } from "../../store/appContext";
import { getParameterByName, isEmpty } from "../../utils/functionUtils";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import Button from "../../components/common/button/button";
import Spinner from "../../components/common/spinner/spinner";
import resources from '../../components/common/resources.json'

const Login = ({ signinRedirect, generateToken }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [{ userLoggedIn, authCodeSearch }, dispatch] = useCentralStore();

  // useEffect(()=>{
  //   signinRedirect();
  // })

  const handleLoginButton = () => {
    signinRedirect();
    // dispatch({ type: ActionTypes.SET_USER_LOGGED_IN, payload: true });
  };

  // useEffect(()=>{
  //   signinRedirect();
  // }, [])

  const navigateToDashboard = useCallback(() => {
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    if (userLoggedIn) {
      navigateToDashboard();
    }
  }, [navigateToDashboard, userLoggedIn]);

  useEffect(() => {
    if (userLoggedIn) {
      navigateToDashboard();
    }
    if (
      !isEmpty(authCodeSearch) &&
      authCodeSearch.startsWith("?code=") &&
      authCodeSearch.includes("&state=")
    ) {
      handleLoginScenario();
    } else {
      signinRedirect();
    }
    window.history.pushState(null, null, document.URL);
    window.addEventListener('popstate', function(event) {
      window.location.replace(`/`);
    });
  }, []);

  const handleLoginScenario = async () => {
    const codeNew = getParameterByName("code", authCodeSearch);
    const code = codeNew?.code
    const stateNew = getParameterByName("state", authCodeSearch);
    const state = stateNew?.state
    const user1 = await generateToken(
      code,
      state,
      window.localStorage.getItem(`oidc.${state}`)
    );
    if (!isEmpty(user1)) {
      dispatch({ type: ActionTypes.SET_USER_LOGGED_IN, payload: true });
      dispatch({ type: ActionTypes.SET_USER_DATA, payload: user1 });
      navigateToDashboard();
    }
  };

  return (
    <div className="loadingBgImage">
      <Spinner showOverlay={true} loadingText={resources.LOADING_SCRIPT} />
      {/* <div className="spinner-text">OneOps- Operations Re-envision</div> */}
      {/* <Logo className="w-80 m-auto" />
      <div className="w-full flex justify-center py-8">
      <Button onClick={handleLoginButton} label="Sign in with Asurion" />
      </div> */}
    </div>
  );
};

export default Login;
