import React from 'react'

const icon = ({ className, fill, onClick }) => 
(
    <svg className={className} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_d_258_194)">
		<circle cx="15" cy="15" r="8" fill="url(#paint0_radial_258_194)"/>
		<circle cx="15" cy="15" r="8" fill="url(#paint1_radial_258_194)"/>
		<circle cx="15" cy="15" r="8.5" stroke="#FF9595"/>
		</g>
		<defs>
		<filter id="filter0_d_258_194" x="0" y="0" width="30" height="30" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
		<feFlood flood-opacity="0" result="BackgroundImageFix"/>
		<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
		<feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_258_194"/>
		<feOffset/>
		<feGaussianBlur stdDeviation="2"/>
		<feComposite in2="hardAlpha" operator="out"/>
		<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.314974 0 0 0 0 0.440562 0 0 0 0.3 0"/>
		<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_258_194"/>
		<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_258_194" result="shape"/>
		</filter>
		<radialGradient id="paint0_radial_258_194" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15 15) rotate(90) scale(8)">
		<stop stop-color="#FF5070"/>
		<stop offset="1" stop-color="#DB1B1B"/>
		</radialGradient>
		<radialGradient id="paint1_radial_258_194" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.5 8) rotate(72.646) scale(8.38153)">
		<stop stop-color="#FF7E7E"/>
		<stop offset="1" stop-color="white" stop-opacity="0"/>
		</radialGradient>
		</defs>
	</svg>
)
export default icon


