import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import faker from "faker";
import { useState } from "react";
import { useCentralStore } from "../../../store/appContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ServiceRequestReport = ({
  chartReportCountSuccess,
  reportName,
  getBgColor,
}) => {
  const options = {
    plugins: {
      title: {
        display: true,
        text: reportName,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const ServiceRequestReportCount = {
    Months: chartReportCountSuccess["ServiceRequestReportCount"]?.map(
      (v) => v?.Month
    ),
    ["Total SR"]: chartReportCountSuccess["ServiceRequestReportCount"]?.map(
      (v) => v?.["Total Service Requests"]
    ),
    ["Accepted SR"]: chartReportCountSuccess["ServiceRequestReportCount"]?.map(
      (v) => v?.["Accepted Service Requests"]
    ),
    ["Cancelled SR"]: chartReportCountSuccess["ServiceRequestReportCount"]?.map(
      (v) => v?.["Canceled Service Requests"]
    ),
    ["Rejected SR"]: chartReportCountSuccess["ServiceRequestReportCount"]?.map(
      (v) => v?.["Rejected Service Requests"]
    ),
  };

  let labelssorting = ServiceRequestReportCount?.Months;

  const sorter = (a, b) => {
    a = a.split("-");
    b = b.split("-");
    if (a[0] !== b[0]) {
      return a[0] - b[0];
    } else {
      return a[1] - b[1];
    }
  };
 const labels = labelssorting.sort(sorter);

  let subscriptionLabel = ["Accepted SR", "Cancelled SR", "Rejected SR"];
  // 'Total Service Request',

  let _reportData = [];
  for (let i = 0; i < subscriptionLabel.length; i++) {
    _reportData.push({
      label: subscriptionLabel[i],
      data: ServiceRequestReportCount[subscriptionLabel[i]],
      backgroundColor: getBgColor[i],
      stack: 0,
    });
  }

  const reportData = {
    labels,
    datasets: _reportData,
  };

  return (
    <div>
      <Bar options={options} data={reportData} />
    </div>
  );
};

export default ServiceRequestReport;
