import React from 'react'

const icon = ({ className, fill, onClick }) => 
(
    <svg className={className} onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00005 5.72363L11.8048 9.52837L10.862 10.4712L8.00005 7.60925L5.13812 10.4712L4.19531 9.52837L8.00005 5.72363Z" fill={fill} />
    </svg>

)
export default icon


