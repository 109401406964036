import { generateCSV, saveExcel } from "./export-to-csv-native";
import moment from "moment";
import resources from "../../common/resources.json";

export default function exportToCSVInventory(tableColumns,rawData, callback, location, label, dispatch, ActionTypes, ClientName, AGING) {
  const now = moment().format("MMDDYYYY");
  let columnHeders = []
  tableColumns?.map(v => columnHeders.push({
    header: v.key, key: v.key
  }))

  if(columnHeders?.length === 0){
    const totalKeys = {};
    rawData.reduce((acc, current) => {
      const keys = Object.keys(current);
      for (var i in keys) {
        totalKeys[keys[i]] = 1;
      }
    }, totalKeys);

    var keys = Object.keys(totalKeys);
    for (var i in keys) {
      columnHeders.push({
        header: keys[i], key: keys[i]
      })
    }
  }

  let csvData = rawData?.map(data => data)
  const departmentTypes = [...new Set(rawData.map(item => item.DEPARTMENT_TYPE))];  
  const statusType = [...new Set(rawData.map(item => item.STATUS))];
  rawData?.map(data => data?.STATUS)
  const range = AGING === 10 ? "in_between 0-10" : AGING === 11 ? "greater_than_10_days" : "";
  saveExcel({
    workBookName: `${ClientName}_${departmentTypes}_${statusType}_${range}_days_export_${now}`,
    data: csvData,
    workSheetName: label,
    columns: columnHeders,
    dispatch: dispatch,
    ActionTypes: ActionTypes
  })

}
