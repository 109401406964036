import Routes from "./routes";
import ContextProvider from "./store/appContext";
import reducer, { initialState } from "./store/reducers";
import { AuthProvider } from "./utils/authProvider";
import TagManager from 'react-gtm-module';
import  config  from '../src/config/config'
import { Amplify } from 'aws-amplify';
import RoutesOps from "./routesOps";

const Config = config
const GTMID = Config?.SSO?.GTM_ID
const tagManagerArgs = {
  gtmId: GTMID
}

const oauth = {
  domain: Config.cognito.domain,
  scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  responseType: 'token'
};

Amplify.configure({
  Auth: {
    oauth: oauth,
    region: Config.cognito.region,
    userPoolId: Config.cognito.userPoolId,
    userPoolWebClientId: Config.cognito.userPoolWebClientId,
    // identityPoolId: Config.cognito.identityPoolId,
    // identityPoolRegion: Config.cognito.identityPoolRegion,
    authenticationFlowType: 'CUSTOM_AUTH'
  },
  // aws_appsync_region: Config.cognito.appsyncRegion,
  // aws_appsync_graphqlEndpoint: Config.cognito.appsyncGraphQLEndpoint,
  // aws_appsync_authenticationType: Config.cognito.appsyncAuthenticationType,
});

function App() {

  // Disable browser back button
  // if (!Config.SSO.isLocal) {
  //   window.history.pushState(null, null, window.location.href);
  //   window.history.back();
  //   window.onpopstate = () => window.history.forward();
  // }
  TagManager.initialize(tagManagerArgs)
  return (
    <ContextProvider initialState={initialState} reducer={reducer}>
      {Config.isSGP === 'true' ? 
        <AuthProvider>
          <Routes />
        </AuthProvider>
        :
        <AuthProvider>
          <RoutesOps />
        </AuthProvider>
      }
    </ContextProvider>
  );
}

export default App;
