import { UserManager } from "oidc-client";
import { SsoConfig } from "../config/ssoConfig";
import config from "../config/config";
import API from "../api";
import ActionTypes from "../store/actionTypes";
import { Auth } from 'aws-amplify';

export default class AuthService {
  UserManager;

  cognitoUser = { challengeParam: { email: '' } };

  async signIn(email, password) {
    // const data = await Auth.signIn(email, password);
     this.cognitoUser = await Auth.signIn(email, password);
    // console.log("cognitoUser data", this.cognitoUser)
    return this.cognitoUser;
  
  }

  async changePassword(password) {
    return await Auth.completeNewPassword(
      this.cognitoUser, 
      password
    );
  }

  async verifyOtp(otp) {
    const challengeResponse = otp || '202111';
    // to send the answer of the custom challenge
    const loggedUser = await Auth.sendCustomChallengeAnswer(this.cognitoUser, challengeResponse)
    return loggedUser
  }

  async signOut() {
    await Auth.signOut();
  }

  constructor() {
    this.UserManager = new UserManager({
      ...SsoConfig,
    });
  }

  signinRedirect = () => {
    this.UserManager.signinRedirect();
  };

  generateToken = async (code, state, localString) => {
    try {
      // const localString = window.localStorage.getItem(`oidc.${state}`);
      const { client_id, code_verifier, redirect_uri } =
        JSON.parse(localString);
      window.localStorage.clear();
      const responseData = await API[ActionTypes.GENERATE_TOKEN](
        SsoConfig.metadata.token_endpoint,
        `grant_type=authorization_code&code=${code}&client_id=${client_id}&code_verifier=${code_verifier}&redirect_uri=${redirect_uri}`,
        {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "*/*",
        }
      );
      return responseData.data;
    } catch (err) {
      console.error("Error::", err);
      return { error: "Something Went Wrong!" };
    }
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      window.localStorage.clear();
      window.location.replace(config.SSO.redirectSignOut);
    });
    this.UserManager.clearStaleState();
  };
}
