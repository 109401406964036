import React, { useEffect, useState } from "react";
import UploadFile from "./uploadFile";
import { isEmpty } from "../../utils/functionUtils";
import { useCentralStore } from "../../store/appContext";
import { dataURItoBlob, uploadToS3 } from "../../utils/upload-utils";
import API from "../../api";
import ActionTypes from "../../store/actionTypes";
import Spinner from "../../components/common/spinner/spinner";
import styles from "./iconStyle.module.css";
import resources from "../../components/common/resources.json"
import { Button, Table } from "antd";
import { BackwardOutlined } from "@ant-design/icons";

const CelcomBilligRevenue = () => {
  const [
    {
      selectedFileType,
      chooseFile,
      selectedRevenueMonth,
      selectedRevenueYear,
      uploadedFileData,
      uploadFileError,
      selectedRevenueClient,
      loading,
      userData,
      userDetails
    },
    dispatch,
  ] = useCentralStore();
  let chosenFile = chooseFile?.base64;
  // const [showElement, setShowElement] = useState(true);
  const [revenueFiles, setRevenueFiles] = useState([]);
  const [successMsg, setSuccessMsg] = useState(false);
  const inputRef = React.createRef();
  let data = [];

  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING, payload: action });
  };

  const setProjectName = (name) => {
    dispatch({ type: ActionTypes.SET_PROJECT_NAME, payload: name });
    dispatch({ type: ActionTypes.RESET_REVENUE_STATE, payload: null });
    dispatch({ type: ActionTypes.UPLOAD_FILE_ERROR, payload: null })
    setSuccessMsg(false)
  };

  
  const getRevenueFiles = () => {
    let idToken = userData?.access_token
    setLoading(true);
    API[ActionTypes.GET_BILLING_REVENUE_FILES](idToken, userDetails && userDetails?.email?.split("@")?.[0])
      .then((data) => {
        setLoading(false);
        setRevenueFiles(data?.data?.GetBillingRevenueResponse);
      })
      .catch((error) => {
        setLoading(false);
        console.log("RevenueFiles", error);
      });
  };
  
  
  useEffect(() => {
     getRevenueFiles() ;
  }, []);

  const ProcessingMonthYear = (selectedRevenueMonth, selectedRevenueYear) => {
    return selectedRevenueMonth?.concat("_", selectedRevenueYear);
  };

  let FilteredFiles = revenueFiles?.filter((item) => {
    return (
      ProcessingMonthYear(selectedRevenueMonth, selectedRevenueYear) ===
        item.ProcessingMonthYear &&
      selectedRevenueClient === item.ClientChannelId &&
      selectedFileType.toUpperCase() === item?.DocumentName?.split("_")?.[0]?.toUpperCase()
    );
  });

  const SortedList = FilteredFiles?.sort((a, b) => {
    var c = new Date(a.CreatedDate);
    var d = new Date(b.CreatedDate);
    return d - c;
  });

  let tableColumns = [
    {
      title: "File Name",
      dataIndex: "FileName",
      key: "FileName",
      align: "center",
      width: 300,
    },
    {
      title: "Time Stamp",
      dataIndex: "timeStamp",
      key: "timeStamp",
      align: "center",
      width: 200,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 150,
    },
    {
      title: "No. of Records",
      dataIndex: "records",
      key: "records",
      align: "center",
      width: 150,
    },
    {
      title: "Uploaded By",
      dataIndex: "uplodedBy",
      key: "uplodedBy",
      align: "center",
      width: 150,
    }
  ];
  SortedList?.map((val) => {
    return data.push({
      FileName: val?.DocumentName,
      timeStamp: val?.CreatedDate,
      status: val?.Status,
      records: val?.TotalRecords,
      uplodedBy: val?.CreatedBy
    });
  });

  // const showHideFunc = async () => {
  //   setShowElement(true);
  //   await new Promise((resolve) => {
  //     setTimeout(() => {
  //       resolve(console.log("visible"));
  //     }, 8000);
  //   });
  //   setShowElement(false);
  // };

  // useEffect(() => {
  //   showHideFunc();
  // }, [uploadFileError, uploadedFileData]);

  const callUploadBillingRevenue = () => {
    setLoading(true);
    let idToken = userData?.access_token
    let payload = {
      BillingRevenueUploadParameters: {
        ClientChannelId: selectedRevenueClient,
        Files: [
          {
            DocumentName: chooseFile?.name,
            DocumentType: selectedFileType,
            ProcessingMonthYear: selectedRevenueMonth.concat(
              "_",
              selectedRevenueYear
            ),
          },
        ],
      },
    };

    API[ActionTypes.UPLOAD_BILLING_REVENUE](payload, idToken, userDetails && userDetails?.email?.split("@")?.[0])
      .then((result) => {
        // setLoading(false);
        let signedUrl = result?.data?.BillingRevenueUploadResponse[0].SignedUrl
        let BillingRevenueRecognitionId = result?.data?.BillingRevenueUploadResponse[0].BillingRevenueRecognitionId;
        uploadBySignedUrl(chosenFile, signedUrl, BillingRevenueRecognitionId)
      })
      .catch((error) => {
        setLoading(false);
        dispatch({ type: ActionTypes.UPLOAD_FILE_ERROR, payload: "Something went wrong !!" });
        console.log("uploadFileError", error);
      });
  };

  const updateBillingRevenueApi = (BillingRevenueRecognitionId) => {
    setLoading(true);
    let idToken = userData?.access_token;
    let payload = {
      UpdateBillingRevenueParameters: {
        ProcessedFiles: [
          {
            BillingRevenueRecognitionId: BillingRevenueRecognitionId,
            TotalRecords: chooseFile?.record,
            Status: "Processed",
          },
        ],
      },
    };

    API[ActionTypes.UPDATE_BILLING_REVENUE](payload, idToken, userDetails && userDetails?.email?.split("@")?.[0])
    .then((result) => {
      setLoading(false);
      setSuccessMsg(true);
      dispatch({
        type: ActionTypes.FILE_UPLOADER_SUCCESS,
        payload: result,
        uploadSuccess: true,
      });
      getRevenueFiles() //call getRevenueFile API
       dispatch({
      type: ActionTypes.SELECTED_FILE,
      payload: null
    });
      return result;
    })
      .catch((error) => {
        setLoading(false);
        console.log("updateFileError", error);
      });
  };

  const uploadBySignedUrl = async (chosenFile, signedUrl, BillingRevenueRecognitionId, TotalRecords) => {
    const formData = new FormData();
    const selectedFile = chosenFile;
    const blob = await dataURItoBlob(selectedFile);
    var file = new File([blob], selectedFileType.concat(".","csv"));
    formData.append("blob", blob, "clawback.csv");
    uploadToS3(signedUrl, file, formData, userDetails && userDetails?.email?.split("@")?.[0])
      .then((e) => {
        console.log("uploadedToS3:",e)
        updateBillingRevenueApi(BillingRevenueRecognitionId, TotalRecords)
      })
      .catch((error) => {
        setLoading(false)
        dispatch({ type: ActionTypes.UPLOAD_FILE_ERROR, payload: "Something went wrong !!" });
        console.log(error, "error in uploading to s3");
      });
  };

  const resetState = () => {
    dispatch({ type: ActionTypes.RESET_REVENUE_STATE, payload: null });
    dispatch({ type: ActionTypes.UPLOAD_FILE_ERROR, payload: null })
    inputRef.current.value = "";
    setSuccessMsg(false)
  };

  return (
    <>
      {loading && <Spinner showOverlay={true} />}
      <div className="w-full h-full mt-10">
        <div className="flex items-center justify-start px-2">
          <UploadFile setSuccessMsg={setSuccessMsg} ref={inputRef} />
          <Button
            ghost
            className={
              isEmpty(selectedFileType) || isEmpty(chooseFile)
                ? `${styles.disabledBtn2}`
                : "transition duration-500 ease-in-out cursor-pointer delay-350 hover:-translate-y-0 hover:scale-110"
            }
            size={"large"}
            disabled={
              isEmpty(selectedFileType) || isEmpty(chooseFile) ? true : false
            }
            onClick={() => callUploadBillingRevenue(chosenFile)}
          >
            {"Upload"}
          </Button>
          <div className="pl-4">
            <Button
              ghost
              className={
                "transition duration-500 ease-in-out cursor-pointer delay-350 hover:-translate-y-0 hover:scale-110"
              }
              size={"large"}
              onClick={() => resetState()}
            >
              {"Clear"}
            </Button>
          </div>
        </div>
      </div>
      {successMsg && (
        <div
          className={`flex flex-row border-b border-app-border justify-between items-center`}
        >
          <h2 className="flex justify-center py-2 mx-auto text-xl font-bold text-center text-app-greenBorder">
            File Uploded Successfully !!!
          </h2>
        </div>
      )}
      {uploadFileError?.fileError && (
        <div
          className={`flex flex-row border-b border-app-border justify-between items-center`}
        >
          <h2 className="flex justify-center py-2 mx-auto text-xl font-bold text-center text-app-greenBorder">
            {uploadFileError?.fileError}
          </h2>
        </div>
      )}
      <div className="mx-2 mt-10">
        <span onClick={(e) => setProjectName(resources.Billing_Data)} className="cursor-pointer">
          <Button
            icon={<BackwardOutlined style={{ fontSize: "20px", paddingTop: "1px", paddingLeft: "2px" }}
              theme="outlined" />}
            shape="circle"
            style={{ display: "inline-flex" }}
            className={"transition duration-500 ease-in-out cursor-pointer delay-350 hover:-translate-y-0 hover:scale-110"}

          />
          <span className="mx-2">Go Back</span>
        </span>
      </div>
      <div className="pb-2 mt-2">
        <Table
          columns={tableColumns}
          dataSource={data}
          style={{ fontSize: "12px !important"}}
          rowKey="data"
          key="data"
          pagination={{
            position: ["topRight"],
            pageSizeOptions: ["10", "20", "30", "40", "50"],
            showSizeChanger: true,
            size: "default",
          }}
          className="px-2 border-current border-white table-css hz-scrollbar"
        />
      </div>
    </>
  );
};

export default CelcomBilligRevenue;
