import React, { useEffect, useRef, useState } from "react"
import HamburgerIcon from "../../assets/icons/hamburger.js"
import ActionTypes from "../../store/actionTypes"
import { useCentralStore } from "../../store/appContext";

import HomeIcon from "../../assets/icons/home-icon"
import API from "../../api.js";

function Hamburger({ className, clientData, userDetails, resources }) {
    const [isExpanded, setIsExpanded] = useState(false)
    const [{ selectedClient, userData, selectedTab }, dispatch] = useCentralStore()

    let allDashboardsArray = []
    clientData?.filter(v => v?.ClientId == selectedClient)[0]?.Channels?.map(v => v?.AllDashboards?.map(a => allDashboardsArray.push(a)))
    let allDashboards = allDashboardsArray?.filter((v,i,a)=>a.findIndex(v2=>(v2.Label===v.Label))===i)

    const setLoading = (action) => {
        dispatch({ type: ActionTypes.SET_LOADING, payload: action });
    };
    
    const goToHomePage = () =>{
        let payload = {
            // Username: "shreesh.nirgudkar",
            idToken: userData?.access_token,
          };
          // userDetails && userDetails?.email?.split("@")?.[0]
          API[ActionTypes.GET_ROLE_BASES_MATRICS](payload)
            .then(({ data }) => {
              setLoading(false);
              if (data) {
                dispatch({
                  type: ActionTypes.GET_ROLE_BASES_MATRICS_SUCCESS,
                  payload: data,
                });
              }
            })
            .catch((err) => {
              dispatch({
                type: ActionTypes.GET_ROLE_BASES_MATRICS_FAILURE,
                payload: err,
              });
              dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
              let payload = {
                level: resources.POST_LOGGER_ERROR,
                category: resources.POST_LOGGER_API,
                ApiName: resources.POST_LOGGER_APINAME,
                message: {
                  error: err,
                  info: {
                    idToken: "",
                  },
                },
              };
              API[ActionTypes.POST_LOGGER](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
            });
        }

        const ref = useRef();

        function useOnClickOutside(ref, handler) {
          useEffect(() => {
            const listener = (event) => {
              // Do nothing if clicking ref's element or descendent elements
              if (!ref.current || ref.current.contains(event.target)) {
                return;
              }
              handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
              document.removeEventListener("mousedown", listener);
              document.removeEventListener("touchstart", listener);
            };
          }, [ref, handler]);
        }
        useOnClickOutside(ref, () => setIsExpanded(false));
        
    return (
        <div className={`flex justify-end ${className}`} ref={ref}>
            {isExpanded && (
                <>
                    {allDashboards?.map(v => 
                        <>
                            <button className={`w-14 h-14 rounded-full m-2 ${selectedTab === v?.Label ? "selected-menu" : "default-menu"}`} onClick={() => { 
                                dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: v?.Label });
                                dispatch({
                                  type: ActionTypes.GET_CHILD_SUBMATRICS_SUCCESS,
                                  payload: undefined,
                                })
                            }}>{v?.Label == resources.BPM_DASHBOARD_LBL ? 'BPM' : 'OPS'}</button>
                        </>
                    )}

                    <button className={`w-14 h-14 rounded-full m-2 ${selectedTab === "MAP" ? "selected-menu" : "default-menu"}`} onClick={() => { 
                        dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "MAP" });
                        dispatch({ type: ActionTypes.SET_PROGRAM_NAME, data: '' });
                        dispatch({ type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS, payload: null });
                        goToHomePage();
                        dispatch({
                          type: ActionTypes.GET_CHILD_SUBMATRICS_SUCCESS,
                          payload: undefined,
                        })
                    }}><HomeIcon className="m-auto" /></button>
                </>
            )}
            <button id="menuIcon" className={`w-14 h-14 rounded-full m-2 ${isExpanded ? "selected-menu" : "default-menu"}`} onClick={() => setIsExpanded(!isExpanded)}>
                <HamburgerIcon
                    color={isExpanded ? "#8223D2" : "#FFFFFF"}
                    className="m-auto"
                />
            </button>
        </div>
    )
}

export default Hamburger;