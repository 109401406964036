import { useCentralStore } from "../../store/appContext";
import resources from "../../components/common/resources.json";
import BPMIcon from "../../assets/homeImg/BPM_small_v2";
import HomeIcon from "../../assets/icons/homeone-icon";
import Transactional_ReportingIcon from "../../assets/homeImg/Transactional_Reporting_small_v2";
import ActionTypes from "../../store/actionTypes";
import API from "../../api";
import moment from "moment";
import InfoIcon from "../../assets/icons/infoIcon";
import DownloadIcon from "../../assets/icons/downloadIcon";
import { useNavigate } from "react-router-dom";

const SSDashboardMenu = () => {
  const [
    { selectedTab, userData, userDetails },
    dispatch,
  ] = useCentralStore();

  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING, payload: action });
  };

  const handleBPMClick = () => {
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: null,
    });
    dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: null });
    dispatch({ type: ActionTypes.SET_PROGRAM_NAME, data: "" });
    dispatch({
      type: ActionTypes.SS_SET_REPORT_FILTER_DURATION,
      payload: {
        type: null,
        startDate: null,
        endDate: null,
        duration: null,
      },
    });
    dispatch({
      type: ActionTypes.GET_SS_REPORT_COUNT_SUCCESS,
      payload: null,
    });
    dispatch({
      type: ActionTypes.GET_SS_REPORT_DETAILS_SUCCESS,
      payload: null,
    });
    dispatch({
      type: ActionTypes.GET_SS_REPORT_DETAILS_SUCCESS,
      payload: null,
    });
    dispatch({ type: ActionTypes.SS_SET_REPORT_FILTER, payload: "real time" });
    goToHomePage();
  };

  const goToHomePage = () => {
    let payload = {
      idToken: userData?.access_token,
    };
    setLoading(true);
    navigate('/')
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: resources.BPM_PROJECT_NAME,
    });
    dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "MAP" });
    API[ActionTypes.GET_ROLE_BASES_MATRICS](payload, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          dispatch({ type: ActionTypes.SELECTED_STORE, payload: "all" });
          dispatch({
            type: ActionTypes.SELECTED_DASHBOARD_DATE,
            payload: {
              name: resources.LAST_90_DAYS_VALUE,
              value: resources.LAST_90_DAYS_VALUE,
            },
          });
          dispatch({
            type: ActionTypes.GET_ROLE_BASES_MATRICS_SUCCESS,
            payload: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.GET_ROLE_BASES_MATRICS_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
    // API[ActionTypes.GET_ROLE_BASES_MATRICS](
    //   payload,
    //   userDetails && userDetails?.email?.split("@")?.[0]
    // )
    //   .then(({ data }) => {
    //     setLoading(false);
    //     if (data) {
    //       dispatch({
    //         type: ActionTypes.GET_ROLE_BASES_MATRICS_SUCCESS,
    //         payload: data,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     dispatch({
    //       type: ActionTypes.GET_ROLE_BASES_MATRICS_FAILURE,
    //       payload: err,
    //     });
        
    //   });
  };



  const navigate = useNavigate();

  return (
    <div className="flex ml-10 justify-between">
      <div className="flex">
        <div
          className={`cursor-pointer py-0 pr-3 pl-1 flex justify-center items-center mr-5 ${
            selectedTab === "HomePage"
              ? "bg-app-lightGreen font-bold rounded-lg"
              : ""
          }`}
        >
          <div className="flex items-center bg-app-lightGreen rounded-md" onClick={() => handleBPMClick()}>
            <HomeIcon
              className=""
              fill={selectedTab === "HomePage" ? "black" : "#000000"}
            />
            <div
              className={`text-black font-bold pr-3 text-md bg-app-lightGreen`}
            >
              Home
            </div>
          </div>
        </div>

        {/* {projectSHSubMenu &&
          projectSHSubMenu?.subLabels?.map((v) => (
            <div
              className={`cursor-pointer py-3 px-3 flex justify-center items-center border-b bg-app-lightGreen mr-5 ${
                selectedTab === v?.value
                  ? "bg-app-starhubGreen font-bold rounded-lg"
                  : ""
              }`}
            >
              <div
                className="flex items-center mr-2"
                onClick={() => handleRealTimeTab(v?.value, v?.parentLabel)}
              >
                {v?.value === "DownloadReport" ? (
                  <DownloadIcon
                    className="w-5 h-5"
                    fill={selectedTab === v?.value ? "black" : "#1ED760"}
                  />
                ) : v?.value === "Glossary" ? (
                  <InfoIcon
                    className="flex w-3 h-3"
                    fill={selectedTab === v?.value ? "black" : "#1ED760"}
                  />
                ) : (
                  <BPMIcon
                    className=""
                    fill={selectedTab === v?.value ? "black" : "#1ED760"}
                  />
                )}
                <div className={`text-black text-xs ml-1 `}>{v?.label}</div>
              </div>
            </div>
          ))} */}
      </div>
    </div>
  );
};

export default SSDashboardMenu;
