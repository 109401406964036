const config = {
    SSO: {
      provider: process.env.REACT_APP_SSO_PROVIDER,
      clientId: process.env.REACT_APP_SSO_CLIENT_ID,
      redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGNOUT,
      GTM_ID: process.env.REACT_APP_GTM_ID,
      isLocal: process.env.REACT_APP_IS_LOCAL === "true",
    },
    isLocal: process.env.REACT_APP_IS_LOCAL === "true",
    language: "EN-US",
    baseURL: process.env.REACT_APP_BASE_URL,
    xApiKey: process.env.REACT_APP_API_KEY,
    faviconUrl: process.env.REACT_APP_BASE_URL_FAVICON,
    loadingImageUrl: process.env.REACT_APP_LOADING_IMAGE_URL,
    GTM_ID: process.env.REACT_APP_GTM_ID,
    isSGP: process.env.REACT_APP_IS_SGP,
    cognito: {
      region: process.env.REACT_APP_REGION,
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOLID,
      identityPoolRegion: process.env.REACT_APP_COGNITO_IDENTITY_REGION,
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      appsyncRegion: process.env.REACT_APP_REGION,
      appsyncGraphQLEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
      appsyncAuthenticationType: process.env.REACT_APP_GRAPHQL_AUTHENTICATION_TYPE
    },  
  };

  export default config;
  