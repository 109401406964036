import React from 'react'

const icon = ({ className, fill, onClick }) => 
(
    <svg className={className} onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00005 10.9429L11.8048 7.13813L10.862 6.19532L8.00005 9.05725L5.13812 6.19532L4.19531 7.13813L8.00005 10.9429Z" fill={fill} />
    </svg>
)
export default icon


