import React from 'react'

const icon = ({ className, fill, onClick }) => 
(
    <svg className={className} onClick={onClick} width="44" height="44" viewBox="0 0 44 44" fill={fill} xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 23V32C13.5 32.5523 13.9477 33 14.5 33H19C19.2761 33 19.5 32.7761 19.5 32.5V28.5C19.5 27.1193 20.6193 26 22 26C23.3807 26 24.5 27.1193 24.5 28.5V32.5C24.5 32.7761 24.7239 33 25 33H29.5C30.0523 33 30.5 32.5523 30.5 32V23.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.5 22.5L22 11L33.5 22.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M26 12H29C29.2761 12 29.5 12.2239 29.5 12.5V15.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    
)
export default icon