import { getGuageIcons, getSSTypeIndicator, numberWithCommas } from "../../utils/functionUtils";
import { getMyColors } from "../../utils/functionUtils";
import ArrowDiv from "../../assets/icons/arrowDiv";
import DashboardArrow from "../../assets/icons/dashboardArrow";
import CrossIcon from "../../assets/icons/cross-icon";
import { useState } from "react";
import ActionTypes from "../../store/actionTypes";
import API from "../../api";
import { useCentralStore } from "../../store/appContext";
import resources from "../../components/common/resources.json";
import Popup from "../../components/common/popup/popup";
import SSDataTable from "../../components/common/dataTable/SSDataTable";
import moment from "moment";
import GreenDashboardCircle from "../../assets/icons/greenDashboardCircle";

const SSDashboardData = () => {
  const [
    {
      userData,
      userDetails,
      ssreportdetailsSuccess,
      ssreportCountSuccess,
    },
    dispatch,
  ] = useCentralStore();

  let dataBlocks = [
    {
      label: "Smartstock config",
      Type: "SMARTSTOCKCONFIG",
      value: 31,
      primary: "#8223D2",
      secondary: "#D648FD",
      metricsLabel: "Total models for which ",
      subMetricsLabel: "Smart Stock configured",
      subMetrics: [
        { label: "APPLE", keyLabel: "APPLE", value: 19 },
        { label: "SAMSUNG", keyLabel: "SAMSUNG", value: 9 },
        { label: "OTHER", keyLabel: "OTHER", value: 3 },
      ],
    },
    {
      label: "LFL",
      Type: "LFL",
      value: 56,
      primary: "#6E767D",
      secondary: "#D5D6DA",
      metricsLabel: "Total Swap Claims with SS ",
      subMetricsLabel: "Offered and Shipped (LFL)",
      subMetrics: [
        { label: "APPLE", keyLabel: "APPLE", value: 37 },
        { label: "SAMSUNG", keyLabel: "SAMSUNG", value: 13 },
        { label: "OTHER", keyLabel: "OTHER", value: 6 },
      ],
    },
    {
      label: "Layered RPM",
      Type: "NLFL",
      value: 21,
      primary: "#00B574",
      secondary: "#1DFFBA",
      metricsLabel: "Total Swap Claims with SS ",
      subMetricsLabel: "Offered and Shipped (Layered RPM)",
      subMetrics: [
        { label: "APPLE", keyLabel: "APPLE", value: 12 },
        { label: "SAMSUNG", keyLabel: "SAMSUNG", value: 6 },
        { label: "OTHER", keyLabel: "OTHER", value: 3 },
      ],
    },
    {
      label: "Upgrade",
      Type: "Upgrade",
      value: 32,
      primary: "#4A68F9",
      secondary: "#00B9FD",
      metricsLabel: "Total Swap Claims with SS ",
      subMetricsLabel: "Offered and Shipped (UPGRADE)",
      subMetrics: [
        { label: "APPLE", keyLabel: "APPLE", value: 0, },
        { label: "SAMSUNG", keyLabel: "SAMSUNG", value: 7, },
        { label: "OTHER", keyLabel: "OTHER", value: 2, },
      ],
    },
    {
      label: "Downgrade",
      Type: "DOWNGRADE",
      value: 32,
      primary: "#82A700",
      secondary: "#D2FA46",
      metricsLabel: "Total Swap Claims with SS ",
      subMetricsLabel: "Offered and Shipped (DOWNGRADE)",
      subMetrics: [
        { label: "APPLE", keyLabel: "APPLE", value: 1 },
        { label: "SAMSUNG", keyLabel: "SAMSUNG", value: 0 },
        { label: "OTHER", keyLabel: "OTHER", value: 2 },
      ],
    },
    {
      label: "Backorder",
      Type: "1",
      value: 4,
      primary: "#A839F3",
      secondary: "#32E7FF",
      metricsLabel: "Total Swap Claims with SS Offered",
      subMetricsLabel: "and Shipped (Ever On Backorder)",
      subMetrics: [
        { label: "APPLE", keyLabel: "APPLE", value: 3 },
        { label: "SAMSUNG", keyLabel: "SAMSUNG", value: 1 },
        { label: "OTHER", keyLabel: "OTHER", value: 0 },
      ],
    },
    {
      label: "NPS",
      Type: "NPS",
      value: 236.61669999999998,
      primary: "#6E767D",
      secondary: "#D5D6DA",
      metricsLabel: "NPS Score for Claims with",
      subMetricsLabel: "SS Offered and Shipped",
      subMetrics: [
        { label: "APPLE", keyLabel: "APPLE", value: 69.95 },
        { label: "SAMSUNG", keyLabel: "SAMSUNG", value: 66.6667 },
        { label: "OTHER", keyLabel: "OTHER", value: 0 },
      ],
    },
    {
      label: "Service Fee",
      Type: "SERVICEFEE",
      MetricsTypeIndicator: "Currency_Sum",
      value: 794017,
      primary: "#00B574",
      secondary: "#1DFFBA",
      metricsLabel: "Amount of total Service ",
      subMetricsLabel: "Fee with SS Offered",
      subMetrics: [
        { label: "APPLE", keyLabel: "APPLE", value: 531789, SubMetricsTypeIndicator: "Currency_Sum", },
        { label: "SAMSUNG", keyLabel: "SAMSUNG", value: 226339, SubMetricsTypeIndicator: "Currency_Sum", },
        { label: "OTHER", keyLabel: "OTHER", value: 35889, SubMetricsTypeIndicator: "Currency_Sum", },
      ],
    },
  ];

  const getValue = (l, r) => {
    return ssreportCountSuccess?.Metrics?.filter(
      (v) =>
        v?.Type.toLowerCase() === r?.toLowerCase() &&
        v?.label.toLowerCase() === l?.toLowerCase()
    )[0]?.value;
  };

  const getSubmetricsValue = (l, index) => {
    return ssreportCountSuccess?.Metrics?.[index]?.submetrics?.filter(
      (v) => v?.label.toLowerCase() === l?.toLowerCase()
    )[0]?.value;
  };

  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
  };

  const [isOpen, openPopup] = useState(false);
  const [classInOut, setClassInOut] = useState(null);
  const [_label, setLabel] = useState(null);
  const [_index, setIndex] = useState(null);
  const [_metricsLabel, setMetricsLabel] = useState(null);
  const [_Type, setReportType] = useState(null);
  const [_subLabel, setSubLabel] = useState(null);

  const getReportDetails = (Type, label, index, mLabel, subLabel) => {
    setLabel(label);
    setIndex(index);
    setMetricsLabel(mLabel);
    setReportType(Type);
    setSubLabel(subLabel);
    // setLoading(true);
    dispatch({
      type: ActionTypes.GET_SUBMATRICS_DETAILS_SUCCESS,
      payload: [],
    });
    let startDate = moment(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000)).format("YYYY-MM-DD 00:00:00");
    let endDate = moment(new Date()).format("YYYY-MM-DD 23:59:59");
    let payload = {
      StartDate: startDate,
      EndDate: endDate,
      Type: Type,
      SubType: subLabel,
      StartingRow: 1,
      RowsCount: 10,
    };
    setLoading(true);
    API[ActionTypes.GET_SS_REPORT_DETAILS](
      payload,
      userData?.access_token,
      userDetails && userDetails?.email?.split("@")?.[0]
    )
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          openPopup(true);
          setClassInOut("modal-container modal-space-in");
          dispatch({
            type: ActionTypes.GET_SS_REPORT_DETAILS_SUCCESS,
            payload: data,
          });
        }
      })
      .catch((err) => {
        openPopup(false);
        dispatch({
          type: ActionTypes.GET_SS_REPORT_DETAILS_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](
          _payload,
          userData?.access_token,
          userDetails && userDetails?.email?.split("@")?.[0]
        );
      });
  };

  const [tooltipTextType, setTooltipType] = useState(null);

  const [stopAnimation, setStopAnimation] = useState(false);

  setTimeout(() => {
    setStopAnimation(true);
  }, [5000]);

  const openPopup1 = () => {
    setTimeout(() => {
      openPopup(false);
      dispatch({
        type: ActionTypes.GET_SS_REPORT_DETAILS_SUCCESS,
        payload: [],
      });
    }, [2000]);
  };

  return (
    <div className="flex">
      {dataBlocks?.map((v, index) => (
        <div className="flex flex-col p-2 w-60 mx-8 ">
          <div className={``}>
            <div className="text-center">
              <div className={`text-3xl font-bold mb-1 text-white  flex justify-center items-center `}>
                {getGuageIcons(v?.label, v?.primary)}
                <p style={{ color: v?.primary }}>
                  {getSSTypeIndicator(v?.MetricsTypeIndicator, null)}
                  {numberWithCommas(Math?.round(getValue(v?.label, v.Type))) || 0}
                </p>
              </div>
              <div className={`text-[11px] px-3 font-bold  whitespace-nowrap overflow-hidden text-ellipsis `} style={{ color: v?.primary }} >{v?.metricsLabel}</div>
              <div className={`text-11px px-3 font-bold whitespace-nowrap overflow-hidden text-ellipsis`} style={{ color: v?.primary }}>{v?.subMetricsLabel}</div>
            </div>
            <div className="relative ">
              <div className="">
                <p className={`text-xs  absolute font-bold text-center `} style={{ color: "black", top: "50%", right: "48%", transform: "translate(50%, -50%)", }} >
                  {v?.label?.toUpperCase()}
                </p>
                <ArrowDiv
                  className=""
                  primaryColor={v.primary}
                  secodaryColor={v.secondary}
                  label={v?.label?.toUpperCase()}
                />
              </div>
              {/* {!(index === dataBlocks?.length - 1) && (
                <DashboardArrow
                  className={`w-10 h-10 absolute right-m32% top-23% `}
                  fill={v?.primary}
                />
              )} */}
            </div>
          </div>
          <div className="flex flex-col mx-3">
            {v?.subMetrics?.map((s) => (
              <>
                <div className={`  ${v?.status?.toLowerCase()}-cards rounded-xl shadow-card text-black p-2 border-3px my-3 bg-card-greenBg border-card-greenBorder shadow-card-greenBorder 
                  ${ tooltipTextType !== s?.keyLabel ? "transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-125 hover: duration-500" : "" } perf-card
                  ${ getSubmetricsValue(s?.keyLabel, index) === 0 ? "cursor-not-allowed" : "cursor-pointer" }`}
                  onClick={(evt) =>
                    getSubmetricsValue(s?.keyLabel, index) === 0 ? undefined
                    : 
                    getReportDetails(
                      v?.Type,
                      s?.keyLabel,
                      index,
                      v?.label,
                      s?.label
                    )
                  }
                >
                  <div className="flex flex-row justify-between w-full items-center" id="label" >
                    <div className={` text-sm bg-inherit text-app-border w-[90%]`} id="label1" >
                      {s?.label}
                    </div>
                    <GreenDashboardCircle className={`w-5 h-5 animate-pulse-green`} />
                  </div> 
                  <div id="value" className="text-2xl mt-5 font-normal text-black flex flex-row justify-between items-center px-1 h-8" >
                    <div className="flex">
                      <p className={` mr-[2px]`}>
                        {getSSTypeIndicator(s.SubMetricsTypeIndicator, null)}
                      </p>
                      <div>
                        <p>
                          {isNaN(Number(getSubmetricsValue(s?.keyLabel, index)))
                            ? 0
                            : numberWithCommas(Math.round(
                              Number(
                                getSubmetricsValue(s?.keyLabel, index)
                              )
                            )) || 0
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      ))}
      <Popup
        show={isOpen}
        global={true}
        className={`rounded-xl ${classInOut}`}
        fitContent={true}
        submatricsDetailsData={ssreportdetailsSuccess}
        submatrics={true}
      >
        {isOpen && ssreportdetailsSuccess && (
          <SSDataTable
            ssreportdetailsSuccess={ssreportdetailsSuccess}
            label={_label}
            value={getSubmetricsValue(_label, _index)}
            MetricsLabel={_metricsLabel}
            onClose={(e) => (
              openPopup1(),
              setClassInOut("modal-container modal-space-out"),
              e.stopPropagation()
            )}
            resources={resources}
            reportType={_Type}
            subLabel={_subLabel}
          />
        )}
      </Popup>
    </div>
  );
};

export default SSDashboardData;
