import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, PointElement, LineElement, LineController, BarController } from 'chart.js';
import { Chart, Doughnut, Pie } from 'react-chartjs-2';
import { API } from 'aws-amplify';
import ActionTypes from '../../store/actionTypes';
import resources from '../../components/common/resources.json'
import { useCentralStore } from '../../store/appContext'; 
import { Progress, Space } from 'antd';

import SwapConfirmed from '../../assets/images/SwapConfirmed.png'
import SwapDelivered from '../../assets/images/SwapDelivered.png'
import ReplaceConfirmed from '../../assets/images/ReplaceConfirmed.png'

import { Bar } from 'react-chartjs-2';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    LineController,
    BarController
  );

ChartJS.register(ArcElement, Tooltip, Legend);


const ProgramSummary = () => {

    const [ { chartReportCountSuccess, userData, userDetails }, dispatch ] = useCentralStore()

    const setLoading = (action) => {
        dispatch({ type: ActionTypes.SET_LOADING, payload: action });
    };

    const date = new Date()
    const prevMonth = date?.getFullYear() + '-0' +date?.getMonth()

    const customerSLA = chartReportCountSuccess?.CustomerServiceSLA[prevMonth]
    const technicalSLA = chartReportCountSuccess?.TechnicalSLA[prevMonth]

    const serviceRequestReportCount = chartReportCountSuccess?.ServiceRequestReportCount?.filter(v => v?.Month === prevMonth)
    const acceptedCnt = Number(serviceRequestReportCount?.[0]?.['Accepted Service Requests']) || 0
    const cancelledCnt = Number(serviceRequestReportCount?.[0]?.['Canceled Service Requests']) || 0
    const rejectedCnt = Number(serviceRequestReportCount?.[0]?.['Rejected Service Requests']) || 0

    const totalServiceRequestReportCount = acceptedCnt + cancelledCnt + rejectedCnt
    const acceptedPer = ((acceptedCnt / totalServiceRequestReportCount) * 100) > 0 ? ((acceptedCnt / totalServiceRequestReportCount) * 100).toFixed(2) : 0
    const cancelledPer = (cancelledCnt / totalServiceRequestReportCount) * 100 > 0 ? ((cancelledCnt / totalServiceRequestReportCount) * 100).toFixed(2) : 0
    const rejectedPer = (rejectedCnt / totalServiceRequestReportCount) * 100 > 0 ? ((rejectedCnt / totalServiceRequestReportCount) * 100).toFixed(2) : 0

    const optionsPieChart = {
        responsive: true,
        plugins: {
          legend: {
            position: "right",
            labels: {
                usePointStyle: true,
              },
          },
          datalabels: {
            formatter: function(value, context) {
              return value > 0 ? value+'%' : null;
            },
            // align: "center",
            labels: {
                usePointStyle: true,
                    font: {
                        weight: 700,
                        color: 'black',
                        size: 16,
                    }
            },
          }
        }
      };


    const dataPieChartSR = {
        labels: ['Accepted %', 'Rejected %', 'Cancelled %'],
        datasets: [
          {
            label: '',
            data: [acceptedPer, rejectedPer, cancelledPer],
            backgroundColor: [
              '#1ED760',
              '#500878',
              '#C800A1'
            ],
            borderColor: [
                '#fff',
                '#fff',
                '#fff'
            ],
            borderWidth: 4,
          },
        ],
      };
      
      const npsCurrentMonth = chartReportCountSuccess?.NPSCount?.filter(v => v?.Month === prevMonth)?.length > 0 ? chartReportCountSuccess?.NPSCount?.filter(v => v?.Month === prevMonth) : chartReportCountSuccess?.NPSCount?.filter(v => v?.Month === '2022-12')

      const _npsPrevMonth = date?.getFullYear() + '-0' +(date?.getMonth()-1)
      const npsPrevMonth = chartReportCountSuccess?.NPSCount?.filter(v => v?.Month === _npsPrevMonth)?.length > 0 ? chartReportCountSuccess?.NPSCount?.filter(v => v?.Month === _npsPrevMonth) : chartReportCountSuccess?.NPSCount?.filter(v => v?.Month === '2022-10')
      let getBgColor = {0: '#1ED760', 1: '#685BC7', 2: '#C800A1', 3: '#500878', 4: '#1CD4B0', 5: '#09411D', 6: '#8CBDFA', 7: '#C800A1', 8: '#8A2252', 9: '#878787', 10: '#DC3545', 11: '#1ED760'}
      let labels = ['Prev month', 'Current Month']

      const npsTrendChart = {
        labels,
        datasets: [
          {
            type: 'line',
            label: 'NPS KPI',
            borderColor: getBgColor[2],
            borderWidth: 2,
            fill: false,
            data: [60, 60],
          },
          {
            type: 'bar',
            label: 'Survey Completion Rate',
            backgroundColor: getBgColor[1],
            data: [npsPrevMonth?.[0]?.TotalCount || 0, npsCurrentMonth?.[0]?.TotalCount || 0],
            borderColor: 'white',
            borderWidth: 2,
          },
          {
            type: 'bar',
            label: "NPS",
            data: [80, 90],
            backgroundColor: getBgColor[0],
            stack: 1,
            borderRadius: 10,
            // barThickness: 14
        },
        ],
      }
      
      
    const npsTrendChartOptions = {
        plugins: {
          title: {
            display: false,
            text: 'NPS Trend',
          },
          legend: {
            position: "right",
            labels: {
                usePointStyle: true,
                  font: {
                    size: 10,
                    weight: 700
                }
              },
          },
          datalabels: {
            formatter: function(value, context) {
              return context?.dataset?.label === 'Survey Completion Rate' ? value+'%' : value;
            },
            align: "top",
            labels: {
                usePointStyle: true,
                    font: {
                        weight: 700,
                        color:'black',
                        size: 16,
                    }
            },
          }
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      };

    return(
        <div className='flex flex-wrap justify-center mx-2 gap-5 py-10'>
            <div className='flex flex-col items-center w-[30%] shadow-xl rounded-xl p-4 pb-0'>
                <p className='p-2 w-full text-black text-xl'>Service Requests</p>
                <div className=''>
                    <Pie data={dataPieChartSR} options={optionsPieChart} />
                </div>
            </div>
            <div className='w-[30%] shadow-xl rounded-xl p-4 pb-0'>
                <p className='p-2 w-full text-black text-xl'>NPS Score</p>
                <div className='flex justify-between items-center mt-12 mx-5 2xl:w-[65%] 2xl:mx-auto'>
                    <div className=''>
                        {/* <Doughnut data={dataCircle} height='200px' width='200px' options={optionsDoughnut} /> */}
                        <Progress  type="circle" percent={npsCurrentMonth?.[0]?.TotalCount} strokeWidth={10} strokeColor={{ '0%': '#7DF4A5', '100%': '#1ED760' }} size={170} format={(percent) => `${percent}`} />
                    </div>
                    <div className=''>
                        <p className='text-black text-sm mb-5 font-semibold'>Completion rate 42%</p>
                        <p className='text-black text-sm font-semibold'>152 respondents</p>
                    </div>
                </div>
            </div>
            <div className='flex flex-col items-center w-[30%] shadow-xl rounded-xl p-4 pb-0'>
                <p className='p-2 w-full text-black text-xl'>Customer SLA</p>
                    <div className='flex flex-row mx-2 items-center w-full'>
                        <div className='flex flex-col mt-10 w-full mx-5'>
                            <div className='flex flex-col items-center justify-center SLASection'>
                                <Progress percent={customerSLA?.filter(v => v?.Category === 'Acceptance/ Rejection of Service Requests with communication to Subscriber')[0]?.['ActualSLA%']} 
                                    size={['100%', 40]} strokeColor={customerSLA?.filter(v => v?.Category === 'Acceptance/ Rejection of Service Requests with communication to Subscriber')[0]?.['ActualSLA%'] < customerSLA?.filter(v => v?.Category === 'Acceptance/ Rejection of Service Requests with communication to Subscriber')[0]?.['ExpectedSLA%'] ? {'0%': '#E553D0', '100%': '#C800A1'} : {'0%': '#7DF4A5', '100%': '#1ED760'}} format={(percent) => `${percent}%`} />
                                <p className='text-black text-[9px] text-center'>Service Request Acceptance/Rejection</p>
                            </div>
                            <div className='flex flex-col items-center justify-center mt-10 SLASection'>
                                <Progress percent={customerSLA?.filter(v => v?.Category === 'Delivery')[0]?.['ActualSLA%']}
                                    size={['100%', 40]} strokeColor={customerSLA?.filter(v => v?.Category === 'Delivery')[0]?.['ActualSLA%'] < customerSLA?.filter(v => v?.Category === 'Delivery')[0]?.['ExpectedSLA%'] ? {'0%': '#E553D0', '100%': '#C800A1'} : {'0%': '#7DF4A5', '100%': '#1ED760'}} format={(percent) => `${percent}%`} />
                                <p className='text-black text-[10px] text-center'>Delivery</p>
                            </div>
                        </div>
                        <div className='flex flex-col mt-10 w-full'>
                            <div className='flex flex-col items-center justify-center SLASection'>
                                <Progress percent={customerSLA?.filter(v => v?.Category === 'Device Repair')[0]?.['ActualSLA%']} 
                                    size={['100%', 40]} strokeColor={customerSLA?.filter(v => v?.Category === 'Device Repair')[0]?.['ActualSLA%'] < customerSLA?.filter(v => v?.Category === 'Device Repair')[0]?.['ExpectedSLA%'] ? {'0%': '#E553D0', '100%': '#C800A1'} : {'0%': '#7DF4A5', '100%': '#1ED760'}} format={(percent) => `${percent}%`} />
                                <p className='text-black text-[10px] text-center'>Device Repair (1 day delivery)</p>
                            </div>
                            
                            <div className='flex flex-col items-center justify-center  mt-10 SLASection'>
                                <Progress percent={customerSLA?.filter(v => v?.Category === 'Customer complaints management')[0]?.['ActualSLA%']}
                                    size={['100%', 40]} strokeColor={customerSLA?.filter(v => v?.Category === 'Customer Complaints')[0]?.['ActualSLA%'] < customerSLA?.filter(v => v?.Category === 'Customer Complaints')[0]?.['ExpectedSLA%'] ? {'0%': '#E553D0', '100%': '#C800A1'} : {'0%': '#7DF4A5', '100%': '#1ED760'}} format={(percent) => `${percent}%`} />
                                <p className='text-black text-[10px] text-center'>Customer Complaints</p>
                            </div>
                        </div>
                </div>
            </div>
            {/* <div className='flex flex-col items-center w-[30%] shadow-xl rounded-xl p-4 pb-0'>
                <p className='p-2 w-full text-black text-xl'>Customer SLA</p>
                    <div className='flex flex-row mx-2 items-center'>
                        <div className='flex flex-col mt-10 mr-5'>
                            <div className='flex flex-col items-center justify-center customerSLA'>
                                <Progress className='relative' percent={customerSLA?.filter(v => v?.Category === 'Acceptance/ Rejection of Service Requests with communication to Subscriber')[0]?.['ActualSLA%']} 
                                    size={[160, 30]} strokeColor={{ '0%': '#7DF4A5', '100%': '#1ED760' }} format={(percent) => `${percent}%`} />
                                <p className='text-black text-[10px] text-center'>Service Request Acceptance/Rejection</p>
                            </div>
                            <div className='flex flex-col items-center justify-center mt-10 customerSLA'>
                                <Progress percent={customerSLA?.filter(v => v?.Category === 'Delivery')[0]?.['ActualSLA%']}
                                    size={[160, 30]} strokeColor={{ '0%': '#7DF4A5', '100%': '#1ED760' }} format={(percent) => `${percent}%`} />
                                <p className='text-black text-[10px] text-center'>Delivery</p>
                            </div>
                        </div>
                        <div className='flex flex-col mt-10'>
                            <div className='flex flex-col items-center justify-center customerSLADeviceRepiar'>
                                <Progress percent={customerSLA?.filter(v => v?.Category === 'Device Repair')[0]?.['ActualSLA%']} 
                                    size={[160, 30]} strokeColor={{ '0%': '#E553D0', '100%': '#C800A1' }} format={(percent) => `${percent}%`} />
                                <p className='text-black text-[10px] text-center'>Device Repair (1 day delivery)</p>
                            </div>
                            
                            <div className='flex flex-col items-center justify-center  mt-10 customerSLADeviceRepiar'>
                                <Progress percent={customerSLA?.filter(v => v?.Category === 'Customer complaints management')[0]?.['ActualSLA%']}
                                    size={[160, 30]} strokeColor={{ '0%': '#7DF4A5', '100%': '#1ED760' }} format={(percent) => `${percent}%`} />
                                <p className='text-black text-[10px] text-center'>Customer Complaints</p>
                            </div>
                        </div>
                </div>
            </div> */}
            <div className='flex flex-col items-center w-[30%] shadow-xl rounded-xl p-4'>
                <p className='p-2 w-full text-black text-xl'>Service Requests Duration</p>
                <div className='m-5 w-full'>
                    <p className='text-black font-semibold'>Swap</p>
                    <div className='flex  w-full'>
                        <div className='relative top-[45%] left-[50%] z-50'>
                            <p className='!m-0 durationCircleCSS  text-center w-12 h-12 w-12 p-3 rounded-full'>
                                3
                            </p>
                            <p className='text-black text-[10px] text-center'>Hours</p>
                        </div>
                        <div>
                            <img src={SwapConfirmed} />
                            <p className='text-black text-[10px] text-center'>Confirmation</p>
                        </div>
                        <hr className='durationCircleCSS  w-full h-1 my-2 relative top-3 right-[2%]'/>
                        <div className='relative right-[2%]'>
                            <img src={SwapDelivered} />
                            <p className='text-black text-[10px] text-center'>Delivered</p>
                        </div>
                    </div>
                </div>
                <div className='mt-5  w-full'>
                    <p className='text-black font-semibold'>Replace</p>
                    <div className='flex  w-full'>
                        <div className='relative top-[45%] left-[50%] z-50'>
                            <p className='!m-0 durationCircleCSS  text-center w-12 h-12 w-12 p-3 rounded-full'>
                                3
                            </p>
                            <p className='text-black text-[10px] text-center'>Hours</p>
                        </div>
                        <div>
                            <img src={ReplaceConfirmed} />
                            <p className='text-black text-[10px] text-center'>Confirmation</p>
                        </div>
                        <hr className='durationCircleCSS  w-full h-1 my-2 relative top-3 right-[2%]'/>
                        <div className='relative right-[2%]'>
                            <img src={SwapDelivered} />
                            <p className='text-black text-[10px] text-center'>Delivered</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex flex-col w-[30%] h-full items-center shadow-xl rounded-xl p-5 pb-10'>
                <p className='p-2 w-full text-black text-xl mb-5'>NPS Trend</p>
                <Chart  type='bar' options={npsTrendChartOptions} data={npsTrendChart} />
            </div>
            <div className='flex flex-col items-center w-[30%] shadow-xl rounded-xl p-4 pb-0'>
                <p className='p-2 w-full text-black text-xl'>Technical SLA</p>
                    <div className='flex flex-row mx-2 items-center w-full'>
                        <div className='flex flex-col mt-10 w-full mx-5'>
                            <div className='flex flex-col items-center justify-center SLASection'>
                                <Progress percent={technicalSLA?.filter(v => v?.Category === 'Service Request Portal/ App')[0]?.['ActualSLA%']} 
                                    size={['100%', 40]} strokeColor={customerSLA?.filter(v => v?.Category === 'Service Request Portal/ App')[0]?.['ActualSLA%'] < customerSLA?.filter(v => v?.Category === 'Service Request Portal/ App')[0]?.['ExpectedSLA%'] ? {'0%': '#E553D0', '100%': '#C800A1'} : {'0%': '#7DF4A5', '100%': '#1ED760'}} format={(percent) => `${percent}%`} />
                                <p className='text-black text-[10px] text-center'>Service Request Portal Availability</p>
                            </div>
                            <div className='flex flex-col items-center justify-center mt-10 SLASection'>
                                <Progress percent={technicalSLA?.filter(v => v?.Category === 'Priority 2 (P2) – Minor Incident')[0]?.['ActualSLA%']}
                                    size={['100%', 40]} strokeColor={customerSLA?.filter(v => v?.Category === 'Priority 2 (P2) – Minor Incident')[0]?.['ActualSLA%'] < customerSLA?.filter(v => v?.Category === 'Priority 2 (P2) – Minor Incident')[0]?.['ExpectedSLA%'] ? {'0%': '#E553D0', '100%': '#C800A1'} : {'0%': '#7DF4A5', '100%': '#1ED760'}} format={(percent) => `${percent}%`} />
                                <p className='text-black text-[10px] text-center'>P2 – Minor Incident</p>
                            </div>
                        </div>
                        <div className='flex flex-col mt-10 w-full'>
                            <div className='flex flex-col items-center justify-center SLASection'>
                                <Progress percent={technicalSLA?.filter(v => v?.Category === 'Priority 1 (P1) – Critical Incident')[0]?.['ActualSLA%']} 
                                    size={['100%', 40]} strokeColor={customerSLA?.filter(v => v?.Category === 'Priority 1 (P1) – Critical Incident')[0]?.['ActualSLA%'] < customerSLA?.filter(v => v?.Category === 'Priority 1 (P1) – Critical Incident')[0]?.['ExpectedSLA%'] ? {'0%': '#E553D0', '100%': '#C800A1'} : {'0%': '#7DF4A5', '100%': '#1ED760'}} format={(percent) => `${percent}%`} />
                                <p className='text-black text-[10px] text-center'>P1 – Critical Incident</p>
                            </div>
                            
                            <div className='flex flex-col items-center justify-center  mt-10 SLASection'>
                                <Progress percent={technicalSLA?.filter(v => v?.Category === 'Priority 3 (P3) – Improvement')[0]?.['ActualSLA%']}
                                    size={['100%', 40]} strokeColor={customerSLA?.filter(v => v?.Category === 'Priority 3 (P3) – Improvement')[0]?.['ActualSLA%'] < customerSLA?.filter(v => v?.Category === 'Priority 3 (P3) – Improvement')[0]?.['ExpectedSLA%'] ? {'0%': '#E553D0', '100%': '#C800A1'} : {'0%': '#7DF4A5', '100%': '#1ED760'}} format={(percent) => `${percent}%`} />
                                <p className='text-black text-[10px] text-center'>P3 - Improvement</p>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default ProgramSummary