import React from 'react'

const icon = ({ className, fill, onClick }) => 
(
    <svg className={className} onClick={onClick} width="160" height="51" viewBox="0 0 180 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="168" height="49" rx="7" fill={fill} fill-opacity="0.3"/>
        <rect x="1" y="1" width="168" height="49" rx="7" stroke={fill} stroke-width="2"/>
    </svg>

)
export default icon


