import { useRef, useEffect, useState } from "react";
import BPMIcon from "../../../assets/homeImg/BPM_small_v2";
import Transactional_ReportingIcon from "../../../assets/homeImg/Transactional_Reporting_small_v2";
import Application_Health_MonitoringIcon from "../../../assets/homeImg/Application_Health_Monitoring_small_v2";
import WelcomeSection from "../../../pages/dashboard/welcomeSection";
import ArrowUpIcon from "../../../assets/icons/arrow-up";
import ArrowDownIcon from "../../../assets/icons/arrow-down";
import { isEmpty } from "../../../utils/functionUtils";
import API from "../../../api";
import ActionTypes from "../../../store/actionTypes";
import { useCentralStore } from "../../../store/appContext";
import config from "../../../config/config";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const DashboardMenu = ({ resources }) => {
  const [
    {
      selectedTab,
      projectName,
      projectData,
      isTradeDashboardOpen,
      userData,
      isTransactionOptionsOpen,
      selectedCategory,
      allDashboardsList,
      selectedDate,
      selectedStore,
      projectDataError,
      userDetails,
      RoleData
    },
    dispatch,
  ] = useCentralStore();
  const navigate = useNavigate();
  const refBPM = useRef();
  const refTransactional = useRef();
  const [isBPMOpen, BPMOpen] = useState(isTradeDashboardOpen);

  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING, payload: action });
  };
  const escoUserFilter = RoleData.filter(item => item === "ESCO_USER");
  const expandDashboardOption = (payload) => {
    dispatch({
      type: ActionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS,
      payload: payload,
    });
  };

  const expandTransactionOptions = (payload) => {
    dispatch({
      type: ActionTypes.EXPAND_TRANSACTIONAL_OPTIONS,
      payload: payload,
    });
  };

  const bpmOptions = [];
  bpmOptions.push(
    { name: "GEO page", value: "GEO page" },
  );

  allDashboardsList?.map(c => bpmOptions.push({ name: c.Label, value: c.Label }))

  const Option = ({ name, value, isSelected, onSelect, disabled }) => {
    return (
      <div
        className={`p-2 text-sm last:rounded-b-md cursor-pointer w-full border-b last:border-b-0 hover:text-black hover:font-bold border-app-lightGreen ${
          isSelected
            ? "bg-app-deepGreen1 text-black font-bold"
            : "bg-app-deepGreen hover:bg-app-deepGreen1 text-app-lightGreen"
        } ${disabled ? "bg-gray-300 text-gray-400 cursor-not-allowed" : ""}`}
        onClick={disabled ? undefined : (e) => onSelect(e, value)}
      >
        {name}
      </div>
    );
  };

  const goToHomePage = () => {
    let payload = {
      // Username: userDetails && userDetails?.email?.split("@")?.[0],
      idToken: userData?.access_token,
    };
    setLoading(true);
    dispatch({
      type: ActionTypes.GET_DASHBOARD_DETAILS_DATA_SUCCESS,
      payload: false,
    });
    API[ActionTypes.GET_ROLE_BASES_MATRICS](payload, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          dispatch({ type: ActionTypes.SELECTED_STORE, payload: "all" });
          dispatch({
            type: ActionTypes.SELECTED_DASHBOARD_DATE,
            payload: {
              name: resources.LAST_90_DAYS_VALUE,
              value: resources.LAST_90_DAYS_VALUE,
            },
          });
          dispatch({
            type: ActionTypes.GET_ROLE_BASES_MATRICS_SUCCESS,
            payload: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.GET_ROLE_BASES_MATRICS_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
  };

    const goToTransaction = () =>{
      let payload = {
        idToken: userData?.access_token,
        // userName: userDetails && userDetails?.email?.split("@")?.[0]
      };
      setLoading(true);
      API[ActionTypes.GET_TRANSACTION_REPORTS](payload, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          dispatch({
            type: ActionTypes.GET_TRANSACTION_REPORTS_SUCCESS,
            payload: data?.TransactionalReportsResponse?.DataSourceList,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.GET_TRANSACTION_REPORTS_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
    }

  const handleBPMClick = (isSGP) => {
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: isSGP === 'true' ? null : resources.BPM_PROJECT_NAME,
    });
    dispatch({ type: ActionTypes.SET_CATEGORY, payload: null });
    dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: isSGP === 'true' ? null : "MAP" });
    dispatch({ type: ActionTypes.SET_PROGRAM_NAME, data: "" });
    dispatch({
      type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
      payload: null,
    });
    dispatch({ type: ActionTypes.SET_MAP_SELECTED, data: null });
    expandDashboardOption(undefined);
    dispatch({
      type: ActionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS,
      payload: false,
    });
    dispatch({
      type: ActionTypes.EXPAND_TRANSACTIONAL_OPTIONS,
      payload: false,
    });
    dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: null });
    dispatch({ type: ActionTypes.GO_TO_MAPS_PAGE, payload: isSGP === 'true' ? null : true });
    dispatch({ type: ActionTypes.SET_SELECTED_DASHBOARD, payload: undefined })
    dispatch({ type: ActionTypes.SGP_SET_REPORT_NAME, payload: {
      reportLabel: null,
      reportValue: null
    }})
    dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION, payload: {
      type: null,
      startDate: null,
      endDate: null,
      duration: null
    }})
    
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS, payload: null})
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS_SUCCESS, payload: null})
    dispatch({ type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS, payload: null})

    dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER, payload: 'real time'})
    goToHomePage();
  };

  const handleBPMOptionSelection = (e, value) => {
    
    dispatch({
      type: ActionTypes.SET_SELECTED_TAB,
      payload: value == "GEO page" ? "MAP" : value,
    });
    dispatch({
      type: ActionTypes.GET_CHILD_SUBMATRICS_SUCCESS,
      payload: undefined,
    });
    dispatch({ type: ActionTypes.SELECTED_STORE, payload: selectedStore || "all" });
    dispatch({
      type: ActionTypes.SELECTED_DASHBOARD_DATE,
      payload: {
        name: selectedDate || resources.LAST_90_DAYS_VALUE,
        value: selectedDate || resources.LAST_90_DAYS_VALUE,
      },
    });
    if (value == "GEO page") {
      dispatch({ type: ActionTypes.SET_PROGRAM_NAME, data: "" });
      dispatch({
        type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
        payload: null,
      });
      goToHomePage();
    }
    dispatch({
      type: ActionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS,
      payload: false,
    });
    dispatch({
      type: ActionTypes.EXPAND_TRANSACTIONAL_OPTIONS,
      payload: false,
    });
  };

  const handleTransactionalReporting = (isSGP) => {
      dispatch({
        type: ActionTypes.SET_PROJECT_NAME,
        payload: isSGP === 'true' ? null : resources.TRANSACTIONAL_REPORTING_PROJECT_NAME,
      });
      dispatch({ type: ActionTypes.SET_CATEGORY, payload: null });
      dispatch({
        type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
        payload: null,
      });
      dispatch({
        type: ActionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS,
        payload: false,
      });
      dispatch({
        type: ActionTypes.EXPAND_TRANSACTIONAL_OPTIONS,
        payload: false,
      });
      dispatch({ type: ActionTypes.SGP_SET_REPORT_NAME, payload: {
        reportLabel: null,
        reportValue: null
      }})
      dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION, payload: {
        type: null,
        startDate: null,
        endDate: null,
        duration: null
      }})
      dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_SUCCESS, payload: null})
      dispatch({ type: ActionTypes.GET_STARHUB_REPORT_COUNT_DETAILS_SUCCESS, payload: null})
      dispatch({ type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS, payload: null})
      dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER, payload: 'real time'})

      if(isSGP === 'false') goToTransaction()
      else goToHomePage()
  };

  const transactionalOptions = [];
  transactionalOptions.push(
    {name: 'Finance', value: 'Finance'},
    {name: 'Enrollments', value: 'Enrollment'},
    {name: 'Claims', value: 'Claims'},
    {name: 'Customer Solutions', value: 'CS'},
    {name: 'Supply Chain', value: 'SCM'}
  )

  const handleTransactionalOptionSelection = (e, value) => {
    dispatch({ type: ActionTypes.SET_CATEGORY, payload: value });
    dispatch({
      type: ActionTypes.EXPAND_TRANSACTIONAL_OPTIONS,
      payload: false,
    });
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        isTradeDashboardOpen &&
        refBPM.current &&
        !refBPM.current.contains(e.target)
      ) {
        BPMOpen(false);
        expandDashboardOption(!isTradeDashboardOpen);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isBPMOpen, isTradeDashboardOpen]);

  const filteredbpmOptions = bpmOptions?.filter(item => item?.name !== "AIS_Smart_Stock BPM Performance");
  const tradeOptions = filteredbpmOptions?.filter(item => (item?.name !== "Telstra BPM Performance" && item?.name !== "Telstra Operational Performance" && item?.name !== "Harvey_Norman BPM Performance" && item?.name !== "Harvey_Norman Operational Performance" && item?.name !== "Samsung BPM Performance" && item?.name !== "Samsung Operational Performance"))
  // const dpbpmOptions = filteredbpmOptions?.filter(item => (item?.name !== "Trade-In BPM Performance" && item?.name !== "Trade-In Operational Performance" && item?.name !== "Home Plus BPM Performance"))
  const dpbpmOptions = filteredbpmOptions?.filter(item => (item?.name !== "Trade-In BPM Performance" && item?.name !== "Trade-In Operational Performance"))
  const homeplusOption = filteredbpmOptions?.filter(item => (item?.name === "Home Plus BPM Performance" || item?.name === "GEO page"))
  // const programName = selectedTab === "Trade-In BPM Performance" || selectedTab === "Trade-In Operational Performance" ? tradeOptions : selectedTab === "Home Plus BPM Performance" ? homeplusOption : dpbpmOptions
  const programName = selectedTab === "Trade-In BPM Performance" || selectedTab === "Trade-In Operational Performance" ? tradeOptions : dpbpmOptions

  const handlePRMClick = (isSGP) => {
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: "PRM Ops Scorecard Summary",
    });
    dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "PRM Scoreboard" });
  };

  const handleInquiryClick = (isSGP) => {
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: "Inquiry Portal",
    });
    dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "Trade-In" });
    dispatch({
      type: ActionTypes.SET_START_DATE,
      payload: moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format('YYYY-MM-DD 00:00:00')
    })      ;
    dispatch({
      type: ActionTypes.SET_END_DATE,
      payload: moment(new Date()).format('YYYY-MM-DD 23:59:59')
    })
    dispatch({
      type: ActionTypes.SS_SET_REPORT_FILTER_DURATION,
      payload: {
        type: null,
        startDate: moment(
          new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
        ).format("YYYY-MM-DD 00:00:00"),
        endDate: moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        duration: null,
      },
    });
  };

  return (
    <>
    {config.isSGP === 'false' &&
      <div className="flex ml-20 justify-between">
      <div className="flex">
        <div className="relative mr-4 w-fit" ref={refBPM}>
          <div
            className={`cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5 ${
              projectName === resources.BPM_PROJECT_NAME
                ? "bg-app-lightGreen font-bold rounded-lg"
                : ""
            }`}
          >
            <div
              className="flex items-center mr-2"
              onClick={() => handleBPMClick(config.isSGP)}
            >
              <BPMIcon
                className=""
                fill={
                  projectName === resources.BPM_PROJECT_NAME
                    ? "black"
                    : "#37E7A7"
                }
              />
              <div
                className={`${
                  projectName === resources.BPM_PROJECT_NAME
                    ? "text-black"
                    : "text-app-lightGreen"
                } text-xs ml-1`}
              >
                {resources.BPM_PROJECT_NAME}
              </div>
            </div>
              <div className="flex cursor-pointer">
              {(!isEmpty(projectData) || projectDataError?.response?.data?.error?.message === 'NO_DATA_TO_REFRESH') && (
                <>
                  {isTradeDashboardOpen ? (
                    <ArrowUpIcon
                      className="hover:bg-app-deepGreen1 hover:rounded-lg"
                      onClick={() =>
                        expandDashboardOption(!isTradeDashboardOpen)
                      }
                      fill="black"
                    />
                  ) : (
                    <ArrowDownIcon
                      className="hover:bg-app-deepGreen1 hover:rounded-lg"
                      onClick={() =>
                        expandDashboardOption(!isTradeDashboardOpen)
                      }
                      fill="black"
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div className="absolute z-20 w-92%">
            {isTradeDashboardOpen && (!isEmpty(projectData) || projectDataError?.response?.data?.error?.message === 'NO_DATA_TO_REFRESH') && (
              <>
                {programName.map(({ name, value }) => (
                // {filteredbpmOptions.map(({ name, value }) => (
                  <Option
                    key={`key-${value}`}
                    isSelected={selectedTab === value}
                    value={value}
                    name={name}
                    onSelect={(e, value) => handleBPMOptionSelection(e, value)}
                  />
                ))}
              </>
            )}
          </div>
        </div>

        <div className="relative mr-4 w-fit" ref={refTransactional}>
          <div
            className={`cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5 ${
              (projectName === resources.TRANSACTIONAL_REPORTING_PROJECT_NAME || projectName === resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME)
                ? "bg-app-lightGreen font-bold rounded-lg"
                : ""
            }`}
          >
            <div
              className="flex items-center mr-2"
              onClick={() => handleTransactionalReporting(config.isSGP)}
            >
              <Transactional_ReportingIcon
                className=""
                fill={
                  (projectName === resources.TRANSACTIONAL_REPORTING_PROJECT_NAME || projectName === resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME)
                    ? "black"
                    : "#37E7A7"
                }
              />
              <div
                className={`${
                  (projectName === resources.TRANSACTIONAL_REPORTING_PROJECT_NAME || projectName === resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME)
                    ? "text-black"
                    : "text-app-lightGreen"
                } text-xs ml-1`}
              >
                {config.isSGP === 'true' ? resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME : resources.TRANSACTIONAL_REPORTING_PROJECT_NAME}
              </div>
            </div>
              <>
              {projectName !== resources.TRANSACTIONAL_REPORTING_PROJECT_NAME ? (
                <ArrowDownIcon className="cursor-not-allowed" fill="#37E7A7" />
              ) : (
              <div className="flex cursor-pointer">
                {isTransactionOptionsOpen ? (
                  <ArrowUpIcon
                    className="hover:bg-app-deepGreen1 hover:rounded-lg"
                    onClick={() =>
                      expandTransactionOptions(!isTransactionOptionsOpen)
                    }
                    fill="black"
                  />
                ) : (
                  <ArrowDownIcon
                    className="hover:bg-app-deepGreen1 hover:rounded-lg"
                    onClick={() =>
                      expandTransactionOptions(!isTransactionOptionsOpen)
                    }
                    fill="black"
                  />
                )}
              </div>
            )}
            </>
          </div>
          <div className="absolute z-20 w-92%">
            {isTransactionOptionsOpen && (
              <>
                {transactionalOptions.map(({ name, value }) => (
                  <Option
                    key={`key-${value}`}
                    isSelected={selectedCategory === value}
                    value={value}
                    name={name}
                    onSelect={(e, value) =>
                      handleTransactionalOptionSelection(e, value)
                    }
                  />
                ))}
              </>
            )}
          </div>
        </div>
        {/* <div className="cursor-not-allowed py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5">
          <Application_Health_MonitoringIcon
            className=""
            fill={
              projectName ===
              resources.APPLICATION_HEALTH_MONITORING_PROJECT_NAME
                ? "black"
                : "#37E7A7"
            }
          />
          <p
            className={`${
              projectName ===
              resources.APPLICATION_HEALTH_MONITORING_PROJECT_NAME
                ? "text-black"
                : "text-app-lightGreen"
            } text-xs ml-1`}
          >
            {resources.APPLICATION_HEALTH_MONITORING_PROJECT_NAME}
          </p>
        </div> */}
        {userDetails && resources.PRM_Scorecard_Users?.map((user)=>user?.toLowerCase())?.includes(userDetails?.email?.toLowerCase()) &&
          <div className="cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5" onClick={() => handlePRMClick()}>
            <Application_Health_MonitoringIcon
              className=""
              fill={
                projectName ===
                "PRM Ops Scorecard Summary"
                  ? "black"
                  : "#37E7A7"
              }
            />
            <p
              className={`${
                projectName ===
                "PRM Ops Scorecard Summary"
                  ? "text-black"
                  : "text-app-lightGreen"
              } text-xs ml-1`}
            >
              {"PRM"}
            </p>
          </div>
        }

        {((userDetails && escoUserFilter.length > 0) || (userDetails && resources.Inquiry_Portal_Users?.map((user)=>user?.toLowerCase())?.includes(userDetails?.email?.toLowerCase()))) &&
          <div className="cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5" onClick={() => handleInquiryClick()}>
            <Application_Health_MonitoringIcon
              className=""
              fill={
                projectName ===
                "Inquiry Portal"
                  ? "black"
                  : "#37E7A7"
              }
            />
            <p
              className={`${
                projectName ===
                "Inquiry Portal"
                  ? "text-black"
                  : "text-app-lightGreen"
              } text-xs ml-1`}
            >
              {"Inquiry Portal"}
            </p>
          </div>
        }
      </div>
      {!isEmpty(projectData) && <WelcomeSection resources={resources} />}
    </div>
    }
    {config.isSGP === 'true' &&
    <></>
    // <div className="flex ml-10 justify-between">
      
    //   <div className="flex">
    //     <div
    //         className={`cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5 ${
    //           projectName === resources.BPM_PROJECT_NAME
    //             ? "bg-app-lightGreen font-bold rounded-lg"
    //             : ""
    //         }`}
    //       >
    //         <div
    //           className="flex items-center mr-2"
    //           onClick={() => handleBPMClick(config.isSGP)}
    //         >
    //           <BPMIcon
    //             className=""
    //             fill={
    //               projectName === resources.BPM_PROJECT_NAME
    //                 ? "black"
    //                 : "#37E7A7"
    //             }
    //           />
    //           <div
    //             className={`${
    //               projectName === resources.BPM_PROJECT_NAME
    //                 ? "text-black"
    //                 : "text-app-lightGreen"
    //             } text-xs ml-1 `}
    //           >
    //             Home Page
    //           </div>
    //         </div>
    //       </div>
    //     <div
    //         className={`cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5 ${
    //           projectName
    //             ? "bg-app-starhubGreen font-bold rounded-lg"
    //             : ""
    //         }`}
    //       >
    //         <div
    //           className="flex items-center mr-2"
    //           // onClick={() => handleBPMClick(config.isSGP)}
    //         >
    //           <BPMIcon
    //             className=""
    //             fill={
    //               projectName
    //                 ? "black"
    //                 : "#37E7A7"
    //             }
    //           />
    //           <div
    //             className={`${
    //               projectName
    //                 ? "text-black"
    //                 : "text-app-lightGreen"
    //             } text-xs ml-1 `}
    //           >
    //             {projectName}
    //           </div>
    //         </div>
    //     </div>
    //     <div
    //         className={`cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5 ${
    //           projectName
    //             ? "bg-app-starhubGreen font-bold rounded-lg"
    //             : ""
    //         }`}
    //       >
    //         <div
    //           className="flex items-center mr-2"
    //           // onClick={() => handleBPMClick(config.isSGP)}
    //         >
    //         <Transactional_ReportingIcon
    //             className=""
    //             fill={
    //               (projectName)
    //                 ? "black"
    //                 : "#37E7A7"
    //             }
    //           />
    //           <div
    //             className={`${
    //               projectName
    //                 ? "text-black"
    //                 : "text-app-lightGreen"
    //             } text-xs ml-1 `}
    //           >
    //             Trend Analysis
    //           </div>
    //         </div>
    //       </div>
    //   </div>
    // </div>
    }
    </>
  );
};

export default DashboardMenu;
