import { useState, useRef } from "react";
import { ReactComponent as Logo } from "../../assets/icons/OneOplogo.svg";
import ScorecardSummaryFilter from "./ScorecardSummaryFilterInquiry";
import Selector from "../../components/common/selector/selectorNew";
import config from "../../config/config";
import BPMIcon from "../../assets/homeImg/BPM_small_v2";
import Transactional_ReportingIcon from "../../assets/homeImg/Transactional_Reporting_small_v2";
import Application_Health_MonitoringIcon from "../../assets/homeImg/Application_Health_Monitoring_small_v2";
import { toPascalCase, isEmpty } from "../../utils/functionUtils";
import resources from "../../components/common/resources.json";
import { ReactComponent as DownArrow } from "../../assets/icons/down-arrow.svg";
import ActionTypes from "../../store/actionTypes";
import { useCentralStore } from "../../store/appContext";
import API from "../../api";

const HeaderInquiry = () => {
  const [
    {
      userDetails,
      userData,
      RoleData,
      projectName,
      selectedTab,
      selectedCategory,
      ssSelectedReportFilter,
      isTransactionOptionsOpen,
      reportType
    },
    dispatch,
  ] = useCentralStore();
  const [selectedClient, setSelectedClient] = useState("Samsung");
  const refBPM = useRef();
  const refTransactional = useRef();
  const [isOptionsVisible, setOptionsVisible] = useState(false);
  let [isRefreshed, setRefresh] = useState(false);
  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING, payload: action });
  };
  const escoUserFilter = RoleData.filter((item) => item === "ESCO_USER");
  const SummaryFilterTitle =
    selectedTab === "Trade-In" ? "Trade-In" : "Device Care";
  const [, setIsTransactionOptionsOpen] = useState(false);

  const expandTransactionOptions = (payload) => {
    setIsTransactionOptionsOpen(!isTransactionOptionsOpen);
    dispatch({
      type: ActionTypes.EXPAND_TRANSACTIONAL_OPTIONS,
      payload: payload,
    });
  };

  const transactionalOptions = [];
  transactionalOptions.push(
    { name: "Trade-In", value: "Trade-In" }
    // {name: 'Device Care', value: 'Device Care'}
  );

  const Option = ({ name, value, isSelected, onSelect, disabled }) => {
    return (
      <div
        className={`p-2 text-sm last:rounded-b-md cursor-pointer w-full border bg-gray-300 hover:text-black hover:font-bold  ${
          isSelected ? "text-black font-bold" : "text-black"
        } ${disabled ? "bg-gray-300 text-gray-400 cursor-not-allowed" : ""}`}
        onClick={disabled ? undefined : (e) => onSelect(e, value)}
      >
        {name}
      </div>
    );
  };

  const handleTransactionalOptionSelection = (e, value) => {
    if (value === "Trade-In") {
      dispatch({ type: ActionTypes.SET_CATEGORY, payload: "Trade-In" });
      dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "Trade-In" });
    } else if (value === "Device Care") {
      dispatch({ type: ActionTypes.SET_CATEGORY, payload: "Device Care" });
      dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "Device Care" });
    }
    dispatch({
      type: ActionTypes.EXPAND_TRANSACTIONAL_OPTIONS,
      payload: false,
    });
  };

  const handlePRMClick = (isSGP) => {
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: "PRM Ops Scorecard Summary",
    });
    dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "PRM Scoreboard" });
  };

  const handleBPMClick = (isSGP) => {
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: isSGP === "true" ? null : resources.BPM_PROJECT_NAME,
    });
    dispatch({ type: ActionTypes.SET_CATEGORY, payload: null });
    dispatch({
      type: ActionTypes.SET_SELECTED_TAB,
      payload: isSGP === "true" ? null : "MAP",
    });
    dispatch({ type: ActionTypes.SET_PROGRAM_NAME, data: "" });
    dispatch({
      type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
      payload: null,
    });
    dispatch({ type: ActionTypes.SET_MAP_SELECTED, data: null });
    expandDashboardOption(undefined);
    dispatch({
      type: ActionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS,
      payload: false,
    });
    dispatch({
      type: ActionTypes.EXPAND_TRANSACTIONAL_OPTIONS,
      payload: false,
    });
    dispatch({ type: ActionTypes.SELECTED_CLIENT, payload: null });
    dispatch({
      type: ActionTypes.GO_TO_MAPS_PAGE,
      payload: isSGP === "true" ? null : true,
    });
    dispatch({ type: ActionTypes.SET_SELECTED_DASHBOARD, payload: undefined });
    dispatch({
      type: ActionTypes.SGP_SET_REPORT_NAME,
      payload: {
        reportLabel: null,
        reportValue: null,
      },
    });
    dispatch({
      type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION,
      payload: {
        type: null,
        startDate: null,
        endDate: null,
        duration: null,
      },
    });
    dispatch({ type: ActionTypes.SGP_SET_REPORT_FILTER, payload: "real time" });
    goToHomePage();
  };

  const handleInquiryClick = (isSGP) => {
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload: "Inquiry Portal",
    });
    dispatch({ type: ActionTypes.SET_SELECTED_TAB, payload: "Trade-In" });
    dispatch({ type: ActionTypes.SET_SELECTED_REPORT_TYPE, payload: null });
  };

  const handleTransactionalReporting = (isSGP) => {
    dispatch({
      type: ActionTypes.SET_PROJECT_NAME,
      payload:
        isSGP === "true"
          ? null
          : resources.TRANSACTIONAL_REPORTING_PROJECT_NAME,
    });
    dispatch({ type: ActionTypes.SET_CATEGORY, payload: null });
    dispatch({
      type: ActionTypes.GET_DASHBOARD_DETAILS_SUCCESS,
      payload: null,
    });
    dispatch({
      type: ActionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS,
      payload: false,
    });
    dispatch({
      type: ActionTypes.EXPAND_TRANSACTIONAL_OPTIONS,
      payload: false,
    });
    dispatch({
      type: ActionTypes.SGP_SET_REPORT_NAME,
      payload: {
        reportLabel: null,
        reportValue: null,
      },
    });
    dispatch({
      type: ActionTypes.SGP_SET_REPORT_FILTER_DURATION,
      payload: {
        type: null,
        startDate: null,
        endDate: null,
        duration: null,
      },
    });
    if (isSGP === "false") goToTransaction();
    else goToHomePage();
  };

  const expandDashboardOption = (payload) => {
    dispatch({
      type: ActionTypes.EXPAND_TRADEIN_DASHBOARD_OPTIONS,
      payload: payload,
    });
  };

  const goToHomePage = () => {
    let payload = {
      idToken: userData?.access_token,
    };
    setLoading(true);
    dispatch({
      type: ActionTypes.GET_DASHBOARD_DETAILS_DATA_SUCCESS,
      payload: false,
    });
    API[ActionTypes.GET_ROLE_BASES_MATRICS](
      payload,
      userDetails && userDetails?.email?.split("@")?.[0]
    )
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          dispatch({ type: ActionTypes.SELECTED_STORE, payload: "all" });
          dispatch({
            type: ActionTypes.SELECTED_DASHBOARD_DATE,
            payload: {
              name: resources.LAST_90_DAYS_VALUE,
              value: resources.LAST_90_DAYS_VALUE,
            },
          });
          dispatch({
            type: ActionTypes.GET_ROLE_BASES_MATRICS_SUCCESS,
            payload: data,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.GET_ROLE_BASES_MATRICS_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](
          _payload,
          userData?.access_token,
          userDetails && userDetails?.email?.split("@")?.[0]
        );
      });
  };

  const goToTransaction = () => {
    let payload = {
      idToken: userData?.access_token,
    };
    setLoading(true);
    API[ActionTypes.GET_TRANSACTION_REPORTS](
      payload,
      userDetails && userDetails?.email?.split("@")?.[0]
    )
      .then(({ data }) => {
        setLoading(false);
        if (data) {
          dispatch({
            type: ActionTypes.GET_TRANSACTION_REPORTS_SUCCESS,
            payload: data?.TransactionalReportsResponse?.DataSourceList,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ActionTypes.GET_TRANSACTION_REPORTS_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](
          _payload,
          userData?.access_token,
          userDetails && userDetails?.email?.split("@")?.[0]
        );
      });
  };

  let getRefresheState = (refresh) => {
    setRefresh(refresh);
  };

  return (
    <div className={`h-full w-full`}>
      <div id="main" className=" flex flex-col mx-5">
        <div className={`flex pb-2 flex-row justify-between items-center`}>
          <div className="flex justify-start items-center">
            <Logo
              className="w-14 cursor-pointer"
              onClick={() => handlePRMClick()}
            />
            <p className="text-white text-2xl pb-3 ml-4 font-bold">
              Inquiry Portal
            </p>
          </div>
          <div className="flex flex-row items-center">
            <div className="flex justify-end bg-white p-1 text-center rounded-full text-black mr-2 font-bold text-sm">
              {userDetails &&
                userDetails?.firstName?.split()?.[0]?.charAt(0)?.toUpperCase()}
              {userDetails &&
                userDetails?.lastName?.split()?.[0]?.charAt(0)?.toUpperCase()}
            </div>
            <span className="font-bold">
              {userDetails && toPascalCase(userDetails?.firstName)}{" "}
              {userDetails && toPascalCase(userDetails?.lastName)}
            </span>
          </div>
        </div>

        <div className="flex justify-between">
          <div className="flex">
            <div className="relative mr-4 w-fit" ref={refBPM}>
              <div
                className={`cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5 ${
                  projectName === resources.BPM_PROJECT_NAME
                    ? "bg-black font-bold rounded-lg"
                    : ""
                }`}
              >
                <div
                  className="flex items-center mr-2"
                  onClick={() => handleBPMClick(config.isSGP)}
                >
                  <BPMIcon
                    className=""
                    fill={
                      projectName === resources.BPM_PROJECT_NAME
                        ? "#37E7A7"
                        : "#37E7A7"
                    }
                  />
                  <div
                    className={`${
                      projectName === resources.BPM_PROJECT_NAME
                        ? "text-app-lightGreen"
                        : "text-app-lightGreen"
                    } text-xs ml-1`}
                  >
                    {resources.BPM_PROJECT_NAME}
                  </div>
                </div>
              </div>
            </div>

            <div className="relative mr-4 w-fit" ref={refTransactional}>
              <div
                className={`cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5 ${
                  projectName ===
                    resources.TRANSACTIONAL_REPORTING_PROJECT_NAME ||
                  projectName ===
                    resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME
                    ? "bg-black font-bold rounded-lg"
                    : ""
                }`}
              >
                <div
                  className="flex items-center mr-2"
                  onClick={() => handleTransactionalReporting(config.isSGP)}
                >
                  <Transactional_ReportingIcon
                    className=""
                    fill={
                      projectName ===
                        resources.TRANSACTIONAL_REPORTING_PROJECT_NAME ||
                      projectName ===
                        resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME
                        ? "#37E7A7"
                        : "#37E7A7"
                    }
                  />
                  <div
                    className={`${
                      projectName ===
                        resources.TRANSACTIONAL_REPORTING_PROJECT_NAME ||
                      projectName ===
                        resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME
                        ? "text-app-lightGreen"
                        : "text-app-lightGreen"
                    } text-xs ml-1`}
                  >
                    {config.isSGP === "true"
                      ? resources.SGP_TRANSACTIONAL_REPORTING_PROJECT_NAME
                      : resources.TRANSACTIONAL_REPORTING_PROJECT_NAME}
                  </div>
                </div>
              </div>
            </div>

            {userDetails &&
              resources.PRM_Scorecard_Users?.map((user) =>
                user?.toLowerCase()
              )?.includes(userDetails?.email?.toLowerCase()) && (
                <div
                  className="cursor-pointer py-2 px-3 flex justify-center items-center border-b border-app-lightGreen mr-5"
                  onClick={() => handlePRMClick()}
                >
                  <Application_Health_MonitoringIcon
                    className=""
                    fill={
                      projectName === "PRM Ops Scorecard Summary"
                        ? "#37E7A7"
                        : "#37E7A7"
                    }
                  />
                  <p
                    className={`${
                      projectName === "PRM Ops Scorecard Summary"
                        ? "text-app-lightGreen"
                        : "text-app-lightGreen"
                    } text-xs ml-1`}
                  >
                    {"PRM"}
                  </p>
                </div>
              )}

            {((userDetails && escoUserFilter.length > 0) ||
              (userDetails &&
                resources.Inquiry_Portal_Users?.map((user) =>
                  user?.toLowerCase()
                )?.includes(userDetails?.email?.toLowerCase()))) && (
              <div
                className="cursor-pointer py-2 px-3 flex justify-center items-center border  rounded-lg bg-app-lightGreen border-app-lightGreen mr-5"
                onClick={() => handleInquiryClick()}
              >
                <Application_Health_MonitoringIcon
                  className=""
                  fill={
                    projectName === "Inquiry Portal" ? "#000000" : "#000000"
                  }
                />
                <p
                  className={`${
                    projectName === "Inquiry Portal"
                      ? "text-black font-bold"
                      : "text-black font-bold"
                  } text-xs ml-1`}
                >
                  {"Inquiry Portal"}
                </p>
              </div>
            )}
          </div>

          <div className="flex mr-5 pt-2">
            <a
              className="text-blue-500 underline font-bold"
              href="https://app.powerbi.com/groups/720ab3c6-47ce-406f-b601-32d7eb2d6fc1/reports/32ab11cb-8cf1-4241-993c-8c46040fa029/ReportSectionfc3677a70edb2d6c7be1?experience=power-bi"
              target="_blank"
            >
              View AI response analyser here.
            </a>
          </div>
        </div>

        { reportType && reportType === "Inquiry" &&   <div className="flex justify-between">
          <div className="flex gap-4 mt-10" style={{}}>
            <Selector
              key="Client Dropdown"
              options={[
                { name: "Samsung", value: "Samsung" },
                { name: "Vodafone", value: "Vodafone" },
                { name: "Harvey Norman", value: "Harvey Norman" },
              ]}
              label="Client"
              value={selectedClient}
              onSelect={(val) => {
                setSelectedClient(val);
                // gotoDashBoard();
              }}
              placeholder="Samsung"
              className="relative top-0 mt-10"
              style={{ zIndex: 1000 }}
            />
            <div className="relative w-fit" ref={refTransactional}>
              <div
                className={`cursor-pointer py-2 px-3 flex justify-center items-center rounded-lg border border-app-border text-white font-bold`}
              >
                {" "}
                {SummaryFilterTitle}
                <div className="flex cursor-not-allowed ml-5">
                  <DownArrow
                    onClick={() => expandTransactionOptions}
                    fill="black"
                  />
                </div>
              </div>
              {isOptionsVisible && (
                <div className="absolute z-20 w-full overflow-y-auto">
                  {isTransactionOptionsOpen && (
                    <>
                      {transactionalOptions.map(({ name, value }) => (
                        <Option
                          key={`key-${value}`}
                          isSelected={selectedCategory === value}
                          value={value}
                          name={name}
                          onSelect={(e, value) =>
                            handleTransactionalOptionSelection(e, value)
                          }
                        />
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col mt-10">
            <ScorecardSummaryFilter
              getRefresheState={getRefresheState}
              ssSelectedReportFilter={ssSelectedReportFilter}
              dispatch={dispatch}
              ActionTypes={ActionTypes}
              selectedClient={selectedClient}
            />
          </div>
        </div>
}
      </div>
    </div>
  );
};

export default HeaderInquiry;
