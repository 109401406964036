import React from "react";
import reportData from "../../utils/reportData.json";
import { useCentralStore } from "../../store/appContext";

export default function ReportFinance({DataSourceList}) {

  const [{ selectedCategory, reportsdetails, countryData }, dispatch] = useCentralStore();
  const countries = DataSourceList?.filter((item) => selectedCategory === item?.Group)
  const Country = countries?.[0]?.Countries
  const rbmCountry = countryData === undefined ? "" : countryData.map((v) => v)
  const trCountry = Country.map((v) => v)
  const CommonCountry = rbmCountry === "" ? "" : trCountry.filter(o => rbmCountry.some(({Country}) => o.Country === Country))
  const CommonCountryLength = CommonCountry.length
  return (
    <div className="mt-12">
      {CommonCountryLength === 0 ? <div className="flex justify-center items-center py-28 font-bold text-2xl"> No Reports Available.</div> :
      <div className="flex overflow-x-auto mx-5">
        {CommonCountry?.map((item) => {
          return (
            <div className="flex flex-col mx-4">
              <div className="mx-4 font-semibold text-purple-500 text-2xl border-b border-app-border border-spacing-b-2">
                {item.Country}
              </div>
              <div className="mt-4 mx-4">
                {item.Reports.map((ele) => {
                  return (
                    <div className="w-44 md:w-64 flex flex-col justify-start border-1 border-gray-700 bg-app-border mb-1 text-white mt-2 py-1 pl-4 hover:bg-app-landingColor1">
                      <a target="_blank" href={ele.ReportUrl} rel="noopener noreferrer"> {ele.ReportName} </a>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
      }
    </div>
  );
}
