import React, { useEffect, useRef, useState } from "react";
import { Table  } from "antd";
import { getColumnSearchProps } from "./AntTableSearchFilter";
import CrossIcon from "../../../assets/icons/cross-icon";
import API from "../../../api";
import moment from "moment";
import ActionTypes from "../../../store/actionTypes";
import { useCentralStore } from "../../../store/appContext";
import Button from "../button/button";
import exportToCSVInventory from "../hepler/export-to-csv-submatricsData";
import Popup from "../popup/popup";

const SGPDataTable = ({
  label,
  value,
  onClose,
  reportDetailsSuccess,
  MetricsLabel,
  resources,
  reportType,
  subLabel
}) => {
  const inputRef = useRef(null);
  const resetRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [column, setColumn] = useState("");
  const [
    {
      exportSubmatricsData,
      projectData,
      nintyDaysData,
      selectedStore,
      selectedClient,
      clientData,
      userData,
      userDetails,
      reportFilterDuration
    },
    dispatch,
  ] = useCentralStore();
  const setLoading = (action) => {
    dispatch({ type: ActionTypes.SET_LOADING_DATA, payload: action });
  };

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [firstLoad, setFirstLoad] = useState(true);
  const [recoreMoreThanThousand, setRecoreMoreThanThousand] = useState(false);
  const [currentSearchedData, setCurrentSearchedData] = useState(undefined);
  
  let tableColumns = [];
  const totalKeys = {};

  reportDetailsSuccess?.reduce((acc, current) => {
    const keys = Object.keys(current);
    for (var i in keys) {
      totalKeys[keys[i]] = 1;
    }
  }, totalKeys);

  var keys = Object.keys(totalKeys);
  for (var i in keys) {
    tableColumns.push({
      title: keys[i]?.replace(/([a-z0-9])([A-Z])/g, "$1 $2")?.toUpperCase(),
      dataIndex: keys[i],
      key: keys[i],
      width: 280
    });
  }

  const getPartsColumns = () => {
    let headerColumns = [];
    tableColumns.forEach((c, i) => {
      if (i < 5) {
        headerColumns.push({
          ...c,
          ...getColumnSearchProps(
            c.key,
            inputRef,
            resetRef,
            searchText,
            setSearchText,
            column,
            setColumn
          ),
        });
      } else {
        headerColumns.push({
          ...c,
        });
      }
    });
    return headerColumns;
  };

  const [exportErrMsg, setExportErrMsg] = useState(null);

  const exportData = async () => {
    let payload = {
      StartDate: reportFilterDuration?.startDate,
      EndDate: reportFilterDuration?.endDate,
      ReportType: reportType,
      Type: reportType.toLowerCase() === 'servicerequest' ? undefined : label ,
      StatusCode: reportType.toLowerCase() === 'servicerequest' ? label : undefined,
      StartingRow: 1,
      RowsCount: Number(value > 1000 ? 1000 : value),
    }
    setLoading(true)
    API[ActionTypes.GET_STARHUB_REPORT_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
    .then(({ data }) => {
        setLoading(false);
        if (data) {
          exportToCSVInventory(
            tableColumns,
            data?.GetReportDetailsResponse,
            "",
            "",
            MetricsLabel+' '+label,
            dispatch,
            ActionTypes
          )
          // dispatch({
          //   type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS,
          //   payload: data,
          // });
        }
        if(MetricsLabel !== 'SERVICE REQUEST REVENUE' && value > 1000){
          setRecoreMoreThanThousand(true)
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: ActionTypes.GET_STARHUB_REPORT_DETAILS_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
  };

  const reportDetailsSuccessData = function (reportDetailsSuccess) {
      return reportDetailsSuccess.map((obj) => {
        let dateObj = {};
        Object.entries(obj).map(
          ([key, value]) =>
            (dateObj[key] = key?.toUpperCase()?.includes("_DATE")
              ? value?.length !== 0
                ? (MetricsLabel?.toLowerCase() === 'device registration' || MetricsLabel?.toLowerCase() === 'enrolment') ? moment(value).format("DD-MM-YYYY") : moment(value).format("DD-MM-YYYY HH:mm:ss")
                : null
              : value)
        );
        return dateObj;
      });
  };

  const onPerEntryPage = (e) => {
    setExportErrMsg(null)
    setFirstLoad(false)
    setPagination({
      current: 1,
      pageSize: e.target.value
    })
  };

  const onPageChange = (e) => {
    setExportErrMsg(null)
    setFirstLoad(false)
    setPagination({
      current: (e.target.value > Math.ceil(value/pagination.pageSize)) || e.target.value == '' ? pagination.current : e.target.value,
      pageSize: pagination.pageSize
    })
  };  
  
  const handleTableChange = (currentPagination, filters, sorter, extra) => {

    let payload = {
      StartDate: reportFilterDuration?.startDate,
      EndDate: reportFilterDuration?.endDate,
      ReportType: reportType,
      Type: reportType.toLowerCase() === 'servicerequest' ? undefined : label ,
      StatusCode: reportType.toLowerCase() === 'servicerequest' ? label : undefined,
      StartingRow: 1,
      RowsCount: 10
    }
    setLoading(true)
    API[ActionTypes.GET_STARHUB_REPORT_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
    .then(({ data }) => {
        setLoading(false);
        if (data) {
          dispatch({
            type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS,
            payload: data,
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch({
          type: ActionTypes.GET_STARHUB_REPORT_DETAILS_FAILURE,
          payload: err,
        });
        dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
        let _payload = {
          level: resources.POST_LOGGER_ERROR,
          category: resources.POST_LOGGER_API,
          ApiName: resources.POST_LOGGER_APINAME,
          message: {
            error: err,
            info: {
              idToken: "",
            },
          },
        };
        API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
      });
    
    let isFilter = false;
    for (let objKey in filters) {
      setCurrentSearchedData(extra?.currentDataSource);

      if (filters[objKey] && filters[objKey].length) {
        isFilter = true;
        const totalCount = currentSearchedData?.filter(
          (element) => element.objKey === filters[objKey][0]
        ).length;
        setPagination({
          ...pagination,
          current: 1,
          pageSize: pagination.pageSize,
          total: totalCount,
        });
      }
    }
    if (isFilter === false) {
      setCurrentSearchedData(undefined);
      setPagination({
        ...pagination,
        current: Number(pagination.current),
        pageSize: Number(pagination.pageSize),
        total: currentSearchedData?.length,
      });
    }
  };


  useEffect(()=>{
    if(pagination && firstLoad !== true){
      let payload = {
        StartDate: reportFilterDuration?.startDate,
        EndDate: reportFilterDuration?.endDate,
        ReportType: reportType,
        Type: reportType.toLowerCase() === 'servicerequest' ? undefined : label ,
        StatusCode: reportType.toLowerCase() === 'servicerequest' ? label : undefined,
        StartingRow: Number(pagination.current),
        RowsCount: Number(pagination.pageSize),
      }
      setLoading(true)
      API[ActionTypes.GET_STARHUB_REPORT_DETAILS](payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0])
      .then(({ data }) => {
          setLoading(false);
          if (data) {
            dispatch({
              type: ActionTypes.GET_STARHUB_REPORT_DETAILS_SUCCESS,
              payload: data,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          dispatch({
            type: ActionTypes.GET_STARHUB_REPORT_DETAILS_FAILURE,
            payload: err,
          });
          dispatch({ type: ActionTypes.SET_API_ERROR, data: true });
          let _payload = {
            level: resources.POST_LOGGER_ERROR,
            category: resources.POST_LOGGER_API,
            ApiName: resources.POST_LOGGER_APINAME,
            message: {
              error: err,
              info: {
                idToken: "",
              },
            },
          };
          API[ActionTypes.POST_LOGGER](_payload, userData?.access_token, userDetails && userDetails?.email?.split("@")?.[0]);
        });
    }
  }, [pagination, firstLoad])


  return (
    <div className="py-3">
      <div className="flex flex-row justify-between items-center mx-7 mb-3">
        <p className="text-black text-lg font-bold">{subLabel?.toUpperCase()}</p>
        <div className="flex flex-row justify-end items-center">
          {value > 0 && (
            <Button
              label={'Download'}
              className="text-sm px-5 py-1 rounded-3xl mr-4 !bg-[#1ED760] text-black hover:text-slate-500"
              onClick={() => exportData()}
            />
          )}
          <CrossIcon
            className="float-right m-auto cursor-pointer"
            fill="black"
            onClick={onClose}
          />
        </div>
      </div>
      <Table
        columns={getPartsColumns()}
        dataSource={currentSearchedData || reportDetailsSuccessData(reportDetailsSuccess)}
        onChange={handleTableChange}
        style={{ fontSize: "10px !important" }}
        rowKey="data"
        key="data"
        scroll={{
          y: "65vh",
          // x: true
        }}
        className="table-css border-current border-white"
        locale={{ emptyText: "No data" }}
        pagination={false}
      />
      {reportDetailsSuccess?.length > 0 &&
        <div className="pt-5 px-5">
          <div className="text-app-lightgray1 text-xs flex flex-row">
              <div className="flex flex-row items-center">
                <p>{resources.PAGINATION_PAGE_NO}&nbsp;</p> 
                <input type="number" id="pageno" name="pageno" 
                  className="border border-app-lightgray1 rounded-sm w-12 text-center text-sm mr-5"
                  min={1} max={Math.ceil(value/pagination.pageSize)} value={pagination?.current}
                  onChange={(e) => onPageChange(e)}/>
              </div>
              <div className="flex flex-row items-center">
                <p>{resources.PAGINATION_ENTRIES_PER_PAGE}&nbsp;</p> 
                <input type="number" id="entriesperpage" name="entriesperpage"
                  className="border border-app-lightgray1 rounded-sm w-12 text-center text-sm"
                  min={10} max={Math.ceil(value)} value={pagination.pageSize} step={10}
                  onChange={(e) => onPerEntryPage(e)}/>
              </div>
          </div>
          <p className="text-10px text-app-lightgray1">{resources.PAGINATION_SHOWING} {pagination?.current} of {Math.ceil(value/pagination.pageSize)}</p>
        </div>
      }
      {recoreMoreThanThousand && (
            <>
              {/* <p className="text-card-greenBorder">
                {resources.EMAIL_SEND_MSG}
              </p> */}
              <Popup
                show={recoreMoreThanThousand}
                global={true}
                fitContent={true}
                emailPopup={true}
                // onClose={() => setEmailPopup(false)}
              >
                <div className="p-4 pb-6 rounded-xl" style={{height: "auto !important"}}>
                {/* <div className="flex justify-center bg-app-purple px-3 pt-6px pb-6px items-center font-bold rounded-lg -mt-9 w-20 text-center"><p className="text-white">Alert!</p></div> */}
                  <div className="flex justify-end">
                    <CrossIcon className="cursor-pointer" onClick={() => setRecoreMoreThanThousand(false)} />
                  </div>
                  <p className="text-black font-semibold inline-block mt-2 px-4">
                    <p className="text-sm text-center">Total records downloaded: 1000</p>
                  </p>
                </div>
              </Popup>
            </>
          )}
    </div>
  );
};

export default SGPDataTable;
