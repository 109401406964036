import React, { useEffect, useState } from "react";
import { useCentralStore } from "../../store/appContext";
import { getUniqueValuesFromArray, isEmpty, toPascalCase } from "../../utils/functionUtils";
import ActionTypes from "../../store/actionTypes";

import AustraliaMap from "../../assets/maps/australia_map";
import MalaysiaMap from "../../assets/maps/malaysia_map";
import NewZealandMap from "../../assets/maps/new_zealand_map";
import SingaporeMap from "../../assets/maps/singapore_map";
import SouthKoreaMap from "../../assets/maps/south_korea_map";
import TaiwanMap from "../../assets/maps/taiwan_map";
import ThailandMap from "../../assets/maps/thailand_map";


import ClientsInfo from "./ClientsInfo";
import FavouriteCards from "./FavouriteCards";

const MapsContainer = ({ resources }) => {
  const [{ mapSelected, MasterData, country_Data, unique_Clients, program_Data, clientsResultData, uniqueClientsList, selectedClient, userData }, dispatch] = useCentralStore();

  const handleMapClick = (name) => {
    dispatch({ type: ActionTypes.SET_MAP_SELECTED, data: name });
  };

  let [mapData, setMapData] = useState();

  useEffect(() => {
    if (mapSelected) {
      dispatch({ type: ActionTypes.SET_COUNTRYDATA, payload: MasterData?.filter((u) => mapSelected === u?.Country)})
    }
  }, [mapSelected]);

  useEffect(() => {
    if (mapSelected && !isEmpty(country_Data)) {
      dispatch({ type: ActionTypes.SET_UNIQUE_CLIENTS, payload:  getUniqueValuesFromArray(country_Data, "ClientName")?.map((v) => v?.ClientName)})

      let result = country_Data.reduce(function (r, a) {
        r[a.ClientName] = r[a.ClientName] || [];
        r[a.ClientName].push({ Program: a.Program, Color: a.CountryColor });
        return r;
      }, Object.create(null));
      dispatch({ type: ActionTypes.SET_PROGRAM_DATA, payload: result})
    }
  }, [mapSelected, country_Data]);

  useEffect(() => {
    if(!isEmpty(unique_Clients) && !isEmpty(clientsResultData)){
      let unique_Clients_name = unique_Clients?.map(c => c?.toLowerCase()?.replace(' ',''))
      dispatch({ type: ActionTypes.SET_UNIQUE_CLIENS_DATA, payload: clientsResultData.filter(c => unique_Clients_name.includes(c.Client?.toLowerCase()?.replace(' ','')))})
    }
  }, [unique_Clients, clientsResultData])

  // useEffect(() => {
  //   if(!isEmpty(uniqueClientsList) && !isEmpty(selectedClient)){
  //     let allDashboardsArray = []
  //     uniqueClientsList?.filter(v => v?.ClientId == selectedClient)[0]?.Channels?.map(v => v?.AllDashboards?.map(a => allDashboardsArray.push(a)))
  //     let allDashboards = allDashboardsArray?.filter((v,i,a)=>a.findIndex(v2=>(v2.Label===v.Label))===i)  
  //     dispatch({ type: ActionTypes.SET_ALL_DASHBOARDS, payload: allDashboards })
  //   }
  // }, [selectedClient, uniqueClientsList])

  useEffect(() => {
    if (!isEmpty(MasterData)) {
      let result = MasterData.reduce(function (r, a) {
        r[a.Country] = r[a.Country] || [];
        r[a.Country].push({
          CountryColor:
            a.CountryColor === "Red"
              ? "Red"
              : a.CountryColor === "Yellow"
              ? "Yellow"
              : "Green",
        });
        return r;
      }, Object.create(null));
      setMapData(result);
    }
  }, [MasterData]);

  // useEffect(()=>{
  //   mapData?.some(v => v?.CountryColor == 'Red')
  // }, [!isEmpty(mapData)])

  const Coutries = (data) => {
    switch (data?.data?.name) {
      case resources.AUS_LBL:
        return (
          <AustraliaMap
            className={data?.data?.className}
            fill={data?.data?.fill}
            onClick={data?.data?.onClick}
          />
        );
      case resources.MALAYSIA_LBL:
        return (
          <MalaysiaMap
            className={data?.data?.className}
            fill={data?.data?.fill}
            onClick={data?.data?.onClick}
          />
        );
      case resources.NZ_LBL:
        return (
          <NewZealandMap
            className={data?.data?.className}
            fill={data?.data?.fill}
            onClick={data?.data?.onClick}
          />
        );
      case resources.SINGAPORE_LBL:
        return (
          <SingaporeMap
            className={data?.data?.className}
            fill={data?.data?.fill}
            onClick={data?.data?.onClick}
          />
        );
      case resources.SK_LBL:
        return (
          <SouthKoreaMap
            className={data?.data?.className}
            fill={data?.data?.fill}
            onClick={data?.data?.onClick}
          />
        );
      case resources.TAIWAN_LBL:
        return (
          <TaiwanMap
            className={data?.data?.className}
            fill={data?.data?.fill}
            onClick={data?.data?.onClick}
          />
        );
      case resources.TH_LBL:
        return (
          <ThailandMap
            className={data?.data?.className}
            fill={data?.data?.fill}
            onClick={data?.data?.onClick}
          />
        );
    }
  };

  return (
    // <div className="border-t border-app-border ">
    <div>
      <div className="mx-4">
        {/* <div className="font-bold text-xl mt-2">Welcome Home</div>
        <div className="text-xs ">
          Monitoring operational performance of APAC products
        </div> */}
        <div
          className={`${mapSelected ? "mt-8 h-80vh" : "div-center"} bgImage`}
        >
          <p className="text-center text-2xl mb-7">
            {!mapSelected ? (
              <>
                <p className="mb-3">{resources.MAP_SELECTION_HEADER}</p>
                <p className="text-sm">{resources.MAP_SELECTION_SUBHEADER}</p>
              </>
            ) : (
              resources.PROGRAM_SELECTION_HEADER
            )}
          </p>
          <div className="flex flex-row justify-evenly mx-auto w-3/4">
            {!isEmpty(mapData) &&
              Object?.keys(mapData)?.map((v) => {
                let redColor = getUniqueValuesFromArray(
                  mapData[v],
                  "CountryColor"
                )
                  ?.map((v) => v?.CountryColor)
                  ?.includes("Red");
                let yellowColor = getUniqueValuesFromArray(
                  mapData[v],
                  "CountryColor"
                )
                  ?.map((v) => v?.CountryColor)
                  ?.includes("Yellow");
                let greenColor = getUniqueValuesFromArray(
                  mapData[v],
                  "CountryColor"
                )
                  ?.map((v) => v?.CountryColor)
                  ?.includes("Green");

                return (
                  <div
                    className={`${
                      mapSelected === v ? "" : "opacity-50"
                    } scale-75 ${v?.toLowerCase() == "australia" || v?.toLowerCase() == "singapore" || v?.toLowerCase() == "malaysia" || v?.toLowerCase() == 'thailand' || v?.toLowerCase() == 'taiwan'
                        ? "hover:opacity-100"
                        : ""
                    }`}
                  >
                    <p
                      className={`${
                        mapSelected === v ? `font-bold text-white}` : ""
                      } text-center mb-4`}
                    >
                      {/* ${redColor ? 'red-500' : 'card-greenBorder' */}
                      {v}
                    </p>
                    <Coutries
                      data={{
                        name: v,
                        className: `${v?.toLowerCase() == "australia" || v?.toLowerCase() == "singapore" || v?.toLowerCase() == "malaysia" || v?.toLowerCase() == 'thailand' || v?.toLowerCase() == 'taiwan'
                            ? "cursor-pointer transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-110 duration-500"
                            : "cursor-not-allowed"
                        }`,
                        fill: redColor
                          ? "#DB1B1B"
                          : yellowColor
                          ? "#EDB800"
                          : "#37E7A7",
                        onClick: () =>
                          `${v?.toLowerCase() == "australia" || v?.toLowerCase() == "singapore" || v?.toLowerCase() == "malaysia" || v?.toLowerCase() == 'thailand' || v?.toLowerCase() == 'taiwan'
                              ? handleMapClick(v)
                              : undefined
                          }`,
                      }}
                    />
                    {/* <AustraliaMap
                className="cursor-pointer transition ease-in-out delay-350 hover:-translate-y-0 hover:scale-110 duration-500"
                fill={"#37E7A7"}
                onClick={() => handleMapClick("Australia")}
              /> */}
                  </div>
                );
              })}
          </div>
          {/* {mapSelected && clientData1 && (
            <div className={`flex overflow-x-auto justify-center`}>
              {clientData1?.map((c, i) => (
                <ClientsInfo
                  key={c.client}
                  clientObject={c}
                  hideLastArrow={i === clientData1?.length - 1}
                  dispatch={dispatch}
                  actionTypes={ActionTypes}
                />
              ))}
            </div>
          )} */}

          {(mapSelected && unique_Clients) ? (
            <div className={`flex overflow-x-auto justify-center`}>
              {unique_Clients?.map((c, i) => (
                <ClientsInfo
                  key={c}
                  clientObject={c}
                  hideLastArrow={i === unique_Clients?.length - 1}
                  dispatch={dispatch}
                  actionTypes={ActionTypes}
                  programData={program_Data}
                  resources={resources}
                  userData={userData}
                />
              ))}
            </div>
          )
        :
          <FavouriteCards />
        }
        </div>
      </div>
    </div>
  );
};

export default MapsContainer;
