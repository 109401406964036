import React, { useContext, useEffect } from 'react';
import { useCentralStore } from '../store/appContext';
import ActionTypes from '../store/actionTypes';
import Button from '../components/common/button/button';
import resources from '../components/common/resources.json'

const CommonError = (props) => {
    const { isExceptionalError, errorInfo, errorMessage } = props;
    const [{ projectDataError }, dispatch] = useCentralStore()
    const close = () => {
        if(isExceptionalError){
            window.open('/','_self');
        }
        else{
            dispatch({ type: ActionTypes.SET_API_ERROR, data: false })
            window.open('/','_self');
        }

    }
    const retry = () => {
        if(isExceptionalError){
            close()
        }
        else{
            dispatch({ type: ActionTypes.SET_API_ERROR, data: false })
        }
    }
    return (
        // <div className='mt-10'>
            <div class="Container">
                <div className='Container-content'>

                <div class="MainGraphic">
                    <svg class="Cog" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M29.18 19.07c-1.678-2.908-.668-6.634 2.256-8.328L28.29 5.295c-.897.527-1.942.83-3.057.83-3.36 0-6.085-2.743-6.085-6.126h-6.29c.01 1.043-.25 2.102-.81 3.07-1.68 2.907-5.41 3.896-8.34 2.21L.566 10.727c.905.515 1.69 1.268 2.246 2.234 1.677 2.904.673 6.624-2.24 8.32l3.145 5.447c.895-.522 1.935-.82 3.044-.82 3.35 0 6.066 2.725 6.083 6.092h6.29c-.004-1.035.258-2.08.81-3.04 1.676-2.902 5.4-3.893 8.325-2.218l3.145-5.447c-.9-.515-1.678-1.266-2.232-2.226zM16 22.48c-3.578 0-6.48-2.902-6.48-6.48S12.423 9.52 16 9.52c3.578 0 6.48 2.902 6.48 6.48s-2.902 6.48-6.48 6.48z"/></svg>
                    </div>

                    <h1 class="MainTitle">
                       {projectDataError?.response?.data?.name == 'TokenExpiredError' ? resources.SESSION_EXIPRED_LBL : resources.ERROR_OCCURED_LBL}
                    </h1>
                    <p class="Main Description">
                        <Button className="my-4 py-2 px-8 text-black"
                            onClick={()=> {!isExceptionalError ? close() : retry()}} 
                            label={projectDataError?.response?.data?.name == 'TokenExpiredError' ? 'Reload' : 'Try again!'} />
                        {projectDataError?.response?.data?.name != 'TokenExpiredError' && 
                        <p className="text-center md:text-base text-xl md:leading-21 leading-6">
                            {resources.ERROR_MSG_TEXT}
                        </p>}
                    </p>   
                </div>
            </div>
        // </div>
        // <div className='mt-10'>
        //     <div className='flex flex-col justify-center items-center'>
        //         <img alt="menu-img" onClick={() => close()} src={require('../../assets/error-screen-img.png')}  /> <br />
        //         <p className='font-bold text-lg'>Something went wrong...!</p> <br />
        //         <Button className="my-4 p-3"
        //             onClick={()=> {!isExceptionalError ? close() : retry()}} 
        //             label='Try again!' />
        //         <p className="text-center md:text-base text-xl md:leading-21 leading-6">
        //             If you still experience issues, Please contact administrator
        //         </p>
        //     </div>
        // </div>
    )
}

export default CommonError
