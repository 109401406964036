import React from 'react'

const icon = ({ className, fill, onClick }) => 
(
    <svg className={className} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_d_258_229)">
		<circle cx="15" cy="15" r="8" fill="url(#paint0_radial_258_229)"/>
		<circle cx="15" cy="15" r="8" fill="url(#paint1_radial_258_229)"/>
		<circle cx="15" cy="15" r="8.5" stroke="#65FFC7"/>
		</g>
		<defs>
		<filter id="filter0_d_258_229" x="0" y="0" width="30" height="30" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
		<feFlood flood-opacity="0" result="BackgroundImageFix"/>
		<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
		<feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_258_229"/>
		<feOffset/>
		<feGaussianBlur stdDeviation="2"/>
		<feComposite in2="hardAlpha" operator="out"/>
		<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.905882 0 0 0 0 0.631373 0 0 0 0.3 0"/>
		<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_258_229"/>
		<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_258_229" result="shape"/>
		</filter>
		<radialGradient id="paint0_radial_258_229" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15 15) rotate(90) scale(8)">
		<stop stop-color="#00E7A1"/>
		<stop offset="1" stop-color="#00B574"/>
		</radialGradient>
		<radialGradient id="paint1_radial_258_229" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.5 10.5) rotate(59.0362) scale(5.83095)">
		<stop stop-color="#92FEDE"/>
		<stop offset="1" stop-color="#92FEDE" stop-opacity="0"/>
		</radialGradient>
		</defs>
	</svg>
)
export default icon


