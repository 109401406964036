import React from 'react'

const icon = ({ className, fill, onClick }) => 
(
    <svg className={className} onClick={onClick} width="160" height="52" viewBox="0 0 182 62" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M160.318 3.76138C158.707 1.7028 156.238 0.5 153.624 0.5H8.99999C4.30557 0.5 0.5 4.30558 0.5 9V53C0.5 57.6944 4.30558 61.5 9 61.5H153.624C156.238 61.5 158.707 60.2972 160.318 58.2386L180.429 32.5408C181.137 31.6357 181.137 30.3643 180.429 29.4592L160.318 3.76138Z" fill="url(#paint0_linear_728_32425)" stroke="#A839F3"/>
        <defs>
        <linearGradient id="paint0_linear_728_32425" x1="1" y1="31" x2="181" y2="31" gradientUnits="userSpaceOnUse">
        <stop stop-color="#8223D2"/>
        <stop offset="0.4375" stop-color="#9E11D3"/>
        <stop offset="1" stop-color="#D648FD"/>
        </linearGradient>
        </defs>
    </svg>

)
export default icon


