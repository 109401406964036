import React from "react";

const icon = ({ className, fill, onClick, stroke }) => (
  <svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.1665 19.1668C14.1665 20.0873 14.9127 20.8335 15.8332 20.8335H19.1665V26.6668L24.9998 20.8335H37.4998C38.4203 20.8335 39.1665 20.0873 39.1665 19.1668V2.50016C39.1665 1.57969 38.4203 0.833496 37.4998 0.833496H15.8332C14.9127 0.833496 14.1665 1.57969 14.1665 2.50016V19.1668Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.83325 20.8335C7.90432 20.8335 9.58325 19.1546 9.58325 17.0835C9.58325 15.0124 7.90432 13.3335 5.83325 13.3335C3.76218 13.3335 2.08325 15.0124 2.08325 17.0835C2.08325 19.1546 3.76218 20.8335 5.83325 20.8335Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.83325 22.5C3.07183 22.5 0.833252 24.7386 0.833252 27.5V30.8333H3.33325L4.16659 39.1667H7.49992L8.33325 30.8333H10.8333V27.5C10.8333 24.7386 8.59468 22.5 5.83325 22.5Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M27.4999 15.8335C27.73 15.8335 27.9166 16.02 27.9166 16.2502C27.9166 16.4803 27.73 16.6668 27.4999 16.6668C27.2698 16.6668 27.0833 16.4803 27.0833 16.2502C27.0833 16.02 27.2698 15.8335 27.4999 15.8335" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25 8.3335C25 6.95278 26.1193 5.8335 27.5 5.8335C28.8807 5.8335 30 6.95278 30 8.3335C30 9.71421 28.8807 10.8335 27.5 10.8335V12.5002" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
);
export default icon;
