import React from "react";

const icon = ({ className, fill, onClick, stroke }) => (
  <svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.8332 29.9998V23.3332C30.8332 22.4127 30.087 21.6665 29.1665 21.6665H15.8332C14.9127 21.6665 14.1665 22.4127 14.1665 23.3332V34.9998H15.8332" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M30.8333 33.3332V24.1665H34.1666C36.928 24.1665 39.1666 26.4051 39.1666 29.1665V33.3332C39.1666 34.2536 38.4204 34.9998 37.4999 34.9998H33.3333" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M39.1665 29.9998H35.8332C34.9127 29.9998 34.1665 29.2536 34.1665 28.3332V24.1665" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.8333 38.333C32.214 38.333 33.3333 37.2137 33.3333 35.833C33.3333 34.4523 32.214 33.333 30.8333 33.333C29.4525 33.333 28.3333 34.4523 28.3333 35.833C28.3333 37.2137 29.4525 38.333 30.8333 38.333Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.3333 38.333C19.714 38.333 20.8333 37.2137 20.8333 35.833C20.8333 34.4523 19.714 33.333 18.3333 33.333C16.9525 33.333 15.8333 34.4523 15.8333 35.833C15.8333 37.2137 16.9525 38.333 18.3333 38.333Z" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M28.3284 35H20.8284" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M10.8333 26.6665H2.49992C1.57944 26.6665 0.833252 25.9203 0.833252 24.9998V9.02317C0.833346 8.58118 1.009 8.15732 1.32159 7.84484L7.01159 2.15484C7.32407 1.84225 7.74793 1.6666 8.18992 1.6665H16.8099C17.2519 1.6666 17.6758 1.84225 17.9883 2.15484L23.6783 7.84484C23.9908 8.15732 24.1665 8.58118 24.1666 9.02317V11.6665" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.5 1.6665V8.33317" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24.0165 8.33301H0.983154" stroke={stroke} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

);
export default icon;
