import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { useCentralStore } from "../../store/appContext";
import "./tableStyle.css";

const SlaReport = () => {
  const [{ slaReportCountSuccess }, dispatch] = useCentralStore();
  const [deviceRepairPlan, setDeviceRepairPlan] = useState();

  let CustomerServiceSLA =
    slaReportCountSuccess?.GetSLADetailsResponse?.CustomerServiceSLA;

  let deviceRepairCategory = CustomerServiceSLA?.filter(
    (node) => node.Category === "Device Repair"
  );
  let TechnicalSLA = slaReportCountSuccess?.GetSLADetailsResponse?.TechnicalSLA;

  useEffect(() => {
    // if(!rowData.some(item => item?.Category === "Device Repair")) {
    //   Array.isArray(TechnicalSLA) && deviceRepairCategory && TechnicalSLA.unshift(deviceRepairCategory[0])
    // }
    setDeviceRepairPlan(rowData);
  }, [TechnicalSLA, deviceRepairCategory]);

  let technicalSLAData =
    Array.isArray(TechnicalSLA) &&
    TechnicalSLA?.map((item) => {
      const {
        "ExpectedSLA%": expectedSlaPercentage,
        "ActualSLA%": actualSlaPercentage,
        ...rest
      } = item;
      return {
        ...rest,
        category: item?.Category,
        expectedSla: expectedSlaPercentage,
        actualSla: actualSlaPercentage,
        rebate:
          actualSlaPercentage > 0 && actualSlaPercentage < expectedSlaPercentage
            ?  Math.ceil(
              ((expectedSlaPercentage - actualSlaPercentage)/100) * 0.65 * 4140
             )
            : 0,
      };
    });

  let rowData = CustomerServiceSLA?.filter(
    (node) => node.Category !== "Delivery" &&
     node.Category !== "Acceptance/ Rejection of Service Requests with communication to Subscriber" &&
     node.Category !== "Customer complaints management" &&
     node.Category !== "Rejection rate" 
  )?.map((item) => {
    const {
      "ExpectedSLA%": expectedSlaPercentage,
      "ActualSLA%": actualSlaPercentage,
      ...rest
    } = item;
    return {
      ...rest,
      category: item?.Category,
      expectedSla: expectedSlaPercentage,
      actualSla: actualSlaPercentage,
      rebate:
        actualSlaPercentage > 0 && actualSlaPercentage < expectedSlaPercentage
          ?  Math.ceil(
            ((expectedSlaPercentage - actualSlaPercentage)/100) * 0.65 * 4140
           )
          : 0,
    };
  });

  const customerSla = CustomerServiceSLA?.filter(
    (node) => node.Category !== "Device Repair"
  )?.map((item) => {
    const {
      "ExpectedSLA%": expectedSlaPercentage,
      "ActualSLA%": actualSlaPercentage,
      ...rest
    } = item;
    return {
      ...rest,
      category: item?.Category,
      expectedSla: expectedSlaPercentage,
      actualSla: actualSlaPercentage,
      rebate:
        actualSlaPercentage > 0 && actualSlaPercentage < expectedSlaPercentage
          ? Math.ceil(
             ((expectedSlaPercentage - actualSlaPercentage)/100) * 0.65 * 4140
            )
          : 0,
    };
  });


  let tableColumns = [
    {
      title: "CATEGORY",
      dataIndex: "category",
      key: "category",
      align: "center",
      width: 300,
    },
    {
      title: "EXPECTED SLA%",
      dataIndex: "expectedSla",
      key: "expectedSla",
      align: "center",
      width: 150,
    },
    {
      title: "ACTUAL SLA%",
      dataIndex: "actualSla",
      key: "actualSla",
      align: "center",
      width: 150,
    },
    {
      title: "$ REBATE",
      dataIndex: "rebate",
      key: "rebate",
      align: "center",
      width: 150,
    },
  ];

  const rowClassName = (record, index) => {
    if (record.rebate > 0) {
      return "amber-row";
    }
    return "";
  };

  return (
    <div className="py-3 mx-auto mt-6">
      {/* <p className="flex flex-row items-center justify-center mt-5 mb-4 font-bold text-black">
        Device Protection Plan
      </p> */}
      <div className="flex flex-row items-center justify-center mb-3 mx-7">
        <Table
          columns={tableColumns}
          dataSource={customerSla}
          rowKey="data"
          key="data"
          pagination={false}
          className="mb-6 border-current border-white table-css"
          locale={{ emptyText: "No data" }}
          rowClassName={rowClassName}
        />
      </div>
      <p className="flex flex-row items-center justify-center mb-4 font-bold text-black">
        Device Repair SLA
      </p>
      <div className="flex flex-row items-center justify-center mb-3 mx-7">
        <Table
          columns={tableColumns}
          dataSource={deviceRepairPlan}
          rowKey="data"
          key="data"
          pagination={false}
          className="mb-6 border-current border-white table-css"
          locale={{ emptyText: "No data" }}
          rowClassName={rowClassName}
        />
      </div>
      <p className="flex flex-row items-center justify-center mb-4 font-bold text-black">
        Technical SLA
      </p>
      <div className="flex flex-row items-center justify-center mb-3 mx-7">
        <Table
          columns={tableColumns}
          dataSource={technicalSLAData}
          rowKey="data"
          key="data"
          pagination={false}
          className="mb-10 border-current border-white table-css"
          locale={{ emptyText: "No data" }}
          rowClassName={rowClassName}
        />
      </div>
    </div>
  );
};

export default SlaReport;
